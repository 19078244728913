import React, { useState } from 'react';
import { TextInputProps } from 'react-native';

import {
	BUTTON_ROUNDED_SIZES,
	ButtonRoundedView,
	IButtonRoundedStyles,
} from '../buttons/button-rounded.view';
import { COLOR_CODES } from '../enums/colors';
import { ICON_CODES } from '../texts/text-icon.view';
import {
	ALIGN,
	DIRECTION,
	WrapperAlignView,
} from '../wrappers/wrapper-align.view';
import {
	extractWrapperProps,
	IWrapperStyles,
} from '../wrappers/wrapper-interfaces';
import { WrapperView } from '../wrappers/wrapper.view';
import {
	extractTextfieldProps,
	ITextfieldStyles,
	TextfieldView,
} from './textfield.view';

const SIZE = BUTTON_ROUNDED_SIZES.md;

export interface ITextfieldSubmitStyles extends IWrapperStyles {
	textfield: ITextfieldStyles;
	button: IButtonRoundedStyles;
}

export interface ITextfieldSubmitProps {
	styles?: ITextfieldSubmitStyles;
	onChange?: (text: string) => void;
	onSubmit?: (text: string) => Promise<void>;
	onFocus?: () => void;
	nativeTextInputProps?: TextInputProps;
	color?: COLOR_CODES;
	maxLines?: number;
	autoHeight?: boolean;
	clear?: boolean;
}

export interface ITextfieldSubmitState {
	value: string;
}

let textfieldHandler;
let waiting = false;

export const TextfieldSubmitView = (props: ITextfieldSubmitProps) => {
	const defaultValue =
		(props.nativeTextInputProps &&
			props.nativeTextInputProps.defaultValue) ||
		'';

	const [state, setState] = useState<ITextfieldSubmitState>({
		value: defaultValue,
	});

	const handleChange = (value: string) => {
		props.onChange && props.onChange(value);
		state.value = value;
		setState({ ...state });
	};

	const handleTextfieldSubmit = async (value: string): Promise<void> => {
		if (!waiting) {
			if (props.clear) {
				textfieldHandler.clear();
				state.value = '';
				setState({ ...state });
			}
			if (value.length > 0 && props.onSubmit) {
				waiting = true;
				await props.onSubmit(value);
				waiting = false;
			}
		}
		return Promise.resolve();
	};

	const handleButtonSubmit = async (): Promise<void> => {
		return handleTextfieldSubmit(state.value);
	};

	const getButtonSubmitHandler =
		(props.onSubmit &&
			state.value &&
			state.value.trim().length > 0 &&
			handleButtonSubmit) ||
		undefined;

	const textfieldProps = extractTextfieldProps(props);
	const wrapperProps = extractWrapperProps(props);

	return (
		<WrapperView
			styles={{
				wrapper: {
					backgroundColor: COLOR_CODES.White,
					position: 'relative',
					borderTopWidth: 1,
					borderTopColor: COLOR_CODES.Grey10,
					borderRightWidth: 1,
					borderRightColor: COLOR_CODES.Grey10,
					borderLeftWidth: 1,
					borderLeftColor: COLOR_CODES.Grey10,
					borderTopRightRadius: 18,
					borderTopLeftRadius: 18,
					...props.styles?.wrapper,
				},
			}}
			wrapperTop={6}
			wrapperBottom={6}
		>
			<WrapperAlignView
				itemsDirection={DIRECTION.Horizontal}
				itemsAlign={ALIGN.Center}
				wrapperLeft={10}
				wrapperRight={5}
			>
				<TextfieldView
					{...wrapperProps}
					{...textfieldProps}
					onChange={handleChange}
					onSubmit={handleTextfieldSubmit}
					wrapperLeft={10}
					wrapperRight={10}
					styles={{
						...props.styles?.textfield,
						wrapper: {
							paddingTop: 5,
							paddingBottom: 5,
							backgroundColor: COLOR_CODES.White,
							flex: 1,
							borderTopColor: COLOR_CODES.Grey25,
							borderTopWidth: 1,
							borderBottomColor: COLOR_CODES.Grey25,
							borderBottomWidth: 1,
							borderRightColor: COLOR_CODES.Grey25,
							borderRightWidth: 1,
							borderLeftColor: COLOR_CODES.Grey25,
							borderLeftWidth: 1,
							height:
								props.maxLines && props.maxLines > 1
									? 'auto'
									: SIZE * 2,
							minHeight: SIZE * 2,
							borderRadius: SIZE,
							...props.styles?.textfield?.wrapper,
						},
						textfield: {
							...props.styles?.textfield?.textfield,
						},
					}}
					handler={handler => {
						textfieldHandler = handler;
					}}
				/>
				<ButtonRoundedView
					size={SIZE}
					icon={ICON_CODES.ArrowUp}
					wrapperLeft={5}
					styles={props.styles?.button}
					onClick={getButtonSubmitHandler}
				/>
			</WrapperAlignView>
		</WrapperView>
	);
};
