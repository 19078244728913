import React from 'react';
import { ICON_CODES } from '../texts/text-icon.view';
import { ALIGN, WrapperAlignView } from '../wrappers/wrapper-align.view';
import { ButtonPrimaryView } from '../buttons/button-primary.view';
import { useWindowDimensions } from 'react-native';

interface IPaginationCourseProps {
	left: {
		onClick?: () => void;
		hidden: boolean;
		label: string;
		iconBefore?: ICON_CODES;
		iconAfter?: ICON_CODES;
	};
	right: {
		onClick?: () => void;
		hidden: boolean;
		label: string;
		iconBefore?: ICON_CODES;
		iconAfter?: ICON_CODES;
	};
}
export const PaginationCourseView = (props: IPaginationCourseProps) => {
	const windowWidth = useWindowDimensions().width;

	return (
		<WrapperAlignView
			height={65}
			wrapperLeft={20}
			wrapperRight={20}
			itemsAlign={ALIGN.Start}
			styles={{ wrapper: { maxWidth: windowWidth } }}
		>
			<ButtonPrimaryView
				rounded={true}
				animated={true}
				label={props.left.label}
				onClick={props.left.onClick}
				hidden={props.left.hidden}
				iconBefore={props.left.iconBefore}
				iconAfter={props.left.iconAfter}
				styles={
					(props.left.hidden && {
						wrapper: {
							position: 'absolute',
							left: 0,
							top: 0,
						},
					}) ||
					undefined
				}
			/>

			<ButtonPrimaryView
				rounded={true}
				animated={true}
				label={props.right.label}
				onClick={props.right.onClick}
				hidden={props.right.hidden}
				iconBefore={props.right.iconBefore}
				iconAfter={props.right.iconAfter}
				styles={
					(props.right.hidden && {
						wrapper: {
							position: 'absolute',
							right: 0,
							top: 0,
						},
					}) ||
					undefined
				}
			/>
		</WrapperAlignView>
	);
};
