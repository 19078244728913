import React from 'react';
import { Table, Rows } from '../../fallbacks/table-component.view';

export interface IHtmlTableProps {
	data: string[][];
}

export const HtmlTableView = (props: IHtmlTableProps) => {
	return (
		<Table>
			<Rows data={props.data} />
		</Table>
	);
};
