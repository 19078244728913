import {
	COLOR_CODES,
	INativeProps,
	IWrapperAlignProps,
	TextSubheadlineDefaultView,
	WrapperAlignView,
} from '@nui/views';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import styled from 'styled-components';

const StyledSVG = styled.svg`
	fill: none;
	stroke: white;
	stroke-width: 2px;
	position: absolute;
	left: 1px;
	top: 7px;
`;

const Checkmark = () => {
	return (
		<StyledSVG height="15" width="15" viewBox="0 0 24 24">
			<polyline points="20 6 9 17 4 12" />
		</StyledSVG>
	);
};

const CheckboxPlain = styled(props => {
	return <Input {...props} type="checkbox" />;
})`
		appearance: none;
		height: 16px;
		width: 16px;
		border: 1px solid;
		border-color: ${COLOR_CODES.SteelGrey}
		border-radius: 5px;
		outline: none;
		cursor: pointer;
		background-color: ${COLOR_CODES.White};
		&:checked {
			border-color: ${COLOR_CODES.Pink}
			background-color: ${COLOR_CODES.Pink};
		}
	`;

const CheckboxWithCheckmark = styled(props => {
	return (
		<div>
			<CheckboxPlain {...props} />
			<Checkmark />
		</div>
	);
})``;

interface IFormCheckboxProps extends IWrapperAlignProps {
	checked?: boolean;
	onChange?: (checked: boolean) => void;
	disabled?: boolean;
}

export const FormCheckboxView = (props: IFormCheckboxProps & INativeProps) => {
	const { onChange, checked, disabled, ...wrapperProps } = props;

	return (
		<WrapperAlignView {...wrapperProps}>
			<FormGroup check disabled={disabled}>
				<Label check>
					<CheckboxWithCheckmark
						checked={checked}
						onChange={onChange}
					/>
					<TextSubheadlineDefaultView wrapperLeft={10}>
						{props.children}
					</TextSubheadlineDefaultView>
				</Label>
			</FormGroup>
		</WrapperAlignView>
	);
};
