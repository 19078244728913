import XDate from 'xdate';
import { FirebaseAuthProvider } from '@nui/providers';
import { ConvertersUtil } from '@nui/utils';

import {
	FirestoreTeamMembersModel,
	IFirestoreTeamMembers,
} from './firestore-team-members.model';
import {
	FirestoreTeamPaymentsModel,
	IFirestoreTeamPayments,
} from './firestore-team-payments.model';
import {
	FirestoreTeamSeniorModel,
	IFirestoreTeamSenior,
} from './firestore-team-senior.model';

const config = require('../../../../../env-config.json');

export interface IFirestoreTeam {
	created: XDate;
	updated: XDate;
	ownerId: string;
	members: IFirestoreTeamMembers;
	payments?: IFirestoreTeamPayments;
	senior: IFirestoreTeamSenior;
}

export class FirestoreBlankTeamModel {
	protected seniorFirstName?: string;

	setSeniorFirstName(firstName: string) {
		this.seniorFirstName = firstName;
	}

	getSeniorFirstName() {
		return this.seniorFirstName;
	}

	hasSeniorFirstName() {
		return this.seniorFirstName && this.seniorFirstName !== '';
	}

	async save() {
		if (this.getSeniorFirstName()) {
			try {
				const idToken = await FirebaseAuthProvider.getIdToken();
				await fetch(config.cloudFunctionBaseURL + 'createTeam', {
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${idToken}`,
					},
					body: JSON.stringify({
						seniorName: this.getSeniorFirstName(),
					}),
				});

				// Refresh local id token
				await FirebaseAuthProvider.getIdToken();
				return Promise.resolve();
			} catch (error) {
				return Promise.reject(error);
			}
		} else {
			Promise.reject();
		}
	}
}
export class FirestoreTeamModel {
	static toTeam(
		userId: string,
		teamDTO: any,
		defaultXDate = new XDate(),
	): IFirestoreTeam | undefined {
		const members = FirestoreTeamMembersModel.toTeamMembers(
			userId,
			teamDTO?.members,
		);
		/* team is invalid, when userId is not member of team */
		if (members) {
			return {
				created:
					ConvertersUtil.toXDate(teamDTO?.created, defaultXDate) ||
					defaultXDate,
				updated:
					ConvertersUtil.toXDate(teamDTO?.updated, defaultXDate) ||
					defaultXDate,
				ownerId: ConvertersUtil.toString(teamDTO?.ownerId) || userId,
				members,
				payments: FirestoreTeamPaymentsModel.toTeamPayments(
					teamDTO.payments,
				),
				senior: FirestoreTeamSeniorModel.toSenior(teamDTO.senior),
			};
		} else {
			return undefined;
		}
	}
}
