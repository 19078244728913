import { useImageURI } from '@nui/hooks';
import React from 'react';
import { ImageURISource } from 'react-native';
import FastImage from 'react-native-fast-image-xp';

import { IStyles } from '../../wrappers/wrapper-interfaces';

export interface IHtmlImgProps {
	image: () => Promise<ImageURISource> | ImageURISource;
	extraStyles?: IStyles;
}

export const HtmlImgView = (props: IHtmlImgProps) => {
	const imageURL = useImageURI(props.image);

	return (
		(imageURL && (
			<FastImage
				style={{
					...props.extraStyles,
				}}
				resizeMode="cover"
				source={{ uri: imageURL.uri }}
			/>
		)) ||
		null
	);
};
