import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { COLOR_CODES } from '../enums/colors';
import { IBadgeSmallProps } from '../misc/badge-small.view';
import { TextBodyLargeDefaultView } from '../texts/text-body-large-default.view';
import { TextEntryStateSemiboldView } from '../texts/text-entryState-semibold.view';
import { ICON_CODES, ICON_SIZES, TextIconView } from '../texts/text-icon.view';
import { INativeProps, IWrapperStyles } from '../wrappers/wrapper-interfaces';
import { ButtonView, IButtonProps } from './button.view';

export interface IButtonPlainStyles extends IWrapperStyles {
	container?: object;
	label?: object;
	icon?: object;
}
export interface IButtonPlainProps extends IButtonProps {
	label?: string;
	/** Icon from enum ICON_CODES */
	iconBefore?: ICON_CODES;
	/** Icon from enum ICON_CODES */
	iconAfter?: ICON_CODES;
	styles?: IButtonPlainStyles;
	/** Text/Label/Icon color from enum COLOR_CODES */
	color?: COLOR_CODES;
	/** Size from enum ICON_SIZES */
	size?: ICON_SIZES;
	smallFont?: boolean;
	badge?: IBadgeSmallProps;
}
export const ButtonPlainContainer = styled(
	(props: IButtonPlainProps & INativeProps) => (
		<View style={props.style}>{props.children}</View>
	),
)`
	flex-direction: row;
	align-items: center;
	${props => ({
		...((props.styles && props.styles.container) || {}),
	})};
`;

export const ButtonPlainView = (props: IButtonPlainProps) => {
	return (
		<ButtonView {...props}>
			<ButtonPlainContainer {...props}>
				{(props.iconBefore && (
					<TextIconView
						color={props.color || COLOR_CODES.Black}
						icon={props.iconBefore}
						size={props.size}
						wrapperRight={(props.label && 10) || 0}
						styles={{
							text: (props.styles && props.styles.icon) || {},
						}}
						badge={props.badge}
					/>
				)) ||
					null}
				{(props.label && props.smallFont && (
					<TextEntryStateSemiboldView
						styles={{
							text: (props.styles && props.styles.label) || {},
						}}
					>
						{props.label}
					</TextEntryStateSemiboldView>
				)) ||
					(props.label && (
						<TextBodyLargeDefaultView
							color={props.color || COLOR_CODES.Black}
							styles={{
								text:
									(props.styles && props.styles.label) || {},
							}}
						>
							{props.label}
						</TextBodyLargeDefaultView>
					)) ||
					null}
				{(props.iconAfter && (
					<TextIconView
						color={props.color || COLOR_CODES.Black}
						icon={props.iconAfter}
						size={props.size}
						wrapperLeft={(props.label && 10) || 0}
						styles={{
							text: (props.styles && props.styles.icon) || {},
						}}
						badge={props.badge}
					/>
				)) ||
					null}
			</ButtonPlainContainer>
		</ButtonView>
	);
};
