import React from 'react';
import styled from 'styled-components/native';
import { INativeProps } from '../wrappers/wrapper-interfaces';
import { ITextProps } from './text-interfaces';
import { TextView } from './text.view';

export const TextFootnoteXsmallView = styled(
	(props: ITextProps & INativeProps) => <TextView {...props} />,
)`
	font-size: 12;
	letter-spacing: -0.39;
	line-height: 20;
	${(props: ITextProps) => ({
		...props.styles?.text,
	})};
`;
