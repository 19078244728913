import { useImageURI } from '@nui/hooks';
import { IHTMLChunks, IParagraph } from '@nui/utils';
import React from 'react';
import { ImageURISource } from 'react-native';
import FastImage from 'react-native-fast-image-xp';
import styled from 'styled-components/native';

import { ButtonView, IButtonProps } from '../buttons/button.view';
import { COLOR_CODES } from '../enums/colors';
import { TextBodySemiboldLeftView } from '../texts/text-body-semibold-left.view';
import { TextEntryStateSemiboldView } from '../texts/text-entryState-semibold.view';
import {
	ALIGN,
	DIRECTION,
	JUSTIFY,
	WrapperAlignView,
} from '../wrappers/wrapper-align.view';
import {
	extractWrapperProps,
	IWrapperProps,
} from '../wrappers/wrapper-interfaces';
import { WrapperView } from '../wrappers/wrapper.view';

const CORNER_RADIUS = 9;
const CARD_WIDTH = 218;
const IMAGE_HEIGHT = 140;

export interface ISmallCardProps extends IButtonProps {
	image: () => Promise<ImageURISource> | ImageURISource;
	headline: string;
	metaInfo: IParagraph[];
	onCardClick: () => void;
}

const CardWrapperView = styled((props: IWrapperProps) => (
	<WrapperView {...props} />
))`
	width: ${CARD_WIDTH};
	border-radius: ${CORNER_RADIUS};
	background-color: ${COLOR_CODES.White};
	shadow-color: ${COLOR_CODES.Black};
	shadow-offset: 0px 2px;
	shadow-opacity: 0.15;
	shadow-radius: 4;
	elevation: 2;
	${(props: IWrapperProps) => ({
		...props.styles?.wrapper,
	})};
`;

export const SmallCardView = (props: ISmallCardProps) => {
	const { image, headline, metaInfo, onCardClick } = props;
	const wrapperProps = extractWrapperProps(props);
	const imageURL = useImageURI(image);

	return (
		<CardWrapperView {...wrapperProps}>
			<ButtonView
				onClick={onCardClick}
				nativeTouchableOpacityProps={{ delayPressIn: 60 }}
			>
				<WrapperView height={IMAGE_HEIGHT} width={CARD_WIDTH}>
					{imageURL && (
						<FastImage
							source={{ uri: imageURL.uri }}
							style={{
								flex: 1,
								borderTopLeftRadius: CORNER_RADIUS,
								borderTopRightRadius: CORNER_RADIUS,
							}}
							resizeMode="cover"
						/>
					)}
				</WrapperView>
				<WrapperView
					wrapper={10}
					wrapperTop={0}
					styles={{
						wrapper: {
							justifyContent: 'flex-start',
						},
					}}
				>
					<WrapperAlignView
						itemsAlign={ALIGN.Start}
						itemsJustify={JUSTIFY.Start}
						itemsDirection={DIRECTION.Vertical}
						height={65}
					>
						<TextBodySemiboldLeftView wrapperBottom={5}>
							{headline}
						</TextBodySemiboldLeftView>
						<WrapperAlignView itemsJustify={JUSTIFY.Start}>
							{metaInfo.map((paragraph, index) => {
								const separator: IHTMLChunks = [];
								if (index < metaInfo.length - 1) {
									separator.push({
										span: {
											text: ' • ',
										},
									});
								}
								return (
									<TextEntryStateSemiboldView
										key={index}
										htmlChunks={paragraph.chunks.concat(
											separator,
										)}
									/>
								);
							})}
						</WrapperAlignView>
					</WrapperAlignView>
				</WrapperView>
			</ButtonView>
		</CardWrapperView>
	);
};
