import { email, password, username } from './regexp-patterns.util';
import { HelpersUtil } from './helpers.util';

export class DataScrubberUtil {
	public static redactPII(input: any) {
		const output = HelpersUtil.clone(input);
		for (let key in output) {
			if (DataScrubberUtil.testForPII(key)) {
				output[key] = '****';
			}
		}
		return output;
	}

	private static testForPII(input: string): boolean {
		const containsUsername = username.test(input);
		const containsPassword = password.test(input);
		const containsEmail = email.test(input);
		return containsPassword || containsUsername || containsEmail;
	}
}
