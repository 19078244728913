import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { IWrapperProps, INativeProps } from './wrapper-interfaces';
import { COLOR_CODES } from '../enums/colors';

const getPaddingTop = (props: IWrapperProps) => {
	const { padding } = props;
	if (padding?.length === 1) {
		return padding[0];
	} else if (padding?.length === 2) {
		return padding[0];
	} else if (padding?.length === 4) {
		return padding[0];
	} else return 0;
};

const getPaddingBottom = (props: IWrapperProps) => {
	const { padding } = props;
	if (padding?.length === 1) {
		return padding[0];
	} else if (padding?.length === 2) {
		return padding[0];
	} else if (padding?.length === 4) {
		return padding[2];
	} else return 0;
};

const getPaddingRight = (props: IWrapperProps) => {
	const { padding } = props;
	if (padding?.length === 1) {
		return padding[0];
	} else if (padding?.length === 2) {
		return padding[1];
	} else if (padding?.length === 4) {
		return padding[1];
	} else return 0;
};

const getPaddingLeft = (props: IWrapperProps) => {
	const { padding } = props;
	if (padding?.length === 1) {
		return padding[0];
	} else if (padding?.length === 2) {
		return padding[1];
	} else if (padding?.length === 4) {
		return padding[3];
	} else return 0;
};

export const WrapperView = styled((props: IWrapperProps & INativeProps) => (
	<View {...props} />
))`
	height: ${(props: IWrapperProps) => props.height || 'auto'};
	min-height: ${(props: IWrapperProps) => props.minHeight || 'auto'};
	width: ${(props: IWrapperProps) => props.width || 'auto'};
	${(props: IWrapperProps) => (props.width == 'auto' ? 'flex:1' : undefined)};
	justify-content: center;
	background-color: ${(props: IWrapperProps) =>
		props.background || COLOR_CODES.Transparent};
	padding-top: ${(props: IWrapperProps) =>
		getPaddingTop(props) ||
		(props.wrapperTop === 0 ? 0 : props.wrapperTop) ||
		props.wrapper ||
		0};
	padding-right: ${(props: IWrapperProps) =>
		getPaddingRight(props) ||
		(props.wrapperRight === 0 ? 0 : props.wrapperRight) ||
		props.wrapper ||
		0};
	padding-bottom: ${(props: IWrapperProps) =>
		getPaddingBottom(props) ||
		(props.wrapperBottom === 0 ? 0 : props.wrapperBottom) ||
		props.wrapper ||
		0};
	padding-left: ${(props: IWrapperProps) =>
		getPaddingLeft(props) ||
		(props.wrapperLeft === 0 ? 0 : props.wrapperLeft) ||
		props.wrapper ||
		0};
	border-width: ${(props: IWrapperProps) =>
		props.borderTop === 'none'
			? 0
			: (props.borderTop && (props.borderWidth || 1)) ||
			  (props.border && (props.borderWidth || 1)) ||
			  0};
	border-right-width: ${(props: IWrapperProps) =>
		props.borderRight === 'none'
			? 0
			: (props.borderRight && (props.borderWidth || 1)) ||
			  (props.border && (props.borderWidth || 1)) ||
			  0};
	border-bottom-width: ${(props: IWrapperProps) =>
		props.borderBottom === 'none'
			? 0
			: (props.borderBottom && (props.borderWidth || 1)) ||
			  (props.border && (props.borderWidth || 1)) ||
			  0};
	border-left-width: ${(props: IWrapperProps) =>
		props.borderLeft === 'none'
			? 0
			: (props.borderLeft && (props.borderWidth || 1)) ||
			  (props.border && (props.borderWidth || 1)) ||
			  0};
	border-top-color: ${(props: IWrapperProps) =>
		props.borderTop === 'none'
			? COLOR_CODES.Transparent
			: props.borderTop || props.border || COLOR_CODES.Transparent};
	border-right-color: ${(props: IWrapperProps) =>
		props.borderRight === 'none'
			? COLOR_CODES.Transparent
			: props.borderRight || props.border || COLOR_CODES.Transparent};
	border-bottom-color: ${(props: IWrapperProps) =>
		props.borderBottom === 'none'
			? COLOR_CODES.Transparent
			: props.borderBottom || props.border || COLOR_CODES.Transparent};
	border-left-color: ${(props: IWrapperProps) =>
		props.borderLeft === 'none'
			? COLOR_CODES.Transparent
			: props.borderLeft || props.border || COLOR_CODES.Transparent};
	${props => ({ ...props.styles?.wrapper })};
`;
