import React from 'react';
import styled from 'styled-components/native';
import { ITextProps, FONT_WEIGHTS } from './text-interfaces';
import { INativeProps } from '../wrappers/wrapper-interfaces';
import { TextView } from './text.view';
import { COLOR_CODES } from '../enums/colors';

export const TextBodySemiboldLeftView = styled(
	(props: ITextProps & INativeProps) => <TextView {...props} />,
)`
	font-size: 17;
	letter-spacing: -0.41;
	line-height: 22;
	color: ${(props: ITextProps) => props.color || COLOR_CODES.Grey75};
	font-weight: ${(props: ITextProps) =>
		props.weight || FONT_WEIGHTS.semiBold};
	${(props: ITextProps) => ({
		...props.styles?.text,
	})};
`;
