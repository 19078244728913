import _ from 'lodash';
import XDate from 'xdate';
import {
	FirestoreUserModel,
	IFirestoreUser,
} from '../firestore/user/firestore-user.model';
import { IState } from './state-interfaces';
import { StateModel } from './state.model';

type topicId = string;
export class StateUserModel {
	protected state: IState;
	protected stateModel: StateModel;
	protected stateHasChanged: boolean;

	constructor(state: IState, stateModel: StateModel) {
		this.state = state;
		this.stateModel = stateModel;
		this.stateHasChanged = true;
	}

	initializeState() {
		if (this.stateHasChanged) {
			this.stateHasChanged = false;
		}
	}

	setUser(user: IFirestoreUser) {
		this.stateHasChanged = true;
		this.state.user = user;
	}

	getUser(): IFirestoreUser | undefined {
		return this.state.user;
	}

	unsetUser() {
		this.stateHasChanged = true;
		this.state.user = undefined;
	}

	getRelevantTopics(courseId: string): topicId[] {
		const sortedTopics = this.stateModel.stateCoursesModel.getSortedTopics(
			courseId,
		);
		return sortedTopics?.map(topic => topic.topicId) || [];
	}

	hasTopicFinished(courseId: string, topicId: string) {
		const inProgressTopics =
			this.state.user?.nui?.courseProgress[courseId] || {};
		const chapterId = inProgressTopics[topicId];
		const chapter = this.state.courses[courseId].topics[topicId].chapters[
			chapterId
		];
		return chapter.progress == 100;
	}

	getLatestTopic() {
		return this.state.user?.nui?.latestTopic;
	}

	hasProgress(courseId) {
		return this.state.user?.nui?.courseProgress[courseId];
	}

	getNextTopic() {
		const latestTopic = this.state.user?.nui?.latestTopic;
		if (latestTopic) {
			const lastestProgress = this.stateModel.stateCoursesModel.getProgress(
				latestTopic.courseId,
				latestTopic.topicId,
			);
			if (lastestProgress.percent == 100) {
				// First find a topic in the same course which is not done
				const primarySortedTopics = this.stateModel.stateCoursesModel.getSortedTopics(
					latestTopic.courseId,
				);
				for (const i in primarySortedTopics) {
					const nextProgress = this.stateModel.stateCoursesModel.getProgress(
						latestTopic.courseId,
						primarySortedTopics[i].topicId,
					);
					if (nextProgress.percent != 100) {
						return {
							courseId: latestTopic.courseId,
							topicId: primarySortedTopics[i].topicId,
						};
					}
				}

				// Second find a topic with the best pregress
				let nearestTopic:
					| {
							percent: number;
							courseId: string;
							topicId: string;
					  }
					| undefined;
				const sortedCourses = this.stateModel.stateCoursesModel.getSortedCourses();
				for (const i in sortedCourses) {
					const courseId = sortedCourses[i].courseId;
					const sortedTopics = this.stateModel.stateCoursesModel.getSortedTopics(
						courseId,
					);
					for (const j in sortedTopics) {
						const topicId = sortedTopics[j].topicId;
						const nearestProgress = this.stateModel.stateCoursesModel.getProgress(
							courseId,
							topicId,
						);
						if (
							nearestProgress.percent != 100 &&
							(!nearestTopic ||
								nearestTopic.percent < nearestProgress.percent)
						) {
							nearestTopic = {
								courseId,
								topicId,
								percent: nearestProgress.percent,
							};
						}
					}
				}
				if (nearestTopic) {
					return {
						courseId: nearestTopic.courseId,
						topicId: nearestTopic.topicId,
					};
				} else {
					return latestTopic;
				}
			} else {
				return latestTopic;
			}
		} else {
			return undefined;
		}
	}

	setProgress(courseId: string, topicId: string, chapterId?: string) {
		const userId = this.state.auth?.uid;
		const user = this.state.user;
		if (userId && user) {
			_.set(
				this,
				'state.user.nui.courseProgress.' + courseId + '.' + topicId,
				chapterId,
			);
		}
	}

	saveProgress(courseId: string, topicId: string, chapterId?: string) {
		this.setProgress(courseId, topicId, chapterId);
		const userId = this.state.auth?.uid;
		const user = this.state.user;
		if (userId && user) {
			FirestoreUserModel.saveProgress(
				userId,
				courseId,
				topicId,
				chapterId,
			);
		}
	}

	saveLastSeenAssistant() {
		if (!this.hasSeenAssistant()) {
			const userId = this.state.auth?.uid;
			if (userId) {
				const latestHistoryCreated = this.stateModel.stateAssistantModel
					.getLatestHistory()
					?.history.created.getTime();
				const latestSeen =
					(latestHistoryCreated && new XDate(latestHistoryCreated)) ||
					new XDate();
				_.set(
					this,
					'state.user.settings.lastSeenAssistant',
					latestSeen,
				);
				return FirestoreUserModel.saveLastSeenAssistant(
					userId,
					latestSeen,
				);
			} else {
				return Promise.reject(
					new Error(
						'Not authorized to save the last viewed assistant',
					),
				);
			}
		} else {
			return Promise.resolve();
		}
	}

	hasSeenAssistant() {
		const latestAssistantSeen = this.state.user?.settings.lastSeenAssistant?.getTime();
		const latestHistoryCreated = this.stateModel.stateAssistantModel
			.getLatestHistory()
			?.history.created.getTime();

		return (
			!latestHistoryCreated ||
			(latestAssistantSeen && latestHistoryCreated <= latestAssistantSeen)
		);
	}
}
