import { FirestoreDocumentProvider } from '@nui/providers';
import { ConvertersUtil, SUPPORTED_LANGUAGE_CODES } from '@nui/utils';
import { firebase } from 'firebase-xp';
import ImageResizer from 'modules/react-native-image-resizer-xp';
import { ImageURISource } from 'react-native';
import XDate from 'xdate';

import {
	FirestoreUserTeamInvitationsModel,
	IFirestoreUserTeamInvitations,
} from './firestore-user-team-invitations.model';

import config from '../../../../../env-config.json';
import Sentry from 'sentry-xp';

export enum FirestoreUserInvitationState {
	ACCEPTED = 'accepted',
	DECLINED = 'declined',
}

export interface IFirestoreUser {
	created: XDate;
	updated: XDate;
	name?: string;
	avatar?: ImageURISource;
	teamInvitations: IFirestoreUserTeamInvitations;
	deviceTokens: string[];
	settings: {
		hasSeenInviteFamily: boolean;
		hasSeenInviteFamilyDashboard: boolean;
		language: SUPPORTED_LANGUAGE_CODES;
		hasSeenCareship: boolean;
		lastSeenAssistant?: XDate;
	};
	nui?: {
		courseProgress: {
			[courseId: string]: {
				[topicId: string]: string;
			};
		};
		latestTopic?: {
			courseId: string;
			topicId: string;
			visited?: boolean;
		};
	};
	activeSkill?: {
		staticInfo?: {
			placeholder: string;
			hideTextfield: boolean;
		};
	};
	lastLogin?: XDate;
}

export class FirestoreBlankUserModel {
	protected name?: string;
	protected avatar?: ImageURISource;

	setName(name: string) {
		this.name = name;
	}

	async setAvatar(avatar?: ImageURISource) {
		if (avatar) {
			if (avatar.uri) {
				try {
					this.avatar = await ImageResizer.createResizedImage(
						avatar.uri,
						240,
						240,
						'JPEG',
						100,
					);
				} catch (error) {
					return Promise.reject(error);
				}
			} else {
				return Promise.reject(new Error('Invalid uri ressource'));
			}
		} else {
			this.avatar = undefined;
		}
		return Promise.resolve(this);
	}

	getName() {
		return this.name;
	}

	getAvatar() {
		return this.avatar;
	}

	hasName() {
		return this.name && this.name !== '';
	}

	async save() {
		if (this.getName()) {
			try {
				const uid = firebase.auth().currentUser?.uid;
				if (uid && this.name) {
					FirestoreUserModel.saveUserProfileAndPicture(
						uid,
						this.name,
						this.avatar,
					);
				}
			} catch (e) {
				Sentry.captureException(e);
			}
		}
	}
}

export class FirestoreUserModel {
	static saveProgress(
		userId: string,
		courseId: string,
		topicId: string,
		chapterId?: string,
	) {
		const docProvider = new FirestoreDocumentProvider(
			firebase.firestore().collection('user-profiles').doc(userId),
		);
		if (chapterId) {
			return docProvider.update({
				[`nui.courseProgress.${courseId}.${topicId}`]: chapterId,
				[`nui.courseProgress.${courseId}.latestTopic`]: topicId,
				[`nui.latestTopic`]: {
					courseId,
					topicId,
					visited: true,
				},
			});
		} else {
			return docProvider.update({
				[`nui.courseProgress.${courseId}.${topicId}`]: 'noProgress',
				[`nui.courseProgress.${courseId}.latestTopic`]: topicId,
				[`nui.latestTopic`]: {
					courseId,
					topicId,
					visited: true,
				},
			});
		}
	}

	static saveUserProfileAndPicture(
		userId: string,
		name: string,
		avatar?: ImageURISource,
	) {
		return firebase.firestore().collection('user-profiles').doc(userId).set(
			{
				avatar,
				name,
			},
			{ merge: true },
		);
	}

	static saveLastSeenAssistant(userId: string, xDate: XDate) {
		const docProvider = new FirestoreDocumentProvider(
			firebase.firestore().collection('user-profiles').doc(userId),
		);
		return docProvider.update({
			[`settings.lastSeenAssistant`]: firebase.firestore.Timestamp.fromDate(
				xDate.toDate(),
			),
		});
	}

	static removeCourseDocuments(userId: string, courseId: string) {
		const docProvider = new FirestoreDocumentProvider(
			firebase.firestore().collection('user-profiles').doc(userId),
		);
		return docProvider.update({
			[`nui.courseProgress.${courseId}`]: firebase.firestore.FieldValue.delete(),
		});
	}

	static async fetchAssistant(body) {
		try {
			const idToken = await firebase.auth().currentUser?.getIdToken();
			const response = await fetch(config.conversationBaseURL, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${idToken}`,
				},
				body: JSON.stringify(body),
			});
			if (response.ok) {
				return Promise.resolve();
			} else {
				return Promise.reject(
					new Error(response.status + ': ' + response.statusText),
				);
			}
		} catch (error) {
			return Promise.reject(error);
		}
	}

	static toUser(userDTO: any, defaultXDate = new XDate()): IFirestoreUser {
		/* Created should be a number, otherwise converted to dafaultDate */
		const created =
			ConvertersUtil.toXDate(userDTO?.created, defaultXDate) ||
			defaultXDate;

		/* Updated should be a number, otherwise converted to dafaultDate */
		const updated =
			ConvertersUtil.toXDate(userDTO?.updated, defaultXDate) ||
			defaultXDate;

		const name = ConvertersUtil.toString(userDTO?.name);

		const teamInvitations = FirestoreUserTeamInvitationsModel.toUserTeamInvitations(
			userDTO?.teamInvitations,
			defaultXDate,
		);

		/* deviceTokens should be an array of strings with at least one character*/
		const deviceTokens =
			ConvertersUtil.toArray<string>(userDTO?.deviceTokens, [], 0, elem =>
				ConvertersUtil.toString(elem, undefined, 1),
			) || [];

		const language =
			ConvertersUtil.toLanguageCode(
				userDTO?.settings?.language,
				SUPPORTED_LANGUAGE_CODES.de,
			) || SUPPORTED_LANGUAGE_CODES.de;

		const settings = {
			hasSeenInviteFamily:
				userDTO?.settings?.hasSeenInviteFamily ?? false,
			hasSeenInviteFamilyDashboard:
				userDTO?.settings?.hasSeenInviteFamilyDashboard ?? false,
			language,
			hasSeenCareship: userDTO?.settings?.hasSeenCareship ?? false,
			lastSeenAssistant: ConvertersUtil.toXDate(
				userDTO?.settings?.lastSeenAssistant,
			),
		};

		const courseId = ConvertersUtil.toString(
			userDTO?.nui?.latestTopic?.courseId,
		);
		const topicId = ConvertersUtil.toString(
			userDTO?.nui?.latestTopic?.topicId,
		);
		const visited = ConvertersUtil.toBool(
			userDTO?.nui?.latestTopic?.visited,
		);

		const nui = {
			courseProgress:
				ConvertersUtil.toHashtable<{
					[topicId: string]: string;
				}>(userDTO?.nui?.courseProgress, {}, 0, courseDTO => {
					return ConvertersUtil.toHashtable<string>(
						courseDTO,
						undefined,
						0,
						chapterId => {
							return ConvertersUtil.toString(chapterId);
						},
					);
				}) || {},
			latestTopic:
				(courseId &&
					topicId && {
						courseId,
						topicId,
						visited,
					}) ||
				undefined,
		};

		const activeSkill =
			userDTO?.activeSkill?.staticInfo?.placeholder &&
			userDTO?.activeSkill?.staticInfo?.hideTextfield != undefined
				? {
						staticInfo: {
							placeholder:
								userDTO.activeSkill?.staticInfo?.placeholder,
							hideTextfield:
								userDTO.activeSkill?.staticInfo?.hideTextfield,
						},
				  }
				: undefined;

		return {
			created,
			updated,
			name,
			avatar: ConvertersUtil.toImageSource(userDTO?.avatar),
			teamInvitations,
			deviceTokens,
			settings,
			nui,
			lastLogin: ConvertersUtil.toXDate(userDTO?.lastLogin),
			activeSkill,
		};
	}
}
