import { ConvertersUtil, IContent } from '@nui/utils';

export interface IFirebaseCourseTopicChapter {
	content: IContent;
	progress: number;
	pos: number;
	title: IContent;
	id: string;
}

export class FirebaseCourseTopicChapterModel {
	static toChapter(chapterDTO: any): IFirebaseCourseTopicChapter | undefined {
		const content = ConvertersUtil.toContent(chapterDTO.content, {
			de: 'unbekannt',
			en: 'unknown',
		});
		const pos = ConvertersUtil.toNumber(chapterDTO.pos);
		const progress = ConvertersUtil.toNumber(chapterDTO.progress);
		const title = ConvertersUtil.toContent(chapterDTO.title, {
			de: 'unbekannt',
			en: 'unknown',
		});
		const id = ConvertersUtil.toString(chapterDTO.id) || 'XXX';

		if (
			content != undefined &&
			pos != undefined &&
			progress != undefined &&
			title != undefined
		) {
			return {
				content,
				progress,
				pos,
				title,
				id,
			};
		} else {
			console.log('Chapter entity is undefined', chapterDTO);
			return undefined;
		}
	}
}
