import { firebase } from 'firebase-xp';
import { ImageURISource } from 'react-native';
import XDate from 'xdate';

import { ConvertersUtil } from '@nui/utils';
import { StorageProvider } from '@nui/providers';
import ImageResizer from 'react-native-image-resizer';
import { IMAGE_DIMENSION } from '../chat/firestore-chat-message.model';

interface IImage {
	small: ImageURISource;
	large: ImageURISource;
}

export enum COMMENT_IMAGE_SIZES {
	Small = 'small',
	Large = 'large',
}
export const COMMENT_IMAGE_DIMENSION = {
	small: {
		maxHeight: 750,
		maxWidth: 750,
		quality: 80,
	},
	large: {
		maxHeight: 2250,
		maxWidth: 2250,
		quality: 100,
	},
};

export interface IFirestoreComment {
	created: XDate;
	updated: XDate;
	ownerId: string;
	text?: string;
	image?: IImage;
	entryId: string;
	teamId: string;
	isDeleted: boolean;
}

export class FirestoreCommentModel {
	static toComment(
		userId: string,
		commentDTO: any,
		defaultXDate = new XDate(),
	): IFirestoreComment | undefined {
		const imageSmall = ConvertersUtil.toImageSource(
			commentDTO?.image,
			ConvertersUtil.toImageSource(
				commentDTO?.image?.small,
				ConvertersUtil.toImageSource(commentDTO?.image?.large),
			),
		);

		const imageLarge = ConvertersUtil.toImageSource(
			commentDTO?.image,
			ConvertersUtil.toImageSource(
				commentDTO?.image?.large,
				ConvertersUtil.toImageSource(commentDTO?.image?.small),
			),
		);

		const text = ConvertersUtil.toString(commentDTO?.text);
		const entryId = ConvertersUtil.toString(commentDTO?.entryId);
		const teamId = ConvertersUtil.toString(commentDTO?.teamId);

		if (!entryId || !teamId) {
			return undefined;
		} else {
			return {
				created:
					ConvertersUtil.toXDate(commentDTO?.created) || defaultXDate,
				updated:
					ConvertersUtil.toXDate(commentDTO?.updated) || defaultXDate,
				ownerId: ConvertersUtil.toString(commentDTO?.ownerId) || userId,
				text,
				image:
					(imageSmall &&
						imageLarge && {
							small: imageSmall,
							large: imageLarge,
						}) ||
					undefined,
				teamId,
				entryId,
				isDeleted: ConvertersUtil.toBool(commentDTO?.isDeleted),
			};
		}
	}

	static async createAndSaveComment(
		userId: string,
		teamId: string,
		entryId: string,
		image?,
		text?: string,
	): Promise<string> {
		const docReference = firebase
			.firestore()
			.collection('teams')
			.doc(teamId)
			.collection('entries')
			.doc(entryId)
			.collection('comments')
			.doc();

		const comment = {
			created: firebase.firestore.Timestamp.fromDate(new Date()),
			updated: firebase.firestore.Timestamp.fromDate(new Date()),
			ownerId: userId,
			entryId,
			teamId,
			isDeleted: false,
		};

		try {
			if (image && image.uri) {
				const smallResizedImage = await ImageResizer.createResizedImage(
					image.uri,
					IMAGE_DIMENSION.small.maxWidth,
					IMAGE_DIMENSION.small.maxHeight,
					'JPEG',
					IMAGE_DIMENSION.small.quality,
				);

				const largeResizedImage = await ImageResizer.createResizedImage(
					image.uri,
					IMAGE_DIMENSION.small.maxWidth,
					IMAGE_DIMENSION.small.maxHeight,
					'JPEG',
					IMAGE_DIMENSION.small.quality,
				);

				// Upload to storage
				const downloadURLSmall = await StorageProvider.uploadImage(
					smallResizedImage,
					'entries/' +
						teamId +
						'/' +
						entryId +
						'/' +
						docReference.id +
						'_small',
				);

				const downloadURLLarge = await StorageProvider.uploadImage(
					largeResizedImage,
					'entries/' +
						teamId +
						'/' +
						entryId +
						'/' +
						docReference.id +
						'_large',
				);

				Object.assign(comment, {
					image:
						(downloadURLSmall &&
							downloadURLLarge && {
								small: downloadURLSmall,
								large: downloadURLLarge,
							}) ||
						undefined,
				});
			} else {
				Object.assign(comment, {
					text,
				});
			}

			await docReference.set(comment);

			return docReference.id;
		} catch (error) {
			return Promise.reject('Some error');
		}
	}
}
