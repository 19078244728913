import { useImageURI } from '@nui/hooks';
import { IHTMLChunks, IParagraph } from '@nui/utils';
import React from 'react';
import { Dimensions, ImageURISource } from 'react-native';
import FastImage from 'react-native-fast-image-xp';
import styled from 'styled-components/native';

import { ButtonPrimaryView } from '../buttons/button-primary.view';
import { ButtonView, IButtonProps } from '../buttons/button.view';
import { COLOR_CODES } from '../enums/colors';
import { TextBodySemiboldLeftView } from '../texts/text-body-semibold-left.view';
import { TextEntryStateSemiboldView } from '../texts/text-entryState-semibold.view';
import { JUSTIFY, WrapperAlignView } from '../wrappers/wrapper-align.view';
import {
	extractWrapperProps,
	INativeProps,
	IWrapperProps,
} from '../wrappers/wrapper-interfaces';
import { WrapperView } from '../wrappers/wrapper.view';

export interface IBigCardProps extends IButtonProps {
	image: () => Promise<ImageURISource> | ImageURISource;
	headline: IParagraph;
	metaInfo: IParagraph[];
	buttonLabel: string;
	onCardClick: () => void;
}
const CORNER_RADIUS = 9;

const CardWrapperView = styled((props: IWrapperProps & INativeProps) => (
	<WrapperView {...props} />
))`
	border-radius: ${CORNER_RADIUS};
	background-color: ${COLOR_CODES.White};
	shadow-color: ${COLOR_CODES.Black};
	shadow-offset: 0px 2px;
	shadow-opacity: 0.15;
	shadow-radius: 4;
	elevation: 2;
	${(props: IWrapperProps) => ({
		...props.styles?.wrapper,
	})};
`;

export const BigCardView = (props: IBigCardProps) => {
	const { image, headline, metaInfo, onCardClick, buttonLabel } = props;

	const wrapperProps = extractWrapperProps(props);
	const imageURL = useImageURI(image);
	// This is to preserve image aspect ratio, when scaling the card horizontally
	const windowWidth = Dimensions.get('window').width;
	const viewWidth = windowWidth > 800 ? 800 : windowWidth;
	const imageHeight = viewWidth * 0.34;

	return (
		<CardWrapperView {...wrapperProps} width={'100%'}>
			<ButtonView
				onClick={onCardClick}
				nativeTouchableOpacityProps={{ delayPressIn: 60 }}
			>
				<WrapperView height={imageHeight}>
					{imageURL && (
						<FastImage
							source={{ uri: imageURL.uri }}
							style={{
								borderTopLeftRadius: CORNER_RADIUS,
								borderTopRightRadius: CORNER_RADIUS,
								flex: 1,
							}}
							resizeMode="cover"
						/>
					)}
				</WrapperView>

				<WrapperView wrapper={10}>
					<TextBodySemiboldLeftView
						wrapperBottom={10}
						nativeTextProps={{
							ellipsizeMode: 'tail',
							numberOfLines: 2,
						}}
						htmlChunks={headline.chunks}
					/>
					<WrapperAlignView>
						<WrapperAlignView itemsJustify={JUSTIFY.Start}>
							{metaInfo.map((paragraph, index) => {
								const separator: IHTMLChunks = [];
								if (index < metaInfo.length - 1) {
									separator.push({
										span: {
											text: ' • ',
										},
									});
								}
								return (
									<TextEntryStateSemiboldView
										key={index}
										htmlChunks={paragraph.chunks.concat(
											separator,
										)}
									/>
								);
							})}
						</WrapperAlignView>
						<ButtonPrimaryView
							rounded={true}
							label={buttonLabel}
							onClick={onCardClick}
							small={true}
						/>
					</WrapperAlignView>
				</WrapperView>
			</ButtonView>
		</CardWrapperView>
	);
};
