export default {
	moduleIntro: {
		coursesCount: {
			de: 'THEMEN',
			en: 'THEMEN',
		},
	},
	buttonHeadline: {
		de:
			'Beantworte kurz ein paar Fragen, um die Inhalte auf Deine Situation abzustimmen:',
		en:
			'Beantworte kurz ein paar Fragen, um die Inhalte auf Deine Situation abzustimmen:',
	},
	chapterCountLabel: {
		de: 'Kapitel',
		en: 'Kapitel',
	},
	allTopics: {
		de: 'Alle Themen',
		en: 'Alle Themen',
	},
	lastSeen: {
		de: 'Zuletzt gesehen',
		en: 'Zuletzt gesehen',
	},
	furtherTopics: {
		de: 'Weitere Themen',
		en: 'Weitere Themen',
	},
};
