export default {
	labelLeft: {
		de: 'Zurück',
		en: 'Zurück',
	},
	labelRight: {
		de: 'Weiter',
		en: 'Weiter',
	},
	labelRightLastChapter: {
		de: 'Abschließen',
		en: 'Abschließen',
	},
	labelRightLastCard: {
		de: 'Nächstes Kapitel',
		en: 'Nächstes Kapitel',
	},
	topicDone: {
		headline: {
			de: 'Pflege 1x1 abgeschlossen!',
			en: 'Pflege 1x1 abgeschlossen!',
		},
		buttonDone: {
			de: 'Zurück zur Übersicht',
			en: 'Zurück zur Übersicht',
		},
		body: {
			de:
				"Du hast das Pflege 1x1 [sb]''{courseTitle}''[/sb] abgeschlossen. Starte gleich das nächste Thema.",
			en:
				"Du hast das Pflege 1x1 [sb]''{courseTitle}''[/sb] abgeschlossen. Starte gleich das nächste Thema.",
		},
	},
	chapters: {
		statusbar: {
			labelOf: {
				de: 'VON',
				en: 'VON',
			},
			labelChaptersSG: {
				de: 'KAPITEL',
				en: 'KAPITEL',
			},
			labelChaptersPL: {
				de: 'KAPITELN',
				en: 'KAPITELN',
			},
		},
	},
};
