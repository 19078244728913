import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { COLOR_CODES } from '../enums/colors';
import { TextBodyLargeDefaultView } from '../texts/text-body-large-default.view';
import { ICON_CODES, TextIconView } from '../texts/text-icon.view';
import { INativeProps } from '../wrappers/wrapper-interfaces';
import { ButtonView, IButtonProps, IButtonStyles } from './button.view';

export interface IButtonOutlineStyles extends IButtonStyles {
	container?: object;
	label?: object;
	icon?: object;
}
export interface IButtonOutlineProps extends IButtonProps {
	label?: string;
	/** Icon from enum ICON_CODES */
	iconBefore?: ICON_CODES;
	/** Icon from enum ICON_CODES */
	iconAfter?: ICON_CODES;
	styles?: IButtonOutlineStyles;
	/** Label/Text/Icon/Border Color -- enum BUTTON_THEMES */
	theme?: BUTTON_OUTLINE_THEMES;
}

export enum BUTTON_OUTLINE_THEMES {
	Pink = 'Pink',
	Blue = 'Blue',
}

export const ButtonOutlineContainer = styled(
	(props: IButtonOutlineProps & INativeProps) => (
		<View style={props.style}>{props.children}</View>
	),
)`
	height: 36;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	border-radius: 18;
	border-color: ${(props: IButtonOutlineProps) =>
		(props.onClick &&
			((props.theme && COLOR_CODES[props.theme]) || COLOR_CODES.Pink)) ||
		COLOR_CODES.Grey50};
	border-width: 1;
	padding-left: 15;
	padding-right: 15;
	${(props: IButtonOutlineProps) => ({
		...((props.styles && props.styles.container) || {}),
	})};
`;

export const ButtonOutlineView = (
	props: IButtonOutlineProps & INativeProps,
) => {
	const { label, iconBefore, iconAfter, theme, ...buttonProps } = props;
	const color =
		(buttonProps.onClick &&
			((theme && COLOR_CODES[theme]) || COLOR_CODES.Pink)) ||
		COLOR_CODES.Grey50;
	return (
		<ButtonView {...buttonProps}>
			<ButtonOutlineContainer styles={props.styles} {...props}>
				{iconBefore && (
					<TextIconView
						color={color}
						icon={iconBefore}
						wrapperLeft={10}
						wrapperRight={10}
						styles={{
							text: (props.styles && props.styles.icon) || {},
						}}
					/>
				)}
				{label && (
					<TextBodyLargeDefaultView
						color={color}
						styles={{
							text: (props.styles && props.styles.label) || {},
						}}
					>
						{label}
					</TextBodyLargeDefaultView>
				)}
				{iconAfter && (
					<TextIconView
						color={color}
						icon={iconAfter}
						wrapperLeft={10}
						wrapperRight={10}
						styles={{
							text: (props.styles && props.styles.icon) || {},
						}}
					/>
				)}
			</ButtonOutlineContainer>
		</ButtonView>
	);
};
