import { useImageURI } from '@nui/hooks';
import React from 'react';
import {
	buildStyles,
	CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import { ImageURISource } from 'react-native';
import FastImage from 'react-native-fast-image-xp';

import { COLOR_CODES } from '../enums/colors';
import { CheckBadge } from '../misc/checkBadge.view';
import { IWrapperProps } from '../wrappers/wrapper-interfaces';
import { WrapperView } from '../wrappers/wrapper.view';

export interface IProgressBarAvatarProps extends IWrapperProps {
	percent?: number;
	image: () => Promise<ImageURISource> | ImageURISource;
	disabled?: boolean;
}

const radius = 28;
const borderWidth = 4;

export const ProgressBarAvatar = (props: IProgressBarAvatarProps) => {
	const imageURL = useImageURI(props.image);
	const { percent, disabled } = props;

	return (
		<WrapperView
			width={(radius + borderWidth) * 2}
			height={(radius + borderWidth) * 2}
			styles={{
				wrapper: {
					filter: disabled ? 'grayscale(100%)' : '',
				},
			}}
		>
			<CircularProgressbarWithChildren
				value={percent || 0}
				strokeWidth={7}
				styles={buildStyles({
					strokeLinecap: 'round',
					pathColor: COLOR_CODES.Pink,
					rotation: 0.5,
				})}
			>
				{imageURL && (
					<FastImage
						source={{ uri: imageURL.uri }}
						style={{
							width: radius * 2,
							height: radius * 2,

							borderRadius: radius,
						}}
						resizeMode="cover"
					/>
				)}
			</CircularProgressbarWithChildren>

			{percent === 100 && (
				<WrapperView
					styles={{
						wrapper: {
							position: 'absolute',
							top: 0,
							right: 0,
						},
					}}
				>
					<CheckBadge badgeRadius={10} />
				</WrapperView>
			)}
		</WrapperView>
	);
};
