import React from 'react';
import styled from 'styled-components/native';

import { ALIGN } from '../../../wrappers/wrapper-align.view';
import { INativeProps } from '../../../wrappers/wrapper-interfaces';
import { WrapperListView } from '../../../wrappers/wrapper-list.view';
import {
	ISelectOptionProps,
	SelectOptionView,
} from './components/select-option.view';

export interface ISelectProps {
	options: ISelectOptionProps[];
	key: string;
}

export const SelectView = styled((props: ISelectProps & INativeProps) => {
	return (
		<WrapperListView
			wrapperBetween={5}
			itemsAlign={ALIGN.End}
			wrapperRight={10}
		>
			{props.options.map((button, index) => {
				return <SelectOptionView key={index} {...button} />;
			})}
		</WrapperListView>
	);
})``;
