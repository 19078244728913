import React from 'react';

export enum AVATAR_RADIUS {
	xs = 18,
	sm = 20,
	md = 24,
	lg = 30,
	xl = 60,
}

export interface ISVGAvatarProps {
	radius: AVATAR_RADIUS;
}

export const SVGAvatarView = (props: ISVGAvatarProps) => {
	return (
		<svg
			width={props.radius * 2}
			height={props.radius * 2}
			viewBox="0 0 120 120"
		>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g
					id="iPhone-8-ProfileSeniorDefault"
					transform="translate(-128.000000, -85.000000)"
					fill="#B0B6C4"
				>
					<g id="Avatar" transform="translate(128.000000, 85.000000)">
						<path
							d="M60,0 C93.137085,0 120,26.862915 120,60 C120,93.137085 93.137085,120 60,120 C26.862915,120 0,93.137085 0,60 C0,26.862915 26.862915,0 60,0 Z M59.5058417,64.8 C43.9116391,64.8 30.2677282,73.0743122 22.7996788,85.4350505 C30.8997362,96.7650671 43.7268906,104.007921 59.6788244,104.007921 C75.4303215,104.007921 88.2486918,96.9459371 96.4637866,85.8593567 C89.0555572,73.2645175 75.2782614,64.8 59.5058417,64.8 Z M59.809901,21.6 C49.3110226,21.6 40.8,30.1110226 40.8,40.609901 C40.8,51.1087794 49.3110226,59.619802 59.809901,59.619802 C70.3087794,59.619802 78.819802,51.1087794 78.819802,40.609901 C78.819802,30.1110226 70.3087794,21.6 59.809901,21.6 Z"
							id="Combined-Shape"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};
