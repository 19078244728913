import { ConvertersUtil } from '@nui/utils';

export interface IFirebaseAuth {
	phoneNumber?: string;
	uid: string;
	isAnonymous: boolean;
}

export class FirebaseAuthModel {
	static toAuth(authDTO: any): IFirebaseAuth | undefined {
		const phoneNumber = ConvertersUtil.toString(authDTO?.phoneNumber);
		const uid = ConvertersUtil.toString(authDTO?.uid, undefined, 1);
		const isAnonymous = ConvertersUtil.toBool(authDTO?.isAnonymous);
		if (uid) {
			return { phoneNumber, uid, isAnonymous };
		} else {
			return undefined;
		}
	}
}
