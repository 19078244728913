export default {
	moduleIntro: {
		courseLength: {
			de: 'KAPITEL',
			en: 'KAPITEL',
		},
	},
	startButtonLabel: {
		de: 'Thema beginnen',
		en: 'Thema beginnen',
	},
};
