export enum COLOR_CODES {
	Pink = '#f05f97',
	White = '#ffffff',
	Black = '#000000',
	Transparent = '#ffffff00',
	Blue = '#849ed6',
	LightBlue = '#dee7ff',
	Glitter = '#fafaff',
	SteelGrey = '#b0b6c4',
	LightBlueGrey = '#c8c9cc',
	SlightlyTransparent = 'rgba(0,0,0,0.8)',
	Grey10 = '#efefef',
	Grey25 = '#dbdbd8',
	Grey50 = '#999999',
	Grey65 = '#666666',
	Grey75 = '#4a4a4a',
	Red = '#FF3B30',
	Green = '#7ed321',
}
