import React from 'react';
import styled from 'styled-components/native';
import { Text } from 'react-native';
import { INativeProps, IStyles } from '../../wrappers/wrapper-interfaces';
import { ITextProps } from '../text-interfaces';

interface IHtmlH2Props extends ITextProps {
	onClick?: () => void;
	extraStyles?: IStyles;
}
// TODO: enum FONT_WEIGHTS is undefined in Styleguiddist
export const HtmlH2View = styled((props: IHtmlH2Props & INativeProps) => {
	const [opacity, setOpacity] = React.useState(1);

	const handleOnPress = () => {
		props.onClick && props.onClick();
		setOpacity(0.5);
		setTimeout(() => {
			setOpacity(1);
		}, 100);
	};

	return (
		<Text
			{...props.nativeTextProps}
			onPress={props.onClick && handleOnPress}
			style={[props.style, { opacity }]}
			suppressHighlighting={!!props.onClick}
		>
			{props.children}
		</Text>
	);
})`
	font-size: 22;
	letter-spacing: 0.35;
	line-height: 28;
	font-weight: 700;
	margin-bottom: 4px;
	${(props: IHtmlH2Props) => ({
		...props.styles?.text,
		...props.styles?.h2,
		...props.extraStyles,
	})};
`;
