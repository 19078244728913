import React from 'react';
import styled from 'styled-components/native';
import { INativeProps } from '../wrappers/wrapper-interfaces';
import { FONT_WEIGHTS, ITextProps } from './text-interfaces';
import { TextView } from './text.view';

export const TextTitleTitle2View = styled(
	(props: ITextProps & INativeProps) => <TextView {...props} />,
)`
	font-size: 22;
	letter-spacing: 0.35;
	line-height: 28;
	font-weight: ${(props: ITextProps) => props.weight || FONT_WEIGHTS.bold};
	${(props: ITextProps) => ({
		...props.styles?.text,
	})};
`;
