import * as React from 'react';
import { COLOR_CODES } from '../enums/colors';
import { ICON_CODES, ICON_SIZES, TextIconView } from '../texts/text-icon.view';
import { BadgePlain, IBadgeProps } from './badge.view';

export const CheckBadge = (props: IBadgeProps) => {
	return (
		<BadgePlain
			badgeRadius={props.badgeRadius}
			styles={{
				wrapper: {
					backgroundColor: COLOR_CODES.Green,
				},
			}}
		>
			<TextIconView
				icon={ICON_CODES.Checkmark}
				size={ICON_SIZES.ss}
				styles={{
					text: {
						color: COLOR_CODES.White,
						textAlign: 'center',
					},
				}}
			/>
		</BadgePlain>
	);
};
