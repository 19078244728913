import LottieView from 'lottie-xp';
import React from 'react';
import { Animated, View } from 'react-native';
import { INativeProps } from '../../wrappers/wrapper-interfaces';
import { IAccordionProps } from '../types/accordion/accordion.view';
import { ILinkButtonsProps } from '../types/link-buttons/link-buttons.view';
import { ISelectProps } from '../types/select/select.view';
import { ITextMessageProps } from '../types/text-message/text-message-text.view';
import { AssistantBubbleLeftView } from './assistant-bubble-left.view';

export enum ASSISTANT_HISTORY_TYPE {
	textMessage = 'textMessage',
	phoneInput = 'phoneInput',
	codeInput = 'codeInput',
	linkButtons = 'linkButtons',
	select = 'select',
	accordion = 'accordion',
	textInput = 'textInput',
	consent = 'consent',
}

export const MAX_SELECT_OPTIONS = 3;

export interface IAssistantItemTextMessage extends ITextMessageProps {
	type: ASSISTANT_HISTORY_TYPE.textMessage;
	onFinished?: () => void;
	thinking?: boolean;
}

export interface IAssistantItemRequestPhone extends ITextMessageProps {
	type: ASSISTANT_HISTORY_TYPE.phoneInput;
	onFinished?: () => void;
	thinking?: boolean;
}

export interface IAssistantItemRequestedCode extends ITextMessageProps {
	type: ASSISTANT_HISTORY_TYPE.codeInput;
	onFinished?: () => void;
	thinking?: boolean;
}
export interface IAssistantItemTextInput extends ITextMessageProps {
	type: ASSISTANT_HISTORY_TYPE.textInput;
	onFinished?: () => void;
	thinking?: boolean;
}
export interface IAssistantItemLinkButtons extends ILinkButtonsProps {
	type: ASSISTANT_HISTORY_TYPE.linkButtons;
	onFinished?: () => void;
	thinking?: boolean;
}

export interface IAssistantItemAccordion extends IAccordionProps {
	type: ASSISTANT_HISTORY_TYPE.accordion;
	onFinished?: () => void;
	thinking?: boolean;
}

export interface IAssistantItemSelect extends ISelectProps {
	type: ASSISTANT_HISTORY_TYPE.select;
	onFinished?: () => void;
	thinking?: boolean;
	selected: boolean;
}

export type IAssistantItem =
	| IAssistantItemTextMessage
	| IAssistantItemLinkButtons
	| IAssistantItemAccordion
	| IAssistantItemSelect;

export const isAssistantItemTextMessage = (
	item: any,
): item is IAssistantItemTextMessage => {
	return item.type === ASSISTANT_HISTORY_TYPE.textMessage;
};
export const isAssistantItemLinkButtons = (
	item: any,
): item is IAssistantItemLinkButtons => {
	return item.type === ASSISTANT_HISTORY_TYPE.linkButtons;
};

export const isAssistantItemAccordion = (
	item: any,
): item is IAssistantItemAccordion => {
	return item.type === ASSISTANT_HISTORY_TYPE.accordion;
};

export const isAssistantItemSelect = (
	item: any,
): item is IAssistantItemSelect => {
	return item.type === ASSISTANT_HISTORY_TYPE.select;
};

export interface IAssistantItemProps {
	thinkingTime?: number;
	onFinished?: () => void;
}

const DURATION = 400;

export const AssistantItemView = (
	props: IAssistantItemProps & INativeProps,
) => {
	const timer = React.useRef<number | null>(null);
	const initialized = React.useRef(false);
	const lottieComponent = React.useRef<LottieView | null>(null);
	const opacity = React.useRef(new Animated.Value(props.onFinished ? 0 : 1));

	const [thinking, setThinking] = React.useState(
		!!props.thinkingTime && !!props.onFinished,
	);

	// perform asynchronous effect after second render
	React.useEffect(() => {
		if (initialized.current) {
			Animated.timing(opacity.current, {
				toValue: 1,
				duration: DURATION,
				useNativeDriver: true,
			}).start(props.onFinished);
		} else {
			initialized.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [thinking]);

	// perform synchronous effect only after the first render. This keeps the initial value of props.onFinished
	React.useLayoutEffect(() => {
		if (props.onFinished) {
			if (thinking) {
				// The web implementation does not provide this imperative api
				if (lottieComponent.current?.hasOwnProperty('play')) {
					lottieComponent.current?.play();
				}
				Animated.timing(opacity.current, {
					toValue: 1,
					duration: DURATION,
					useNativeDriver: true,
				}).start(() => {
					timer.current = setTimeout(() => {
						Animated.timing(opacity.current, {
							toValue: 0,
							duration: DURATION,
							useNativeDriver: true,
						}).start(() => {
							setThinking(false);
						});
					}, props.thinkingTime);
				});
			} else {
				Animated.timing(opacity.current, {
					toValue: 1,
					duration: DURATION,
					useNativeDriver: true,
				}).start(props.onFinished);
			}
		}
		return () => {
			timer.current && clearTimeout(timer.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Animated.View
			style={{
				opacity: opacity.current,
			}}
		>
			{thinking ? (
				<AssistantBubbleLeftView isLast={true}>
					<View
						style={{
							width: 21,
							height: 21,
						}}
					>
						<LottieView
							ref={animation => {
								lottieComponent.current = animation;
							}}
							autoPlay={true}
							source={require('../../../assets/animations/dots.json')}
						/>
					</View>
				</AssistantBubbleLeftView>
			) : (
				props.children
			)}
		</Animated.View>
	);
};
