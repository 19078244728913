import React from 'react';
import Lottie, { LottieProps } from 'react-lottie';

interface ILottieProps extends LottieProps {
	source: string | { uri: string };
	loop?: boolean;
	autoPlay?: boolean;
	ref?: React.RefObject<any>;
}
class LottieView extends React.Component<ILottieProps> {
	defaultOptions = {
		loop: this.props.loop,
		autoplay: this.props.autoPlay,
		animationData: this.props.source,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	render() {
		return (
			<Lottie
				{...this.props}
				options={this.defaultOptions}
				height="100%"
				width="100%"
			/>
		);
	}
}
export default LottieView;
