import { INativeProps } from '@nui/views';
import { FingerprintJS } from 'fingerprinting-xp';
import { firebase, FirebaseFirestoreTypes } from 'firebase-xp';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import Sentry from 'sentry-xp';

import * as appMetadata from '../../../../package.json';

export enum EventTypes {
	IA = 'IA',
	OB = 'OB',
	PAY = 'PAY',
	NAV = 'NAV',
	IN = 'IN',
	DCM = 'DCM',
	PC = 'PC',
	OC = 'OC',
	CHB = 'CHB',
	DEMO = 'DEMO',
	CONDEMO = 'CONDEMO',
}

/*
	Event types are specified in this table:
	https://docs.google.com/spreadsheets/d/1YL34Tb5Ft0yA39gKDyiFVi53H17Hkv9fQqQw-yb9R4g/edit?ts=5e500b41#gid=0
*/
export interface IEvent {
	type: EventTypes;
	subtype: string;
	meta: {
		userId: string | null;
		deviceId: string;
		teamId: string | null;
		timestamp: FirebaseFirestoreTypes.Timestamp;
		platform: string; // OS
		platformVersion: string; // OS-Version
		deviceModel: string;
		appVersion: string; // Version of this app
	};
	options?: {
		// User inputs either as raw value or inputId
		inputValue?: string | number | boolean;
		inputIds?: string[];
	} | null;
}

export type TrackEventFunction = (
	type: EventTypes,
	subtype: string,
	options?: {
		inputValue?: string | number | boolean;
		inputIds?: string[];
	},
	meta?: {
		userId?: string;
		teamId?: string;
	},
) => void;

const EventContext = createContext<TrackEventFunction | undefined>(undefined);

export const useTrackEvent = () => {
	const context = useContext(EventContext);
	if (context == undefined) {
		throw new Error('useEvent must be used within an EventProvider');
	}
	return context;
};

export const EventTrackingProvider = (props: INativeProps) => {
	const [deviceId, setDeviceId] = useState<string>();

	useEffect(() => {
		if (Platform.OS == 'web') {
			const initializeFingerprint = async () => {
				try {
					const fingerprint = await FingerprintJS.load();
					const result = await fingerprint.get();
					setDeviceId(prev => result.visitorId || prev);
				} catch (error) {
					console.error(error);
				}
			};
			initializeFingerprint();
		} else {
			// The deviceId API is only implemented for ios and android
			setDeviceId(prev => DeviceInfo.getUniqueId() || prev);
		}
	}, []);

	const trackEventWithContext: TrackEventFunction = (
		type: EventTypes,
		subtype: string,
		options?: {
			inputValue?: string | number | boolean;
			inputIds?: string[];
		},
		meta?: {
			userId?: string;
			teamId?: string;
		},
	) => {
		if (deviceId === undefined) {
			return;
		}
		const event: IEvent = {
			type,
			subtype,
			meta: {
				userId: meta?.userId ?? null,
				teamId: meta?.teamId ?? null,
				deviceId,
				timestamp: firebase.firestore.Timestamp.now(),
				platform: Platform.OS,
				platformVersion: DeviceInfo.getSystemVersion(),
				deviceModel: DeviceInfo.getDeviceId(),
				appVersion: appMetadata.version,
			},
			options: options ?? null,
		};
		firebase
			.firestore()
			.collection('events')
			.add(event)
			.catch(error => {
				Sentry.captureException(error);
			});
	};

	return (
		<EventContext.Provider
			value={trackEventWithContext}
			children={deviceId ? props.children : null}
		/>
	);
};
