import * as React from 'react';
import { Animated } from 'react-native';

const DURATION = 150;

export const useRotate = (degrees: number, onFinished?: () => void) => {
	const rotateAnimation = React.useRef(new Animated.Value(degrees)).current;

	React.useLayoutEffect(() => {
		Animated.timing(rotateAnimation, {
			toValue: degrees,
			duration: DURATION,
			useNativeDriver: true,
		}).start(() => {
			onFinished && onFinished();
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [degrees]);

	return rotateAnimation.interpolate({
		inputRange: [0, 360],
		outputRange: ['0deg', '360deg'],
	});
};
