import { PermissionsAndroid, Platform } from 'react-native';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';

export class HelpersUtil {
	static clone(item, def?: object) {
		if (item && typeof item !== 'object' && !Array.isArray(item)) {
			return def;
		}
		if (!item) {
			return def;
		}
		try {
			return JSON.parse(JSON.stringify(item));
		} catch (error) {
			return undefined;
		}
	}

	static hasSameElements<T>(a: T[], b: T[]) {
		return (
			a.length === b.length &&
			a.every((value, index) => {
				return value === b[index];
			})
		);
	}

	static hasSameKeys(o1: object, o2: object, deep = false) {
		const o1keys = Object.keys(o1).sort();
		const o2keys = Object.keys(o2).sort();
		if (deep) {
			if (o1keys.join() !== o2keys.join()) {
				return false;
			}
			return o1keys.every(key => {
				const v1 = o1[key];
				const v2 = o2[key];
				if (v1 === null) {
					return v2 === null;
				}
				const t1 = typeof v1;
				const t2 = typeof v2;
				if (t1 !== t2) {
					return false;
				}
				return t1 === 'object'
					? HelpersUtil.hasSameKeys(v1, v2, deep)
					: true;
			});
		} else {
			return JSON.stringify(o1keys) === JSON.stringify(o2keys);
		}
	}

	static hasProperties(item, minLength = 1) {
		return (
			(item &&
				typeof item == 'object' &&
				Object.keys(item).length >= minLength) ||
			false
		);
	}

	static countProperties(item) {
		return (
			(item && typeof item == 'object' && Object.keys(item).length) ||
			undefined
		);
	}

	static fbid() {
		const chars =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let autoId = '';
		for (let i = 0; i < 20; i++) {
			autoId += chars.charAt(Math.floor(Math.random() * chars.length));
		}
		return autoId;
	}

	static guid() {
		function s4() {
			return Math.floor((1 + Math.random()) * 0x10000)
				.toString(16)
				.substring(1);
		}
		return (
			s4() +
			s4() +
			'-' +
			s4() +
			'-' +
			s4() +
			'-' +
			s4() +
			'-' +
			s4() +
			s4() +
			s4()
		);
	}

	static isIOS() {
		return Platform.OS === 'ios';
	}

	static isAndroid() {
		return Platform.OS === 'android';
	}

	static isWeb() {
		return Platform.OS === 'web';
	}

	static vibrate(mode?: ReactNativeHapticFeedback.HapticFeedbackTypes) {
		ReactNativeHapticFeedback.trigger(mode || 'impactMedium');
	}

	static getHeaderHeight() {
		return HelpersUtil.isIOS() ? 64 : 56;
	}

	static async requestStoragePermissionAndroid(): Promise<void> {
		try {
			const granted = await PermissionsAndroid.requestMultiple([
				PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
				PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
			]);
			if (
				granted[
					PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
				] === PermissionsAndroid.RESULTS.GRANTED &&
				granted[
					PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE
				] === PermissionsAndroid.RESULTS.GRANTED
			) {
				return Promise.resolve();
			} else {
				return Promise.reject();
			}
		} catch (error) {
			return Promise.reject();
		}
	}
}
