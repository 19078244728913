import { StorageProvider } from '@nui/providers';
import { SUPPORTED_LANGUAGE_CODES } from '@nui/utils';
import FastImage from 'react-native-fast-image-xp';
import { ImageURISource } from 'react-native';
import Sentry from 'sentry-xp';

import { IFirebaseCourseTopicChapter } from '../firebase/courses/firebase-course-topic-chapter.model';
import { IFirebaseCourseTopic } from '../firebase/courses/firebase-course-topic.model';
import { IFirebaseCourse } from '../firebase/courses/firebase-course.model';
import { IState } from './state-interfaces';
import { StateModel } from './state.model';

const config = require('../../../../env-config.json');
export interface ISortedCourseTopicChapter {
	chapterId: string;
	chapter: IFirebaseCourseTopicChapter;
}
export interface ISortedCourseTopic {
	topicId: string;
	topic: IFirebaseCourseTopic;
	sortedChapters: ISortedCourseTopicChapter[];
}
export interface ISortedCourse {
	courseId: string;
	course: IFirebaseCourse;
	sortedTopics: ISortedCourseTopic[];
}

export class StateCoursesModel {
	protected state: IState;
	protected stateModel: StateModel;
	protected sortedCourses: ISortedCourse[] = [];
	protected sortedTopics: {
		[courseId: string]: ISortedCourseTopic[];
	};
	protected sortedChapters: {
		[courseId: string]: {
			[topicId: string]: ISortedCourseTopicChapter[];
		};
	};
	protected stateHasChanged: boolean;

	constructor(state: IState, stateModel: StateModel) {
		this.state = state;
		this.stateModel = stateModel;
		this.stateHasChanged = true;
		this.sortedTopics = {};
		this.sortedChapters = {};
	}

	isRelevant(courseId, topicId, relevantTopicsId?: string[]) {
		if (!relevantTopicsId) {
			relevantTopicsId = this.stateModel.stateUserModel.getRelevantTopics(
				courseId,
			);
		}
		for (let i of relevantTopicsId) {
			if (topicId === i) {
				return true;
			}
		}
		return false;
	}

	getSortedCourses(): ISortedCourse[] {
		this.initializeState();
		return this.sortedCourses;
	}

	getSortedTopics(courseId: string): ISortedCourseTopic[] | undefined {
		this.initializeState();
		return this.sortedTopics[courseId];
	}

	getSeenTopics(courseId: string): ISortedCourseTopic[] | undefined {
		let primaryTopics: ISortedCourseTopic[] = [];
		const sortedTopics = this.getSortedTopics(courseId);
		if (sortedTopics) {
			for (let i of sortedTopics) {
				if (this.hasCourseProgress(courseId, i.topicId)) {
					if (this.hasLastSeen(courseId, i.topicId)) {
						primaryTopics.unshift(i);
					} else {
						primaryTopics.push(i);
					}
				}
			}
		}
		return primaryTopics;
	}

	hasLastSeen(courseId: string, topicId: string) {
		const courseProgress = this.stateModel.stateUserModel.getUser()?.nui
			?.courseProgress;
		return (
			(courseProgress &&
				courseProgress[courseId] &&
				courseProgress[courseId].latestTopic == topicId) ||
			false
		);
	}

	getFurtherTopics(courseId: string): ISortedCourseTopic[] | undefined {
		let primaryTopics: ISortedCourseTopic[] = [];
		const sortedTopics = this.getSortedTopics(courseId);
		if (sortedTopics) {
			for (let i of sortedTopics) {
				if (!this.hasCourseProgress(courseId, i.topicId)) {
					primaryTopics.push(i);
				}
			}
		}
		return primaryTopics;
	}

	getSortedChapters(
		courseId: string,
		topicId: string,
	): ISortedCourseTopicChapter[] {
		this.initializeState();
		return (
			(this.sortedChapters[courseId] &&
				this.sortedChapters[courseId][topicId]) ||
			[]
		);
	}

	getProgress(
		courseId: string,
		topicId: string,
	): {
		chapterId?: string;
		percent: number;
		index: number;
	} {
		const chapterId =
			this.state.user?.nui?.courseProgress[courseId] &&
			this.state.user?.nui?.courseProgress[courseId][topicId];
		if (chapterId) {
			const chapter = this.getChapter(courseId, topicId, chapterId);
			const sortedChapters = this.getSortedChapters(courseId, topicId);
			let index = 0;
			if (sortedChapters) {
				for (; index < sortedChapters.length; index++) {
					if (sortedChapters[index].chapterId == chapterId) {
						return {
							chapterId,
							percent: (chapter && chapter.progress) || 0,
							index,
						};
					}
				}
			}
		}
		return {
			chapterId: undefined,
			percent: 0,
			index: -1,
		};
	}

	initializeState() {
		const prefetchPromises: (() => Promise<void>)[] = [];
		if (this.stateHasChanged) {
			this.sortedTopics = {};
			this.sortedChapters = {};
			const sortedCourses: ISortedCourse[] = [];
			for (const cId in this.state.courses) {
				// Prefetch image
				prefetchPromises.push(() => this.prefetchCourseImage(cId));

				this.sortedChapters[cId] = {};
				const sortedTopics: ISortedCourseTopic[] = [];
				for (const topicId in this.state.courses[cId].topics) {
					// Prefetch images
					prefetchPromises.push(() =>
						this.prefetchTopicImage(cId, topicId),
					);
					prefetchPromises.push(() =>
						this.prefetchTopicIcon(cId, topicId),
					);

					const sortedChapters: ISortedCourseTopicChapter[] = [];
					for (const chapterId in this.state.courses[cId].topics[
						topicId
					].chapters) {
						// Prefetch images
						prefetchPromises.push(() =>
							this.prefetchChapterImage(cId, topicId),
						);

						for (const code in SUPPORTED_LANGUAGE_CODES) {
							this.state.courses[cId].topics[topicId].chapters[
								chapterId
							].content[code]
								.match(/\[img[^\]]*\](.*?)\[\/img\]/g)
								?.map(name => {
									prefetchPromises.push(() =>
										this.prefetchContentImage(
											cId,
											topicId,
											chapterId,
											name
												.replace(/\[\/?.+?\]/g, '')
												.trim(),
										),
									);
								});
						}

						sortedChapters.push({
							chapterId,
							chapter: this.state.courses[cId].topics[topicId]
								.chapters[chapterId],
						});
					}
					sortedChapters.sort((a, b) => {
						return a.chapter.pos - b.chapter.pos;
					});
					sortedTopics.push({
						topicId,
						topic: this.state.courses[cId].topics[topicId],
						sortedChapters,
					});
					this.sortedChapters[cId][topicId] = sortedChapters;
				}
				sortedTopics.sort((a, b) => {
					return a.topic.pos - b.topic.pos;
				});
				sortedCourses.push({
					courseId: cId,
					course: this.state.courses[cId],
					sortedTopics,
				});
				this.sortedTopics[cId] = sortedTopics;
			}
			sortedCourses.sort((a, b) => {
				return a.course.pos - b.course.pos;
			});
			this.sortedCourses = sortedCourses;
			this.stateHasChanged = false;
		}

		(async () => {
			for (const index in prefetchPromises) {
				try {
					await prefetchPromises[index]();
				} catch (error) {
					Sentry.captureException(error);
				}
			}
		})().catch();
	}

	addCourse(courseId: string, course: IFirebaseCourse) {
		this.stateHasChanged = true;
		this.state.courses[courseId] = course;
	}

	getCourse(courseId: string): IFirebaseCourse | undefined {
		return this.state.courses[courseId];
	}

	getTopic(
		courseId: string,
		topicId: string,
	): IFirebaseCourseTopic | undefined {
		return (
			this.state.courses[courseId] &&
			this.state.courses[courseId].topics[topicId]
		);
	}

	prefetchContentImage(
		courseId: string,
		topicId: string,
		chapterId: string,
		name: string,
	): Promise<void> {
		return new Promise((resolve, reject) => {
			const image = this.getContentImage(
				courseId,
				topicId,
				chapterId,
				name,
			)();
			if (image instanceof Promise) {
				image
					.then(imageURISource => {
						FastImage.preload([{ uri: imageURISource.uri }]);
						resolve();
					})
					.catch(error => {
						reject(error);
					});
			} else {
				FastImage.preload([{ uri: image.uri }]);
				resolve();
			}
		});
	}

	getContentImage(
		courseId: string,
		topicId: string,
		chapterId: string,
		name: string,
	): () => Promise<ImageURISource> | ImageURISource {
		return StorageProvider.getDownloadURL(
			'/care101s/' +
				courseId +
				'/topics/' +
				topicId +
				'/chapters/' +
				chapterId +
				'/' +
				name,
			config.contentBucketURI,
		);
	}

	getChapter(
		courseId: string,
		topicId: string,
		chapterId?: string,
	): IFirebaseCourseTopicChapter | undefined {
		if (!chapterId) {
			return undefined;
		}
		return (
			this.state.courses[courseId] &&
			this.state.courses[courseId].topics[topicId] &&
			this.state.courses[courseId].topics[topicId].chapters[chapterId]
		);
	}

	prefetchCourseImage(courseId: string): Promise<void> {
		return new Promise((resolve, reject) => {
			const image = this.getCourseImage(courseId)();
			if (image instanceof Promise) {
				image
					.then(imageURISource => {
						FastImage.preload([{ uri: imageURISource.uri }]);
						resolve();
					})
					.catch(error => {
						reject(error);
					});
			} else {
				FastImage.preload([{ uri: image.uri }]);
				resolve();
			}
		});
	}

	getCourseImage(courseId): () => Promise<ImageURISource> | ImageURISource {
		return StorageProvider.getDownloadURL(
			'/care101s/' + courseId + '/title.png',
			config.contentBucketURI,
		);
	}

	prefetchTopicIcon(courseId: string, topicId: string): Promise<void> {
		return new Promise((resolve, reject) => {
			const image = this.getTopicIcon(courseId, topicId)();
			if (image instanceof Promise) {
				image
					.then(imageURISource => {
						FastImage.preload([{ uri: imageURISource.uri }]);
						resolve();
					})
					.catch(error => {
						reject(error);
					});
			} else {
				FastImage.preload([{ uri: image.uri }]);
				resolve();
			}
		});
	}

	getTopicIcon(
		courseId: string,
		topicId: string,
	): () => Promise<ImageURISource> | ImageURISource {
		return StorageProvider.getDownloadURL(
			'/care101s/' + courseId + '/topics/' + topicId + '/icon.png',
			config.contentBucketURI,
		);
	}

	prefetchChapterImage(courseId: string, topicId: string): Promise<void> {
		return new Promise((resolve, reject) => {
			const image = this.getChapterImage(courseId, topicId)();
			if (image instanceof Promise) {
				image
					.then(imageURISource => {
						FastImage.preload([{ uri: imageURISource.uri }]);
						resolve();
					})
					.catch(error => {
						reject(error);
					});
			} else {
				FastImage.preload([{ uri: image.uri }]);
				resolve();
			}
		});
	}

	getChapterImage(
		courseId: string,
		topicId: string,
	): () => Promise<ImageURISource> | ImageURISource {
		return StorageProvider.getDownloadURL(
			'/care101s/' + courseId + '/topics/' + topicId + '/title.png',
			config.contentBucketURI,
		);
	}

	prefetchTopicImage(courseId: string, topicId: string): Promise<void> {
		return new Promise((resolve, reject) => {
			const image = this.getTopicImage(courseId, topicId)();
			if (image instanceof Promise) {
				image
					.then(imageURISource => {
						FastImage.preload([{ uri: imageURISource.uri }]);
						resolve();
					})
					.catch(error => {
						reject(error);
					});
			} else {
				FastImage.preload([{ uri: image.uri }]);
				resolve();
			}
		});
	}

	getTopicImage(
		courseId: string,
		topicId: string,
	): () => Promise<ImageURISource> | ImageURISource {
		return StorageProvider.getDownloadURL(
			'/care101s/' + courseId + '/topics/' + topicId + '/title.png',
			config.contentBucketURI,
		);
	}

	getRemainingChapters(
		courseId: string,
		topicId: string,
	): number | undefined {
		const topic = this.getTopic(courseId, topicId);
		if (topic) {
			const { chapterId } = this.getProgress(courseId, topicId);
			const chapter = this.getChapter(courseId, topicId, chapterId);
			if (chapter) {
				return topic.chapterCount - chapter.pos - 1;
			} else {
				return topic.chapterCount;
			}
		} else {
			return undefined;
		}
	}

	removeCourses(courseId?: string) {
		this.stateHasChanged = true;
		if (courseId) {
			delete this.state.courses[courseId];
		} else {
			this.state.courses = {};
		}
	}

	hasCourseProgress(courseId: string, topicId: string) {
		const courseProgress = this.stateModel.stateUserModel.getUser()?.nui
			?.courseProgress;
		return (
			(courseProgress &&
				courseProgress[courseId] &&
				courseProgress[courseId][topicId] &&
				true) ||
			false
		);
	}
}
