import React from 'react';
import styled from 'styled-components/native';
import { COLOR_CODES } from '../enums/colors';
import { INativeProps } from '../wrappers/wrapper-interfaces';
import { ITextProps } from './text-interfaces';
import { TextView } from './text.view';

export const TextSubheadlineDefaultView = styled(
	(props: ITextProps & INativeProps) => <TextView {...props} />,
)`
	font-size: 15;
	letter-spacing: -0.24;
	line-height: 20;
	color: ${(props: ITextProps) => props.color || COLOR_CODES.Grey75};
	${(props: ITextProps) => ({
		...props.styles?.text,
	})};
`;
