import React from 'react';
import { FlatList } from 'react-native';
import { TextCaptionBoldView } from '../texts/text-caption-bold.view';
import { WrapperAlignView, JUSTIFY } from '../wrappers/wrapper-align.view';
import { CommentBlockLeftView } from './comment-block-left.view';
import { CommentBlockRightView } from './comment-block-right.view';
import { IComment } from './comment.view';
import { IWrapperProps } from '../wrappers/wrapper-interfaces';
import { WrapperView } from '../wrappers/wrapper.view';

export enum CommentPositions {
	left,
	right,
}

export interface ICommentBlock {
	position: CommentPositions;
	comments: IComment[];
}

export interface IConversation {
	day: string;
	commentBlocks: ICommentBlock[];
}

export interface IConversationsProps extends IWrapperProps {
	conversations: IConversation[];
}

export const ConversationsView = (props: IConversationsProps) => {
	const { conversations } = props;

	return (
		<FlatList
			inverted={true}
			keyboardShouldPersistTaps="always"
			keyboardDismissMode="on-drag"
			data={conversations}
			keyExtractor={item => item.day}
			renderItem={({ item }) => (
				<WrapperView wrapperLeft={20} wrapperRight={20}>
					<WrapperAlignView
						itemsJustify={JUSTIFY.Center}
						wrapper={15}
					>
						<TextCaptionBoldView>{item.day}</TextCaptionBoldView>
					</WrapperAlignView>

					{item.commentBlocks.map((block, key) => {
						return (
							<WrapperView key={key}>
								{block.position === CommentPositions.left && (
									<CommentBlockLeftView
										comments={block.comments}
									/>
								)}
								{block.position === CommentPositions.right && (
									<CommentBlockRightView
										comments={block.comments}
									/>
								)}
							</WrapperView>
						);
					})}
				</WrapperView>
			)}
		/>
	);
};
