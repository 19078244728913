import { ContentType } from '@nui/models';
import { IParagraph, Language } from '@nui/utils';
import { HomescreenHeaderView, IHomescreenHeaderProps } from '@nui/views';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { stateModel } from '../../../../globals';

import content from '../assets/content';

export const HomescreenHeaderController = () => {
	const nextTopic = stateModel.stateUserModel.getNextTopic();

	const history = useHistory();

	const handleTopicClick = (courseId: string, topicId: string) => {
		history.push(`/care101s/${courseId}/topics/${topicId}`, {
			redirect: '/',
		});

		if (stateModel.stateCoursesModel.hasCourseProgress(courseId, topicId)) {
			history.push(`/care101s/${courseId}/topics/${topicId}/chapters`, {
				redirect: '/',
			});
		}
	};

	if (nextTopic) {
		const topic = stateModel.stateCoursesModel.getTopic(
			nextTopic.courseId,
			nextTopic.topicId,
		);
		if (topic) {
			const metaCaption: IParagraph[] = [];
			const courseType = stateModel.stateCoursesModel.getCourse(
				nextTopic.courseId,
			)?.type;
			if (courseType == ContentType.benefit) {
				const headerProps: IHomescreenHeaderProps = {
					image: stateModel.stateCoursesModel.getTopicImage(
						nextTopic.courseId,
						nextTopic.topicId,
					),
					headline: Language.translate(topic?.title),
					topicCaption: Language.translate(topic?.intro),
					onButtonClick: () => {
						handleTopicClick(nextTopic.courseId, nextTopic.topicId);
					},
				};
				return <HomescreenHeaderView {...headerProps} />;
			} else {
				const remainingChapters = stateModel.stateCoursesModel.getRemainingChapters(
					nextTopic.courseId,
					nextTopic.topicId,
				);
				if (remainingChapters && remainingChapters > 0) {
					metaCaption.push(
						...Language.translateFromBBCode(
							Language.replace(
								content.header.remainingChapters,
								'{COUNT}',
								remainingChapters.toString(),
							),
						),
					);
				} else if (remainingChapters == 0) {
					metaCaption.push(
						...Language.translateFromBBCode(content.header.done),
					);
				}

				const headerProps: IHomescreenHeaderProps = {
					image: stateModel.stateCoursesModel.getTopicImage(
						nextTopic.courseId,
						nextTopic.topicId,
					),
					headline: Language.translate(topic?.title),
					metaCaption,
					onButtonClick: () => {
						handleTopicClick(nextTopic.courseId, nextTopic.topicId);
					},
				};
				return <HomescreenHeaderView {...headerProps} />;
			}
		} else {
			return null;
		}
	} else {
		const DEFAULT_COURSE_ID = 'senior';
		const course = stateModel.stateCoursesModel.getCourse(
			DEFAULT_COURSE_ID,
		);
		if (course) {
			const headerProps: IHomescreenHeaderProps = {
				image: stateModel.stateCoursesModel.getCourseImage(
					DEFAULT_COURSE_ID,
				),
				headline: Language.translate(
					Language.replace(
						course.title,
						'{senior}',
						stateModel.stateTeamsModel.getSenior()?.firstName || '',
					),
				),
				featuredCaption: Language.translateFromBBCode(
					course?.featuredCaption,
				),

				onButtonClick: () => {
					history.push(`/care101s/${DEFAULT_COURSE_ID}`, {
						redirect: '/',
					});
				},
			};
			return <HomescreenHeaderView {...headerProps} />;
		} else {
			return null;
		}
	}
};
