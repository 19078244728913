import {
	IModuleIntro,
	ITopicsListProps,
	ModuleIntro,
	TopicsListView,
	WrapperPageView,
} from '@nui/views';
import React from 'react';

export interface IPagePflege1x1OverviewProps {
	moduleIntro: IModuleIntro;
	topicsList: ITopicsListProps[];
}

export const PagePflege1x1OverviewView = (
	props: IPagePflege1x1OverviewProps,
) => {
	const { moduleIntro, topicsList } = props;

	return (
		<WrapperPageView wrapperLeft={20} wrapperRight={20} wrapperTop={30}>
			<ModuleIntro {...moduleIntro} />
			{topicsList.map((topics, i) => (
				<TopicsListView key={i} {...topics} />
			))}
		</WrapperPageView>
	);
};
