import * as React from 'react';
import { ImageURISource, View } from 'react-native';
import FastImage from 'react-native-fast-image-xp';
import styled from 'styled-components/native';

import { useImageURI } from '../../hooks/use-image-uri.hook';
import {
	extractWrapperProps,
	INativeProps,
	IWrapperProps,
} from '../wrappers/wrapper-interfaces';
import { WrapperView } from '../wrappers/wrapper.view';

interface ICourseCardProps extends IWrapperProps {
	cardMargin: number;
	cardWidth: number | string;
	image: () => Promise<ImageURISource> | ImageURISource;
	imageHeight: number;
}

export const CourseCardBorderView = styled(
	(props: ICourseCardProps & INativeProps) => {
		const imageURL = useImageURI(props.image);

		return (
			<View style={props.style}>
				<View style={{ height: props.imageHeight, overflow: 'hidden' }}>
					{imageURL && (
						<FastImage
							source={{ uri: imageURL.uri }}
							style={{
								borderTopLeftRadius: 20,
								borderTopRightRadius: 20,
								width: '100%',
								height: '160%',
								position: 'absolute',
								top: 0,
							}}
						/>
					)}
				</View>
			</View>
		);
	},
)`
	flex: 1;
	border-radius: 20px;
	border-color: black;
	shadow-radius: 10px;
	shadow-opacity: 0.15;
	shadow-color: black;
	shadow-offset: 0px 2px;
	elevation: 2;
	background-color: white;
`;

export const CourseCardView = (props: ICourseCardProps) => {
	const wrapperProps = extractWrapperProps(props);
	return (
		<WrapperView {...wrapperProps}>
			<CourseCardBorderView {...props} />
		</WrapperView>
	);
};
