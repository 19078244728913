import { ConvertersUtil } from '@nui/utils';
import {
	FirestoreTeamPaymentModel,
	IFirestoreTeamPayment,
} from './firestore-team-payment.model';

export interface IFirestoreTeamPayments {
	[paymentId: string]: IFirestoreTeamPayment;
}

export class FirestoreTeamPaymentsModel {
	static toTeamPayments(
		userTeamPaymentsDTO: any,
	): IFirestoreTeamPayments | undefined {
		return ConvertersUtil.toHashtable<IFirestoreTeamPayment>(
			userTeamPaymentsDTO,
			undefined,
			0,
			userTeamPaymentDTO =>
				FirestoreTeamPaymentModel.toTeamPayment(userTeamPaymentDTO),
		);
	}
}
