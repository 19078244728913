import { useCollpase } from '@nui/hooks';
import { TextSubheadlineSemiboldSteelgreyView } from '@nui/views';
import React from 'react';
import {
	Animated,
	ImageURISource,
	LayoutAnimation,
	UIManager,
	View,
} from 'react-native';
import styled from 'styled-components/native';

import { useImageURI } from '../../../../../hooks/use-image-uri.hook';
import { useRotate } from '../../../../../hooks/use-rotate.hook';
import { HelpersUtil } from '../../../../../utils/helpers.util';
import { IParagraph } from '../../../../../utils/language.util';
import { ButtonView, IButtonProps } from '../../../../buttons/button.view';
import { COLOR_CODES } from '../../../../enums/colors';
import {
	ICON_CODES,
	ICON_SIZES,
	TextIconView,
} from '../../../../texts/text-icon.view';
import {
	ALIGN,
	DIRECTION,
	JUSTIFY,
	WrapperAlignView,
} from '../../../../wrappers/wrapper-align.view';
import { INativeProps } from '../../../../wrappers/wrapper-interfaces';
import { AccordionButtonsView } from './accordion-buttons.view';
import { AccordionItemImageView } from './accordion-item-image.view';
import { AccordionTextView } from './accordion-text.view';
import { AccordionTitleView } from './accordion-title.view';

if (HelpersUtil.isAndroid()) {
	if (UIManager.setLayoutAnimationEnabledExperimental) {
		UIManager.setLayoutAnimationEnabledExperimental(true);
	}
}

export interface IAccordionItemProps {
	image: () => ImageURISource | Promise<ImageURISource>;
	body: IParagraph[];
	subtitle?: string;
	title: string;
	buttons?: IButtonProps[];
	showOpened?: boolean;
}

export const CardView = styled((props: INativeProps) => {
	return <View style={props.style}>{props.children}</View>;
})`
	padding: 10px;
	shadow-color: ${COLOR_CODES.Black};
	shadow-offset: 0px 2px;
	shadow-opacity: 0.15;
	shadow-radius: 4px;
	elevation: 2;
	border-radius: 8px;
	background-color: ${COLOR_CODES.White};
	${(props: INativeProps) => ({ ...props.style })};
`;

export const AccordionItemView = (props: IAccordionItemProps) => {
	const [collapsed, setCollapsed] = React.useState(!props.showOpened);
	const [height, setHeight] = React.useState(0);

	const handleClick = () => {
		!HelpersUtil.isWeb() &&
			LayoutAnimation.configureNext(
				LayoutAnimation.Presets.easeInEaseOut,
			);
		setCollapsed(collpased => !collpased);
	};

	const { heightAnimation, opacityAnimation } = useCollpase(
		collapsed,
		(HelpersUtil.isWeb() && height) || 100,
	);

	const rotateAnimation = useRotate(collapsed ? 0 : 90);

	const imageURL = useImageURI(props.image);

	return (
		<CardView>
			<ButtonView
				onClick={handleClick}
				nativeTouchableOpacityProps={{ activeOpacity: 1 }}
			>
				<WrapperAlignView
					itemsDirection={DIRECTION.Vertical}
					itemsAlign={ALIGN.Start}
					itemsJustify={JUSTIFY.SpaceBetween}
				>
					<WrapperAlignView
						itemsDirection={DIRECTION.Horizontal}
						itemsAlign={ALIGN.Center}
						itemsJustify={JUSTIFY.SpaceBetween}
						styles={{ wrapper: { width: '100%' } }}
					>
						<WrapperAlignView
							itemsDirection={DIRECTION.Horizontal}
							itemsAlign={ALIGN.Center}
							itemsJustify={JUSTIFY.End}
							styles={{ wrapper: { flex: 1 } }}
						>
							{imageURL && (
								<AccordionItemImageView source={imageURL} />
							)}
							<AccordionTitleView
								wrapperLeft={15}
								styles={{ wrapper: { flex: 1 } }}
								wrapperRight={15}
							>
								{props.title}
							</AccordionTitleView>
						</WrapperAlignView>

						<Animated.View
							style={{
								transform: [{ rotate: rotateAnimation }],
							}}
						>
							<TextIconView
								icon={ICON_CODES.Next}
								color={COLOR_CODES.SteelGrey}
								size={ICON_SIZES.sm}
								wrapperRight={5}
							></TextIconView>
						</Animated.View>
					</WrapperAlignView>

					{props.subtitle && (
						<TextSubheadlineSemiboldSteelgreyView
							wrapperTop={5}
							itemsAlign={'flex-start'}
							wrapperRight={15}
							nativeTextProps={{
								numberOfLines: collapsed ? 1 : 2,
							}}
							styles={{ wrapper: { width: '100%' } }}
						>
							{props.subtitle}
						</TextSubheadlineSemiboldSteelgreyView>
					)}
				</WrapperAlignView>
			</ButtonView>
			<Animated.View
				style={{
					height: HelpersUtil.isWeb()
						? heightAnimation
						: collapsed
						? 0
						: 'auto',
					opacity: opacityAnimation,
				}}
			>
				<View
					onLayout={e =>
						HelpersUtil.isWeb() &&
						setHeight(e.nativeEvent.layout.height)
					}
				>
					{props.body.map((paragraph, index) => (
						<AccordionTextView
							key={index}
							htmlChunks={paragraph.chunks}
							wrapperTop={10}
						/>
					))}
					{props.buttons && (
						<AccordionButtonsView buttons={props.buttons} />
					)}
				</View>
			</Animated.View>
		</CardView>
	);
};
