import { ConvertersUtil } from '@nui/utils';

import {
	FirestoreTeamMemberModel,
	IFirestoreTeamMember,
} from './firestore-team-member.model';

export interface IFirestoreTeamMembers {
	[memberId: string]: IFirestoreTeamMember;
}

export class FirestoreTeamMembersModel {
	static toTeamMembers(
		userId: string,
		userTeamMembersDTO: any,
	): IFirestoreTeamMembers | undefined {
		const members = ConvertersUtil.toHashtable<IFirestoreTeamMember>(
			userTeamMembersDTO,
			undefined,
			1,
			userTeamMemberDTO =>
				FirestoreTeamMemberModel.toTeamMember(userTeamMemberDTO),
		);
		for (const memberId in members) {
			if (memberId == userId && members[memberId].accepted) {
				return members;
			}
		}
		return undefined;
	}
}
