import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { COLOR_CODES } from '../enums/colors';
import { INativeProps } from '../wrappers/wrapper-interfaces';

export interface IBadgeSmallProps {
	top?: number;
	right?: number;
}

export const BadgeSmallView = styled(
	(props: IBadgeSmallProps & INativeProps) => <View style={props.style} />,
)`
	position: absolute;
	top: ${props => props.top || 0};
	right: ${props => props.right || 0};
	width: 6;
	height: 6;
	border-radius: 3;
	background-color: ${COLOR_CODES.Pink};
`;
