import React, { useEffect, useRef } from 'react';
import { ScrollView, View } from 'react-native';

import {
	ASSISTANT_HISTORY_TYPE,
	IAssistantItem,
} from './components/assistant-item.view';
import { AssistantLeftView } from './components/assistant-left.view';
import { AssistantRightView } from './components/assistant-right.view';

export enum DIALOG_POSITION {
	LEFT = 'left',
	RIGHT = 'right',
}
export interface IAssistantDialog {
	position: DIALOG_POSITION;
	authorId: string;
	items: IAssistantItem[];
	type: ASSISTANT_HISTORY_TYPE;
}
export interface IAssistantProps {
	dialogs: IAssistantDialog[];
}

export const AssistantView = (props: IAssistantProps) => {
	const messagesEndRef = useRef<any | null>(null);

	useEffect(() => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	}, [props.dialogs]);

	const getBottomSpace = (index: number) => {
		if (
			index < props.dialogs.length - 1 &&
			props.dialogs[index + 1].type === props.dialogs[index].type
		) {
			return 7;
		} else {
			return index < props.dialogs.length - 1 ? 20 : 0;
		}
	};

	// This view is align to bottom (chats scroll up)
	return (
		<View style={{ marginTop: 'auto' }}>
			<ScrollView
				contentContainerStyle={{
					padding: 10,
					paddingBottom: 20,
					transform: [{ scaleY: -1 }],
				}}
				style={{ transform: [{ scaleY: -1 }] }}
			>
				{props.dialogs.map((item, index) => {
					if (item.position == DIALOG_POSITION.LEFT) {
						return (
							<AssistantLeftView
								key={index}
								wrapperBottom={getBottomSpace(index)}
								authorId={item.authorId}
								items={item.items}
							/>
						);
					} else {
						return (
							<AssistantRightView
								key={index}
								wrapperBottom={getBottomSpace(index)}
								items={item.items}
							/>
						);
					}
				})}
				<div ref={messagesEndRef} />
			</ScrollView>
		</View>
	);
};
