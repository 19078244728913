import {
	IHistoryBlock,
	IHistoryBlockItem,
	isFirestoreAssistantAccordion,
	isFirestoreAssistantLinkButtons,
	isFirestoreAssistantRequestPhone,
	isFirestoreAssistantTextInput,
	isFirestoreAssistantSelect,
	isFirestoreAssistantTextMessage,
	isFirestoreAssistantRequestCode,
} from '@nui/models';
import { Language } from '@nui/utils';
import {
	AssistantView,
	DIALOG_POSITION,
	IAssistantDialog,
	IAssistantItemAccordion,
	IAssistantItemLinkButtons,
	IAssistantItemRequestedCode,
	IAssistantItemRequestPhone,
	IAssistantItemSelect,
	IAssistantItemTextInput,
	IAssistantItemTextMessage,
	ASSISTANT_HISTORY_TYPE,
} from '@nui/views';
import React, { useEffect, useRef } from 'react';
import LottieView from 'react-lottie';
import { Animated, View } from 'react-native';

import { stateModel } from '../../../../globals';

interface AssistantHistroyProps {
	groupedHistory: IHistoryBlock[];
	handleClick: (historyIndex: number, optionIndex: number) => Promise<void>;
	handleFinished: () => void;
	handleNavigation: (link: string) => void;
}

const getHistoryItem = (
	item: IHistoryBlockItem,
	thinking: boolean,
	onFinished: (() => void) | undefined,
	onClick: (historyIndex: number, optionIndex: number) => Promise<void>,
	handleNavigation: (link: string) => void,
) => {
	const history = item.history;
	let locked = false;
	if (isFirestoreAssistantTextMessage(history)) {
		const assistantItem: IAssistantItemTextMessage = {
			type: history.type,
			message: Language.translateFromBBCode(
				Language.replace(
					history.message,
					'{SENIOR}',
					stateModel.stateTeamsModel.getSenior()?.firstName,
				),
			),
			thinking,
			onFinished,
			key: item.historyId,
		};
		return assistantItem;
	} else if (isFirestoreAssistantRequestPhone(history) && history.message) {
		const assistantItem: IAssistantItemRequestPhone = {
			type: history.type,
			message: Language.translateFromBBCode(
				Language.replace(
					history.message,
					'{SENIOR}',
					stateModel.stateTeamsModel.getSenior()?.firstName,
				),
			),
			thinking,
			onFinished: () => {},
			key: item.historyId,
		};
		return assistantItem;
	} else if (isFirestoreAssistantRequestCode(history) && history.message) {
		const assistantItem: IAssistantItemRequestedCode = {
			type: history.type,
			message: Language.translateFromBBCode(
				Language.replace(
					history.message,
					'{SENIOR}',
					stateModel.stateTeamsModel.getSenior()?.firstName,
				),
			),
			thinking,
			onFinished: () => {},
			key: item.historyId,
		};
		return assistantItem;
	} else if (isFirestoreAssistantTextInput(history) && history.value) {
		const assistantItem: IAssistantItemTextInput = {
			type: history.type,
			message: Language.translateFromBBCode(
				Language.replace(
					history.value,
					'{SENIOR}',
					stateModel.stateTeamsModel.getSenior()?.firstName,
				),
			),
			thinking,
			onFinished: () => {},
			key: item.historyId,
		};
		return assistantItem;
	} else if (isFirestoreAssistantLinkButtons(history)) {
		const assistantItem: IAssistantItemLinkButtons = {
			type: history.type,
			buttons: history.buttons.map(button => ({
				label: Language.translate(button.label),
				onClick: () => {
					handleNavigation(button.link);
				},
			})),
			onFinished,
			key: item.historyId,
		};
		return assistantItem;
	} else if (isFirestoreAssistantAccordion(history)) {
		const assistantItem: IAssistantItemAccordion = {
			type: history.type,
			items: history.items.map(item => ({
				image: stateModel.stateAssistantModel.getAccordionIcon(
					item.icon,
				),
				body: Language.translateFromBBCode(item.body),
				title: Language.translate(item.title),
				subtitle: item.subtitle,
				buttons: item.buttons.map(button => ({
					label: Language.translate(button.label),
					onClick: () => {
						handleNavigation(button.link);
					},
					type: button.type,
				})),
				showOpened: item.showOpened,
			})),
			onFinished,
			key: item.historyId,
			more:
				(history.remaining > 0 && {
					label: history.remaining + ' weitere Pflegeleistungen',
					image: require('../../assets/images/accordion-more.png'),
				}) ||
				undefined,
		};
		return assistantItem;
	} else if (isFirestoreAssistantSelect(history)) {
		const assistantItem: IAssistantItemSelect = {
			type: history.type,
			options: history.options.map((option, optionIndex) => ({
				label: Language.translate(
					Language.replace(
						option.label,
						'{SENIOR}',
						stateModel.stateTeamsModel.getSenior()?.firstName,
					),
				),
				selected: option.selected,
				onClick:
					(!history.selected &&
						(async () => {
							if (!locked) {
								locked = true;
								try {
									await onClick(item.index, optionIndex);
								} catch (error) {
									console.log(error);
								}
								locked = false;
							}
						})) ||
					undefined,
			})),
			onFinished,
			key: item.historyId,
			selected: history.selected,
		};
		return assistantItem;
	} else if (history.type == ASSISTANT_HISTORY_TYPE.consent) {
		const consentItem = {
			onClick: async () => {
				await onClick(item.index, 0);
			},
		};
		return consentItem;
	} else {
		return null;
	}
};

const AssistantHistoryController = (props: AssistantHistroyProps) => {
	const fadeAnim = useRef(new Animated.Value(1)).current;
	const statics = React.useRef<{
		initialized: boolean;
	}>({ initialized: false }).current;

	const authId = stateModel.stateAuthModel.getAuth()?.uid;

	React.useLayoutEffect(() => {
		statics.initialized = true;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isEmpty = !authId || props.groupedHistory.length === 0;
	useEffect(() => {
		Animated.timing(fadeAnim, {
			toValue: 0,
			duration: 4500,
			useNativeDriver: true,
		}).start();
	}, [fadeAnim]);

	return (
		<View>
			<AssistantView
				dialogs={
					props.groupedHistory
						.map((block, blockIndex) => {
							return {
								position:
									block.authorId === authId
										? DIALOG_POSITION.RIGHT
										: DIALOG_POSITION.LEFT,
								items: block.items
									.map((item, itemIndex) => {
										const isLastItem =
											blockIndex ==
												props.groupedHistory.length -
													1 &&
											itemIndex == block.items.length - 1;
										return getHistoryItem(
											item,
											statics.initialized &&
												block.authorId !== authId,
											(statics.initialized &&
												isLastItem &&
												props.handleFinished) ||
												undefined,
											props.handleClick,
											props.handleNavigation,
										);
									})
									.filter(item => !!item),
								authorId: block.authorId,
								type: block.type,
							};
						})
						.filter(item => !!item) as IAssistantDialog[]
				}
			/>
			<Animated.View
				style={{
					opacity: fadeAnim,
					display: isEmpty ? 'flex' : 'none',
					marginBottom: '40vh',
				}}
			>
				<LottieView
					speed={1}
					direction={1}
					isClickToPauseDisabled={true}
					height="120px"
					width="120px"
					options={{
						autoplay: true,
						loop: true,
						animationData: require('../../../../assets/animations/waiting.json'),
					}}
				/>
			</Animated.View>
		</View>
	);
};

export const AssistantHistoryMemorizedController = React.memo(
	AssistantHistoryController,
	(prevProps, nextProps) => {
		const prevBlockLength = prevProps.groupedHistory.length;
		const nextBlockLength = nextProps.groupedHistory.length;
		if (prevBlockLength > 0 && nextBlockLength > 0) {
			const prevItemLength =
				prevProps.groupedHistory[prevBlockLength - 1].items.length;
			const nextItemLength =
				nextProps.groupedHistory[nextBlockLength - 1].items.length;
			const prevType =
				prevProps.groupedHistory[prevBlockLength - 1].items[
					prevItemLength - 1
				].history.type;
			const nextType =
				nextProps.groupedHistory[nextBlockLength - 1].items[
					nextItemLength - 1
				].history.type;

			const isEqual =
				prevBlockLength == nextBlockLength &&
				prevItemLength == nextItemLength &&
				prevType == nextType &&
				nextType != ASSISTANT_HISTORY_TYPE.consent;

			return isEqual;
		} else {
			return false;
		}
	},
);
