import { COLOR_CODES } from '../../enums/colors';
import styled from 'styled-components/native';

export interface IAssistantBubbleLeftProps {
	isLast?: boolean;
}

export const AssistantBubbleLeftView = styled.View`
	padding: 7px 12px;
	border-radius: 18px;
	min-height: 36px;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	background-color: ${COLOR_CODES.LightBlue};
	border-bottom-left-radius: ${(props: IAssistantBubbleLeftProps) =>
		props.isLast ? 2 : 20};
	align-self: flex-start;
`;
