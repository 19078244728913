import { COLOR_CODES } from '../../enums/colors';
import styled from 'styled-components/native';

export interface IAssistantBubbleRightProps {
	isLast?: boolean;
}

export const AssistantBubbleRightView = styled.View`
	padding: 7px 12px;
	border-radius: 18px;
	min-height: 36px;
	align-items: center;
	justify-content: center;
	margin-left: 50px;
	margin-right: 10px;
	background-color: ${COLOR_CODES.Blue};
	border-bottom-right-radius: ${(props: IAssistantBubbleRightProps) =>
		props.isLast ? 2 : 20};
`;
