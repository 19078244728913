import { Language } from '@nui/utils';
import { ICON_CODES, PaginationCourseView } from '@nui/views';
import React from 'react';

import content from '../assets/content';

interface IChaptersPaginationProps {
	chapterIndex: number;
	chapterCount: number;
	onChapterChange: (chapterIndex: number) => void;
	onGoBack: () => void;
}

export const ChaptersPaginationController = (
	props: IChaptersPaginationProps,
) => {
	const handlePrevClick = () => {
		props.onChapterChange(props.chapterIndex - 1);
	};

	const handleNextClick = () => {
		props.onChapterChange(props.chapterIndex + 1);
	};

	const isLastChapter = () => {
		return props.chapterIndex == props.chapterCount - 1;
	};

	const isFirstChapter = () => {
		return props.chapterIndex == 0;
	};

	let labelLeft = Language.translate(content.labelLeft);
	let labelRight = Language.translate(content.labelRight);
	let iconRightRight: ICON_CODES | undefined = ICON_CODES.IconGo;
	let iconRightLeft: ICON_CODES | undefined;
	let iconLeftRight: ICON_CODES | undefined;
	let iconLeftLeft: ICON_CODES | undefined = ICON_CODES.IconBack;
	let hiddenLeft = false;
	let hiddenRight = false;
	let prevClick: (() => void) | undefined = handlePrevClick;
	let nextClick: (() => void) | undefined = handleNextClick;

	if (isFirstChapter()) {
		prevClick = props.onGoBack;
	}
	if (isLastChapter()) {
		labelRight = Language.translate(content.labelRightLastChapter);
		iconRightRight = undefined;
		iconRightLeft = ICON_CODES.IconDone;
	}

	return (
		<PaginationCourseView
			left={{
				onClick: prevClick,
				hidden: hiddenLeft,
				label: labelLeft,
				iconBefore: iconLeftLeft,
				iconAfter: iconLeftRight,
			}}
			right={{
				onClick: nextClick,
				hidden: hiddenRight,
				label: labelRight,
				iconBefore: iconRightLeft,
				iconAfter: iconRightRight,
			}}
		/>
	);
};
