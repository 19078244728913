import React from 'react';
import { ImageURISource } from 'react-native';

import {
	extractWrapperProps,
	IWrapperProps,
} from '../../../wrappers/wrapper-interfaces';
import { WrapperListView } from '../../../wrappers/wrapper-list.view';
import {
	AccordionItemView,
	IAccordionItemProps,
} from './components/accordion-item.view';
import { AccordionMoreView } from './components/accordion-more.view';

export interface IAccordionProps extends IWrapperProps {
	items: IAccordionItemProps[];
	more?: {
		label: string;
		image: ImageURISource;
		onClick?: () => void;
	};
	key: string;
}

export const AccordionView = React.memo(
	(props: IAccordionProps) => {
		return (
			<WrapperListView wrapperBetween={7} {...extractWrapperProps(props)}>
				{props.items.map((item, index) => (
					<AccordionItemView key={index} {...item} />
				))}
				{props.more && <AccordionMoreView {...props.more} />}
			</WrapperListView>
		);
	},
	() => true,
);
