import { FirebaseAuthModel } from '@nui/models';
import { FirebaseAuthProvider } from '@nui/providers';

import { stateModel } from '../../../globals';
import { AssistantObserver } from './assistant.observer';
import { CoursesObserver } from './courses.observer';
import { TeamObserver } from './team.observer';
import { UserObserver } from './user.observer';

export class AuthObserver {
	protected authProvider: FirebaseAuthProvider;
	protected courseObserver: CoursesObserver;
	protected teamObserver: { [teamId: string]: TeamObserver };
	protected userObserver?: UserObserver;
	protected assistantObserver?: AssistantObserver;

	constructor() {
		this.authProvider = new FirebaseAuthProvider();
		this.courseObserver = new CoursesObserver();
		this.teamObserver = {};
	}
	public unsubscribeAuthObserver() {
		stateModel.stateAuthModel.unsetAuth();
		if (this.authProvider) {
			this.authProvider.unsubscribeObserver;
		}
	}

	public subscribeAuthObserver(
		onChange: () => void,
		onReject: () => void,
	): Promise<void> {
		let firstObservation = true;

		return new Promise((resolve, reject) => {
			this.authProvider
				.subscribeObserver()
				.loggedIn(async (authDTO, teams) => {
					console.log('Logged in ', authDTO, teams);
					// unsubscribe all observers
					this.courseObserver.unsubscribeCoursesObserver();

					const auth = FirebaseAuthModel.toAuth(authDTO);
					if (auth) {
						stateModel.stateAuthModel.setAuth(auth);

						try {
							// Observe user
							this.userObserver = new UserObserver(auth.uid);
							await this.userObserver.subscribeUserObserver(
								() => {
									onChange();
								},
								() => {
									FirebaseAuthProvider.logout();
									onReject();
								},
							);

							// Observe team
							for (const index in teams) {
								this.teamObserver[
									teams[index]
								] = new TeamObserver(auth.uid, teams[index]);
								await this.teamObserver[
									teams[index]
								].subscribeTeamObserver(
									() => {
										onChange();
									},
									() => {
										FirebaseAuthProvider.logout();
										onReject();
									},
								);
								stateModel.setTeamId(teams[index]);
							}

							// Observe courses
							await this.courseObserver.subscribeCoursesObserver(
								() => {
									onChange();
								},
							);

							// Observe assistant
							this.assistantObserver = new AssistantObserver(
								auth.uid,
							);
							await this.assistantObserver.subscribeAssistantObserver(
								() => {
									onChange();
								},
							);

							// ... add more observers here

							if (firstObservation) {
								resolve();
							} else {
								onChange();
							}
						} catch (error) {
							FirebaseAuthProvider.logout();
							if (firstObservation) {
								reject(error);
							} else {
								onReject();
							}
						}
					} else {
						FirebaseAuthProvider.logout();
						if (firstObservation) {
							reject();
						} else {
							onReject();
						}
					}
					firstObservation = false;
				})
				.loggedOut(() => {
					console.log('logged out');
					// unsubscribe all observers
					this.courseObserver.unsubscribeCoursesObserver();
					this.assistantObserver?.unsubscribeAssistantObserver();
					this.userObserver?.unsubscribeUserObserver();

					stateModel.stateAuthModel.unsetAuth();
					if (firstObservation) {
						resolve();
					} else {
						onChange();
					}
					firstObservation = false;
				})
				.teamChanged(async (authDTO, teams) => {
					const auth = FirebaseAuthModel.toAuth(authDTO);
					if (auth) {
						stateModel.stateAuthModel.setAuth(auth);
						console.log('team changed', teams);
						// unsubscribe affected observers
						for (const teamId in this.teamObserver) {
							this.teamObserver[teamId].unsubscribeTeamObserver();
						}
						stateModel.unsetTeamId();

						// Observe team
						for (const index in teams) {
							this.teamObserver[teams[index]] = new TeamObserver(
								auth.uid,
								teams[index],
							);
							await this.teamObserver[
								teams[index]
							].subscribeTeamObserver(
								() => {
									onChange();
								},
								() => {
									FirebaseAuthProvider.logout();
									onReject();
								},
							);
							stateModel.setTeamId(teams[index]);
						}

						// ... add more affected observers here
					} else {
						FirebaseAuthProvider.logout();
						if (firstObservation) {
							reject();
						} else {
							onReject();
						}
					}
					firstObservation = false;
				})
				.tokenChanged(authDTO => {
					const auth = FirebaseAuthModel.toAuth(authDTO);
					if (auth) {
						stateModel.stateAuthModel.setAuth(auth);
					} else {
						FirebaseAuthProvider.logout();
						if (firstObservation) {
							reject();
						} else {
							onReject();
						}
					}
				});
		});
	}
}
