import { useImageURI } from '@nui/hooks';
import { IParagraph } from '@nui/utils';
import React from 'react';
import { ImageURISource, useWindowDimensions } from 'react-native';
import FastImage from 'react-native-fast-image-xp';
import LinearGradient from 'react-native-linear-gradient-xp';

import {
	BUTTON_ROUNDED_SIZES,
	BUTTON_ROUNDED_OPTIONS,
	ButtonRoundedView,
} from '../buttons/button-rounded.view';
import { ButtonView } from '../buttons/button.view';
import { COLOR_CODES } from '../enums/colors';
import { ICON_CODES } from '../texts/text-icon.view';
import { TextSubheadlineDefaultView } from '../texts/text-subheadline-default.view';
import { TextTitleTitle1View } from '../texts/text-title-title1.view';
import { WrapperAlignView } from '../wrappers/wrapper-align.view';
import { WrapperView } from '../wrappers/wrapper.view';

const IMAGE_HEIGHT = 236;
const GRADIENT_OFFSET = 55;
export interface IHomescreenHeaderProps {
	image: () => Promise<ImageURISource> | ImageURISource;
	headline: string;
	metaCaption?: IParagraph[];
	featuredCaption?: IParagraph[];
	topicCaption?: string;
	onButtonClick: () => void;
}

export const HomescreenHeaderView = (props: IHomescreenHeaderProps) => {
	const {
		image,
		headline,
		featuredCaption,
		metaCaption,
		topicCaption,
		onButtonClick,
	} = props;
	const imageURL = useImageURI(image);
	const windowWidth = useWindowDimensions().width;

	return (
		<ButtonView
			onClick={onButtonClick}
			nativeTouchableOpacityProps={{ delayPressIn: 60 }}
		>
			<WrapperView styles={{ wrapper: { maxWidth: windowWidth } }}>
				<WrapperView
					height={IMAGE_HEIGHT}
					styles={{
						wrapper: {
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							zIndex: -2,
						},
					}}
				>
					{imageURL && (
						<FastImage
							resizeMode="cover"
							style={{ flex: 1 }}
							source={{ uri: imageURL.uri }}
						/>
					)}
				</WrapperView>

				<LinearGradient
					colors={['#ffffff00', '#ffffff']}
					style={{
						height: GRADIENT_OFFSET,
						position: 'absolute',
						top: IMAGE_HEIGHT - GRADIENT_OFFSET,
						left: 0,
						right: 0,
						zIndex: -1,
					}}
				/>
				<WrapperView
					wrapperLeft={20}
					wrapperRight={20}
					wrapperTop={IMAGE_HEIGHT - 20}
				>
					<WrapperAlignView>
						<TextTitleTitle1View width="auto" wrapperBottom={10}>
							{headline}
						</TextTitleTitle1View>
						<ButtonRoundedView
							size={BUTTON_ROUNDED_SIZES.lg}
							onClick={onButtonClick}
							icon={ICON_CODES.ArrowNext}
							styleOption={BUTTON_ROUNDED_OPTIONS.WhiteOnPink}
						/>
					</WrapperAlignView>
					<WrapperAlignView>
						{(featuredCaption &&
							featuredCaption.map((paragraph, index) => {
								return (
									<TextSubheadlineDefaultView
										key={index}
										htmlChunks={paragraph.chunks}
										styles={{
											wrapper: {
												flex: 1,
											},
											semibold: {
												color: COLOR_CODES.SteelGrey,
											},
										}}
									/>
								);
							})) ||
							null}
						{(metaCaption &&
							metaCaption.map((paragraph, index) => {
								return (
									<TextSubheadlineDefaultView
										key={index}
										htmlChunks={paragraph.chunks}
										styles={{
											wrapper: {
												flex: 1,
											},
											semibold: {
												color: COLOR_CODES.SteelGrey,
											},
										}}
									/>
								);
							})) ||
							null}
						{(topicCaption && (
							<TextSubheadlineDefaultView
								nativeTextProps={{
									ellipsizeMode: 'tail',
									numberOfLines: 2,
								}}
								styles={{
									wrapper: {
										flex: 1,
									},
									semibold: {
										color: COLOR_CODES.SteelGrey,
									},
								}}
							>
								{topicCaption}
							</TextSubheadlineDefaultView>
						)) ||
							null}
					</WrapperAlignView>
				</WrapperView>
			</WrapperView>
		</ButtonView>
	);
};
