import { firebase } from 'firebase-xp';
import { ImageURISource } from 'react-native';
import {
	FirestoreQueryProvider,
	FirestoreCollectionProvider,
} from '@nui/providers';
import { HelpersUtil, ConvertersUtil } from '@nui/utils';

export interface IFirestoreChatRoom {
	name: string;
	avatar?: ImageURISource;
	phone?: string;
}

export class FirestoreChatRoomModel {
	static seen(userId, chatRoomId) {
		return new Promise(async (resolve, reject) => {
			const firestoreQueryProvider = new FirestoreQueryProvider(
				firebase
					.firestore()
					.collection('chat-messages')
					.where('recipientId', '==', userId)
					.where('senderId', '==', chatRoomId)
					.where('hasSeen', '==', false),
			);
			try {
				const chatMessagesDTO = await firestoreQueryProvider.get();
				const batch = {};
				for (const chatMessageId in chatMessagesDTO) {
					batch[chatMessageId] = { hasSeen: true };
				}
				if (HelpersUtil.hasProperties(batch)) {
					const firestoreCollectionProvider = new FirestoreCollectionProvider(
						firebase.firestore().collection('chat-messages'),
					);
					await firestoreCollectionProvider.updateBatch(batch);
				}
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	static toChatRoom(chatRoomDTO: any): IFirestoreChatRoom {
		return {
			name: ConvertersUtil.toString(chatRoomDTO.name) || '',
			avatar: ConvertersUtil.toImageSource(chatRoomDTO.avatar),
			phone: ConvertersUtil.toString(chatRoomDTO.phone),
		};
	}
}
