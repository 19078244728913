import React from 'react';
import { Text } from 'react-native';
import styled from 'styled-components/native';

import {
	ButtonView,
	extractButtonProps,
	IButtonProps,
} from '../../../../buttons/button.view';
import { COLOR_CODES } from '../../../../enums/colors';
import { FONT_WEIGHTS } from '../../../../texts/text-interfaces';
import {
	extractWrapperProps,
	INativeProps,
} from '../../../../wrappers/wrapper-interfaces';
import { WrapperView } from '../../../../wrappers/wrapper.view';

export interface ISelectOptionProps extends IButtonProps {
	label: string;
	selected: boolean;
}

const SelectOptionContainerView = styled.View`
	min-height: 35px;
	border: 2px solid ${COLOR_CODES.Blue};
	border-radius: 18px;
	align-items: center;
	justify-content: center;
	padding: 0 12px;
	background-color: ${(props: ISelectOptionProps) =>
		props.selected ? COLOR_CODES.Blue : COLOR_CODES.Transparent};
`;

export const SelectOptionView = styled(
	(props: ISelectOptionProps & INativeProps) => {
		if (props.onClick) {
			return (
				<ButtonView
					{...extractButtonProps(props)}
					{...extractWrapperProps(props)}
				>
					<SelectOptionContainerView {...props}>
						<Text style={props.style}>{props.label}</Text>
					</SelectOptionContainerView>
				</ButtonView>
			);
		} else {
			return (
				<WrapperView {...extractWrapperProps(props)}>
					<SelectOptionContainerView {...props}>
						<Text style={props.style}>{props.label}</Text>
					</SelectOptionContainerView>
				</WrapperView>
			);
		}
	},
)`
	color: ${props => (props.selected ? COLOR_CODES.White : COLOR_CODES.Blue)};
	font-size: 17;
	line-height: 20px;
	letter-spacing: 0.5;
	font-weight: ${FONT_WEIGHTS.semiBold};
`;
