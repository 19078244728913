import React, { useState } from 'react';
import { TextInputProps } from 'react-native';

import {
	BUTTON_ROUNDED_SIZES,
	ButtonRoundedView,
	IButtonRoundedStyles,
} from '../buttons/button-rounded.view';
import { COLOR_CODES } from '../enums/colors';
import { ICON_CODES } from '../texts/text-icon.view';
import {
	ALIGN,
	DIRECTION,
	WrapperAlignView,
} from '../wrappers/wrapper-align.view';
import {
	extractWrapperProps,
	IWrapperStyles,
} from '../wrappers/wrapper-interfaces';
import { WrapperView } from '../wrappers/wrapper.view';
import {
	extractTextfieldNewProps,
	ITextfieldNewProps,
	ITextfieldNewStyles,
} from './textfield-new.view';

const SIZE = BUTTON_ROUNDED_SIZES.md;

export interface ITextfieldSubmitStyles extends IWrapperStyles {
	textfield?: ITextfieldNewStyles;
	button?: IButtonRoundedStyles;
}

export interface ITextfieldSubmitProps {
	styles?: ITextfieldSubmitStyles;
	onChange?: (text: string) => void;
	onSubmit?: (text: string) => Promise<void>;
	onFocus?: () => void;
	nativeTextInputProps?: TextInputProps;
	color?: COLOR_CODES;
	maxLines?: number;
	autoHeight?: boolean;
	clear?: boolean;
	horizontalSpacing: number;
	verticalSpacing: number;
	textfieldLeftPadding: number;
	textfieldRadius?: number;
}

export interface ITextfieldSubmitState {
	value: string;
}

export const withSubmit = (
	TextfieldView: React.JSXElementConstructor<ITextfieldNewProps>,
) => (props: ITextfieldSubmitProps) => {
	const TextfieldSubmitView = (props: ITextfieldSubmitProps) => {
		const waiting = React.useRef(false);
		const textfieldHandler = React.useRef<{
			clear: () => void;
			isValid: () => boolean;
		} | null>(null);

		const defaultValue =
			(props.nativeTextInputProps &&
				props.nativeTextInputProps.defaultValue) ||
			'';

		const [state, setState] = useState<ITextfieldSubmitState>({
			value: defaultValue,
		});

		const handleChange = (value: string) => {
			props.onChange && props.onChange(value);
			setState(s => ({ ...s, value }));
		};

		const handleTextfieldSubmit = async (value: string): Promise<void> => {
			if (!waiting.current) {
				if (props.clear) {
					textfieldHandler.current &&
						textfieldHandler.current.clear();
					setState(s => ({ ...s, value: '' }));
				}
				if (props.onSubmit) {
					waiting.current = true;
					await props.onSubmit(value);
					waiting.current = false;
				}
			}
		};

		const handleButtonSubmit = async (): Promise<void> => {
			return handleTextfieldSubmit(state.value);
		};

		const getButtonSubmitHandler =
			(props.onSubmit &&
				textfieldHandler.current &&
				textfieldHandler.current.isValid() &&
				handleButtonSubmit) ||
			undefined;

		const textfieldProps = extractTextfieldNewProps(props);
		const wrapperProps = extractWrapperProps(props);

		return (
			<WrapperView
				styles={{
					wrapper: {
						backgroundColor: COLOR_CODES.White,
						position: 'relative',
						borderTopWidth: 1,
						borderTopColor: COLOR_CODES.Grey10,
						borderRightWidth: 1,
						borderRightColor: COLOR_CODES.Grey10,
						borderLeftWidth: 1,
						borderLeftColor: COLOR_CODES.Grey10,
						borderTopRightRadius: props.textfieldRadius ?? SIZE,
						borderTopLeftRadius: props.textfieldRadius ?? SIZE,
						...props.styles?.wrapper,
					},
				}}
				wrapperTop={props.verticalSpacing}
				wrapperBottom={props.verticalSpacing}
			>
				<WrapperAlignView
					itemsDirection={DIRECTION.Horizontal}
					itemsAlign={ALIGN.Center}
					wrapperLeft={props.horizontalSpacing}
					wrapperRight={props.horizontalSpacing}
				>
					<TextfieldView
						{...wrapperProps}
						{...textfieldProps}
						onChange={handleChange}
						onSubmit={handleTextfieldSubmit}
						styles={{
							...props.styles?.textfield,
							wrapper: {
								backgroundColor: COLOR_CODES.White,
								flex: 1,
								height:
									props.maxLines && props.maxLines > 1
										? 'auto'
										: SIZE * 2,
								...props.styles?.textfield?.wrapper,
							},
							textfield: {
								...props.styles?.textfield?.textfield,
								paddingTop: 5,
								paddingBottom: 5,
								paddingLeft: props.textfieldLeftPadding,
								borderTopColor: COLOR_CODES.Grey25,
								borderTopWidth: 1,
								borderBottomColor: COLOR_CODES.Grey25,
								borderBottomWidth: 1,
								borderRightColor: COLOR_CODES.Grey25,
								borderRightWidth: 1,
								borderLeftColor: COLOR_CODES.Grey25,
								borderLeftWidth: 1,
								minHeight: SIZE * 2,
								borderRadius: SIZE,
							},
						}}
						handler={handler => {
							textfieldHandler.current = handler;
						}}
					/>
					<ButtonRoundedView
						size={SIZE}
						icon={ICON_CODES.ArrowUp}
						wrapperLeft={props.horizontalSpacing}
						styles={props.styles?.button}
						onClick={getButtonSubmitHandler}
					/>
				</WrapperAlignView>
			</WrapperView>
		);
	};
	return <TextfieldSubmitView {...props} />;
};
