import { firebase } from 'firebase-xp';

import { HelpersUtil } from '../../utils/helpers.util';

type TLoggedIn = (sysuserDTO: any, teamIds: string[]) => void;
type TLoggedOut = () => void;
type TTeamChanged = (sysuserDTO: any, teamIds: string[]) => void;
type TTokenChanged = (sysuserDTO: any) => void;
type TFailure = (err: Error) => void;
export class FirebaseAuthListenerEvents {
	protected handleLoggedIn?: TLoggedIn;
	protected handleLoggedOut?: TLoggedOut;
	protected handleTeamChanged?: TTeamChanged;
	protected handleTokenChanged?: TTokenChanged;
	protected handleFailure?: TFailure;

	constructor(
		promiseHandler: (
			loggedId: TLoggedIn,
			loggedOut: TLoggedOut,
			teamChanged: TTeamChanged,
			tokenChanged: TTokenChanged,
			failure: TFailure,
		) => void,
	) {
		this.onLoggedIn = this.onLoggedIn.bind(this);
		this.onLoggedOut = this.onLoggedOut.bind(this);
		this.onTeamChanged = this.onTeamChanged.bind(this);
		this.onTokenChanged = this.onTokenChanged.bind(this);
		this.onFailure = this.onFailure.bind(this);
		promiseHandler(
			this.onLoggedIn,
			this.onLoggedOut,
			this.onTeamChanged,
			this.onTokenChanged,
			this.onFailure,
		);
	}

	public loggedIn(handleLoggedIn: TLoggedIn): FirebaseAuthListenerEvents {
		this.handleLoggedIn = handleLoggedIn;
		return this;
	}

	public loggedOut(handleLoggedOut: TLoggedOut): FirebaseAuthListenerEvents {
		this.handleLoggedOut = handleLoggedOut;
		return this;
	}

	public teamChanged(
		handleTeamChanged: TTeamChanged,
	): FirebaseAuthListenerEvents {
		this.handleTeamChanged = handleTeamChanged;
		return this;
	}

	public tokenChanged(
		handleTokenChanged: TTokenChanged,
	): FirebaseAuthListenerEvents {
		this.handleTokenChanged = handleTokenChanged;
		return this;
	}

	public failure(handleFailure: TFailure): FirebaseAuthListenerEvents {
		this.handleFailure = handleFailure;
		return this;
	}

	protected onLoggedIn(sysuserDTO: object, teams: string[]): void {
		if (this.handleLoggedIn) {
			this.handleLoggedIn(sysuserDTO, teams);
		}
	}

	protected onLoggedOut(): void {
		if (this.handleLoggedOut) {
			this.handleLoggedOut();
		}
	}

	protected onTeamChanged(sysuserDTO: any, teams: string[]): void {
		this.handleTeamChanged && this.handleTeamChanged(sysuserDTO, teams);
	}

	protected onTokenChanged(sysuserDTO: any): void {
		this.handleTokenChanged && this.handleTokenChanged(sysuserDTO);
	}

	protected onFailure(err: Error): void {
		this.handleFailure && this.handleFailure(err);
	}
}

export class FirebaseAuthProvider {
	protected subscription: (() => void) | undefined;
	protected teamIds: string[] = [];
	protected auth?: any;

	public static getTeamIds(): Promise<string[]> {
		return new Promise((resolve, reject) => {
			firebase
				.auth()
				.currentUser?.getIdTokenResult()
				.then(idTokenResult => {
					if (idTokenResult.claims.teamId) {
						resolve([idTokenResult.claims.teamId]);
					} else {
						resolve([]);
					}
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public static getIdToken(): Promise<string> {
		return new Promise((resolve, reject) => {
			const tokenHandler = firebase.auth().currentUser?.getIdToken(true);
			if (tokenHandler) {
				tokenHandler
					.then(token => {
						if (token) {
							resolve(token);
						} else {
							reject();
						}
					})
					.catch(error => {
						reject(error);
					});
			} else {
				reject();
			}
		});
	}

	public unsubscribeObserver() {
		if (this.subscription) {
			this.subscription();
		}
	}

	public subscribeObserver() {
		this.unsubscribeObserver();
		return new FirebaseAuthListenerEvents(
			(loggedIn, loggedOut, teamChanged, tokenChanged, failure) => {
				this.subscription = firebase
					.auth()
					.onIdTokenChanged(async (sysuserDTO: any) => {
						if (sysuserDTO) {
							if (this.auth) {
								// Do not move outside condition
								try {
									const teamIds = await FirebaseAuthProvider.getTeamIds();
									if (
										!HelpersUtil.hasSameElements<string>(
											this.teamIds,
											teamIds,
										)
									) {
										this.teamIds = teamIds;
										teamChanged(sysuserDTO, teamIds);
									} else {
										tokenChanged(sysuserDTO);
									}
								} catch (error) {
									failure(error);
								}
							} else {
								this.auth = { ...sysuserDTO };
								// Do not move outside condition
								try {
									this.teamIds = await FirebaseAuthProvider.getTeamIds();
									loggedIn(sysuserDTO, this.teamIds);
								} catch (error) {
									failure(error);
								}
							}
						} else {
							this.auth = undefined;
							this.teamIds = [];
							loggedOut();
						}
					});
			},
		);
	}

	public static loginAnonymously() {
		// Do not forget to ctivate anonym auth method in your firebase backend.
		return firebase.auth().signInAnonymously();
	}

	public static logout() {
		firebase
			.auth()
			.signOut()
			.catch(error => {
				if (error.code != 'auth/no-current-user') {
					error;
				}
			});
	}
}
