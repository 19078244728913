import { useImageURI, useOnce } from '@nui/hooks';
import { FirestoreUserModel } from '@nui/models';
import { EventTypes, useTrackEvent } from '@nui/providers';
import {
	BUTTON_ROUNDED_SIZES,
	BUTTON_ROUNDED_OPTIONS,
	ButtonRoundedView,
	ICON_CODES,
	WrapperView,
} from '@nui/views';
import React from 'react';
import FastImage from 'react-native-fast-image-xp';
import { useHistory, useParams } from 'react-router-dom';

import { stateModel } from '../../../globals';
import { LayoutView } from '../../views/layout.view';
import { TopicStartPageSubcontroller } from './care101-topic-start.subcontroller';

interface ICare101TopicControllerProps {
	courseId: string;
	topicId: string;
}

export const Care101TopicController = () => {
	let { courseId, topicId } = useParams<ICare101TopicControllerProps>();
	const history = useHistory();
	const image = stateModel.stateCoursesModel.getTopicImage(courseId, topicId);
	const imageURL = useImageURI(image);
	const userId = stateModel.stateAuthModel.getAuth()?.uid;
	const user = stateModel.stateUserModel.getUser();
	const trackEvent = useTrackEvent();

	useOnce(() => {
		if (userId && user) {
			FirestoreUserModel.saveProgress(userId, courseId, topicId);
			trackEvent(
				EventTypes.OC,
				'viewed',
				{
					inputIds: [topicId],
				},
				{ userId },
			);
		}
	});

	return (
		<LayoutView isSignedIn={true}>
			<WrapperView
				height={250}
				width="100%"
				style={{ overflow: 'hidden' }}
			>
				<ButtonRoundedView
					styles={{
						touchable: {
							position: 'absolute',
							zIndex: 2,
							top: 0,
							left: 0,
						},
						icon: { marginRight: 3 },
					}}
					wrapper={20}
					styleOption={BUTTON_ROUNDED_OPTIONS.GrayOnWhite}
					icon={ICON_CODES.Back}
					size={BUTTON_ROUNDED_SIZES.lg}
					shadow={true}
					onClick={history.goBack}
				/>
				{imageURL && (
					<FastImage
						style={{
							width: '100%',
							paddingTop: '64%',
							position: 'absolute',
							top: 0,
						}}
						source={{ uri: imageURL.uri }}
					/>
				)}
			</WrapperView>
			<TopicStartPageSubcontroller
				courseId={courseId}
				topicId={topicId}
			/>
		</LayoutView>
	);
};
