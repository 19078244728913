import firebase from 'firebase';
import Sentry from 'modules/sentry-xp';

export const trackOrigin = (uid: string) => {
	const searchRegex = RegExp('(^|;) ?search=([^;]*)(;|$)', 'g');
	const referrerRegex = RegExp('(^|;) ?referrer=([^;]*)(;|$)', 'g');
	let search = searchRegex.exec(document.cookie);
	let referrer = referrerRegex.exec(document.cookie);
	const parameters: any = {};
	// Search parameters have precedence over the referral
	// This schema is inspired by https://support.google.com/analytics/answer/6205762?hl=de
	if (search) {
		const searchParams = search[2].substring(1);

		if (searchParams) {
			searchParams.split('&').map(element => {
				const keyValueSplit = element.split('=');
				parameters[keyValueSplit[0]] = keyValueSplit[1];
			});
		}
	} else if (referrer && !parameters.utm_medium && !parameters.utm_source) {
		parameters.utm_source = referrer[2];
		if (isStandardSearch(referrer[2])) {
			parameters.utm_medium = 'organic';
		} else {
			parameters.utm_medium = 'referral';
		}
	}
	firebase
		.firestore()
		.doc(`user-origin/${uid}`)
		.set(parameters, { merge: true })
		.catch(error => {
			// We only want to catch the first event and don't overwrite it. This is reflected in the rules
			if (error.code === 'permission-denied') {
				console.log('Entry already set');
			} else {
				Sentry.captureException(error);
			}
		});
};

const isStandardSearch = (host: string) => {
	// Include more engines from standard list if necessary (https://support.google.com/analytics/answer/2795821)
	const standardList = ['google', 'bing.com', 'duckduckgo.com', 'ecosia.org'];
	return new RegExp(standardList.join('|')).test(host);
};
