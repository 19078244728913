import React from 'react';
import { useOnce } from './use-once.hook';

interface IState {
	width?: number;
	viewport?: Element;
}
export const useWebViewportWidth = (viewportId: string) => {
	const [state, setState] = React.useState<IState>({
		width: undefined,
		viewport: undefined,
	});

	const updateWith = React.useCallback(() => {
		setState(currentState => {
			if (
				currentState.viewport &&
				currentState.width != currentState.viewport.clientWidth
			) {
				currentState.width = currentState.viewport.clientWidth;
				return { ...currentState };
			} else {
				return currentState;
			}
		});
	}, []);

	React.useEffect(() => {
		return () => {
			window.removeEventListener('resize', updateWith);
		};
	}, [updateWith]);

	useOnce(() => {
		let viewport = document.querySelector(viewportId);
		if (viewport) {
			state.viewport = viewport;
			state.width = viewport.clientWidth;
			setState({ ...state });
			window.removeEventListener('resize', updateWith);
			window.addEventListener('resize', updateWith);
		} else {
			window.setTimeout(() => {
				viewport = document.querySelector(viewportId);
				if (viewport) {
					state.viewport = viewport;
					state.width = viewport.clientWidth;
					setState({ ...state });
					window.removeEventListener('resize', updateWith);
					window.addEventListener('resize', updateWith);
				}
			}, 1);
		}
	});
	return state.width;
};
