import LottieView from 'lottie-react-native';
import * as React from 'react';
import { View, ImageURISource, Image } from 'react-native';
import styled from 'styled-components/native';
import { COLOR_CODES } from '../enums/colors';
import { TextIconView, ICON_SIZES, ICON_CODES } from '../texts/text-icon.view';
import { ButtonView, IButtonProps } from './button.view';
import {
	IWrapperStyles,
	extractWrapperProps,
} from '../wrappers/wrapper-interfaces';
import { INativeProps } from '../wrappers/wrapper-interfaces';
import { WrapperView } from '../wrappers/wrapper.view';

export enum BUTTON_ROUNDED_SIZES {
	xs = 11,
	sm = 13,
	md = 16,
	xmd = 24,
	lg = 25,
	xl = 30,
}

export enum BUTTON_ROUNDED_OPTIONS {
	WhiteOnPink,
	GrayOnWhite,
	PinkOnWhite,
}

export interface IButtonRoundedStyles extends IWrapperStyles {
	container?: object;
	icon?: object;
	shadow?: object;
	touchable?: object;
}
export interface IButtonRoundedProps extends IButtonProps {
	/** Icon -- enum ICON_CODES*/
	icon?: ICON_CODES;
	styles?: IButtonRoundedStyles;
	/** Button size  -- enum BUTTON_ROUNDED_SIZES*/
	size: BUTTON_ROUNDED_SIZES;
	animatedDots?: boolean;
	/** Button Color  -- enum COLOR_CODES*/
	shadow?: boolean;
	styleOption?: BUTTON_ROUNDED_OPTIONS;
	image?: ImageURISource;
}

const getBackgroundColor = (
	styleOption: BUTTON_ROUNDED_OPTIONS = BUTTON_ROUNDED_OPTIONS.WhiteOnPink,
) => {
	if (styleOption == BUTTON_ROUNDED_OPTIONS.WhiteOnPink) {
		return COLOR_CODES.Pink;
	} else {
		return COLOR_CODES.White;
	}
};

const getLabelColor = (
	styleOption: BUTTON_ROUNDED_OPTIONS = BUTTON_ROUNDED_OPTIONS.WhiteOnPink,
) => {
	if (styleOption == BUTTON_ROUNDED_OPTIONS.GrayOnWhite) {
		return COLOR_CODES.Grey75;
	} else if (styleOption == BUTTON_ROUNDED_OPTIONS.PinkOnWhite) {
		return COLOR_CODES.Pink;
	} else {
		return COLOR_CODES.White;
	}
};

const getIconSize = (buttonSize: BUTTON_ROUNDED_SIZES) => {
	if (buttonSize === BUTTON_ROUNDED_SIZES.xs) {
		return ICON_SIZES.xs;
	} else if (buttonSize === BUTTON_ROUNDED_SIZES.sm) {
		return ICON_SIZES.sm;
	} else if (buttonSize === BUTTON_ROUNDED_SIZES.lg) {
		return ICON_SIZES.lg;
	} else if (buttonSize === BUTTON_ROUNDED_SIZES.xmd) {
		return ICON_SIZES.ml;
	} else if (buttonSize === BUTTON_ROUNDED_SIZES.xl) {
		return ICON_SIZES.xl;
	} else {
		return ICON_SIZES.md;
	}
};

const ButtonRoundedDefaultContainerView = styled(
	(props: IButtonRoundedProps & INativeProps) => {
		return <View style={props.style}>{props.children}</View>;
	},
)`
	height: ${(props: IButtonRoundedProps) => props.size * 2};
	width: ${(props: IButtonRoundedProps) => props.size * 2};
	justify-content: center;
	align-items: center;
	border-radius: ${(props: IButtonRoundedProps) => props.size};
	background-color: ${(props: IButtonRoundedProps) =>
		props.onClick
			? getBackgroundColor(props.styleOption)
			: COLOR_CODES.Grey50};
	shadow-color: ${(props: IButtonRoundedProps) =>
		props.shadow ? COLOR_CODES.Black : COLOR_CODES.Transparent};
	shadow-radius: ${(props: IButtonRoundedProps) => (props.shadow && 4) || 0};
	shadow-opacity: ${(props: IButtonRoundedProps) =>
		(props.shadow && 0.15) || 0};
	shadow-offset: 0px 2px;
	elevation: ${(props: IButtonRoundedProps) => (props.shadow && 2) || 0};
	${(props: IButtonRoundedProps) => {
		return {
			...((props.styles && props.styles.container) ?? {}),
		};
	}};
`;

const ButtonRoundedContainerView = (props: IButtonRoundedProps) => {
	return <ButtonRoundedDefaultContainerView {...props} />;
};

export class ButtonRoundedView extends React.PureComponent<IButtonRoundedProps> {
	animation: any;

	componentDidMount() {
		if (this.animation) {
			this.animation.play();
		}
	}

	componentDidUpdate() {
		if (this.animation) {
			this.animation.play();
		}
	}

	render() {
		const wrapperProps = extractWrapperProps(this.props);
		return (
			<ButtonView
				onClick={this.props.onClick}
				styles={{ touchable: this.props.styles?.touchable }}
			>
				<WrapperView {...wrapperProps}>
					<ButtonRoundedContainerView {...this.props}>
						{this.props.animatedDots && (
							<LottieView
								style={{ width: 20 }}
								ref={animation => {
									this.animation = animation;
								}}
								source={require('./assets/whitedots.json')}
							/>
						)}
						{!this.props.animatedDots && this.props.icon && (
							<TextIconView
								color={
									this.props.onClick
										? getLabelColor(this.props.styleOption)
										: COLOR_CODES.White
								}
								fontScaling={false}
								icon={this.props.icon}
								size={getIconSize(this.props.size)}
								styles={{
									text:
										(this.props.styles &&
											this.props.styles.icon) ||
										{},
								}}
							/>
						)}
						{!this.props.animatedDots && this.props.image && (
							<Image
								source={this.props.image}
								style={{
									borderRadius: this.props.size,
									overflow: 'hidden',
									width: this.props.size * 2,
									height: this.props.size * 2,
								}}
							/>
						)}
						{this.props.children}
					</ButtonRoundedContainerView>
				</WrapperView>
			</ButtonView>
		);
	}
}
