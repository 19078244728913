import {
	ButtonTertiaryView,
	BUTTON_TERTIARY_THEMES,
	IButtonTertiaryProps,
} from '@nui/views';
import React from 'react';
import styled from 'styled-components/native';

import { ALIGN } from '../../../../wrappers/wrapper-align.view';
import { INativeProps } from '../../../../wrappers/wrapper-interfaces';
import { WrapperListView } from '../../../../wrappers/wrapper-list.view';

export enum ACCORDION_BUTTON_TYPE {
	linkButton = 'linkButton',
}

interface IAccordionButtonsProps {
	buttons: IButtonTertiaryProps[];
}

export const AccordionButtonsView = styled(
	(props: IAccordionButtonsProps & INativeProps) => {
		return (
			<WrapperListView
				wrapperBetween={7}
				wrapperTop={20}
				itemsAlign={ALIGN.End}
			>
				{props.buttons
					.map((button, index) => {
						return (
							<ButtonTertiaryView
								key={index}
								theme={BUTTON_TERTIARY_THEMES.Pink}
								isFilled={true}
								label={button.label}
								onClick={button.onClick}
							/>
						);
					})
					.filter(item => !!item)}
			</WrapperListView>
		);
	},
)``;
