import { FONT_WEIGHTS } from '../../../../texts/text-interfaces';
import React from 'react';
import { Text } from 'react-native';
import styled from 'styled-components/native';

import {
	ButtonView,
	extractButtonProps,
	IButtonProps,
} from '../../../../buttons/button.view';
import { COLOR_CODES } from '../../../../enums/colors';
import {
	extractWrapperProps,
	INativeProps,
} from '../../../../wrappers/wrapper-interfaces';

export interface ILinkButtonProps extends IButtonProps {
	label: string;
	onClick?: () => void;
}

const LinkButtonContainerView = styled.View`
	min-height: 35px;
	border: 2px solid ${COLOR_CODES.Blue};
	border-radius: 18px;
	align-items: center;
	justify-content: center;
	padding: 0 12px;
`;

export const LinkButtonView = styled(
	(props: ILinkButtonProps & INativeProps) => {
		return (
			<ButtonView
				{...extractButtonProps(props)}
				{...extractWrapperProps(props)}
			>
				<LinkButtonContainerView>
					<Text style={props.style}>{props.label}</Text>
				</LinkButtonContainerView>
			</ButtonView>
		);
	},
)`
	color: ${COLOR_CODES.Blue};
	line-height: 20px;
	font-size: 17;
	letter-spacing: 0.5;
	font-weight: ${FONT_WEIGHTS.semiBold};
`;
