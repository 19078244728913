/* eslint-disable @typescript-eslint/no-unused-vars */
export default {
	createResizedImage: (
		uri: string,
		width?: number,
		height?: number,
		format?: 'PNG' | 'JPEG' | 'WEBP',
		quality?: number,
		rotation?: number,
		outputPath?: string,
		keepMeta?: boolean,
	) => {
		return {
			uri,
		};
	},
};
