import React from 'react';
import {
	LayoutAnimation,
	View,
	UIManager,
	useWindowDimensions,
} from 'react-native';

import styled from 'styled-components/native';

import {
	BUTTON_ROUNDED_SIZES,
	BUTTON_ROUNDED_OPTIONS,
	ButtonRoundedView,
} from '../buttons/button-rounded.view';
import { COLOR_CODES } from '../enums/colors';
import { TextCaptionSemiboldView } from '../texts/text-caption-semibold.view';
import { ICON_CODES } from '../texts/text-icon.view';
import { TextTitleTitle3View } from '../texts/text-title-title3.view';
import {
	ALIGN,
	DIRECTION,
	WrapperAlignView,
} from '../wrappers/wrapper-align.view';
import { INativeProps } from '../wrappers/wrapper-interfaces';
import { WrapperView } from '../wrappers/wrapper.view';

UIManager.setLayoutAnimationEnabledExperimental &&
	UIManager.setLayoutAnimationEnabledExperimental(true);

export interface ICourseChaptersHeaderProps {
	onLeftClick?: () => void;
	onRightClick?: () => void;
	title?: string;
	content: {
		statusbar: {
			labelOf: string;
			labelChapters: string;
		};
	};
	statusbar: {
		chardCounts: { [chapterIndex: string]: number };
		chapterIndex: number;
		cardIndex: { [chapterIndex: string]: number };
	};
	screenWidth: number;
}

const HeaderCenterView = (props: ICourseChaptersHeaderProps) => {
	if (Object.keys(props.statusbar.chardCounts).length > 0) {
		return (
			<WrapperAlignView
				itemsDirection={DIRECTION.Vertical}
				itemsAlign={ALIGN.Stretch}
				wrapperTop={5}
				wrapperRight={props.screenWidth > 320 ? 30 : 20}
				styles={{ wrapper: { flex: 1 } }}
			>
				{props.title &&
					((
						<TextTitleTitle3View
							nativeTextProps={{
								adjustsFontSizeToFit: true,
								numberOfLines: 2,
							}}
						>
							{props.title}
						</TextTitleTitle3View>
					) ||
						null)}
				<TextCaptionSemiboldView
					wrapperTop={6}
					color={COLOR_CODES.SteelGrey}
				>
					{props.statusbar.chapterIndex + 1}{' '}
					{props.content.statusbar.labelOf}{' '}
					{Object.keys(props.statusbar.chardCounts).length}{' '}
					{props.content.statusbar.labelChapters}
				</TextCaptionSemiboldView>
				{<ChapterBarsView {...props} />}
			</WrapperAlignView>
		);
	} else {
		return (
			<WrapperAlignView
				itemsDirection={DIRECTION.Vertical}
				itemsAlign={ALIGN.Stretch}
			>
				{props.title &&
					(<TextTitleTitle3View>{props.title}</TextTitleTitle3View> ||
						null)}
			</WrapperAlignView>
		);
	}
};

const ChapterBarsView = (props: ICourseChaptersHeaderProps) => {
	if (Object.keys(props.statusbar.chardCounts).length > 0) {
		const bars: any[] = [];
		for (const i in props.statusbar.chardCounts) {
			const chapterIndex = parseInt(i, 10);
			if (props.statusbar.chapterIndex > chapterIndex) {
				bars.push(<ChapterBarView key={i} percent={100} />);
			} else if (props.statusbar.chapterIndex == chapterIndex) {
				bars.push(
					<ChapterBarView
						key={i}
						percent={Math.ceil(
							((props.statusbar.cardIndex[i] + 1) /
								props.statusbar.chardCounts[i]) *
								100,
						)}
					/>,
				);
			} else {
				bars.push(<ChapterBarView key={i} percent={0} />);
			}
		}

		return (
			<WrapperAlignView
				wrapperTop={5}
				itemsDirection={DIRECTION.Horizontal}
			>
				{bars}
			</WrapperAlignView>
		);
	} else {
		return null;
	}
};

interface ChapterBarProps {
	style?: any;
	percent: number;
}

const DURATION = 200;

const animationConfiguration = {
	duration: DURATION,
	create: {
		type: LayoutAnimation.Types.easeInEaseOut,
		property: 'scaleXY',
	},
	update: {
		type: LayoutAnimation.Types.easeInEaseOut,
		property: 'scaleXY',
	},
	delete: {
		type: LayoutAnimation.Types.easeInEaseOut,
		property: 'scaleXY',
	},
};

const ChapterBarView = styled((props: ChapterBarProps & INativeProps) => {
	const [percent, setPercent] = React.useState(props.percent);

	React.useLayoutEffect(() => {
		if (props.percent != percent) {
			LayoutAnimation.configureNext(animationConfiguration);
			setPercent(props.percent);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.percent]);

	return (
		<WrapperAlignView itemsAlign={ALIGN.Stretch} style={props.style}>
			<View
				style={{
					width: percent + '%',
					backgroundColor: COLOR_CODES.Pink,
					borderRadius: 2,
				}}
			/>
		</WrapperAlignView>
	);
})`
	background-color: ${COLOR_CODES.LightBlue};
	margin: 2px;
	height: 4px;
	border-radius: 2px;
	flex: 1;
	overflow: hidden;
`;

export const CourseChaptersHeaderView = (props: ICourseChaptersHeaderProps) => {
	const windowWidth = useWindowDimensions().width;

	return (
		<WrapperAlignView
			height={95}
			itemsAlign={ALIGN.Center}
			styles={{ wrapper: { maxWidth: windowWidth } }}
		>
			{(props.onLeftClick && (
				<ButtonRoundedView
					wrapperRight={10}
					wrapperLeft={5}
					size={BUTTON_ROUNDED_SIZES.xmd}
					icon={ICON_CODES.Back}
					shadow={true}
					styleOption={BUTTON_ROUNDED_OPTIONS.GrayOnWhite}
					onClick={props.onLeftClick}
					styles={{
						icon: { marginRight: 3 },
						container: { marginRight: 10, marginLeft: 10 },
					}}
				/>
			)) || <WrapperView wrapperLeft={15} />}
			<HeaderCenterView {...props} />
			{(props.onRightClick && (
				<View
					style={{ width: Number(BUTTON_ROUNDED_SIZES.xmd) * 2 + 40 }}
				/>
			)) || <WrapperView wrapperLeft={15} />}
		</WrapperAlignView>
	);
};
