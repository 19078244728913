import { EventTypes, useTrackEvent } from '@nui/providers';
import React, { useEffect } from 'react';
import { LayoutView } from '../../../views/layout.view';
import { HomescreenHeaderController } from '../components/homescreen-header.controller';
import { HomescreenOfferingsController } from '../components/homescreen-offerings.controller';
import { stateModel } from '../../../../globals';

export const HomescreenController = () => {
	const teamId = stateModel.getTeamId();
	const userId = stateModel.stateAuthModel.getAuth()?.uid;
	const trackEvent = useTrackEvent();
	useEffect(() => {
		trackEvent(EventTypes.NAV, 'homescreen-mount', undefined, {
			userId,
			teamId,
		});
		// Adding dependencies here would cause the effect to run twice
		// We should inspect why and how teamId and userId change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<LayoutView isSignedIn={true}>
			<HomescreenHeaderController />
			<HomescreenOfferingsController />
		</LayoutView>
	);
};
