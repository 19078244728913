import AsyncStorage from '@react-native-community/async-storage';
import { firebase } from 'firebase-xp';
import { ImageURISource } from 'react-native';
import { Cache } from 'react-native-cache';

const imageURICache: {
	[bucket: string]: {
		pathCollection: {
			[path: string]: () => Promise<ImageURISource> | ImageURISource;
		};
		cache: Cache;
	};
} = {};

enum BUCKET_TYPE {
	uri = 'uri',
	default = 'default',
}

export class StorageProvider {
	static uploadImage(image: ImageURISource, path: string): Promise<string> {
		return new Promise(async (resolve, reject) => {
			try {
				await firebase.auth().currentUser?.getIdToken(true);
				if (image.uri) {
					const uploadTask = firebase
						.storage()
						.ref(path)
						.putFile(image.uri);
					uploadTask.on(
						firebase.storage.TaskEvent.STATE_CHANGED,
						async snapshot => {
							if (
								snapshot.state ===
								firebase.storage.TaskState.SUCCESS
							) {
								const downloadURLWithToken = await snapshot.ref.getDownloadURL();
								if (downloadURLWithToken) {
									resolve(downloadURLWithToken);
								} else {
									reject();
								}
							}
						},
						error => {
							reject(error);
						},
					);
				} else {
					reject();
				}
			} catch (error) {
				reject(error);
			}
		});
	}

	static getDownloadURL(
		path: string,
		bucket?: string,
	): () => Promise<ImageURISource> | ImageURISource {
		const bucketKey = path.startsWith('gs://')
			? BUCKET_TYPE.uri
			: bucket || BUCKET_TYPE.default;
		if (!imageURICache[bucketKey]) {
			imageURICache[bucketKey] = {
				pathCollection: {},
				cache: new Cache({
					namespace: bucketKey,
					policy: {
						maxEntries: 1000,
					},
					backend: AsyncStorage,
				}),
			};
		}
		if (!imageURICache[bucketKey].pathCollection[path]) {
			imageURICache[bucketKey].pathCollection[path] = () => {
				let resolved = false;
				return new Promise((resolve, reject) => {
					imageURICache[bucketKey].cache
						.get(path)
						.then(cachedURL => {
							if (cachedURL) {
								imageURICache[bucketKey].pathCollection[
									path
								] = () => ({
									uri: cachedURL,
								});
								!resolved &&
									resolve({
										uri: cachedURL,
									});
								resolved = true;
							}
							let ref;
							if (bucketKey == BUCKET_TYPE.uri) {
								ref = firebase.app().storage().refFromURL(path);
							} else {
								ref = firebase.app().storage(bucket).ref(path);
							}
							ref.getDownloadURL()
								.then(storageURL => {
									imageURICache[bucketKey].cache
										.set(path, storageURL)
										.catch(error => console.log(error));
									imageURICache[bucketKey].pathCollection[
										path
									] = () => ({
										uri: storageURL,
									});
									!resolved &&
										resolve({
											uri: storageURL,
										});
									resolved = true;
								})
								.catch(() => {
									reject();
								});
						})
						.catch(error => console.log(error));
				});
			};
		}
		return imageURICache[bucketKey].pathCollection[path];
	}
}
