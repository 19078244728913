import React from 'react';
import styled from 'styled-components/native';
import { INativeProps } from '../wrappers/wrapper-interfaces';
import { FONT_WEIGHTS, ITextProps } from './text-interfaces';
import { TextView } from './text.view';

export const TextTitleView = styled((props: ITextProps & INativeProps) => (
	<TextView {...props} />
))`
	font-size: 28;
	letter-spacing: 0.34;
	line-height: 34;
	font-weight: ${(props: ITextProps) => props.weight || FONT_WEIGHTS.bold};
	${(props: ITextProps) => ({
		...props.styles?.text,
	})};
`;
