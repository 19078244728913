import styled from 'styled-components/native';

export const Picker = styled.Picker`
	padding: 10px;
	-webkit-appearance: none;
	-moz-appearance: none;
	background: transparent;
	background-image: url("data:image/svg+xml;utf8,<svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='icon/disclosureIndicator/down' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><path d='M11.9985714,13.3592455 L16.535337,8.8224799 C16.8700722,8.48774479 17.412785,8.48774479 17.7475201,8.8224799 C18.0822552,9.15721501 18.0822552,9.69992785 17.7475201,10.034663 L12.604663,15.1775201 C12.2699278,15.5122552 11.727215,15.5122552 11.3924799,15.1775201 L6.24962276,10.034663 C5.91488765,9.69992785 5.91488765,9.15721501 6.24962276,8.8224799 C6.58435787,8.48774479 7.12707071,8.48774479 7.46180581,8.8224799 L11.9985714,13.3592455 Z' id='disclosure-indicators' fill='%234A4A4A'></path></g></svg>");
	background-repeat: no-repeat;
	background-position-x: 98%;
	background-position-y: 11px;
	border: 1px solid #dbdbd8;
	border-radius: 10px;
`;
