import { IParagraph } from '@nui/utils';
import React from 'react';
import { TextBodyDefaultView } from '../texts/text-body-default.view';
import { TextCaptionSemiboldView } from '../texts/text-caption-semibold.view';
import { TextTitleTitle1View } from '../texts/text-title-title1.view';
import {
	extractWrapperProps,
	IWrapperProps,
} from '../wrappers/wrapper-interfaces';
import { WrapperView } from '../wrappers/wrapper.view';

export interface IModuleIntro extends IWrapperProps {
	title: string;
	courseLength?: string;
	courseIntro: IParagraph[];
}

export const ModuleIntro = (props: IModuleIntro) => {
	const wrapperProps = extractWrapperProps(props);

	return (
		<WrapperView {...wrapperProps}>
			<TextTitleTitle1View wrapperBottom={5}>
				{props.title}
			</TextTitleTitle1View>
			<TextCaptionSemiboldView wrapperBottom={20}>
				{props.courseLength}
			</TextCaptionSemiboldView>
			{props.courseIntro.map((paragraph, index) => {
				return (
					<TextBodyDefaultView
						wrapperBottom={(paragraph.lineBreak && 12) || 0}
						key={index}
						htmlChunks={paragraph.chunks}
					/>
				);
			})}
		</WrapperView>
	);
};
