import { ConvertersUtil, IContent } from '@nui/utils';
import {
	FirebaseCourseTopicModel,
	IFirebaseCourseTopic,
} from './firebase-course-topic.model';

export enum ContentType {
	info = 'info',
	benefit = 'benefit',
}

export interface IFirebaseCourse {
	intro: IContent;
	caption: IContent;
	featuredCaption: IContent;
	bigCardCaption: IContent;
	title: IContent;
	pos: number;
	topicCount: number;
	chapterCount: number;
	topics: {
		[name: string]: IFirebaseCourseTopic;
	};
	id: string;
	type: ContentType;
}

export class FirebaseCourseModel {
	static toCourse(courseDTO: any): IFirebaseCourse | undefined {
		const intro = ConvertersUtil.toContent(courseDTO?.intro);
		const caption = ConvertersUtil.toContent(courseDTO?.caption);
		const featuredCaption = ConvertersUtil.toContent(
			courseDTO?.featuredCaption,
		);
		const bigCardCaption = ConvertersUtil.toContent(
			courseDTO?.bigCardCaption,
		);
		const title = ConvertersUtil.toContent(courseDTO?.title);
		const pos = ConvertersUtil.toNumber(courseDTO?.pos);
		const topicCount = ConvertersUtil.toNumber(courseDTO?.topicCount) || 0;
		const chapterCount =
			ConvertersUtil.toNumber(courseDTO?.chapterCount) ?? 0;

		const topics = ConvertersUtil.toHashtable<IFirebaseCourseTopic>(
			courseDTO?.topics,
			{},
			0,
			topicDTO => FirebaseCourseTopicModel.toTopic(topicDTO),
		);

		const id = ConvertersUtil.toString(courseDTO?.id) || 'XXX';
		const type = courseDTO?.type;

		if (
			intro != undefined &&
			caption != undefined &&
			featuredCaption != undefined &&
			bigCardCaption != undefined &&
			title != undefined &&
			topics != undefined &&
			pos != undefined &&
			type != undefined
		) {
			return {
				intro,
				caption,
				featuredCaption,
				bigCardCaption,
				title,
				topics,
				pos,
				topicCount,
				chapterCount,
				id,
				type,
			};
		} else {
			console.log('Course entity is undefined', {
				courseDTO,
				caption,
				intro,
				featuredCaption,
				bigCardCaption,
				title,
				topics,
				pos,
				topicCount,
				chapterCount,
				id,
				type,
			});
			return undefined;
		}
	}
}
