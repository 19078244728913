import { ReactNode } from 'react';
import { COLOR_CODES } from '../enums/colors';

export interface IWrapperStyles {
	wrapper?: IStyles;
}

export interface IStyles {
	[key: string]: any;
}

export interface INativeProps {
	children?: ReactNode;
	style?: any;
	className?: string;
}

export interface IWrapperProps {
	padding?: number[];
	wrapper?: number;
	wrapperLeft?: number;
	wrapperRight?: number;
	wrapperBottom?: number;
	wrapperTop?: number;
	border?: COLOR_CODES;
	borderLeft?: COLOR_CODES | 'none';
	borderRight?: COLOR_CODES | 'none';
	borderBottom?: COLOR_CODES | 'none';
	borderTop?: COLOR_CODES | 'none';
	borderWidth?: number;
	styles?: IWrapperStyles;
	height?: number;
	minHeight?: number;
	width?: number | string;
	background?: COLOR_CODES;
}

export const extractWrapperProps = <T extends IWrapperProps>(
	props: T & INativeProps,
): IWrapperProps => {
	return {
		wrapper: props.wrapper,
		wrapperLeft: props.wrapperLeft,
		wrapperRight: props.wrapperRight,
		wrapperBottom: props.wrapperBottom,
		wrapperTop: props.wrapperTop,
		border: props.border,
		borderLeft: props.borderLeft,
		borderRight: props.borderRight,
		borderBottom: props.borderBottom,
		borderTop: props.borderTop,
		borderWidth: props.borderWidth,
		styles: props.styles,
		height: props.height,
		minHeight: props.minHeight,
		width: props.width,
		background: props.background,
		padding: props.padding,
	};
};
