import React from 'react';
import styled from 'styled-components/native';
import { Text, Linking } from 'react-native';
import { INativeProps, IStyles } from '../../wrappers/wrapper-interfaces';
import { ITextProps } from '../text-interfaces';
import { COLOR_CODES } from '../../enums/colors';

interface IHtmlUrlProps extends ITextProps {
	url?: string;
	extraStyles?: IStyles;
}

export const HtmlUrlView = styled((props: IHtmlUrlProps & INativeProps) => {
	const [opacity, setOpacity] = React.useState(1);

	const handleOnPress = () => {
		Linking.openURL(props.url || '');
		setOpacity(0.5);
		setTimeout(() => {
			setOpacity(1);
		}, 100);
	};

	return (
		<Text
			{...props.nativeTextProps}
			onPress={handleOnPress}
			style={[props.style, { opacity }]}
			suppressHighlighting={!!props.url}
		>
			{props.children}
		</Text>
	);
})`
	color: ${COLOR_CODES.Pink};
	${(props: IHtmlUrlProps) => ({
		...props.styles?.text,
		...props.extraStyles,
	})};
`;
