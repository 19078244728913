import { HelpersUtil } from '@nui/utils';
import { FirebaseFirestoreTypes } from '@react-native-firebase/firestore';
import { firebase } from 'firebase-xp';

type TInitialised = (entityDTOs: { [entityId: string]: object }) => void;
type IAdded = (entityDTOs: { [entityId: string]: object }) => void;
type IRemoved = (entityDTOs: { [entityId: string]: object }) => void;
type IModified = (entityDTOs: { [entityId: string]: object }) => void;
type IFailed = (error: Error) => void;
type IRejected = (error: Error) => void;

export class FirestoreCollectionObserverEvents {
	handleInitialized?: TInitialised;
	handleAdded?: IAdded;
	handleRemoved?: IRemoved;
	handleModified?: IModified;
	handleFailed?: IFailed;
	handleRejected?: IRejected;

	constructor(
		promiseHandler: (
			initialized: TInitialised,
			added: IAdded,
			removed: IRemoved,
			modified: IModified,
			failed: IFailed,
			rejected: IRejected,
		) => void,
	) {
		this.onInitialized = this.onInitialized.bind(this);
		this.onAdded = this.onAdded.bind(this);
		this.onRemoved = this.onRemoved.bind(this);
		this.onModified = this.onModified.bind(this);
		this.onFailed = this.onFailed.bind(this);
		this.onRejected = this.onRejected.bind(this);
		promiseHandler(
			this.onInitialized,
			this.onAdded,
			this.onRemoved,
			this.onModified,
			this.onFailed,
			this.onRejected,
		);
	}

	public initialized(
		handleInitialized: TInitialised,
	): FirestoreCollectionObserverEvents {
		this.handleInitialized = handleInitialized;
		return this;
	}

	public added(handleAdded: IAdded): FirestoreCollectionObserverEvents {
		this.handleAdded = handleAdded;
		return this;
	}

	public removed(handleRemoved: IRemoved): FirestoreCollectionObserverEvents {
		this.handleRemoved = handleRemoved;
		return this;
	}

	public modified(
		handleModified: IModified,
	): FirestoreCollectionObserverEvents {
		this.handleModified = handleModified;
		return this;
	}

	public failed(handleFailed: IFailed): FirestoreCollectionObserverEvents {
		this.handleFailed = handleFailed;
		return this;
	}

	public rejected(
		handleRejected: IRejected,
	): FirestoreCollectionObserverEvents {
		this.handleRejected = handleRejected;
		return this;
	}

	protected onInitialized(entityDTOs: { [entityId: string]: object }): void {
		if (this.handleInitialized) {
			this.handleInitialized(entityDTOs);
		}
	}

	protected onAdded(entityDTOs: { [entityId: string]: object }): void {
		if (this.handleAdded) {
			this.handleAdded(entityDTOs);
		}
	}

	protected onRemoved(entityDTOs: { [entityId: string]: object }): void {
		if (this.handleRemoved) {
			this.handleRemoved(entityDTOs);
		}
	}

	protected onModified(entityDTOs: { [entityId: string]: object }): void {
		if (this.handleModified) {
			this.handleModified(entityDTOs);
		}
	}

	protected onFailed(error: Error): void {
		if (this.handleFailed) {
			this.handleFailed(error);
		}
	}

	protected onRejected(error: Error): void {
		if (this.handleRejected) {
			this.handleRejected(error);
		}
	}
}

export class FirestoreCollectionProvider {
	protected unsubscribe?: () => void;
	collectionReference: FirebaseFirestoreTypes.CollectionReference;

	constructor(
		collectionReference: FirebaseFirestoreTypes.CollectionReference,
	) {
		this.collectionReference = collectionReference;
	}

	public getPath() {
		return this.collectionReference.path;
	}

	public unsubscribeObserver() {
		if (this.unsubscribe) {
			this.unsubscribe();
		}
	}

	public subscribeObserver(): FirestoreCollectionObserverEvents {
		this.unsubscribeObserver();
		return new FirestoreCollectionObserverEvents(
			(initialized, added, removed, modified, failed, reject) => {
				let initialize = true;
				this.unsubscribe = this.collectionReference.onSnapshot(
					querySnapshot => {
						let count = querySnapshot.docChanges().length;
						const docsInitialized = {};
						const docsAdded = {};
						const docsModified = {};
						const docsRemoved = {};
						if (count > 0) {
							querySnapshot.docChanges().forEach(change => {
								count--;
								if (initialize) {
									if (change.type === 'added') {
										docsInitialized[
											change.doc.id
										] = change.doc.data();
									}
									if (count == 0) {
										initialize = false;
										initialized(docsInitialized);
									}
								} else {
									if (change.type === 'added') {
										docsAdded[
											change.doc.id
										] = change.doc.data();
									}
									if (change.type === 'modified') {
										docsModified[
											change.doc.id
										] = change.doc.data();
									}
									if (change.type === 'removed') {
										docsRemoved[
											change.doc.id
										] = change.doc.data();
									}
									if (count == 0) {
										if (
											HelpersUtil.hasProperties(docsAdded)
										) {
											added(docsAdded);
										}
										if (
											HelpersUtil.hasProperties(
												docsModified,
											)
										) {
											modified(docsModified);
										}
										if (
											HelpersUtil.hasProperties(
												docsRemoved,
											)
										) {
											removed(docsRemoved);
										}
									}
								}
							});
						} else {
							if (initialize) {
								initialize = false;
								initialized({});
							}
						}
					},
					error => {
						if (initialize) {
							failed(error);
							initialize = false;
						} else {
							reject(error);
						}
						this.unsubscribeObserver();
					},
				);
			},
		);
	}

	public generateId() {
		return this.collectionReference.doc().id;
	}

	public async addBatch<DTO>(docs: DTO[]) {
		const batch = firebase.firestore().batch();
		docs.forEach(doc => {
			const newEntryDocRef = this.collectionReference.doc();
			batch.set(newEntryDocRef, doc);
		});
		return batch.commit();
	}

	public async removeBatch(docIds: string[]) {
		const batch = firebase.firestore().batch();
		const collectionPath = this.collectionReference.path;
		docIds.forEach(id => {
			const docRef = firebase.firestore().doc(`${collectionPath}/${id}`);
			batch.delete(docRef);
		});
		return batch.commit();
	}

	public async updateBatch<DTO>(entities: { [entityId: string]: DTO }) {
		const batch = firebase.firestore().batch();
		const collectionPath = this.collectionReference.path;
		for (const entityId in entities) {
			const docRef = firebase
				.firestore()
				.doc(`${collectionPath}/${entityId}`);
			batch.update(docRef, entities[entityId]);
		}
		return batch.commit();
	}

	public add(doc: object): Promise<{ entityId: string; entity: object }> {
		return new Promise(async (resolve, reject) => {
			try {
				const ref = await this.collectionReference.add(doc);
				const snapshot = await ref.get();
				const entity = snapshot.data();
				if (entity) {
					resolve({
						entityId: snapshot.id,
						entity,
					});
				} else {
					reject();
				}
			} catch (error) {
				reject();
			}
		});
	}
}
