import React from 'react';
import styled from 'styled-components/native';
import { COLOR_CODES } from '../enums/colors';
import { AVATAR_RADIUS } from '../fallbacks/svg-avatar.view';
import { AvatarView, IAvatarProps } from '../misc/avatar.view';
import { BadgeView } from '../misc/badge.view';
import { ICON_CODES, TextIconView } from '../texts/text-icon.view';
import { INativeProps } from '../wrappers/wrapper-interfaces';
import { WrapperView } from '../wrappers/wrapper.view';
import { ButtonView, IButtonProps } from './button.view';

export interface IButtonAvatarProps extends IButtonProps, IAvatarProps {
	showPlusButton?: boolean;
	showBadge?: boolean;
	badgeOutline?: boolean;
}

const getIconSize = (avatarRadius?: AVATAR_RADIUS) => {
	return Number(avatarRadius || AVATAR_RADIUS.md) / 2.5;
};

const getBadgeSize = (avatarRadius?: AVATAR_RADIUS) => {
	return Number(avatarRadius || AVATAR_RADIUS.lg) / 5;
};

export const ButtonPlusView = styled(
	(props: IButtonAvatarProps & INativeProps) => {
		return (
			<WrapperView style={props.style}>
				<TextIconView
					size={getIconSize(props.radius)}
					color={COLOR_CODES.White}
					icon={ICON_CODES.Plus}
				/>
			</WrapperView>
		);
	},
)`
	height: ${(props: IButtonAvatarProps) => getIconSize(props.radius) * 1.5};
	width: ${(props: IButtonAvatarProps) => getIconSize(props.radius) * 1.5};
	justify-content: center;
	align-items: center;
	border-radius: ${(props: IButtonAvatarProps) =>
		getIconSize(props.radius) * 0.75};
	background-color: ${COLOR_CODES.Pink};
`;

const getBadgePadding = radius => {
	if (radius === (AVATAR_RADIUS.md || AVATAR_RADIUS.lg)) {
		return 3;
	} else if (radius === AVATAR_RADIUS.xl) {
		return 5;
	} else {
		return 2;
	}
};

export const ButtonAvatarView = (props: IButtonAvatarProps) => {
	const {
		image,
		showPlusButton,
		radius,
		avatarType,
		showBadge,
		badgeOutline,
		...buttonProps
	} = props;
	return (
		<ButtonView {...buttonProps} height={radius * 2} width={radius * 2}>
			<AvatarView radius={radius} image={image} avatarType={avatarType} />
			{showPlusButton && (
				<WrapperView
					styles={{
						wrapper: {
							position: 'absolute',
							bottom: 0,
							right: 0,
						},
					}}
				>
					<ButtonPlusView radius={radius} />
				</WrapperView>
			)}
			{showBadge && (
				<WrapperView
					styles={{
						wrapper: {
							position: 'absolute',
							top: badgeOutline ? 0 : getBadgePadding(radius),
							right: badgeOutline ? 0 : getBadgePadding(radius),
						},
					}}
				>
					<BadgeView
						badgeRadius={getBadgeSize(radius)}
						outline={badgeOutline}
					/>
				</WrapperView>
			)}
		</ButtonView>
	);
};
