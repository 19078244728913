import { registerRootComponent } from 'expo';
import { Platform } from 'react-native';
import WebApp from './web-app/App';
import NativeApp from './native-app/App';

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in the Expo client or in a native build,
// the environment is set up appropriately
if (Platform.OS === 'web') {
	registerRootComponent(WebApp);
} else {
	registerRootComponent(NativeApp);
}
