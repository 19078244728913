import { firebase } from 'firebase-xp';
import { FirestoreDocumentProvider } from '@nui/providers';
import { stateModel } from '../../../globals';
import { FirestoreTeamModel } from '@nui/models';

export class TeamObserver {
	protected documentProvider: FirestoreDocumentProvider;
	protected authId: string;
	protected teamId: string;

	constructor(authId: string, teamId: string) {
		this.authId = authId;
		this.teamId = teamId;
		this.documentProvider = new FirestoreDocumentProvider(
			firebase.firestore().collection('teams').doc(teamId),
		);
	}

	public unsubscribeTeamObserver() {
		stateModel.stateTeamsModel.removeTeams(this.teamId);
		if (this.documentProvider) {
			this.documentProvider.unsubscribeObserver();
		}
	}

	public subscribeTeamObserver(
		onChange: () => void,
		onReject: () => void,
	): Promise<void> {
		this.unsubscribeTeamObserver();
		return new Promise((resolve, reject) => {
			this.documentProvider
				.subscribeObserver()
				.initialized((receivedTeamId, teamDTO) => {
					if (receivedTeamId) {
						console.log(
							'team initialized',
							receivedTeamId,
							teamDTO,
						);
						const team = FirestoreTeamModel.toTeam(
							this.authId,
							teamDTO,
						);
						if (team) {
							stateModel.stateTeamsModel.addTeam(
								receivedTeamId,
								team,
							);
						} else {
							stateModel.stateTeamsModel.removeTeams(
								receivedTeamId,
							);
						}
						resolve();
					} else {
						reject();
					}
				})
				.modified((receivedTeamId, teamDTO) => {
					if (receivedTeamId) {
						console.log('team modified', receivedTeamId, teamDTO);
						const team = FirestoreTeamModel.toTeam(
							this.authId,
							teamDTO,
						);
						if (team) {
							stateModel.stateTeamsModel.addTeam(
								receivedTeamId,
								team,
							);
						} else {
							stateModel.stateTeamsModel.removeTeams(
								receivedTeamId,
							);
						}
						onChange();
					} else {
						onReject();
					}
				})
				.failed(() => {
					console.log('team failed', this.teamId);
					stateModel.stateTeamsModel.removeTeams(this.teamId);
					reject();
				})
				.rejected(() => {
					console.log('team rejected', this.teamId);
					stateModel.stateTeamsModel.removeTeams(this.teamId);
					onReject();
				});
		});
	}
}
