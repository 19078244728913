import { HelpersUtil } from '@nui/utils';
import firebase from 'firebase';
import {
	FirebaseAuthProvider,
	FirebaseAuthListenerEvents,
} from './firebase-auth.provider';

export class FirebaseWebAuthProvider extends FirebaseAuthProvider {
	protected listener?: () => void;

	public unlisten() {
		if (this.listener) {
			this.listener();
		}
	}

	public listen() {
		this.unlisten();
		return new FirebaseAuthListenerEvents(
			(loggedIn, loggedOut, teamChanged) => {
				this.listener = firebase
					.auth()
					.onIdTokenChanged(async (sysuserDTO: any) => {
						if (sysuserDTO) {
							if (this.auth) {
								// Do not move outside condition
								const teamIds = await FirebaseWebAuthProvider.getTeamIds();
								if (
									!HelpersUtil.hasSameElements<string>(
										this.teamIds,
										teamIds,
									)
								) {
									this.teamIds = teamIds;
									teamChanged(sysuserDTO, teamIds);
								}
							} else {
								this.auth = { ...sysuserDTO };
								// Do not move outside condition
								this.teamIds = await FirebaseWebAuthProvider.getTeamIds();
								loggedIn(sysuserDTO, this.teamIds);
							}
						} else {
							this.auth = undefined;
							this.teamIds = [];
							loggedOut();
						}
					});
			},
		);
	}

	public static verify(
		confirmResult: firebase.auth.ConfirmationResult,
		code: string,
	) {
		if (confirmResult) {
			return confirmResult.confirm(code);
		} else {
			Promise.reject();
		}
	}

	public static initRecaptchaVerifier(domId: string) {
		return new firebase.auth.RecaptchaVerifier(domId, {
			size: 'invisible',
			'expired-callback': () => {
				console.error('expired captcha');
			},
		});
	}

	public static loginWithPhoneNumber(
		phone: string,
		applicationVerifier: firebase.auth.ApplicationVerifier,
	) {
		return firebase
			.auth()
			.signInWithPhoneNumber(phone, applicationVerifier);
	}
}
