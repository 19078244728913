import _ from 'lodash';
import { ImageURISource } from 'react-native';
import FastImage from 'react-native-fast-image-xp';

import { StorageProvider } from '../../providers/storage/storage.provider';
import {
	ASSISTANT_HISTORY_AVATAR,
	ASSISTANT_HISTORY_TYPE,
	FirestoreAssistantModel,
	IFirestoreAssistant,
	isFirestoreAssistantAccordion,
	isFirestoreAssistantRequestCode,
	isFirestoreAssistantRequestPhone,
	isFirestoreAssistantSelect,
	isFirestoreAssistantTextInput,
} from '../firestore/assistant/firestore-assistant.model';
import { IState } from './state-interfaces';
import { StateModel } from './state.model';
import { ConvertersUtil } from '../../utils/converters.util';

const config = require('../../../../env-config.json');

export interface IHistoryItem {
	historyId: string;
	history: IFirestoreAssistant;
	info?: any;
}

export interface IHistoryBlockItem extends IHistoryItem {
	index: number;
}

export interface IHistoryBlock {
	authorId: string;
	items: IHistoryBlockItem[];
	type: ASSISTANT_HISTORY_TYPE;
}

export class StateAssistantModel {
	protected state: IState;
	protected stateModel: StateModel;
	protected stateHasChanged: boolean;
	protected sortedHistory: IHistoryItem[];
	protected limit: number;
	protected version: number;
	protected lastVersion: string[];
	protected assistant: {
		[historyId: string]: IFirestoreAssistant;
	};

	constructor(state: IState, stateModel: StateModel) {
		this.state = state;
		this.stateModel = stateModel;
		this.stateHasChanged = true;
		this.sortedHistory = [];
		this.limit = 0;
		this.version = 0;
		this.lastVersion = [];
		this.assistant = {};
	}

	initializeState() {
		const prefetchPromises: (() => Promise<void>)[] = [];
		if (this.stateHasChanged) {
			this.version++;
			const preSortedHistory: IHistoryItem[] = [];
			const assistant = _.merge({}, this.assistant, this.state.assistant);
			for (const historyId in assistant) {
				const history = assistant[historyId];
				if (isFirestoreAssistantAccordion(history)) {
					for (const item of history.items) {
						prefetchPromises.push(() =>
							this.prefetchAccordionImage(item.icon),
						);
					}
				}
				preSortedHistory.push({
					historyId,
					history,
				});
			}
			preSortedHistory.sort(
				(a, b) =>
					a.history.created.getTime() - b.history.created.getTime(),
			);

			// Filter pre-sorted history
			const currentHistoryId =
				(this.limit > 0 &&
					this.sortedHistory[this.limit - 1].historyId) ||
				undefined;
			this.sortedHistory = [];
			// eslint-disable-next-line @typescript-eslint/prefer-for-of
			for (let i = 0; i < preSortedHistory.length; i++) {
				const history = preSortedHistory[i].history;
				const historyId = preSortedHistory[i].historyId;
				if (isFirestoreAssistantSelect(history)) {
					if (history.selected) {
						const textMessage = FirestoreAssistantModel.toTextMessage(
							history,
							this.stateModel.stateAuthModel.getAuth()?.uid,
						);
						if (textMessage) {
							this.sortedHistory.push({
								historyId,
								history: textMessage,
							});
						}
					} else {
						this.sortedHistory.push({
							historyId,
							history,
						});
					}
				} else if (
					isFirestoreAssistantRequestPhone(history) ||
					isFirestoreAssistantRequestCode(history)
				) {
					if (history.message) {
						const textMessage = FirestoreAssistantModel.toTextMessage(
							history,
							this.stateModel.stateAuthModel.getAuth()?.uid,
						);
						if (textMessage) {
							this.sortedHistory.push({
								historyId,
								history: textMessage,
							});
						}
					} else {
						this.sortedHistory.push({
							historyId,
							history,
						});
					}
				} else if (isFirestoreAssistantTextInput(history)) {
					if (history.value) {
						const textMessage = FirestoreAssistantModel.toTextMessage(
							history,
							this.stateModel.stateAuthModel.getAuth()?.uid,
						);
						if (textMessage) {
							this.sortedHistory.push({
								historyId,
								history: textMessage,
							});
						}
					} else {
						this.sortedHistory.push({
							historyId,
							history,
						});
					}
				} else if (
					history.type == ASSISTANT_HISTORY_TYPE.consent &&
					history.selected
				) {
					const textMessage = FirestoreAssistantModel.toTextMessage(
						history,
						this.stateModel.stateAuthModel.getAuth()?.uid,
					);
					if (textMessage) {
						this.sortedHistory.push({
							historyId,
							history: textMessage,
						});
					}
				} else {
					this.sortedHistory.push({
						historyId,
						history,
					});
				}
				if (
					this.sortedHistory[this.sortedHistory.length - 1]
						.historyId == currentHistoryId
				) {
					this.limit = this.sortedHistory.length;
				}
			}
			this.limit = Math.min(this.limit, this.sortedHistory.length);
			this.stateHasChanged = false;
		}
	}

	hasNewerStateVersion(componentName: string) {
		return (
			!this.lastVersion[componentName] ||
			this.lastVersion[componentName] < this.version
		);
	}

	confirmLatestStateVersion(componentName: string) {
		this.lastVersion[componentName] = this.version;
	}

	getVersion() {
		return this.version;
	}

	getDisplayElementType(type: ASSISTANT_HISTORY_TYPE) {
		if (type == ASSISTANT_HISTORY_TYPE.phoneInput) {
			return ASSISTANT_HISTORY_TYPE.textMessage;
		} else if (type == ASSISTANT_HISTORY_TYPE.codeInput) {
			return ASSISTANT_HISTORY_TYPE.textMessage;
		} else if (type == ASSISTANT_HISTORY_TYPE.textInput) {
			return ASSISTANT_HISTORY_TYPE.textMessage;
		} else {
			return type;
		}
	}

	getGroupedHistory(sortedHistory?: IHistoryItem[]) {
		if (!sortedHistory) {
			sortedHistory = this.sortedHistory;
		}
		const groupedHistory: IHistoryBlock[] = [];
		if (sortedHistory.length > 0) {
			let prevAuthorId = sortedHistory[0].history.authorId;
			let prevType = this.getDisplayElementType(
				sortedHistory[0].history.type,
			);
			let items: IHistoryBlockItem[] = [];
			let latestAvatar: { index: number; authorId: string } | undefined;
			for (let index = 0; index < sortedHistory.length; index++) {
				const nextAuthorId = sortedHistory[index].history.authorId;
				const nextType = this.getDisplayElementType(
					sortedHistory[index].history.type,
				);
				if (prevAuthorId === nextAuthorId && prevType === nextType) {
					items.push({ ...sortedHistory[index], index });
				} else {
					const newAuthorId =
						prevType == ASSISTANT_HISTORY_TYPE.select ||
						prevType == ASSISTANT_HISTORY_TYPE.accordion ||
						prevType == ASSISTANT_HISTORY_TYPE.consent
							? ASSISTANT_HISTORY_AVATAR.hidden
							: prevAuthorId ==
							  this.stateModel.stateAuthModel.getAuth()?.uid
							? prevAuthorId
							: prevAuthorId == ASSISTANT_HISTORY_AVATAR.nui
							? ASSISTANT_HISTORY_AVATAR.nui
							: ASSISTANT_HISTORY_AVATAR.concierge;

					groupedHistory.push({
						authorId: newAuthorId,
						items,
						type: prevType,
					});
					if (
						newAuthorId === ASSISTANT_HISTORY_AVATAR.nui ||
						newAuthorId === ASSISTANT_HISTORY_AVATAR.concierge
					) {
						// && latestAvatar.authorId === newAuthorId
						if (latestAvatar) {
							groupedHistory[latestAvatar.index].authorId =
								ASSISTANT_HISTORY_AVATAR.invisible;
						}
						latestAvatar = {
							index: groupedHistory.length - 1,
							authorId: newAuthorId,
						};
					}
					prevAuthorId = nextAuthorId;
					prevType = nextType;
					items = [{ ...sortedHistory[index], index }];
				}
			}
			const newAuthorId =
				prevType == ASSISTANT_HISTORY_TYPE.select ||
				prevType == ASSISTANT_HISTORY_TYPE.accordion ||
				prevType == ASSISTANT_HISTORY_TYPE.consent
					? ASSISTANT_HISTORY_AVATAR.hidden
					: prevAuthorId ==
					  this.stateModel.stateAuthModel.getAuth()?.uid
					? prevAuthorId
					: prevAuthorId == ASSISTANT_HISTORY_AVATAR.nui
					? ASSISTANT_HISTORY_AVATAR.nui
					: ASSISTANT_HISTORY_AVATAR.concierge;
			groupedHistory.push({
				authorId: newAuthorId,
				items,
				type: prevType,
			});
			if (
				newAuthorId === ASSISTANT_HISTORY_AVATAR.nui ||
				newAuthorId === ASSISTANT_HISTORY_AVATAR.concierge
			) {
				// && latestAvatar.authorId === newAuthorId
				if (latestAvatar) {
					groupedHistory[latestAvatar.index].authorId =
						ASSISTANT_HISTORY_AVATAR.invisible;
				}
				latestAvatar = {
					index: groupedHistory.length - 1,
					authorId: newAuthorId,
				};
			}
		}
		return groupedHistory;
	}

	dequeue() {
		if (this.limit >= this.sortedHistory.length) {
			return undefined;
		} else {
			return this.sortedHistory.slice(0, ++this.limit);
		}
	}

	dequeueAll() {
		this.limit = this.sortedHistory.length;
		return this.sortedHistory;
	}

	getSortedHistory() {
		return this.sortedHistory;
	}

	select(historyId: string, optionIndex: number) {
		const history = this.state.assistant[historyId];
		if (history && isFirestoreAssistantSelect(history)) {
			this.stateHasChanged = true;
			history.selected = true;
			history.options[optionIndex].selected = true;
		} else if (history && history.type == ASSISTANT_HISTORY_TYPE.consent) {
			this.stateHasChanged = true;
			history.selected = true;
		}
	}

	changeValue(historyId: string, value: string) {
		const history = this.state.assistant[historyId];
		if (history && isFirestoreAssistantTextInput(history)) {
			this.stateHasChanged = true;
			history.value = ConvertersUtil.toContent(value);
		}
	}

	getSortedHistoryLength() {
		return this.sortedHistory.length;
	}

	getLatestReplyData() {
		for (let i = this.sortedHistory.length - 1; i >= 0; i--) {
			const history = this.sortedHistory[i].history;
			if (history.type === ASSISTANT_HISTORY_TYPE.textInput) {
				return history.replyData;
			}
		}
		return undefined;
	}

	addHistory(
		historyId: string,
		history: IFirestoreAssistant,
		temporary?: boolean,
	) {
		this.stateHasChanged = true;
		if (temporary) {
			this.assistant[historyId] = history;
		} else {
			this.assistant = {};
			this.state.assistant[historyId] = history;
		}
	}

	getHistory(historyId: string): IFirestoreAssistant | undefined {
		return this.state.assistant[historyId];
	}

	removeHistory(historyId?: string) {
		this.stateHasChanged = true;
		if (historyId) {
			delete this.state.assistant[historyId];
			delete this.assistant[historyId];
		} else {
			this.state.assistant = {};
			this.assistant = {};
		}
	}

	prefetchAccordionImage(path: string): Promise<void> {
		return new Promise((resolve, reject) => {
			const image = this.getAccordionIcon(path)();
			if (image instanceof Promise) {
				image
					.then(imageURISource => {
						FastImage.preload([{ uri: imageURISource.uri }]);
						resolve();
					})
					.catch(error => {
						reject(error);
					});
			} else {
				FastImage.preload([{ uri: image.uri }]);
				resolve();
			}
		});
	}

	getLatestHistory() {
		return (
			(this.sortedHistory.length > 0 &&
				this.sortedHistory[this.sortedHistory.length - 1]) ||
			undefined
		);
	}

	getAccordionIcon(
		path: string,
	): () => Promise<ImageURISource> | ImageURISource {
		return StorageProvider.getDownloadURL(path, config.contentBucketURI);
	}
}
