import { FirebaseFirestoreTypes } from '@react-native-firebase/firestore';

type IInitialised = (entityId?: string, entityDTO?: object) => void;
type IModified = (entityId?: string, eentityDTO?: object) => void;
type IFailed = (error: Error) => void;
type IRejected = (error: Error) => void;

class FirestoreDocumentObserverEvents {
	handleInitialized?: IInitialised;
	handleModified?: IModified;
	handleFailed?: IFailed;
	handleRejected?: IRejected;

	constructor(
		promiseHandler: (
			initialized: IInitialised,
			modified: IModified,
			failed: IFailed,
			rejected: IRejected,
		) => void,
	) {
		this.onInitialized = this.onInitialized.bind(this);
		this.onModified = this.onModified.bind(this);
		this.onFailed = this.onFailed.bind(this);
		this.onRejected = this.onRejected.bind(this);
		promiseHandler(
			this.onInitialized,
			this.onModified,
			this.onFailed,
			this.onRejected,
		);
	}

	public initialized(
		handleInitialized: IInitialised,
	): FirestoreDocumentObserverEvents {
		this.handleInitialized = handleInitialized;
		return this;
	}

	public modified(
		handleModified: IModified,
	): FirestoreDocumentObserverEvents {
		this.handleModified = handleModified;
		return this;
	}

	public failed(handleFailed: IFailed): FirestoreDocumentObserverEvents {
		this.handleFailed = handleFailed;
		return this;
	}

	public rejected(
		handleRejected: IRejected,
	): FirestoreDocumentObserverEvents {
		this.handleRejected = handleRejected;
		return this;
	}

	protected onInitialized(entityId?: string, entityDTO?: object): void {
		if (this.handleInitialized) {
			this.handleInitialized(entityId, entityDTO);
		}
	}

	protected onModified(entityId?: string, entityDTO?: object): void {
		if (this.handleModified) {
			this.handleModified(entityId, entityDTO);
		}
	}

	protected onFailed(error: Error): void {
		if (this.handleFailed) {
			this.handleFailed(error);
		}
	}

	protected onRejected(error: Error): void {
		if (this.handleRejected) {
			this.handleRejected(error);
		}
	}
}

export class FirestoreDocumentProvider {
	protected unsubscribe?: () => void;
	protected documentReference: FirebaseFirestoreTypes.DocumentReference;

	constructor(documentReference: FirebaseFirestoreTypes.DocumentReference) {
		this.documentReference = documentReference;
	}

	public unsubscribeObserver() {
		if (this.unsubscribe) {
			this.unsubscribe();
		}
	}

	public subscribeObserver(): FirestoreDocumentObserverEvents {
		return new FirestoreDocumentObserverEvents(
			(initialized, modified, failed, rejected) => {
				let initialize = true;
				this.unsubscribe = this.documentReference.onSnapshot(
					querySnapshot => {
						const data = querySnapshot.data();
						const id = querySnapshot.id;
						if (initialize) {
							if (data) {
								initialized(id, data);
							} else {
								initialized();
							}
							initialize = false;
						} else {
							if (data) {
								modified(id, data);
							} else {
								modified();
							}
						}
					},
					error => {
						if (initialize) {
							failed(error);
							initialize = false;
						} else {
							rejected(error);
						}
						this.unsubscribeObserver();
					},
				);
			},
		);
	}

	public set(doc: object): Promise<void> {
		return this.documentReference.set(doc);
	}

	public update(doc: object): Promise<void> {
		return this.documentReference.update(doc);
	}

	public save(doc: object): Promise<void> {
		return this.documentReference.set(doc, { merge: true });
	}

	public delete(): Promise<void> {
		return this.documentReference.delete();
	}

	public get(): Promise<FirebaseFirestoreTypes.DocumentSnapshot> {
		return this.documentReference.get();
	}
}
