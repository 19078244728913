import { firebase } from 'firebase-xp';
import { FirestoreDocumentProvider } from '@nui/providers';
import { FirestoreUserModel } from '@nui/models';
import { stateModel } from '../../../globals';

export class UserObserver {
	protected documentProvider: FirestoreDocumentProvider;
	protected authId: string;

	constructor(authId: string) {
		this.authId = authId;
		this.documentProvider = new FirestoreDocumentProvider(
			firebase.firestore().collection('user-profiles').doc(authId),
		);
	}

	public unsubscribeUserObserver() {
		stateModel.stateUserModel.unsetUser();
		if (this.documentProvider) {
			this.documentProvider.unsubscribeObserver();
		}
	}

	public subscribeUserObserver(
		onChange: () => void,
		onReject: () => void,
	): Promise<void> {
		return new Promise((resolve, reject) => {
			this.documentProvider
				.subscribeObserver()
				.initialized((userId, userDTO) => {
					console.log('user initialized', userId, userDTO);
					const user = FirestoreUserModel.toUser(userDTO);
					if (user) {
						stateModel.stateUserModel.setUser(user);
					} else {
						stateModel.stateUserModel.unsetUser();
					}
					resolve();
				})
				.modified((userId, userDTO) => {
					console.log('user modified', userId, userDTO);
					const user = FirestoreUserModel.toUser(userDTO);
					if (user) {
						stateModel.stateUserModel.setUser(user);
					} else {
						stateModel.stateUserModel.unsetUser();
					}
					onChange();
				})
				.failed(() => {
					stateModel.stateUserModel.unsetUser();
					reject();
				})
				.rejected(() => {
					stateModel.stateUserModel.unsetUser();
					onReject();
				});
		});
	}
}
