import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { COLOR_CODES } from '../enums/colors';
import { TextButtonView } from '../texts/text-button.view';
import { ICON_CODES, TextIconView } from '../texts/text-icon.view';
import { IButtonPrimaryStyles } from './button-primary.view';
import { ButtonView, IButtonProps, IButtonStyles } from './button.view';
import { INativeProps } from '../wrappers/wrapper-interfaces';

export enum BUTTON_SECONDARY_THEMES {
	Pink = 'Pink',
	Blue = 'Blue',
	Grey = 'GreyishBrown',
}

export interface IButtonSecodaryStyles extends IButtonStyles {
	container?: object;
	label?: object;
	icon?: object;
}

// tslint:disable-next-line: no-empty-interface
export interface IButtonSecondaryProps extends IButtonProps {
	label?: string;
	iconBefore?: ICON_CODES;
	iconAfter?: ICON_CODES;
	styles?: IButtonPrimaryStyles;
	/** Button Color -- enum BUTTON_THEMES */
	theme?: BUTTON_SECONDARY_THEMES;
	autosize?: boolean;
}

const getForegroundColor = (theme?: BUTTON_SECONDARY_THEMES) => {
	if (theme == BUTTON_SECONDARY_THEMES.Pink) {
		return COLOR_CODES.Pink;
	}
	if (theme == BUTTON_SECONDARY_THEMES.Blue) {
		return COLOR_CODES.Blue;
	}
	if (theme == BUTTON_SECONDARY_THEMES.Grey) {
		return COLOR_CODES.Grey75;
	} else {
		return COLOR_CODES.Black;
	}
};

const ButtonSecondaryContainer = styled(
	(props: IButtonSecondaryProps & INativeProps) => (
		<View style={props.style}>{props.children}</View>
	),
)`
	height: 50;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	background-color: ${COLOR_CODES.Transparent};
`;

export const ButtonSecondaryView = (props: IButtonSecondaryProps) => {
	const {
		label,
		iconBefore,
		iconAfter,
		theme,
		autosize,
		...buttonProps
	} = props;
	const color =
		(buttonProps.onClick &&
			((theme && getForegroundColor(theme)) || COLOR_CODES.Pink)) ||
		COLOR_CODES.Grey50;

	return (
		<ButtonView
			{...buttonProps}
			styles={{
				...buttonProps.styles,
				wrapper: {
					alignItems: autosize ? 'center' : 'stretch',
					...buttonProps.styles?.wrapper,
				},
			}}
		>
			<ButtonSecondaryContainer {...props}>
				{iconBefore && (
					<TextIconView
						color={color}
						icon={iconBefore}
						wrapperRight={10}
						styles={{
							text: (props.styles && props.styles.icon) || {},
						}}
					/>
				)}
				{label && (
					<TextButtonView
						color={color}
						styles={{
							text: (props.styles && props.styles.label) || {},
						}}
					>
						{label}
					</TextButtonView>
				)}
				{iconAfter && (
					<TextIconView
						color={color}
						icon={iconAfter}
						wrapperLeft={10}
						styles={{
							text: (props.styles && props.styles.icon) || {},
						}}
					/>
				)}
			</ButtonSecondaryContainer>
		</ButtonView>
	);
};
