import { COLOR_CODES } from '../../../../enums/colors';
import React from 'react';
import styled from 'styled-components/native';

import { FONT_WEIGHTS, ITextProps } from '../../../../texts/text-interfaces';
import { TextView } from '../../../../texts/text.view';
import { INativeProps } from '../../../../wrappers/wrapper-interfaces';

export const AccordionTitleView = styled((props: ITextProps & INativeProps) => (
	<TextView {...props} />
))`
	font-size: 17;
	letter-spacing: -0.41;
	line-height: 22;
	color: ${COLOR_CODES.Grey75};
	font-weight: ${FONT_WEIGHTS.semiBold};
	${(props: ITextProps) => ({
		...props.styles?.text,
	})};
`;
