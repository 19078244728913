import {
	ASSISTANT_HISTORY_SKILL,
	FirestoreAssistantModel,
	FirestoreUserModel,
	IHistoryBlock,
	isFirestoreAssistantRequestCode,
	isFirestoreAssistantRequestPhone,
	isFirestoreAssistantSelect,
	isFirestoreAssistantTextInput,
} from '@nui/models';
import { HelpersUtil, Language } from '@nui/utils';
import {
	ASSISTANT_HISTORY_TYPE,
	COLOR_CODES,
	MAX_SELECT_OPTIONS,
	PickerView,
	PrivacyAcceptView,
	TextfieldNewSubmitView,
} from '@nui/views';
import { stateModel } from 'globals';
import React from 'react';
import { View } from 'react-native';

import { AssistantRegistrationController } from './assistant.registration.controller';
import { useWebViewportWidth } from '@nui/hooks';
import { breakpoints } from '../../../views/layout.view';

interface AssistantFooterProps {
	groupedHistory: IHistoryBlock[];
	handleClick: (historyIndex: number, optionIndex: number) => Promise<void>;
	handleFinished: () => void;
	handleForceUpdate: () => void;
	forceSkill?: string;
	placeholder: string;
}

enum REGISTRATION_STATUS {
	successful,
	failed,
	inProgress,
}

const openPrivacyStatement = () => {
	window.open('https://nui.care/datenschutzerklaerung_app', '_blank');
};

const openTermsOfUseStatement = () => {
	window.open('https://nui.care/Nutzungsbedingungen_app', '_blank');
};

const AssistantFooterController = (props: AssistantFooterProps) => {
	const registrationStatus = React.useRef<REGISTRATION_STATUS | undefined>();

	const authId = stateModel.stateAuthModel.getAuth()?.uid;

	const viewportWidth = useWebViewportWidth('#layout-center-view') || 0;

	/**
	 * This is used if a user wants to contact Nui
	 * instead of replying to a specific question
	 * @param text The users proactive message
	 */
	const handleQuestion = async (text: string) => {
		const documentId = HelpersUtil.fbid();
		FirestoreUserModel.fetchAssistant({
			message: text,
			skill: props.forceSkill,
			useHistoryElementId: documentId,
		});

		const textMessage = FirestoreAssistantModel.toTextMessage(text, authId);
		if (textMessage) {
			stateModel.stateAssistantModel.addHistory(
				documentId,
				textMessage,
				true,
			);
			stateModel.stateAssistantModel.initializeState();
			props.handleForceUpdate();
		}
	};

	/**
	 * This is used to promt the user for a value such as their name similar
	 * to a traditional form
	 */
	const handleTextInput = async (historyId: string, text: string) => {
		const replyData = stateModel.stateAssistantModel.getLatestReplyData();
		FirestoreUserModel.fetchAssistant({
			...replyData,
			text,
		});

		stateModel.stateAssistantModel.changeValue(historyId, text);
		stateModel.stateAssistantModel.initializeState();
		props.handleForceUpdate();
		props.handleFinished();
	};

	const blockLength = props.groupedHistory.length;
	if (blockLength > 0) {
		const itemLength = props.groupedHistory[blockLength - 1].items.length;
		if (itemLength > 0) {
			const lastHistoryItem =
				props.groupedHistory[blockLength - 1].items[itemLength - 1];
			if (
				isFirestoreAssistantSelect(lastHistoryItem.history) &&
				lastHistoryItem.history.options.length > MAX_SELECT_OPTIONS &&
				!lastHistoryItem.history.selected
			) {
				const handleClick = (itemPosition: number) => {
					props.handleClick(lastHistoryItem.index, itemPosition);
				};
				return (
					<View style={{ backgroundColor: COLOR_CODES.White }}>
						<PickerView
							options={lastHistoryItem.history.options.map(
								option =>
									Language.translate(
										Language.replace(
											option.label,
											'{SENIOR}',
											stateModel.stateTeamsModel.getSenior()
												?.firstName,
										),
									),
							)}
							submit={{
								onClick: handleClick,
								label: 'Übernehmen',
							}}
							wrapperLeft={
								viewportWidth < breakpoints.phone ? 20 : 70
							}
							wrapperRight={
								viewportWidth < breakpoints.phone ? 20 : 70
							}
							wrapperBottom={10}
						/>
					</View>
				);
			} else if (
				registrationStatus.current != REGISTRATION_STATUS.successful &&
				(registrationStatus.current == REGISTRATION_STATUS.inProgress ||
					registrationStatus.current == REGISTRATION_STATUS.failed ||
					isFirestoreAssistantRequestPhone(lastHistoryItem.history) ||
					isFirestoreAssistantRequestCode(lastHistoryItem.history))
			) {
				registrationStatus.current = REGISTRATION_STATUS.inProgress;
				return (
					<AssistantRegistrationController
						{...props}
						onSucceeded={() => {
							registrationStatus.current =
								REGISTRATION_STATUS.successful;
						}}
						onFailed={() => {
							registrationStatus.current =
								REGISTRATION_STATUS.failed;
						}}
						horizontalSpacing={
							viewportWidth < breakpoints.phone ? 6 : 20
						}
						verticalSpacing={
							viewportWidth < breakpoints.phone ? 6 : 15
						}
						textfieldLeftPadding={
							viewportWidth < breakpoints.phone ? 12 : 20
						}
					/>
				);
			} else if (isFirestoreAssistantTextInput(lastHistoryItem.history)) {
				return (
					<TextfieldNewSubmitView
						horizontalSpacing={
							viewportWidth < breakpoints.phone ? 6 : 20
						}
						verticalSpacing={
							viewportWidth < breakpoints.phone ? 6 : 15
						}
						textfieldLeftPadding={
							viewportWidth < breakpoints.phone ? 12 : 20
						}
						nativeTextInputProps={{
							placeholder: Language.translate(
								lastHistoryItem.history.placeholder,
							),
							autoCorrect: false,
						}}
						textfieldRadius={
							viewportWidth < breakpoints.phone ? undefined : 30
						}
						clear={true}
						onSubmit={text =>
							handleTextInput(lastHistoryItem.historyId, text)
						}
					/>
				);
			} else if (
				lastHistoryItem.history.type ==
					ASSISTANT_HISTORY_TYPE.consent &&
				!lastHistoryItem.history.selected
			) {
				return (
					<PrivacyAcceptView
						background={COLOR_CODES.White}
						wrapperLeft={
							viewportWidth < breakpoints.phone ? 20 : 70
						}
						wrapperRight={
							viewportWidth < breakpoints.phone ? 20 : 70
						}
						wrapperBottom={20}
						wrapperTop={20}
						dataLabel={'Datenschutzhinweise'}
						onDataClick={openPrivacyStatement}
						generalLabel={'Allgemeine Geschäftsbedingungen'}
						onGeneralClick={openTermsOfUseStatement}
						switchLabel={
							'Ich habe die Bestimmungen gelesen und willige ein'
						}
						acceptLabel={'Akzeptieren und fortfahren'}
						onClick={() => {
							props.handleClick(lastHistoryItem.index, 0);
							props.handleFinished();
						}}
					/>
				);
			} else if (props.forceSkill !== ASSISTANT_HISTORY_SKILL.webdemo) {
				const placeholder = props.placeholder;
				return (
					<TextfieldNewSubmitView
						horizontalSpacing={
							viewportWidth < breakpoints.phone ? 6 : 20
						}
						verticalSpacing={
							viewportWidth < breakpoints.phone ? 6 : 15
						}
						textfieldLeftPadding={
							viewportWidth < breakpoints.phone ? 12 : 20
						}
						nativeTextInputProps={{
							placeholder,
							autoCorrect: false,
						}}
						textfieldRadius={
							viewportWidth < breakpoints.phone ? undefined : 30
						}
						clear={true}
						onSubmit={handleQuestion}
					/>
				);
			} else {
				return <View style={{ marginBottom: 10 }} />;
			}
		} else {
			return null;
		}
	} else {
		return null;
	}
};

export const AssistantFooterMemorizedController = React.memo(
	AssistantFooterController,
	() => {
		return false;
	},
);
