import React from 'react';
import styled from 'styled-components/native';
import {
	extractWrapperProps,
	IWrapperProps,
	IWrapperStyles,
	INativeProps,
} from './wrapper-interfaces';
import { WrapperView } from './wrapper.view';

export enum JUSTIFY {
	Start = 'flex-start',
	End = 'flex-end',
	Center = 'center',
	SpaceBetween = 'space-between',
	SpaceAround = 'space-around',
	SpaceEvenly = 'space-evenly',
	Stretch = 'stretch',
}

export enum ALIGN {
	Start = 'flex-start',
	End = 'flex-end',
	Center = 'center',
	Stretch = 'stretch',
}

export enum DIRECTION {
	Horizontal = 'row',
	Vertical = 'column',
}
export type IWrapperAlignStyles = IWrapperStyles;
export interface IWrapperAlignProps extends IWrapperProps {
	itemsJustify?: JUSTIFY;
	itemsAlign?: ALIGN;
	itemsDirection?: DIRECTION;
	styles?: IWrapperAlignStyles;
}

export const WrapperAlignView = styled(
	(props: IWrapperAlignProps & INativeProps) => {
		return <WrapperView {...props} />;
	},
)`
	flex-direction: ${(props: IWrapperAlignProps) =>
		props.itemsDirection || DIRECTION.Horizontal};
	justify-content: ${(props: IWrapperAlignProps) =>
		props.itemsJustify || JUSTIFY.SpaceBetween};
	align-items: ${(props: IWrapperAlignProps) =>
		props.itemsAlign || ALIGN.Center};
	${(props: IWrapperAlignProps) => ({ ...props.styles?.wrapper })};
`;

// TODO: set default for extractWrapperProps
export const extractWrapperAlignProps = <T extends IWrapperAlignProps>(
	props: T,
	def?: IWrapperAlignProps,
): IWrapperAlignProps => {
	return {
		...extractWrapperProps(props),
		itemsJustify: props.itemsJustify || def?.itemsJustify,
		itemsAlign: props.itemsAlign || def?.itemsAlign,
		itemsDirection: props.itemsDirection || def?.itemsDirection,
		styles: {
			wrapper: { ...def?.styles?.wrapper, ...props.styles?.wrapper },
		},
	};
};
