import React from 'react';

export const Table = (props: any) => {
	return <table {...props} style={{ alignSelf: 'flex-start' }} />;
};

export const Rows = (props: { data: string[][] }) => {
	return (
		<tbody>
			{props.data.map((row, i) => (
				<tr key={i}>
					{row.map((col, j) => {
						return (
							<td key={j} style={{ paddingRight: 30 }}>
								{col}
							</td>
						);
					})}
				</tr>
			))}
		</tbody>
	);
};
