import { ConvertersUtil } from '@nui/utils';

export interface IFirestoreTeamSenior {
	firstName: string;
}

export class FirestoreTeamSeniorModel {
	static toSenior(seniorDTO: any): IFirestoreTeamSenior {
		return {
			firstName: ConvertersUtil.toString(seniorDTO?.firstName) || '?',
		};
	}
}
