import XDate from 'xdate';

import { Language, SUPPORTED_LANGUAGE_CODES } from './language.util';

const MONTHS = {
	january: {
		long: {
			de: 'Januar',
			en: 'January',
		},
		short: {
			de: 'Jan',
			en: 'Jan',
		},
	},
	february: {
		long: {
			de: 'Februar',
			en: 'February',
		},
		short: {
			de: 'Feb',
			en: 'Feb',
		},
	},
	march: {
		long: {
			de: 'März',
			en: 'March',
		},
		short: {
			de: 'Mär',
			en: 'Mar',
		},
	},
	april: {
		long: {
			de: 'April',
			en: 'April',
		},
		short: {
			de: 'Apr',
			en: 'Apr',
		},
	},
	may: {
		long: {
			de: 'Mai',
			en: 'May',
		},
		short: {
			de: 'Mai',
			en: 'May',
		},
	},
	june: {
		long: {
			de: 'Juni',
			en: 'June',
		},
		short: {
			de: 'Jun',
			en: 'Jun',
		},
	},
	july: {
		long: {
			de: 'Juli',
			en: 'July',
		},
		short: {
			de: 'Jul',
			en: 'Jul',
		},
	},
	august: {
		long: {
			de: 'August',
			en: 'August',
		},
		short: {
			de: 'Aug',
			en: 'Aug',
		},
	},
	september: {
		long: {
			de: 'September',
			en: 'September',
		},
		short: {
			de: 'Sep',
			en: 'Sep',
		},
	},
	october: {
		long: {
			de: 'Oktober',
			en: 'October',
		},
		short: {
			de: 'Okt',
			en: 'Oct',
		},
	},
	november: {
		long: {
			de: 'November',
			en: 'November',
		},
		short: {
			de: 'Nov',
			en: 'Nov',
		},
	},
	december: {
		long: {
			de: 'Dezember',
			en: 'December',
		},
		short: {
			de: 'Dez',
			en: 'Dec',
		},
	},
};
export const DAYS = {
	monday: {
		very_short: {
			de: 'M',
			en: 'M',
		},
		short: {
			de: 'Mo',
			en: 'Mon',
		},
		long: {
			de: 'Montag',
			en: 'Monday',
		},
	},
	tuesday: {
		very_short: {
			de: 'D',
			en: 'T',
		},
		short: {
			de: 'Di',
			en: 'Tue',
		},
		long: {
			de: 'Dienstag',
			en: 'Tuesday',
		},
	},
	wednesday: {
		very_short: {
			de: 'M',
			en: 'W',
		},
		short: {
			de: 'Mi',
			en: 'Wed',
		},
		long: {
			de: 'Mittwoch',
			en: 'Wednesday',
		},
	},
	thursday: {
		very_short: {
			de: 'D',
			en: 'T',
		},
		short: {
			de: 'Do',
			en: 'Thu',
		},
		long: {
			de: 'Donnerstag',
			en: 'Thursday',
		},
	},
	friday: {
		very_short: {
			de: 'F',
			en: 'F',
		},
		short: {
			de: 'Fr',
			en: 'Fri',
		},
		long: {
			de: 'Freitag',
			en: 'Friday',
		},
	},
	saturday: {
		very_short: {
			de: 'S',
			en: 'S',
		},
		short: {
			de: 'Sa',
			en: 'Sat',
		},
		long: {
			de: 'Samstag',
			en: 'Saturday',
		},
	},
	sunday: {
		very_short: {
			de: 'S',
			en: 'S',
		},
		short: {
			de: 'So',
			en: 'Sun',
		},
		long: {
			de: 'Sonntag',
			en: 'Sunday',
		},
	},
	today: {
		de: 'Heute',
		en: 'Today',
	},
	yesterday: {
		de: 'Gestern',
		en: 'yesterday',
	},
	tomorrow: {
		de: 'Morgen',
		en: 'Tomorrow',
	},
};

const customContent = {
	yesterday: {
		de: 'Gestern',
		en: 'Yesterday',
	},
};

export class CalendarUtil {
	public static numberOfDays(date?: XDate) {
		if (!date) {
			date = new XDate();
		}
		return Math.floor(
			(date.getTime() - date.getTimezoneOffset() * 60000) / 8.64e7,
		);
	}

	public static sameDay(a: XDate, b: XDate): boolean {
		return (
			a.getDate() == b.getDate() &&
			a.getMonth() == b.getMonth() &&
			a.getFullYear() == b.getFullYear()
		);
	}

	public static isToday(a: XDate): boolean {
		return this.sameDay(a, new XDate());
	}

	public static isYesterday(a: XDate): boolean {
		const pivot = new XDate();
		pivot.addDays(-1);
		return this.sameDay(a, pivot);
	}

	public static normalizeDay(xDate?: XDate): XDate {
		if (!xDate) {
			xDate = new XDate();
		}
		return xDate
			.clone()
			.setHours(0)
			.setMinutes(0)
			.setSeconds(0)
			.setMilliseconds(0);
	}

	public static normalizeWeek(xDate?: XDate): XDate {
		if (!xDate) {
			xDate = new XDate();
		}
		return xDate.clone().setWeek(xDate.getWeek());
	}

	public static getShortDay(
		xDate: XDate,
		formatToday: boolean = false,
		code?: SUPPORTED_LANGUAGE_CODES,
	): string {
		let today = CalendarUtil.normalizeDay();
		if (
			formatToday &&
			CalendarUtil.normalizeDay(xDate).getTime() == today.getTime()
		) {
			return Language.translate(DAYS.today, code);
		} else {
			return CalendarUtil.getShortDays(code)[(xDate.getDay() + 6) % 7];
		}
	}

	public static getLongDay(
		xDate: XDate,
		formatToday: boolean = false,
		code?: SUPPORTED_LANGUAGE_CODES,
	): string {
		let today = CalendarUtil.normalizeDay();
		if (
			formatToday &&
			CalendarUtil.normalizeDay(xDate).getTime() == today.getTime()
		) {
			return Language.translate(DAYS.today, code);
		} else {
			return CalendarUtil.getLongDays(code)[(xDate.getDay() + 6) % 7];
		}
	}

	public static formatChatDatetime(datetime: Date, code: string = 'de-DE') {
		const diffDays = datetime.getDate() - new Date().getDate();
		const diffYears = new Date().getFullYear() - datetime.getFullYear();

		if (diffYears === 0 && diffDays === 0) {
			return datetime.toLocaleTimeString(code, {
				hour: '2-digit',
				minute: '2-digit',
			});
		} else if (diffYears === 0 && diffDays === 1) {
			return Language.translate(customContent.yesterday);
		} else if (diffYears === 0 && diffDays <= -1 && diffDays > -7) {
			return datetime.toLocaleDateString(code, {
				weekday: 'long',
			});
		} else {
			return datetime.toLocaleDateString(code, {
				day: '2-digit',
				month: '2-digit',
				year: '2-digit',
			});
		}
	}

	public static getShortMonth(
		xDate: XDate,
		code?: SUPPORTED_LANGUAGE_CODES,
	): string {
		return CalendarUtil.getShortMonths(code)[xDate.getMonth()];
	}

	// 2. Oktober 2019
	// Mittwoch, 2. Oktober 2019
	// Wednesday, 2 October 2019
	public static getFormatedLongDate(
		xDate: XDate,
		withoutDay: boolean = false,
		formatToday: boolean = false,
		formatYesterday: boolean = false,
	): string {
		const code = Language.getDefaultLanguageCode();
		if (withoutDay) {
			if (code == SUPPORTED_LANGUAGE_CODES.de) {
				if (formatToday && this.isToday(xDate)) {
					return Language.translate(DAYS.today, code);
				} else if (formatYesterday && this.isYesterday(xDate)) {
					return Language.translate(DAYS.yesterday, code);
				} else {
					return xDate.toString('d. MMMM yyyy');
				}
			} else {
				if (formatToday && this.isToday(xDate)) {
					return Language.translate(DAYS.today, code);
				} else if (formatYesterday && this.isYesterday(xDate)) {
					return Language.translate(DAYS.yesterday, code);
				} else {
					return xDate.toString('d MMMM yyyy');
				}
			}
		} else {
			if (code == SUPPORTED_LANGUAGE_CODES.de) {
				if (formatToday && this.isToday(xDate)) {
					return Language.translate(DAYS.today, code);
				} else if (formatYesterday && this.isYesterday(xDate)) {
					return Language.translate(DAYS.yesterday, code);
				} else {
					return (
						CalendarUtil.getLongDay(xDate) +
						', ' +
						xDate.toString('d. MMMM yyyy')
					);
				}
			} else {
				if (formatToday && this.isToday(xDate)) {
					return Language.translate(DAYS.today, code);
				} else if (formatYesterday && this.isYesterday(xDate)) {
					return Language.translate(DAYS.yesterday, code);
				} else {
					return (
						CalendarUtil.getLongDay(xDate) +
						', ' +
						xDate.toString('d MMMM yyyy')
					);
				}
			}
		}
	}

	//  02.10.2019
	//  Mi, 02.10.2019
	// TODO: Replace localDateString with code parameter
	public static getFormatedDate(
		xDate: XDate,
		withoutDay: boolean = false,
		code?: SUPPORTED_LANGUAGE_CODES,
	): string {
		if (withoutDay) {
			return CalendarUtil.getLocaleDateString(xDate, code);
		} else {
			return (
				CalendarUtil.getShortDay(xDate) +
				', ' +
				CalendarUtil.getLocaleDateString(xDate, code)
			);
		}
	}

	public static getLocaleDateString(
		xDate: XDate,
		code?: SUPPORTED_LANGUAGE_CODES,
	) {
		code = code || Language.getDefaultLanguageCode();
		if (code == SUPPORTED_LANGUAGE_CODES.de) {
			return xDate.toString('dd.MM.yyyy');
		} else {
			return xDate.toString('MM/dd/yyyy');
		}
	}

	// 23:11
	// 12:00 PM
	public static getFormatedTime(
		xDate?: XDate,
		code?: SUPPORTED_LANGUAGE_CODES,
	): string {
		code = code || Language.getDefaultLanguageCode();
		if (!xDate) {
			xDate = new XDate();
		}
		if (code == SUPPORTED_LANGUAGE_CODES.de) {
			return xDate.toString('HH:mm');
		} else {
			let hours = xDate.getHours();
			let minutes = xDate.getMinutes();
			let ampm = hours >= 12 ? 'PM' : 'AM';
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'

			return (
				hours +
				':' +
				(minutes < 10 ? '0' + minutes : minutes) +
				' ' +
				ampm
			);
		}
	}

	// {hours: 11; minutes: 06; seconds: 22; ampm: "AM"}
	public static getTimeTokens(
		xDate?: XDate,
		code?: SUPPORTED_LANGUAGE_CODES,
	): { hours: string; minutes: string; seconds: string; ampm: string } {
		code = code || Language.getDefaultLanguageCode();
		if (!xDate) {
			xDate = new XDate();
		}
		let tokens = {
			hours: xDate.toString('HH'),
			minutes: xDate.toString('mm'),
			seconds: xDate.toString('ss'),
			ampm: '',
		};
		if (!xDate) {
			xDate = new XDate();
		}
		if (code != SUPPORTED_LANGUAGE_CODES.de) {
			let hours = xDate.getHours();
			let minutes = xDate.getMinutes();
			let ampm = hours >= 12 ? 'PM' : 'AM';
			hours = hours % 12;
			hours = hours ? hours : 12;
			tokens.hours = '' + hours;
			tokens.minutes = ('0' + minutes).substr(-2);
			tokens.ampm = ampm;
		}
		return tokens;
	}

	public static getVeryShortDays(
		code?: SUPPORTED_LANGUAGE_CODES,
		startOnSunday: boolean = false,
	): string[] {
		if (startOnSunday) {
			return [
				Language.translate(DAYS.sunday.very_short, code),
				Language.translate(DAYS.monday.very_short, code),
				Language.translate(DAYS.tuesday.very_short, code),
				Language.translate(DAYS.wednesday.very_short, code),
				Language.translate(DAYS.thursday.very_short, code),
				Language.translate(DAYS.friday.very_short, code),
				Language.translate(DAYS.saturday.very_short, code),
			];
		} else {
			return [
				Language.translate(DAYS.monday.very_short, code),
				Language.translate(DAYS.tuesday.very_short, code),
				Language.translate(DAYS.wednesday.very_short, code),
				Language.translate(DAYS.thursday.very_short, code),
				Language.translate(DAYS.friday.very_short, code),
				Language.translate(DAYS.saturday.very_short, code),
				Language.translate(DAYS.sunday.very_short, code),
			];
		}
	}

	public static getShortDays(
		code?: SUPPORTED_LANGUAGE_CODES,
		startOnSunday: boolean = false,
	): string[] {
		if (startOnSunday) {
			return [
				Language.translate(DAYS.sunday.short, code),
				Language.translate(DAYS.monday.short, code),
				Language.translate(DAYS.tuesday.short, code),
				Language.translate(DAYS.wednesday.short, code),
				Language.translate(DAYS.thursday.short, code),
				Language.translate(DAYS.friday.short, code),
				Language.translate(DAYS.saturday.short, code),
			];
		} else {
			return [
				Language.translate(DAYS.monday.short, code),
				Language.translate(DAYS.tuesday.short, code),
				Language.translate(DAYS.wednesday.short, code),
				Language.translate(DAYS.thursday.short, code),
				Language.translate(DAYS.friday.short, code),
				Language.translate(DAYS.saturday.short, code),
				Language.translate(DAYS.sunday.short, code),
			];
		}
	}

	public static getLongDays(
		code?: SUPPORTED_LANGUAGE_CODES,
		startOnSunday: boolean = false,
	): string[] {
		if (startOnSunday) {
			return [
				Language.translate(DAYS.sunday.long, code),
				Language.translate(DAYS.monday.long, code),
				Language.translate(DAYS.tuesday.long, code),
				Language.translate(DAYS.wednesday.long, code),
				Language.translate(DAYS.thursday.long, code),
				Language.translate(DAYS.friday.long, code),
				Language.translate(DAYS.saturday.long, code),
			];
		} else {
			return [
				Language.translate(DAYS.monday.long, code),
				Language.translate(DAYS.tuesday.long, code),
				Language.translate(DAYS.wednesday.long, code),
				Language.translate(DAYS.thursday.long, code),
				Language.translate(DAYS.friday.long, code),
				Language.translate(DAYS.saturday.long, code),
				Language.translate(DAYS.sunday.long, code),
			];
		}
	}

	public static getLongMonths(code?: SUPPORTED_LANGUAGE_CODES): string[] {
		return [
			Language.translate(MONTHS.january.long, code),
			Language.translate(MONTHS.february.long, code),
			Language.translate(MONTHS.march.long, code),
			Language.translate(MONTHS.april.long, code),
			Language.translate(MONTHS.may.long, code),
			Language.translate(MONTHS.june.long, code),
			Language.translate(MONTHS.july.long, code),
			Language.translate(MONTHS.august.long, code),
			Language.translate(MONTHS.september.long, code),
			Language.translate(MONTHS.october.long, code),
			Language.translate(MONTHS.november.long, code),
			Language.translate(MONTHS.december.long, code),
		];
	}

	public static getShortMonths(code?: SUPPORTED_LANGUAGE_CODES): string[] {
		return [
			Language.translate(MONTHS.january.short, code),
			Language.translate(MONTHS.february.short, code),
			Language.translate(MONTHS.march.short, code),
			Language.translate(MONTHS.april.short, code),
			Language.translate(MONTHS.may.short, code),
			Language.translate(MONTHS.june.short, code),
			Language.translate(MONTHS.july.short, code),
			Language.translate(MONTHS.august.short, code),
			Language.translate(MONTHS.september.short, code),
			Language.translate(MONTHS.october.short, code),
			Language.translate(MONTHS.november.short, code),
			Language.translate(MONTHS.december.short, code),
		];
	}
}
