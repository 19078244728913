import React from 'react';

import { FirebaseAuthProvider } from '@nui/providers';
import { Redirect } from 'react-router-dom';

export const LogoutController = () => {
	React.useEffect(() => {
		FirebaseAuthProvider.logout();
	}, []);

	return <Redirect to="/" />;
};
