import {
	COLOR_CODES,
	FONT_WEIGHTS,
	ICON_CODES,
	ICON_SIZES,
	INativeProps,
	TextIconView,
} from '@nui/views';
import React, { useEffect, useRef, useState } from 'react';
import LottieView from 'react-lottie';
import { Animated, Easing, Image, View } from 'react-native';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import styled from 'styled-components';
import { createBreakpoint } from 'styled-components-breakpoint';

export const breakpoints = {
	tablet: 800,
	phone: 540,
};

const breakpoint = createBreakpoint(breakpoints);
const headerHeight = 80;

const Header = styled.div`
	position: fixed;
	width: 100vw;
	height: ${headerHeight}px;
	background-color: ${COLOR_CODES.White};
	z-index: 3;
	/* offset-x | offset-y | blur-radius | spread-radius | color */
	box-shadow: 0px 2px 10px 0px ${COLOR_CODES.SteelGrey};
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 5px;
	padding-left: 15px;
	padding-right: 15px;
`;

const LayoutLeftView = styled.div`
	background-color: ${COLOR_CODES.White};
	display: none;
	flex: 1;
	z-index: 1;
	${breakpoint('tablet')`
        display:flex;
    `}
`;

const LayoutRightView = styled.div`
	background-color: ${COLOR_CODES.White};
	display: none;
	flex: 1;
	z-index: 1;
	${breakpoint('tablet')`
        display:flex;
    `}
`;

const HideOnMobile = styled.div`
	display: none;
	${breakpoint('tablet')`
        display:flex;
    `}
`;

const ShowOnMobile = styled.div`
	display: flex;
	${breakpoint('tablet')`
		display: none;
	`}
`;

const BurgerMenu = (props: { children: React.ReactNode }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isAnimating, setIsAnimating] = useState<boolean>(false);

	const toggleBurger = () => {
		setIsAnimating(true);
		setIsOpen(!isOpen);
	};

	return (
		<View>
			<ShowOnMobile>
				<TouchableWithoutFeedback
					onPress={toggleBurger}
					style={{
						width: 35,
						height: 35,
						marginLeft: 10,
					}}
				>
					<LottieView
						eventListeners={[
							{
								eventName: 'complete',
								callback: () => setIsAnimating(false),
							},
						]}
						speed={2}
						direction={isOpen ? 1 : -1}
						isPaused={!isAnimating}
						isClickToPauseDisabled={true}
						height="100%"
						width="100%"
						options={{
							autoplay: false,
							loop: false,
							animationData: require('../../assets/animations/burger-menu.json'),
						}}
					/>
				</TouchableWithoutFeedback>
				<Navbar isOpen={isOpen}>{props.children}</Navbar>
			</ShowOnMobile>

			<HideOnMobile>{props.children}</HideOnMobile>
		</View>
	);
};

const MenuItems = styled(props => {
	return (
		<div className={props.className}>
			<WhiteTextLink
				href="https://nui.care/datenschutzerklaerung_app"
				target="_blank"
			>
				Datenschutz
			</WhiteTextLink>
			<WhiteTextLink
				href="https://nui.care/Nutzungsbedingungen_app"
				target="_blank"
			>
				Nutzungsbedingungen
			</WhiteTextLink>
			{props.isSignedIn && <LogoutButton />}
		</div>
	);
})`
	display: flex;
	flex-direction: column;
	${breakpoint('tablet')`
		flex-direction: row;
	`}
`;

const Navbar = (props: { isOpen: boolean; children: React.ReactNode }) => {
	const animation = useRef(new Animated.Value(70)).current;

	useEffect(() => {
		Animated.timing(animation, {
			duration: 300,
			toValue: props.isOpen ? -200 : 70,
			easing: Easing.inOut(Easing.cubic),
			useNativeDriver: false,
		}).start();
	}, [props.isOpen, animation]);

	return (
		<Animated.View
			style={[
				{
					position: 'absolute',
					left: '10px',
					borderRadius: '10px',
					width: '320px',
					marginTop: `${headerHeight - 10}px`,
					marginLeft: animation,
					backgroundColor: `${COLOR_CODES.White}`,
					zIndex: 4,
					padding: '10px',
				},
				{
					/* boxShadow works in web, but it is not a valid ViewStyle.
					 offset-x | offset-y | blur-radius | spread-radius | color */
					boxShadow: `0px 2px 10px 0px ${COLOR_CODES.SteelGrey}`,
				} as any,
			]}
		>
			{props.children}
		</Animated.View>
	);
};

const LayoutCenterView = styled(props => (
	<div {...props} id="layout-center-view" />
))`
	width: 100%;
	overflow: auto;
	/* offset-x | offset-y | blur-radius | spread-radius | color */
	box-shadow: 0px 2px 10px 0px ${COLOR_CODES.SteelGrey};
	z-index: 2;

	${breakpoint('tablet')`
		max-width: 800px;
	`}
`;

const LayoutContentView = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	overflow: auto;
	${`min-height: calc(100vh - ${headerHeight}px)`};
	${`max-height: calc(100vh - ${headerHeight}px)`};
	${breakpoint('tablet')`
		max-width: 800px;
	`}
`;

const LayoutWrapperView = styled.div`
	display: flex;
	flex: 1;
	margin-top: ${headerHeight}px;
`;

const BigLink = styled.a`
	margin-left: 5px;
	margin-right: 5px;
	
	font-weight: ${FONT_WEIGHTS.semiBold}
	line-height: 1.2rem;
	&:hover {
		text-decoration: none;
		transition: box-shadow 0.3s;
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
	}

	height: 50px;
	padding: 15px;
	padding-left: 28px;
	padding-right: 28px;
	border-radius: 25px;
`;

const PrimaryBigLink = styled(props => <BigLink {...props} />)`
	color: ${COLOR_CODES.White};
	background-color: ${COLOR_CODES.Pink};
	text-align: center;
	border-radius: 25px;
	&:hover {
		color: ${COLOR_CODES.White};
	}
`;

const WhiteTextLink = styled.a`
	color: ${COLOR_CODES.Black};
	padding: 10px 5px 5px 10px;
	&:hover {
		color: ${COLOR_CODES.Black};
		text-decoration: none;
	}
`;

const NuiLogo = styled(props => {
	return (
		<Link style={{ marginRight: 'auto' }} to="/">
			<Image
				{...props}
				style={{ width: 49, height: 20 }}
				source={require('../../assets/images/logo.png')}
				resizeMode={'contain'}
			/>
		</Link>
	);
})`
	width: 49px;
	height: 20px;
	${breakpoint('tablet')`
		width: 74px;
		height: 30px;
	`}
`;

const LogoutButton = styled(props => {
	return (
		<Link {...props} to="/logout">
			<TextIconView icon={ICON_CODES.Logout} size={ICON_SIZES.lg} />
			<span>Log Out</span>
		</Link>
	);
})`
	display: flex;
	color: ${COLOR_CODES.Black};
	padding: 10px 5px 5px 10px;

	&:hover {
		color: ${COLOR_CODES.Black};
		text-decoration: none;
	}
	span {
		font-weight: ${FONT_WEIGHTS.semiBold};
		font-size: 16px;
	}
`;

interface ILayoutViewProps {
	isSignedIn: boolean;
}

export const LayoutView = (props: INativeProps & ILayoutViewProps) => {
	return (
		<div style={{ minHeight: '100%' }}>
			<Header>
				<NuiLogo />
				{!props.isSignedIn && (
					<PrimaryBigLink href="/assistant-demo">
						Try the demo
					</PrimaryBigLink>
				)}
				<BurgerMenu>
					<MenuItems isSignedIn={props.isSignedIn} />
				</BurgerMenu>
			</Header>
			<LayoutWrapperView>
				<LayoutLeftView>
					<Container fluid={true} />
				</LayoutLeftView>
				<LayoutCenterView>
					<LayoutContentView>{props.children}</LayoutContentView>
				</LayoutCenterView>
				<LayoutRightView>
					<Container fluid={true} />
				</LayoutRightView>
			</LayoutWrapperView>
		</div>
	);
};
