import React from 'react';
import { Image } from 'react-native';

import { ASSISTANT_HISTORY_AVATAR } from '../../../models/firestore/assistant/firestore-assistant.model';
import {
	ALIGN,
	DIRECTION,
	JUSTIFY,
	WrapperAlignView,
} from '../../wrappers/wrapper-align.view';
import {
	extractWrapperProps,
	INativeProps,
	IWrapperProps,
} from '../../wrappers/wrapper-interfaces';
import { WrapperListView } from '../../wrappers/wrapper-list.view';
import { WrapperView } from '../../wrappers/wrapper.view';
import { AccordionView } from '../types/accordion/accordion.view';
import { LinkButtonsView } from '../types/link-buttons/link-buttons.view';
import { SelectView } from '../types/select/select.view';
import { TextMessageLeftView } from '../types/text-message/text-message-left.view';
import { AssistantBubbleLeftView } from './assistant-bubble-left.view';
import {
	AssistantItemView,
	IAssistantItem,
	isAssistantItemAccordion,
	isAssistantItemLinkButtons,
	isAssistantItemSelect,
	isAssistantItemTextMessage,
	MAX_SELECT_OPTIONS,
} from './assistant-item.view';

export interface AssistantLeftProps extends IWrapperProps {
	items: IAssistantItem[];
	authorId: string | ASSISTANT_HISTORY_AVATAR;
}

const renderItems = (props: AssistantLeftProps) => {
	return props.items
		.map((item, index) => {
			if (isAssistantItemTextMessage(item)) {
				return (
					<AssistantItemView
						thinkingTime={
							(item.thinking &&
								item.message &&
								Math.max(
									800,
									Math.min(item.message.length * 40, 2200),
								)) ||
							undefined
						}
						onFinished={item.onFinished}
						key={item.key}
					>
						<AssistantBubbleLeftView
							isLast={
								(props.authorId ===
									ASSISTANT_HISTORY_AVATAR.nui ||
									props.authorId ===
										ASSISTANT_HISTORY_AVATAR.concierge) &&
								index == props.items.length - 1
							}
						>
							<TextMessageLeftView {...item} />
						</AssistantBubbleLeftView>
					</AssistantItemView>
				);
			} else if (isAssistantItemLinkButtons(item)) {
				return (
					<AssistantItemView
						onFinished={item.onFinished}
						key={item.key}
					>
						<LinkButtonsView {...item} />
					</AssistantItemView>
				);
			} else if (isAssistantItemAccordion(item)) {
				return (
					<AssistantItemView
						onFinished={item.onFinished}
						key={item.key}
					>
						<AccordionView {...item} />
					</AssistantItemView>
				);
			} else if (isAssistantItemSelect(item)) {
				if (
					item.options.length > MAX_SELECT_OPTIONS &&
					!item.selected
				) {
					item.onFinished && item.onFinished();
					return null;
				} else {
					return (
						<AssistantItemView
							onFinished={item.onFinished}
							key={item.key}
						>
							<SelectView {...item} />
						</AssistantItemView>
					);
				}
			} else {
				return null;
			}
		})
		.filter(item => !!item);
};

export const AssistantLeftView = (props: AssistantLeftProps & INativeProps) => {
	const isAvatarInvisible =
		props.authorId == ASSISTANT_HISTORY_AVATAR.invisible;
	const isAvatarNui = props.authorId == ASSISTANT_HISTORY_AVATAR.nui;
	const isAvatarConcierge =
		props.authorId == ASSISTANT_HISTORY_AVATAR.concierge;
	const verticalAvatarOffset = 20;
	const avatarStyles = {
		marginLeft: 10,
		width: 40,
		height: 40,
		marginBottom: -verticalAvatarOffset,
	};
	return (
		<WrapperAlignView
			itemsJustify={JUSTIFY.Start}
			itemsAlign={ALIGN.End}
			{...extractWrapperProps(props)}
			styles={{
				wrapper: {
					marginBottom:
						((isAvatarNui || isAvatarConcierge) &&
							verticalAvatarOffset) ||
						0,
				},
			}}
		>
			{(isAvatarNui || isAvatarInvisible || isAvatarConcierge) && (
				<WrapperView width={58}>
					{isAvatarNui && (
						<Image
							source={require('../../../assets/images/assistant.png')}
							style={avatarStyles}
						/>
					)}
					{isAvatarConcierge && (
						<Image
							source={require('../../../assets/images/concierge.png')}
							style={avatarStyles}
						/>
					)}
				</WrapperView>
			)}
			<WrapperListView
				itemsDirection={DIRECTION.Vertical}
				wrapperBetween={5}
				styles={{ wrapper: { flex: 1 } }}
			>
				{renderItems(props)}
			</WrapperListView>
		</WrapperAlignView>
	);
};
