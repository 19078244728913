import { EventTypes, useTrackEvent } from '@nui/providers';
import { Language } from '@nui/utils';
import { ITopicsListProps } from '@nui/views';
import { ISortedCourseTopic } from 'modules/@nui/models/state/state-courses.model';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { stateModel } from '../../../../globals';
import {
	IPagePflege1x1OverviewProps,
	PagePflege1x1OverviewView,
} from '../../../views/pages/page-care101-overview.view';
import content from '../assets/content';

interface PagePflege1x1OverviewProps {
	courseId: string;
}

export const PagePflege1x1OverviewSubcontroller = (
	props: PagePflege1x1OverviewProps,
) => {
	const course = stateModel.stateCoursesModel.getCourse(props.courseId);

	const trackEvent = useTrackEvent();

	if (course?.id) {
		const userId = stateModel.stateAuthModel.getAuth()?.uid;
		trackEvent(
			EventTypes.OC,
			'viewed',
			{
				inputIds: [course?.id],
			},
			{ userId },
		);
	}

	const lastSeenTopics = stateModel.stateCoursesModel.getSeenTopics(
		props.courseId,
	);
	const furtherTopics = stateModel.stateCoursesModel.getFurtherTopics(
		props.courseId,
	);
	const allTopics = stateModel.stateCoursesModel.getSortedTopics(
		props.courseId,
	);

	const topicsList = () => {
		console.log(lastSeenTopics, furtherTopics, allTopics);
		if (lastSeenTopics?.length ?? 0 > 0) {
			return [
				createTopicList(
					Language.translate(content.lastSeen),
					lastSeenTopics,
				),
				createTopicList(
					Language.translate(content.furtherTopics),
					furtherTopics,
				),
			].filter(x => x) as ITopicsListProps[];
		} else {
			return [
				createTopicList(
					Language.translate(content.allTopics),
					allTopics,
				),
			] as ITopicsListProps[];
		}
	};

	const createTopicList = (
		title: string,
		sortedTopics?: ISortedCourseTopic[],
	) => {
		if (sortedTopics && sortedTopics.length > 0) {
			return {
				title,
				topics: sortedTopics.map(item => {
					const progress = stateModel.stateCoursesModel.getProgress(
						props.courseId,
						item.topicId,
					);

					return {
						avatar: {
							percent: progress.percent,
							image: stateModel.stateCoursesModel.getTopicIcon(
								props.courseId,
								item.topicId,
							),
							disabled: false,
						},
						name: Language.translate(item.topic.title),
						chapterCountLabel:
							(item.topic.chapterCount > 0 &&
								item.topic.chapterCount +
									' ' +
									Language.translate(
										content.chapterCountLabel,
									)) ||
							'',
						onClick: () =>
							handleTopicClick(props.courseId, item.topicId),
					};
				}),
			};
		} else {
			return undefined;
		}
	};

	const history = useHistory();

	const handleTopicClick = (courseId: string, topicId: string) => {
		history.push(`/care101s/${courseId}/topics/${topicId}`, {
			redirect: `/care101s/${courseId}`,
		});

		if (stateModel.stateCoursesModel.hasCourseProgress(courseId, topicId)) {
			history.push(`/care101s/${courseId}/topics/${topicId}/chapters`, {
				redirect: `/care101s/${courseId}`,
			});
		}
	};

	const PagePflege1x1OverviewTransfer: IPagePflege1x1OverviewProps = {
		moduleIntro: {
			title: Language.translate(
				Language.replace(
					course!.title,
					'{senior}',
					stateModel.stateTeamsModel.getSenior()?.firstName || '',
				),
			),

			courseLength:
				allTopics &&
				allTopics.length +
					' ' +
					Language.translate(content.moduleIntro.coursesCount),
			courseIntro: Language.translateFromBBCode(
				Language.replace(
					course!.intro,
					'{senior}',
					stateModel.stateTeamsModel.getSenior()?.firstName || '',
				),
			),
		},
		topicsList: topicsList(),
	};

	return <PagePflege1x1OverviewView {...PagePflege1x1OverviewTransfer} />;
};
