import React from 'react';
import styled from 'styled-components/native';
import { Text } from 'react-native';
import { INativeProps, IStyles } from '../../wrappers/wrapper-interfaces';
import { ITextProps } from '../text-interfaces';
import { COLOR_CODES } from '../../enums/colors';

interface IHtmlUnderlineProps extends ITextProps {
	onClick?: () => void;
	extraStyles?: IStyles;
}

const hexToRgb = (hex: any) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
		  }
		: undefined;
};

// TODO: enum FONT_WEIGHTS is undefined in Styleguiddist
export const HtmlUnderlineView = styled(
	(props: IHtmlUnderlineProps & INativeProps) => {
		const rgb = hexToRgb(props.color || COLOR_CODES.Black);
		const r = rgb?.r;
		const g = rgb?.g;
		const b = rgb?.b;

		const [color, setColor] = React.useState(`rgba(${r}, ${g}, ${b}, 1)`);
		const handleOnPress = () => {
			props.onClick && props.onClick();
			setColor(`rgba(${r}, ${g}, ${b}, 0.5)`);
			setTimeout(() => {
				setColor(`rgba(${r}, ${g}, ${b}, 1)`);
			}, 100);
		};

		return (
			<Text
				{...props.nativeTextProps}
				onPress={props.onClick && handleOnPress}
				style={[props.style, { color, textDecorationColor: color }]}
				suppressHighlighting={!!props.onClick}
			>
				{props.children}
			</Text>
		);
	},
)`
	text-decoration: underline;
	${(props: IHtmlUnderlineProps) => ({
		...props.styles?.text,
		...props.styles?.underline,
		...props.extraStyles,
	})};
`;
