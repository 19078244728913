// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
// Hack: Declare empty namespaces to avoid compiler warnings.
// Ideally these should come from firebase (web)
// eslint-disable-next-line @typescript-eslint/no-namespace
namespace FirebaseFirestoreTypes {}
export { firebase, FirebaseFirestoreTypes };
