import React from 'react';

import { ButtonPlainView } from '../buttons/button-plain.view';
import { ButtonPrimaryView } from '../buttons/button-primary.view';
import { COLOR_CODES } from '../enums/colors';
import { SwitchView } from '../misc/switch.view';
import { TextBodySemiboldLeftView } from '../texts/text-body-semibold-left.view';
import { FONT_WEIGHTS } from '../texts/text-interfaces';
import { WrapperAlignView, JUSTIFY } from '../wrappers/wrapper-align.view';
import {
	extractWrapperProps,
	IWrapperProps,
} from '../wrappers/wrapper-interfaces';
import { WrapperView } from '../wrappers/wrapper.view';

export interface IPrivacyAcceptProps extends IWrapperProps {
	dataLabel: string;
	onDataClick: () => void;
	generalLabel: string;
	onGeneralClick: () => void;
	switchLabel: string;
	acceptLabel: string;
	onClick: () => void;
}

export const PrivacyAcceptView = (props: IPrivacyAcceptProps) => {
	const [checked, setChecked] = React.useState(false);

	const wrapperProps = extractWrapperProps(props);
	return (
		<WrapperView {...wrapperProps}>
			<ButtonPlainView
				label={props.dataLabel}
				styles={{
					label: {
						color: COLOR_CODES.Pink,
						fontWeight: FONT_WEIGHTS.semiBold,
					},
				}}
				onClick={props.onDataClick}
			/>
			<ButtonPlainView
				label={props.generalLabel}
				styles={{
					label: {
						color: COLOR_CODES.Pink,
						fontWeight: FONT_WEIGHTS.semiBold,
					},
				}}
				onClick={props.onGeneralClick}
			/>
			<WrapperAlignView wrapperTop={35} wrapperBottom={20}>
				<SwitchView
					checked={checked}
					onChange={hasChecked => setChecked(hasChecked)}
				/>
				<TextBodySemiboldLeftView
					wrapperLeft={15}
					styles={{ wrapper: { flex: 1 } }}
				>
					{props.switchLabel}
				</TextBodySemiboldLeftView>
			</WrapperAlignView>

			<WrapperAlignView itemsJustify={JUSTIFY.End}>
				<ButtonPrimaryView
					rounded={true}
					label={props.acceptLabel}
					onClick={(checked && props.onClick) || undefined}
					styles={{
						container: {
							backgroundColor: checked
								? COLOR_CODES.Pink
								: COLOR_CODES.Grey10,
						},
					}}
				/>
			</WrapperAlignView>
		</WrapperView>
	);
};
