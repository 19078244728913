import React from 'react';
import { ScrollView } from 'react-native';
import { ButtonPlainView } from '../buttons/button-plain.view';
import { TextTitleTitle2View } from '../texts/text-title-title2.view';
import { DIRECTION, WrapperAlignView } from '../wrappers/wrapper-align.view';
import {
	extractWrapperProps,
	INativeProps,
	IWrapperProps,
} from '../wrappers/wrapper-interfaces';
import { WrapperListView } from '../wrappers/wrapper-list.view';
import { WrapperView } from '../wrappers/wrapper.view';
import { ISmallCardProps, SmallCardView } from './smallCard.view';

export interface ISmallCardWrapperProps extends IWrapperProps {
	title: string;
	moreLabel: string;
	onMoreClick: () => void;
	cards: ISmallCardProps[];
}

export const SmallCardWrapperView = (
	props: ISmallCardWrapperProps & INativeProps,
) => {
	const { title, moreLabel, onMoreClick, cards } = props;
	const wrapperProps = extractWrapperProps(props);

	return (
		<WrapperView {...wrapperProps} wrapperTop={30}>
			<WrapperAlignView wrapperLeft={20}>
				<TextTitleTitle2View
					styles={{
						wrapper: {
							flex: 1,
						},
					}}
				>
					{title}
				</TextTitleTitle2View>
				<ButtonPlainView
					label={moreLabel}
					onClick={onMoreClick}
					smallFont={true}
					wrapper={20}
					wrapperBottom={10}
				/>
			</WrapperAlignView>
			<ScrollView
				showsHorizontalScrollIndicator={false}
				horizontal={true}
			>
				<WrapperListView
					itemsDirection={DIRECTION.Horizontal}
					styles={{
						item: { marginRight: 10 },
					}}
					wrapper={20}
					wrapperTop={10}
				>
					{cards.map((card, key) => {
						return (
							<SmallCardView
								key={key}
								onCardClick={card.onCardClick}
								image={card.image}
								headline={card.headline}
								metaInfo={card.metaInfo}
							/>
						);
					})}
				</WrapperListView>
			</ScrollView>
		</WrapperView>
	);
};
