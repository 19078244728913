import { FirebaseWebAuthProvider } from '@nui/providers';
import {
	ALIGN,
	ButtonPrimaryView,
	COLOR_CODES,
	DIRECTION,
	JUSTIFY,
	TextCaptionDefaultView,
	TextfieldPhoneView,
	TextfieldView,
	TextTitleTitle1View,
	TEXT_ALIGN,
	WrapperAlignView,
} from '@nui/views';
import firebase from 'firebase';
import React from 'react';
import { View } from 'react-native';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Link } from 'react-router-dom';
import { FormCheckboxView } from 'web-app/views/forms/form-checkbox.view';
import { LayoutView } from 'web-app/views/layout.view';

export const LoginController = () => {
	const statics = React.useRef<{
		recaptchaVerifier?: firebase.auth.RecaptchaVerifier;
		recaptchaWidgetId?: number;
	}>({}).current;
	const [phone, setPhone] = React.useState('');
	const [code, setCode] = React.useState('');
	const [privacy, setPrivacy] = React.useState(false);
	const [sended, setSended] = React.useState(false);
	const [confirmationResult, setConfirmationResult] = React.useState<
		firebase.auth.ConfirmationResult | undefined
	>();
	const [errorOccurred, setErrorOccurred] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		statics.recaptchaVerifier = FirebaseWebAuthProvider.initRecaptchaVerifier(
			'recaptchaContainer',
		);
	}, [statics]);

	const handlePhoneSubmit = async () => {
		try {
			setLoading(true);
			const recaptchaVerifier = statics.recaptchaVerifier;
			if (recaptchaVerifier) {
				const result = await FirebaseWebAuthProvider.loginWithPhoneNumber(
					phone,
					recaptchaVerifier,
				);
				setConfirmationResult(prev => result || prev);
			}
		} catch (error) {
			setErrorOccurred(true);
			console.error('captcha failed due to', error);
		} finally {
			setLoading(false);
		}
		setSended(true);
	};

	const handleCodeSubmit = async () => {
		try {
			setLoading(true);
			if (confirmationResult) {
				await FirebaseWebAuthProvider.verify(confirmationResult, code);
			} else {
				throw new Error('Missing confirmation result');
			}
		} catch (error) {
			setErrorOccurred(true);
			setLoading(false);
			console.error('fail', error);
		}
	};

	const handlePhoneChange = (newPhone: string) => {
		setPhone(newPhone);
		return Promise.resolve();
	};

	const handleCodeChange = (newCode: string) => {
		setCode(newCode);
		return Promise.resolve();
	};

	const isValid = () => {
		return privacy && isValidPhoneNumber(phone);
	};

	return (
		<LayoutView isSignedIn={false}>
			<WrapperAlignView
				styles={{
					wrapper: {
						marginTop: 'auto',
						marginBottom: 'auto',
					},
				}}
				itemsDirection={DIRECTION.Vertical}
				itemsAlign={ALIGN.Center}
				itemsJustify={JUSTIFY.Center}
				wrapper={20}
			>
				{!sended && (
					<View
						style={{
							maxWidth: 400,
						}}
					>
						<TextTitleTitle1View
							wrapperBottom={50}
							textAlign={TEXT_ALIGN.center}
						>
							Bitte gib Deine Handynummer ein:
						</TextTitleTitle1View>
						<TextfieldPhoneView onChange={handlePhoneChange} />
						<TextCaptionDefaultView
							wrapperTop={5}
							wrapperBottom={30}
						>
							Um Deine Identität zu verifizieren, erhälst Du in
							Kürze einen Bestätigungs-Code per SMS an die
							angegebene Telefonnummer.
						</TextCaptionDefaultView>
						<FormCheckboxView
							onChange={() => setPrivacy(!privacy)}
							checked={privacy}
						>
							<span>
								Ich habe die{' '}
								<a
									href="https://nui.care/datenschutzerklaerung_app"
									title="Privacy Policy "
									target="privacy"
									style={{ color: COLOR_CODES.Pink }}
								>
									Datenschutzbestimmungen
								</a>{' '}
								zur Speicherung meiner Daten gelesen und erkläre
								mich damit einverstanden.
							</span>
						</FormCheckboxView>
						<ButtonPrimaryView
							animatedDots={loading}
							wrapperTop={50}
							label="Anmelden"
							onClick={
								(isValid() && handlePhoneSubmit) || undefined
							}
						/>
					</View>
				)}
				{sended && (
					<View
						style={{
							maxWidth: 400,
						}}
					>
						<TextTitleTitle1View
							wrapperBottom={50}
							textAlign={TEXT_ALIGN.center}
						>
							Bestätige jetzt Deine Identität:
						</TextTitleTitle1View>
						<TextfieldView
							nativeTextInputProps={{ placeholder: 'SMS-Code' }}
							onChange={handleCodeChange}
							color={COLOR_CODES.Grey75}
							borderBottom={COLOR_CODES.Grey25}
						/>
						{(errorOccurred && (
							<TextCaptionDefaultView
								wrapperTop={5}
								color={COLOR_CODES.Red}
							>
								Ein Fehler ist aufgetreten, hast Du den Code
								richtig eingegeben?{' '}
								<Link
									to="/"
									onClick={() => {
										setSended(false);
										setErrorOccurred(false);
									}}
									style={{ color: COLOR_CODES.Pink }}
								>
									Handynummer noch mal prüfen.
								</Link>
							</TextCaptionDefaultView>
						)) || (
							<TextCaptionDefaultView wrapperTop={5}>
								Bitte gib den Bestätigungscode ein, den Du
								gerade per SMS erhalten hast. Du hast keinen
								Code erhalten?{' '}
								<Link
									to="/"
									onClick={() => {
										setSended(false);
										setErrorOccurred(false);
									}}
									style={{ color: COLOR_CODES.Pink }}
								>
									Handynummer noch mal prüfen.
								</Link>
							</TextCaptionDefaultView>
						)}
						<ButtonPrimaryView
							label="Bestätigen"
							onClick={
								(isValid() && handleCodeSubmit) || undefined
							}
							wrapperTop={50}
							animatedDots={loading}
						/>
					</View>
				)}
				<View nativeID="recaptchaContainer" />
			</WrapperAlignView>
		</LayoutView>
	);
};
