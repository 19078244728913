import { HelpersUtil } from '@nui/utils';
import { firebase, FirebaseAuthTypes } from 'firebase-xp';
import Sentry from 'sentry-xp';

import {
	FirebaseAuthProvider,
	FirebaseAuthListenerEvents,
} from './firebase-auth.provider';

export class FirebaseNativeAuthProvider extends FirebaseAuthProvider {
	protected listener?: () => void;

	public unlisten() {
		if (this.listener) {
			this.listener();
		}
	}

	public listen() {
		this.unlisten();
		return new FirebaseAuthListenerEvents(
			(loggedIn, loggedOut, teamChanged) => {
				this.listener = firebase
					.auth()
					.onIdTokenChanged(async (sysuserDTO: any) => {
						if (sysuserDTO) {
							if (this.auth) {
								// Do not move outside condition
								const teamIds = await FirebaseNativeAuthProvider.getTeamIds();
								if (
									!HelpersUtil.hasSameElements<string>(
										this.teamIds,
										teamIds,
									)
								) {
									this.teamIds = teamIds;
									teamChanged(sysuserDTO, teamIds);
								}
							} else {
								// Do not move outside condition
								const teamIds = await FirebaseNativeAuthProvider.getTeamIds();
								console.log(teamIds);
								if (teamIds.length > 0) {
									console.log('Login!');
									this.auth = { ...sysuserDTO };
									this.teamIds = teamIds;
									loggedIn(sysuserDTO, this.teamIds);
								}
							}
						} else {
							this.auth = undefined;
							this.teamIds = [];
							loggedOut();
						}
					});
			},
		);
	}

	public static verify(
		confirmResult: FirebaseAuthTypes.ConfirmationResult,
		code: string,
	) {
		if (confirmResult) {
			return confirmResult.confirm(code);
		} else {
			Promise.reject();
		}
	}

	public static login(phone: string) {
		return firebase.auth().signInWithPhoneNumber(phone);
	}

	public static async verifyPhoneNumber(phone: string): Promise<string> {
		return new Promise((resolve, reject) => {
			firebase
				.auth()
				.verifyPhoneNumber(phone)
				.on('state_changed', phoneAuthSnapshot => {
					switch (phoneAuthSnapshot.state) {
						// ------------------------
						//  IOS AND ANDROID EVENTS
						// ------------------------
						case firebase.auth.PhoneAuthState.CODE_SENT:
							// proceed with manual code input flow
							resolve(phoneAuthSnapshot.verificationId);
							break;
						case firebase.auth.PhoneAuthState.ERROR:
							Sentry.captureException(phoneAuthSnapshot.error);
							reject();
							break;
						// ---------------------
						// ANDROID ONLY EVENTS
						// ---------------------
						case firebase.auth.PhoneAuthState.AUTO_VERIFY_TIMEOUT:
							// proceed with manual code input flow
							resolve(phoneAuthSnapshot.verificationId);
							break;
						case firebase.auth.PhoneAuthState.AUTO_VERIFIED: {
							// auto verified means the code has also been automatically confirmed as correct/received
							const credential = firebase.auth.PhoneAuthProvider.credential(
								phoneAuthSnapshot.verificationId,
								phoneAuthSnapshot.code as string,
							);
							firebase.auth().signInWithCredential(credential);
							break;
						}
					}
				});
		});
	}
}
