import { StateAssistantModel } from './state-assistant.model';
import { StateAuthModel } from './state-auth.model';
import { StateCoursesModel } from './state-courses.model';
import { IState } from './state-interfaces';
import { StateTeamsModel } from './state-teams.model';
import { StateUserModel } from './state-user.model';

export class StateModel {
	state: IState;
	stateCoursesModel: StateCoursesModel;
	stateTeamsModel: StateTeamsModel;
	stateUserModel: StateUserModel;
	stateAuthModel: StateAuthModel;
	stateAssistantModel: StateAssistantModel;

	constructor(state: IState) {
		this.state = state;
		this.stateCoursesModel = new StateCoursesModel(state, this);
		this.stateTeamsModel = new StateTeamsModel(state, this);
		this.stateUserModel = new StateUserModel(state, this);
		this.stateAuthModel = new StateAuthModel(state, this);
		this.stateAssistantModel = new StateAssistantModel(state, this);
	}

	isLoading() {
		return this.state.loading;
	}

	setLoading(loading: boolean) {
		this.state.loading = loading;
	}

	isWaiting() {
		return !!this.state.waiting;
	}

	setWaiting(waiting: boolean) {
		this.state.waiting = waiting;
	}

	setTeamId(teamId: string) {
		this.state.teamId = teamId;
	}

	getTeamId() {
		return this.state.teamId;
	}

	unsetTeamId() {
		this.state.teamId = undefined;
	}

	getState(): IState {
		this.stateCoursesModel.initializeState();
		this.stateUserModel.initializeState();
		this.stateAuthModel.initializeState();
		this.stateAssistantModel.initializeState();
		return { ...this.state };
	}

	removeAll() {
		this.stateCoursesModel.removeCourses();
		this.stateUserModel.unsetUser();
		this.stateAuthModel.unsetAuth();
		this.stateAssistantModel.removeHistory();
	}
}
