import React from 'react';
import { TextTitleTitle3View } from '../texts/text-title-title3.view';
import { WrapperListView } from '../wrappers/wrapper-list.view';
import { ITopicProps, TopicView } from './course-topic.view';
import { WrapperView } from '../wrappers/wrapper.view';

export interface ITopicsListProps {
	topics: ITopicProps[];
	title?: string;
}

export const TopicsListView = (props: ITopicsListProps) => {
	return (
		<WrapperView wrapperTop={15}>
			{props.title && (
				<TextTitleTitle3View>{props.title}</TextTitleTitle3View>
			)}

			<WrapperListView itemHeight={85}>
				{props.topics.map((topic, key) => {
					return (
						<WrapperView key={key}>
							<TopicView
								{...topic}
								disabled={topic.avatar.disabled}
								locked={topic.locked}
							/>
							{key !== props.topics.length - 1 && (
								<WrapperView
									styles={{
										wrapper: {
											position: 'absolute',
											top: 58,
											left: 30,
											zIndex: -1,
										},
									}}
								/>
							)}
						</WrapperView>
					);
				})}
			</WrapperListView>
		</WrapperView>
	);
};
