import React from 'react';
import { AVATAR_RADIUS } from './svg-avatar.view.native';

export interface ISVGAvatarProps {
	radius: AVATAR_RADIUS;
}

export const SVGAvatarPhotoCameraView = (props: ISVGAvatarProps) => {
	return (
		<svg
			width={props.radius * 2}
			height={props.radius * 2}
			viewBox="0 0 60 60"
		>
			<g fill="none">
				<path
					fill="#D1D1D6"
					d="M30,60 C13.4314575,60 0,46.5685425 0,30 C0,13.4314575 13.4314575,0 30,0 C46.5685425,0 60,13.4314575 60,30 C60,46.5685425 46.5685425,60 30,60 Z M23.0857848,18 L15,18 C13.8954305,18 13,18.8954305 13,20 L13,39 C13,40.1045695 13.8954305,41 15,41 C15,41.8284271 15.6715729,42.5 16.5,42.5 L44.5,42.5 C45.3284271,42.5 46,41.8284271 46,41 C47.1045695,41 48,40.1045695 48,39 L48,20 C48,19.1552606 47.4762895,18.4328403 46.7358397,18.1397105 C46.5744958,17.4852993 45.9836167,17 45.2793898,17 L41.5,17 C40.8468906,17 40.2912711,17.4174038 40.0853529,18 L38.7571848,18 C38.621712,17.6171547 38.3321431,17.2902627 37.9288278,17.1191449 L36.4695367,16.5 L25.145898,16.5 L23.8291796,17.1583592 C23.467423,17.3392375 23.2106955,17.6465722 23.0857848,18 Z M24.469824,20 C24.4906828,20.0004374 24.5115779,20.0004389 24.5324961,20 L37.3083181,20 C37.3310074,20.0005173 37.3536617,20.0005151 37.3762643,20 L41.5,20 L45.2793898,20 L46,20 L46,39 L15,39 L15,20 L24.4698239,20 Z M30.5,37 C34.6421356,37 38,33.6421356 38,29.5 C38,25.3578644 34.6421356,22 30.5,22 C26.3578644,22 23,25.3578644 23,29.5 C23,33.6421356 26.3578644,37 30.5,37 Z M30.5,35 C27.4624339,35 25,32.5375661 25,29.5 C25,26.4624339 27.4624339,24 30.5,24 C33.5375661,24 36,26.4624339 36,29.5 C36,32.5375661 33.5375661,35 30.5,35 Z M30,25 C27.790861,25 26,26.790861 26,29 C26,29.5522847 26.4477153,30 27,30 C27.5522847,30 28,29.5522847 28,29 C28,27.8954305 28.8954305,27 30,27 C30.5522847,27 31,26.5522847 31,26 C31,25.4477153 30.5522847,25 30,25 Z M18,24.75 L20.2793898,24.75 C20.9697458,24.75 21.5293898,24.1903559 21.5293898,23.5 C21.5293898,22.8096441 20.9697458,22.25 20.2793898,22.25 L18,22.25 C17.3096441,22.25 16.75,22.8096441 16.75,23.5 C16.75,24.1903559 17.3096441,24.75 18,24.75 Z"
				/>
				<path
					fill="#F05F97"
					d="M8.00308228,6 L13,6 C13.5522847,6 14,6.44771525 14,7 C14,7.55228475 13.5522847,8 13,8 L8.00308228,8 L8.00308228,13.0127473 C8.00308228,13.5650321 7.55536703,14.0127473 7.00308228,14.0127473 C6.45079753,14.0127473 6.00308228,13.5650321 6.00308228,13.0127473 L6.00308228,8 L1,8 C0.44771525,8 0,7.55228475 0,7 C0,6.44771525 0.44771525,6 1,6 L6.00308228,6 L6.00308228,1.01192199 C6.00308228,0.459637239 6.45079753,0.0119219892 7.00308228,0.0119219892 C7.55536703,0.0119219892 8.00308228,0.459637239 8.00308228,1.01192199 L8.00308228,6 Z"
					transform="translate(43 43)"
				/>
			</g>
		</svg>
	);
};
