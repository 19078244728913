import React from 'react';
import styled from 'styled-components/native';
import { Text, View } from 'react-native';
import { INativeProps, IStyles } from '../../wrappers/wrapper-interfaces';
import { ITextProps } from '../text-interfaces';

import { ALIGN, JUSTIFY } from '../../wrappers/wrapper-align.view';
import { SVGAvatarPhotoCameraView } from '../../fallbacks/svg-avatar-photo-camera.view';
import { WrapperAlignView, DIRECTION } from '../../wrappers/wrapper-align.view';

interface IHtmlListProps extends ITextProps {
	onClick?: () => void;
	icon?: string;
	extraStyles?: IStyles;
}

const renderIcon = (props: IHtmlListProps) => {
	const iconName = props.icon;
	if (iconName == 'camera') {
		return <SVGAvatarPhotoCameraView radius={40} />;
	} else {
		return null;
	}
};

const renderBullet = (props: IHtmlListProps) => {
	return (
		(!props.icon && (
			<View
				style={{
					width: 4,
					height: 4,
					marginRight: 6,
					marginLeft: 5,
					marginTop: 10,
					backgroundColor: 'black',
					borderRadius: 2,
				}}
			/>
		)) ||
		null
	);
};
// TODO: enum FONT_WEIGHTS is undefined in Styleguiddist
export const HtmlListView = styled((props: IHtmlListProps & INativeProps) => {
	const [opacity, setOpacity] = React.useState(1);

	const handleOnPress = () => {
		props.onClick && props.onClick();
		setOpacity(0.5);
		setTimeout(() => {
			setOpacity(1);
		}, 100);
	};

	return (
		<WrapperAlignView
			itemsDirection={DIRECTION.Horizontal}
			itemsAlign={ALIGN.Start}
			itemsJustify={JUSTIFY.Start}
			wrapperTop={2}
			wrapperBottom={2}
		>
			{renderBullet(props)}
			{renderIcon(props)}
			<Text
				{...props.nativeTextProps}
				onPress={props.onClick && handleOnPress}
				style={[props.style, { opacity }, { flex: 1 }]}
			>
				{props.children}
			</Text>
		</WrapperAlignView>
	);
})`
	${(props: IHtmlListProps) => ({
		...props.styles?.text,
		...props.styles?.li,
		...props.extraStyles,
	})};
`;
