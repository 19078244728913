/* 
    Patterns are taken from: https://github.com/solvvy/redact-pii/blob/master/src/built-ins/simple-regexp-patterns.ts
*/
// Key Patterns
export const email = /(email|e-mail|mail)|address/gi;
export const username = /(user( ?name)?|login)/gi;
export const password = /(pass(word|phrase)?|secret)/gi;
export const credentials = /(login(cred(ential)?s|info(rmation)?)?|cred(ential)?s)/gi;
// Value Patterns
export const emailAddress = /([a-z0-9_\-.+]+)@\w+(\.\w+)*/gi;
export const ipAddress = /(\d{1,3}(\.\d{1,3}){3}|[0-9A-F]{4}(:[0-9A-F]{4}){5}(::|(:0000)+))/gi;
export const url = /([^\s:/?#]+):\/\/([^/?#\s]*)([^?#\s]*)(\?([^#\s]*))?(#([^\s]*))?/g;
export const creditCardNumber = /\d{4}[ -]?\d{4}[ -]?\d{4}[ -]?\d{4}|\d{4}[ -]?\d{6}[ -]?\d{4}\d?/g;
