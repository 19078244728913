import React from 'react';
import { TrackEventFunction, useTrackEvent } from './event-tracking.provider';

export interface IEventTrackingProps {
	trackEvent: TrackEventFunction;
}

/**
 * Injector HOC: Injects and manages a property, which the wrapped component might rely on
 * via a props interface, but which is forbidden to set from 'outside'.
 * See also: https://medium.com/@jrwebdev/react-higher-order-component-patterns-in-typescript-42278f7590fb
 * @param Component a class Component which needs the trackEvent function,
 * but can not use the `useTrackEvent` hook
 */
export const withEventTracking = <P extends object>(
	Component: React.ComponentType<P>,
) => {
	const WithEventTracking: React.ComponentType<
		Omit<P, keyof IEventTrackingProps>
	> = props => {
		const trackEventFunc = useTrackEvent();
		const injectedProps = { ...props, trackEvent: trackEventFunc } as P;
		return <Component {...injectedProps} />;
	};
	return WithEventTracking;
};
