import React from 'react';
import styled from 'styled-components/native';
import { COLOR_CODES } from '../enums/colors';
import { INativeProps } from '../wrappers/wrapper-interfaces';
import { FONT_WEIGHTS, ITextProps } from './text-interfaces';
import { TextView } from './text.view';

export const TextSubheadlineSemiboldSteelgreyView = styled(
	(props: ITextProps & INativeProps) => <TextView {...props} />,
)`
	font-size: 15;
	letter-spacing: -0.24;
	line-height: 20;
	font-weight: ${(props: ITextProps) =>
		props.weight || FONT_WEIGHTS.semiBold};
	color: ${(props: ITextProps) => props.color || COLOR_CODES.SteelGrey};
	${(props: ITextProps) => ({
		...props.styles?.text,
	})};
`;
