import { HelpersUtil } from '@nui/utils';
import { FirebaseFirestoreTypes } from '@react-native-firebase/firestore';

type TInitialised = (entityDTOs: { [entityId: string]: object }) => void;
type IAdded = (entityDTOs: { [entityId: string]: object }) => void;
type IRemoved = (entityDTOs: { [entityId: string]: object }) => void;
type IModified = (entityDTOs: { [entityId: string]: object }) => void;
type IFailed = (error: Error) => void;
type IRejected = (error: Error) => void;

class FirestoreQueryObserverEvents {
	handleInitialized?: TInitialised;
	handleAdded?: IAdded;
	handleRemoved?: IRemoved;
	handleModified?: IModified;
	handleFailed?: IFailed;
	handleRejected?: IRejected;

	constructor(
		promiseHandler: (
			initialized: TInitialised,
			added: IAdded,
			removed: IRemoved,
			modified: IModified,
			failed: IFailed,
			rejected: IRejected,
		) => void,
	) {
		this.onInitialized = this.onInitialized.bind(this);
		this.onAdded = this.onAdded.bind(this);
		this.onRemoved = this.onRemoved.bind(this);
		this.onModified = this.onModified.bind(this);
		this.onFailed = this.onFailed.bind(this);
		this.onRejected = this.onRejected.bind(this);
		promiseHandler(
			this.onInitialized,
			this.onAdded,
			this.onRemoved,
			this.onModified,
			this.onFailed,
			this.onRejected,
		);
	}

	public initialized(
		handleInitialized: TInitialised,
	): FirestoreQueryObserverEvents {
		this.handleInitialized = handleInitialized;
		return this;
	}

	public added(handleAdded: IAdded): FirestoreQueryObserverEvents {
		this.handleAdded = handleAdded;
		return this;
	}

	public removed(handleRemoved: IRemoved): FirestoreQueryObserverEvents {
		this.handleRemoved = handleRemoved;
		return this;
	}

	public modified(handleModified: IModified): FirestoreQueryObserverEvents {
		this.handleModified = handleModified;
		return this;
	}

	public failed(handleFailed: IFailed): FirestoreQueryObserverEvents {
		this.handleFailed = handleFailed;
		return this;
	}

	public rejected(handleRejected: IRejected): FirestoreQueryObserverEvents {
		this.handleRejected = handleRejected;
		return this;
	}

	protected onInitialized(entityDTOs: { [entityId: string]: object }): void {
		if (this.handleInitialized) {
			this.handleInitialized(entityDTOs);
		}
	}

	protected onAdded(entityDTOs: { [entityId: string]: object }): void {
		if (this.handleAdded) {
			this.handleAdded(entityDTOs);
		}
	}

	protected onRemoved(entityDTOs: { [entityId: string]: object }): void {
		if (this.handleRemoved) {
			this.handleRemoved(entityDTOs);
		}
	}

	protected onModified(entityDTOs: { [entityId: string]: object }): void {
		if (this.handleModified) {
			this.handleModified(entityDTOs);
		}
	}

	protected onFailed(error: Error): void {
		if (this.handleFailed) {
			this.handleFailed(error);
		}
	}

	protected onRejected(error: Error): void {
		if (this.handleRejected) {
			this.handleRejected(error);
		}
	}
}

export class FirestoreQueryProvider {
	protected unsubscribe?: () => void;
	queryReference: FirebaseFirestoreTypes.Query;

	constructor(queryReference: FirebaseFirestoreTypes.Query) {
		this.queryReference = queryReference;
	}

	public unsubscribeObserver() {
		if (this.unsubscribe) {
			this.unsubscribe();
		}
	}

	public subscribeObserver(): FirestoreQueryObserverEvents {
		this.unsubscribeObserver();
		return new FirestoreQueryObserverEvents(
			(initialized, added, removed, modified, failed, reject) => {
				let initialize = true;
				this.unsubscribe = this.queryReference.onSnapshot(
					querySnapshot => {
						let count = querySnapshot.docChanges().length;
						const docsInitialized = {};
						const docsAdded = {};
						const docsModified = {};
						const docsRemoved = {};
						if (count > 0) {
							querySnapshot.docChanges().forEach(change => {
								count--;
								if (initialize) {
									if (change.type === 'added') {
										docsInitialized[
											change.doc.id
										] = change.doc.data();
									}
									if (count == 0) {
										initialize = false;
										initialized(docsInitialized);
									}
								} else {
									if (change.type === 'added') {
										docsAdded[
											change.doc.id
										] = change.doc.data();
									}
									if (change.type === 'modified') {
										docsModified[
											change.doc.id
										] = change.doc.data();
									}
									if (change.type === 'removed') {
										docsRemoved[
											change.doc.id
										] = change.doc.data();
									}
									if (count == 0) {
										if (
											HelpersUtil.hasProperties(docsAdded)
										) {
											added(docsAdded);
										}
										if (
											HelpersUtil.hasProperties(
												docsModified,
											)
										) {
											modified(docsModified);
										}
										if (
											HelpersUtil.hasProperties(
												docsRemoved,
											)
										) {
											removed(docsRemoved);
										}
									}
								}
							});
						} else {
							if (initialize) {
								initialize = false;
								initialized({});
							}
						}
					},
					error => {
						if (initialize) {
							failed(error);
							initialize = false;
						} else {
							reject(error);
						}
						this.unsubscribeObserver();
					},
				);
			},
		);
	}
	get(): Promise<object> {
		return new Promise(async (resolve, reject) => {
			try {
				const docs = await this.queryReference.get();
				const entities = {};
				docs.forEach(doc => {
					entities[doc.id] = doc.data();
				});
				resolve(entities);
			} catch (error) {
				reject(error);
			}
		});
	}
}
