import React from 'react';
import {
	Animated,
	Platform,
	TouchableHighlight,
	TouchableHighlightProps,
	TouchableOpacity,
	TouchableOpacityProps,
	ViewStyle,
} from 'react-native';
import { COLOR_CODES } from '../enums/colors';
import {
	INativeProps,
	IWrapperProps,
	IWrapperStyles,
} from '../wrappers/wrapper-interfaces';
import { WrapperView } from '../wrappers/wrapper.view';

export type IButtonStyles = IWrapperStyles;
export interface IButtonProps extends IWrapperProps {
	onClick?: () => void;
	nativeTouchableOpacityProps?: TouchableOpacityProps;
	nativeTouchableHighlightProps?: TouchableHighlightProps;
	hidden?: boolean;
	animate?: boolean;
	styles?: IWrapperStyles & { touchable?: ViewStyle };
}

export const extractButtonProps = <T extends IButtonProps>(
	props: T,
): IButtonProps => {
	return {
		onClick: props.onClick,
		nativeTouchableOpacityProps: props.nativeTouchableOpacityProps,
		nativeTouchableHighlightProps: props.nativeTouchableHighlightProps,
		hidden: props.hidden,
		animate: props.animate,
		styles: props.styles,
	};
};

export const ButtonView = (props: IButtonProps & INativeProps) => {
	const statics = React.useRef({
		fadeAnim: new Animated.Value(props.hidden ? 0 : props.animate ? 0 : 1),
		prevHidden: props.hidden ? true : !!props.animate,
	}).current;

	React.useEffect(() => {
		const fadeIn = () => {
			Animated.timing(statics.fadeAnim, {
				toValue: 1,
				duration: 200,
				useNativeDriver: false,
			}).start();
		};

		const fadeOut = () => {
			Animated.timing(statics.fadeAnim, {
				toValue: 0,
				duration: 200,
				useNativeDriver: false,
			}).start();
		};
		if (props.hidden != statics.prevHidden) {
			if (props.hidden) {
				fadeOut();
			} else {
				fadeIn();
			}
			statics.prevHidden = !!props.hidden;
		}
	}, [props.hidden, statics]);

	const {
		onClick,
		nativeTouchableOpacityProps,
		nativeTouchableHighlightProps,
		...wrapperProps
	} = props;

	const handleClick = () => {
		(!props.hidden && onClick && onClick()) || undefined;
	};

	if (Platform.OS === 'android') {
		return (
			<TouchableHighlight
				style={props.styles?.touchable}
				onPress={handleClick}
				underlayColor={COLOR_CODES.Transparent}
				{...nativeTouchableHighlightProps}
			>
				{
					<Animated.View style={{ opacity: statics.fadeAnim }}>
						<WrapperView {...wrapperProps} />
					</Animated.View>
				}
			</TouchableHighlight>
		);
	} else {
		return (
			<TouchableOpacity
				style={props.styles?.touchable}
				activeOpacity={0.7}
				onPress={handleClick}
				{...nativeTouchableOpacityProps}
			>
				{
					<Animated.View style={{ opacity: statics.fadeAnim }}>
						<WrapperView {...wrapperProps} />
					</Animated.View>
				}
			</TouchableOpacity>
		);
	}
};
