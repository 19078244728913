import React from 'react';
import { View } from 'react-native';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LinearGradient = (props: any) => {
	return (
		<View style={props.style}>
			<div
				style={{
					flex: 1,
					background: `linear-gradient(0deg, ${props.colors[1]} 0%,  ${props.colors[0]} 100%)`,
				}}
			/>
		</View>
	);
};

export default LinearGradient;
