import { firebase } from 'firebase-xp';
import { FirebaseDatabaseTypes } from '@react-native-firebase/database';

interface IEntities {
	[entityId: string]: object;
}

type IInitialized = (entitiesDTO: IEntities) => void;
type IAdded = (entityId: string, entityDTO: object) => void;
type IRemoved = (entityId: string) => void;
type IModifed = (entityId: string, entityDTO: object) => void;
type IFailed = (error: Error) => void;
type IRejected = (error: Error) => void;

class DatabaseCollectionObserverEvents {
	protected handleInitialized?: IInitialized;
	protected handleAdded?: IAdded;
	protected handleRemoved?: IRemoved;
	protected handleModified?: IModifed;
	protected handleFailed?: IFailed;
	protected handleRejected?: IRejected;

	constructor(
		promiseHandler: (
			initialized: IInitialized,
			added: IAdded,
			removed: IRemoved,
			modified: IModifed,
			failed: IFailed,
			rejected: IRejected,
		) => void,
	) {
		this.onInitialized = this.onInitialized.bind(this);
		this.onAdded = this.onAdded.bind(this);
		this.onRemoved = this.onRemoved.bind(this);
		this.onModified = this.onModified.bind(this);
		this.onFailed = this.onFailed.bind(this);
		this.onRejected = this.onRejected.bind(this);
		promiseHandler(
			this.onInitialized,
			this.onAdded,
			this.onRemoved,
			this.onModified,
			this.onFailed,
			this.onRejected,
		);
	}

	public initialized(
		handleInitialized: IInitialized,
	): DatabaseCollectionObserverEvents {
		this.handleInitialized = handleInitialized;
		return this;
	}

	public added(handleAdded: IAdded): DatabaseCollectionObserverEvents {
		this.handleAdded = handleAdded;
		return this;
	}

	public removed(handleRemoved: IRemoved): DatabaseCollectionObserverEvents {
		this.handleRemoved = handleRemoved;
		return this;
	}

	public modified(
		handleModified: IModifed,
	): DatabaseCollectionObserverEvents {
		this.handleModified = handleModified;
		return this;
	}

	public failed(handleFailed: IFailed): DatabaseCollectionObserverEvents {
		this.handleFailed = handleFailed;
		return this;
	}

	public rejected(
		handleRejected: IRejected,
	): DatabaseCollectionObserverEvents {
		this.handleRejected = handleRejected;
		return this;
	}

	protected onInitialized(entitiesDTO: IEntities): void {
		if (this.handleInitialized) {
			this.handleInitialized(entitiesDTO);
		}
	}

	protected onAdded(entityId: string, entityDTO: object): void {
		if (this.handleAdded) {
			this.handleAdded(entityId, entityDTO);
		}
	}

	protected onRemoved(entityId: string): void {
		if (this.handleRemoved) {
			this.handleRemoved(entityId);
		}
	}

	protected onModified(entityId: string, entityDTO: object): void {
		if (this.handleModified) {
			this.handleModified(entityId, entityDTO);
		}
	}

	protected onFailed(error: Error): void {
		if (this.handleFailed) {
			this.handleFailed(error);
		}
	}

	protected onRejected(error: Error): void {
		if (this.handleRejected) {
			this.handleRejected(error);
		}
	}
}

export class DatabaseCollectionProvider {
	protected collectionReference: FirebaseDatabaseTypes.Reference;
	protected path: string;

	constructor(path: string) {
		this.path = path;
		this.collectionReference = firebase.database().ref(path);
	}

	public unsubscribeObserver() {
		this.collectionReference.off();
	}

	public subscribeObserver() {
		this.unsubscribeObserver();
		return new DatabaseCollectionObserverEvents(
			async (initialized, added, removed, modified, failed, rejected) => {
				try {
					const onceSnapshot = await this.collectionReference.once(
						'value',
					);
					let count = onceSnapshot.numChildren();
					const result: IEntities = {};
					if (count === 0) {
						initialized(result);
					}
					this.collectionReference.on(
						'child_added',
						snapshot => {
							count--;
							if (snapshot && snapshot.key) {
								result[snapshot.key] = snapshot.val();
							}
							if (count === 0) {
								initialized(result);
							} else if (count < 0) {
								if (snapshot && snapshot.key) {
									added(snapshot.key, snapshot.val());
								}
							}
						},
						error => {
							count--;
							if (count >= 0) {
								count = 0;
								failed(error);
							} else {
								rejected(error);
							}
							this.unsubscribeObserver();
						},
					);
					this.collectionReference.on(
						'child_removed',
						snapshot => {
							if (snapshot && snapshot.key) {
								removed(snapshot.key);
							}
						},
						error => {
							rejected(error);
							this.unsubscribeObserver();
						},
					);
					this.collectionReference.on(
						'child_changed',
						snapshot => {
							if (snapshot && snapshot.key) {
								modified(snapshot.key, snapshot.val());
							}
						},
						error => {
							rejected(error);
							this.unsubscribeObserver();
						},
					);
				} catch (error) {
					failed(error);
					this.unsubscribeObserver();
				}
			},
		);
	}

	update(path: string, doc: object) {
		this.collectionReference.child(path).update(doc);
	}

	remove(path: string) {
		this.collectionReference.child(path).remove();
	}
}
