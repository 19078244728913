export default {
	header: {
		remainingChapters: {
			de: '{COUNT} Kapitel verbleibend',
			en: '{COUNT} Kapitel verbleibend',
		},
		more: {
			de: 'Mehr',
			en: 'Mehr',
		},
		done: {
			de: 'Alle Kapitel abgeschlossen',
			en: 'Alle Kapitel abgeschlossen',
		},
	},
	bigCard: {
		learnMore: {
			de: 'Mehr erfahren',
			en: 'Mehr erfahren',
		},
		topicCount: {
			de: '{COUNT_TOPICS} THEMEN',
			en: '{COUNT_TOPICS} THEMEN',
		},
		countChapters: {
			de: '{COUNT_CHAPTERS} KAPITEL',
			en: '{COUNT_CHAPTERS} KAPITEL',
		},
		countQuestions: {
			de: '{COUNT_QUESTIONS} FRAGEN',
			en: '{COUNT_QUESTIONS} FRAGEN',
		},
	},
	smallCard: {
		countChapters: {
			de: '{COUNT_CHAPTERS} KAPITEL',
			en: '{COUNT_CHAPTERS} KAPITEL',
		},
		more: {
			de: 'Mehr',
			en: 'Mehr',
		},
	},
};
