import XDate from 'xdate';

import { ConvertersUtil } from '@nui/utils';

import {
	FirestoreUserTeamInvitationModel,
	IFirestoreUserTeamInvitation,
} from './firestore-user-team-invitation.model';

export interface IFirestoreUserTeamInvitations {
	[teamId: string]: IFirestoreUserTeamInvitation;
}

export class FirestoreUserTeamInvitationsModel {
	static toUserTeamInvitations(
		userTeamInvitationsDTO: any,
		defaultXDate = new XDate(),
	): IFirestoreUserTeamInvitations {
		return (
			ConvertersUtil.toHashtable<IFirestoreUserTeamInvitation>(
				userTeamInvitationsDTO,
				{},
				0,
				userTeamInvitationDTO =>
					FirestoreUserTeamInvitationModel.toUserTeamInvitation(
						userTeamInvitationDTO,
						defaultXDate,
					),
			) || {}
		);
	}
}
