import { DatabaseCollectionProvider } from '@nui/providers';
import { stateModel } from '../../../globals';
import { FirebaseCourseModel } from '@nui/models';

export class CoursesObserver {
	protected observer: DatabaseCollectionProvider;
	constructor() {
		this.observer = new DatabaseCollectionProvider('/care101s');
	}

	public unsubscribeCoursesObserver() {
		stateModel.stateCoursesModel.removeCourses();
		if (this.observer) {
			this.observer.unsubscribeObserver();
		}
	}

	public subscribeCoursesObserver(onChange: () => void): Promise<void> {
		return new Promise((resolve, reject) => {
			this.observer
				.subscribeObserver()
				.initialized(coursesDTO => {
					for (const courseId in coursesDTO) {
						const course = FirebaseCourseModel.toCourse(
							coursesDTO[courseId],
						);
						if (course) {
							stateModel.stateCoursesModel.addCourse(
								courseId,
								course,
							);
						} else {
							stateModel.stateCoursesModel.removeCourses(
								courseId,
							);
						}
					}
					resolve();
				})
				.added((courseId, courseDTO) => {
					const course = FirebaseCourseModel.toCourse(courseDTO);
					if (course) {
						stateModel.stateCoursesModel.addCourse(
							courseId,
							course,
						);
					} else {
						stateModel.stateCoursesModel.removeCourses(courseId);
					}
					onChange && onChange();
				})
				.modified((courseId, courseDTO) => {
					const course = FirebaseCourseModel.toCourse(courseDTO);
					if (course) {
						stateModel.stateCoursesModel.addCourse(
							courseId,
							course,
						);
					} else {
						stateModel.stateCoursesModel.removeCourses(courseId);
					}
					onChange && onChange();
				})
				.removed(courseId => {
					stateModel.stateCoursesModel.removeCourses(courseId);
					onChange && onChange();
				})
				.failed(error => {
					reject(error);
				})
				.rejected(() => {});
		});
	}
}
