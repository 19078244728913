import { useForceUpdate } from '@nui/hooks';
import {
	ASSISTANT_HISTORY_SKILL,
	ASSISTANT_HISTORY_TYPE,
	FirestoreUserModel,
	isFirestoreAssistantSelect,
} from '@nui/models';
import {
	EventTypes,
	trackOrigin,
	useTrackEvent,
	withEventTracking,
} from '@nui/providers';
import { firebase } from 'firebase-xp';
import { stateModel } from 'globals';
import React from 'react';
import { View } from 'react-native';
import { useHistory } from 'react-router-dom';

import { LayoutView } from '../../../views/layout.view';
import { AssistantFooterMemorizedController } from '../component/assistant.footer.controller';
import { AssistantHistoryMemorizedController } from '../component/assistant.history.controller';

interface IAssistantProps {
	forceSkill?: ASSISTANT_HISTORY_SKILL;
}

const AssistantController = (props: IAssistantProps) => {
	const statics = React.useRef<{
		flushing: boolean;
		timer?: NodeJS.Timeout;
		initialized: boolean;
	}>({ flushing: false, initialized: false }).current;
	const [sortedHistory, setSortedHistory] = React.useState(
		stateModel.stateAssistantModel.getSortedHistory(),
	);
	const [placeholder, setPlaceholder] = React.useState(
		stateModel.stateUserModel.getUser()?.activeSkill?.staticInfo
			?.placeholder ?? 'Nachricht an Nui',
	);

	const userId = stateModel.stateAuthModel.getAuth()?.uid;

	const history = useHistory();
	const trackEvent = useTrackEvent();

	const forceUpdate = useForceUpdate();

	React.useEffect(() => {
		if (userId) {
			stateModel.stateAssistantModel.dequeueAll();

			if (stateModel.stateAssistantModel.getSortedHistoryLength() == 0) {
				const startSkill = (skill: ASSISTANT_HISTORY_SKILL) => {
					statics.timer = setTimeout(() => {
						FirestoreUserModel.fetchAssistant({
							skill,
						}).catch(error => console.log(error));
					}, 2000);
				};
				if (props.forceSkill) {
					startSkill(props.forceSkill);
				} else {
					startSkill(ASSISTANT_HISTORY_SKILL.welcome);
				}
			}

			stateModel.stateAssistantModel.confirmLatestStateVersion(
				'AssistantController',
			);

			trackEvent(EventTypes.CHB, 'chatbot-rendered');
			trackOrigin(userId);
			if (
				props.forceSkill === ASSISTANT_HISTORY_SKILL.webdemo &&
				document.cookie
			) {
				trackEvent(
					EventTypes.DEMO,
					'load-with-query',
					{
						inputValue: document.cookie,
					},
					{ userId },
				);
			} else if (
				props.forceSkill === ASSISTANT_HISTORY_SKILL.conciergeDemo &&
				document.cookie
			) {
				trackEvent(
					EventTypes.CONDEMO,
					'load-with-query',
					{
						inputValue: document.cookie,
					},
					{ userId },
				);
			}

			return clearTimer;
		} else if (props.forceSkill) {
			firebase.auth().signInAnonymously();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId]);

	React.useEffect(() => {
		if (statics.initialized) {
			if (
				sortedHistory.length <
					stateModel.stateAssistantModel.getSortedHistoryLength() &&
				!statics.flushing
			) {
				console.log('MORE HISTORY ITEMS');
				statics.flushing = true;
				const dequeuedHistory = stateModel.stateAssistantModel.dequeue();
				if (dequeuedHistory) {
					setSortedHistory(dequeuedHistory);
				}
			} else if (
				sortedHistory.length ==
					stateModel.stateAssistantModel.getSortedHistoryLength() &&
				!statics.flushing
			) {
				console.log('SAME HISTORY ITEMS COUNT');
				// We will only flush when the last element type changes and the new component will mount.
				const dequeuedHistory = stateModel.stateAssistantModel.dequeueAll();
				if (
					sortedHistory.length > 0 &&
					dequeuedHistory[dequeuedHistory.length - 1].history.type !=
						sortedHistory[sortedHistory.length - 1].history.type
				) {
					statics.flushing = true;
				}

				setSortedHistory(dequeuedHistory);
			} else if (
				sortedHistory.length >
				stateModel.stateAssistantModel.getSortedHistoryLength()
			) {
				console.log('LESS HISTORY ITEMS COUNT');
				statics.flushing = false;
				clearTimer();
				setSortedHistory(stateModel.stateAssistantModel.dequeueAll());
			}
		} else {
			statics.initialized = true;
		}
		setPlaceholder(
			stateModel.state.user?.activeSkill?.staticInfo?.placeholder ??
				'Nachricht an Nui',
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stateModel.stateAssistantModel.getVersion()]);

	const clearTimer = () => {
		statics.timer && clearTimeout(statics.timer);
		statics.timer = undefined;
	};

	const handleNavigation = (link: string) => {
		if (link.startsWith('https://')) {
			window.location.href = link;
		} else {
			// its a relative link
			history.push(link);
		}
	};

	const handleFinished = () => {
		console.log('FINISHED');
		const dequeuedHistory = stateModel.stateAssistantModel.dequeue();
		if (dequeuedHistory) {
			clearTimer();
			statics.timer = setTimeout(() => {
				setSortedHistory(dequeuedHistory);
			}, 1000);
		} else {
			statics.flushing = false;
		}
	};

	const handleClick = async (
		historyIndex: number,
		optionIndex: number,
	): Promise<void> => {
		const historyId = sortedHistory[historyIndex].historyId;
		const history = sortedHistory[historyIndex].history;
		if (isFirestoreAssistantSelect(history)) {
			FirestoreUserModel.fetchAssistant(
				history.options[optionIndex].replyData,
			);
			stateModel.stateAssistantModel.select(historyId, optionIndex);
			stateModel.stateAssistantModel.initializeState();
			forceUpdate();
		} else if (history.type == ASSISTANT_HISTORY_TYPE.consent) {
			stateModel.stateAssistantModel.select(historyId, 0);
			stateModel.stateAssistantModel.initializeState();
			forceUpdate();

			FirestoreUserModel.fetchAssistant(history.replyData);
		}
	};

	const groupedHistory = stateModel.stateAssistantModel.getGroupedHistory(
		sortedHistory,
	);

	return (
		<LayoutView isSignedIn={true}>
			<View
				style={{
					marginBottom: 0,
					marginTop: 'auto',
				}}
			>
				<AssistantHistoryMemorizedController
					groupedHistory={groupedHistory}
					handleClick={handleClick}
					handleFinished={handleFinished}
					handleNavigation={handleNavigation}
				/>
			</View>

			<View
				style={
					{
						// 'sticky' value only needs to be valid for react-native-web
						// cast to any because it is not a valid react-native stylesheet
						position: 'sticky',
						bottom: 0,
					} as any
				}
			>
				<AssistantFooterMemorizedController
					groupedHistory={groupedHistory}
					handleClick={handleClick}
					forceSkill={props.forceSkill}
					handleFinished={handleFinished}
					handleForceUpdate={forceUpdate}
					placeholder={placeholder}
				/>
			</View>
		</LayoutView>
	);
};

export const AssistantMemorizedController = React.memo(
	withEventTracking(AssistantController),
	() => {
		if (
			stateModel.stateAssistantModel.hasNewerStateVersion(
				'AssistantController',
			)
		) {
			stateModel.stateAssistantModel.confirmLatestStateVersion(
				'AssistantController',
			);
			return false;
		} else {
			return true;
		}
	},
);
