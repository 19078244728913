import React from 'react';
import styled from 'styled-components/native';

import { INativeProps } from '../wrappers/wrapper-interfaces';
import { extractAllTextProps, ITextProps } from './text-interfaces';
import { TextView } from './text.view';

export enum ICON_SIZES {
	xs = '8',
	ss = '10',
	sm = '14',
	md = '16',
	ml = '20',
	lg = '22',
	xl = '30',
}

export enum ICON_CODES {
	ArrowUp = '\ue903',
	Plus = '\ue90a',
	Topic = '\ue91b',
	More = '\ue911',
	Monthly = '\ue91d',
	Close = '\ue90c',
	ArrowLeft = '\ue91c',
	ArrowRight = '\ue91f',
	Next = '\ue909',
	Send = '\ue920',
	Edit = '\ue90f',
	Checkmark = '\ue902',
	ArrowNext = '\ue904',
	Settings = '\ue91a',
	Privacy = '\ue915',
	Logout = '\ue913',
	Support = '\ue916',
	AGB = '\ue914',
	Back = '\ue908',
	Camera = '\ue923',
	CheckFilled = '\ue922',
	Gallery = '\ue921',
	Nui = 'Nui',
	Dashboard = 'Dashboard',
	Call = '\ue924',
	Message = '\ue917',
	PrivateMessage = '\ue925',
	CloseLarge = '\ue91e',
	Locked = '\ue92a',
	IconGo = '\ue930',
	IconBack = '\ue931',
	IconDone = '\ue92c',
	Restart = '\ue932',
	Assistant = '\ue936',
	Counselor = '\ue934',
	Community = '\ue938',
	TabSettings = '\ue926',
	TabTopics = '\ue935',
	TabDashboard = '\ue939',
	TabAssistant = '\ue93b',
	TabCounselor = '\ue933',
}

export interface ITextIconProps extends ITextProps {
	size?: ICON_SIZES | number;
	icon?: ICON_CODES;
	fontScaling?: boolean;
}

export const TextIconView = styled((props: ITextIconProps & INativeProps) => {
	const textProps = extractAllTextProps(props);
	const { icon, fontScaling } = props;
	return (
		<TextView
			{...textProps}
			style={props.style}
			nativeTextProps={{
				allowFontScaling: fontScaling,
				...textProps.nativeTextProps,
			}}
		>
			{icon}
		</TextView>
	);
})`
	font-family: icomoon;
	font-size: ${(props: ITextIconProps) => props.size || ICON_SIZES.md};
	${(props: ITextIconProps) => ({
		...props.styles?.text,
	})};
`;

export const extractTextIconProps = <T extends ITextIconProps>(
	props: T,
	defaultProps?: ITextIconProps,
): ITextIconProps => {
	return {
		size: props.size || defaultProps?.size,
		icon: props.icon || defaultProps?.icon,
		fontScaling: props.fontScaling ?? defaultProps?.fontScaling,
	};
};

export const extractAllTextIconProps = <T extends ITextIconProps>(
	props: T,
	defaultProps?: ITextIconProps,
): ITextIconProps => {
	const allTextProps = extractAllTextProps(props);
	const textIconProps = extractTextIconProps(props, defaultProps);
	return {
		...allTextProps,
		...textIconProps,
		styles: {
			...allTextProps.styles,
			...textIconProps.styles,
		},
	};
};
