import React from 'react';

export const useOnce = (cb: () => void) => {
	const componentIsMounted = React.useRef(true);

	React.useEffect(() => {
		if (componentIsMounted.current) {
			cb();
			componentIsMounted.current = false;
		}
	}, [cb]);
};
