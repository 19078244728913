import * as React from 'react';
import styled from 'styled-components/native';

import { COLOR_CODES } from '../enums/colors';
import { INativeProps, IWrapperProps } from '../wrappers/wrapper-interfaces';
import { WrapperView } from '../wrappers/wrapper.view';

const getBadgePadding = badgeRadius => {
	if (badgeRadius >= 6) {
		return 3;
	} else {
		return 2;
	}
};

export interface IBadgeProps extends IWrapperProps {
	badgeRadius: number;
	outline?: boolean;
}

export const BadgePlain = styled((props: IBadgeProps & INativeProps) => {
	return <WrapperView {...props} />;
})`
	width: ${(props: IBadgeProps) => props.badgeRadius * 2};
	height: ${(props: IBadgeProps) => props.badgeRadius * 2};
	border-radius: ${(props: IBadgeProps) => props.badgeRadius};
	background-color: ${COLOR_CODES.Pink};
	${(props: IBadgeProps) => ({
		...props.styles?.wrapper,
	})};
`;
// TODO: explain wrapper props extension

export const BadgeView = (props: IBadgeProps) => {
	return props.outline ? (
		<WrapperView
			styles={{
				wrapper: {
					padding: getBadgePadding(props.badgeRadius),
					backgroundColor: COLOR_CODES.White,
					borderRadius:
						props.badgeRadius + getBadgePadding(props.badgeRadius),
				},
			}}
		>
			<BadgePlain badgeRadius={props.badgeRadius} />
		</WrapperView>
	) : (
		<BadgePlain badgeRadius={props.badgeRadius} />
	);
};
