import { StateModel } from './state.model';
import { IFirestoreTeam } from '../firestore/team/firestore-team.model';
import { IFirestoreTeamSenior } from '../firestore/team/firestore-team-senior.model';
import { IState } from './state-interfaces';

export class StateTeamsModel {
	protected state: IState;
	protected stateModel: StateModel;
	protected stateHasChanged: boolean;

	constructor(state: IState, stateModel: StateModel) {
		this.state = state;
		this.stateModel = stateModel;
		this.stateHasChanged = true;
	}

	initializeState() {
		if (this.stateHasChanged) {
			this.stateHasChanged = false;
		}
	}

	addTeam(teamId: string, team: IFirestoreTeam) {
		this.stateHasChanged = true;
		this.state.teams[teamId] = team;
	}

	getTeam(teamId?: string): IFirestoreTeam | undefined {
		const tId = teamId || this.state.teamId;
		return (tId && this.state.teams[tId]) || undefined;
	}

	getSenior(teamId?: string): IFirestoreTeamSenior | undefined {
		const tId = teamId || this.state.teamId;
		return (
			(tId && this.state.teams[tId] && this.state.teams[tId].senior) ||
			undefined
		);
	}

	removeTeams(teamId?: string) {
		this.stateHasChanged = true;
		if (teamId) {
			delete this.state.teams[teamId];
		} else {
			this.state.teams = {};
		}
	}
}
