import { FirestoreCollectionProvider } from '@nui/providers';
import { firebase } from 'firebase-xp';

import { stateModel } from '../../../globals';
import { FirestoreAssistantModel } from '../models/firestore/assistant/firestore-assistant.model';

export class AssistantObserver {
	protected collectionProvider: FirestoreCollectionProvider;
	protected authId: string;

	constructor(authId: string) {
		this.authId = authId;
		this.collectionProvider = new FirestoreCollectionProvider(
			firebase
				.firestore()
				.collection('user-profiles')
				.doc(authId)
				.collection('assistant-history'),
		);
	}

	public unsubscribeAssistantObserver() {
		stateModel.stateAssistantModel.removeHistory();
		if (this.collectionProvider) {
			this.collectionProvider.unsubscribeObserver();
		}
	}

	public subscribeAssistantObserver(onChange: () => void): Promise<void> {
		return new Promise((resolve, reject) => {
			this.collectionProvider
				.subscribeObserver()
				.initialized(historyDTO => {
					for (const historyId in historyDTO) {
						const history = FirestoreAssistantModel.toHistory(
							historyDTO[historyId],
						);
						if (history) {
							stateModel.stateAssistantModel.addHistory(
								historyId,
								history,
							);
						} else {
							stateModel.stateAssistantModel.removeHistory(
								historyId,
							);
						}
					}

					resolve();
				})
				.modified(historyDTO => {
					console.log('assistant modified', historyDTO);
					for (const historyId in historyDTO) {
						const history = FirestoreAssistantModel.toHistory(
							historyDTO[historyId],
						);
						if (history) {
							stateModel.stateAssistantModel.addHistory(
								historyId,
								history,
							);
						} else {
							stateModel.stateAssistantModel.removeHistory(
								historyId,
							);
						}
					}
					onChange && onChange();
				})
				.added(historyDTO => {
					console.log('assistant added', historyDTO);
					for (const historyId in historyDTO) {
						const history = FirestoreAssistantModel.toHistory(
							historyDTO[historyId],
						);
						if (history) {
							stateModel.stateAssistantModel.addHistory(
								historyId,
								history,
							);
						} else {
							stateModel.stateAssistantModel.removeHistory(
								historyId,
							);
						}
					}
					onChange && onChange();
				})
				.removed(historyDTO => {
					console.log('assistant removed', historyDTO);
					for (const historyId in historyDTO) {
						stateModel.stateAssistantModel.removeHistory(historyId);
					}
					onChange && onChange();
				})
				.failed(error => {
					reject(error);
				})
				.rejected(() => {});
		});
	}
}
