import { IContent, ConvertersUtil } from '@nui/utils';
import {
	FirebaseCourseTopicChapterModel,
	IFirebaseCourseTopicChapter,
} from './firebase-course-topic-chapter.model';

export interface IFirebaseCourseTopic {
	chapters: {
		[name: string]: IFirebaseCourseTopicChapter;
	};
	intro: IContent;
	pos: number;
	title: IContent;
	chapterCount: number;
	id: string;
}

export class FirebaseCourseTopicModel {
	static toTopic(topicDTO: any): IFirebaseCourseTopic | undefined {
		const intro = ConvertersUtil.toContent(topicDTO.intro);
		const pos = ConvertersUtil.toNumber(topicDTO.pos);
		const title = ConvertersUtil.toContent(topicDTO.title);
		const chapterCount = ConvertersUtil.toNumber(topicDTO.chapterCount);

		const chapters = ConvertersUtil.toHashtable<IFirebaseCourseTopicChapter>(
			topicDTO.chapters,
			{},
			0,
			chapterDTO => FirebaseCourseTopicChapterModel.toChapter(chapterDTO),
		);
		const id = ConvertersUtil.toString(topicDTO.id) || 'XXX';

		if (
			intro != undefined &&
			pos != undefined &&
			title != undefined &&
			chapters != undefined &&
			chapterCount != undefined
		) {
			return {
				chapters,
				intro,
				pos,
				title,
				chapterCount,
				id,
			};
		} else {
			console.log('Topic entity is undefined', {
				topicDTO,
				intro,
				pos,
				title,
				chapters,
				chapterCount,
				id,
			});
			return undefined;
		}
	}
}
