import { useOnce, useWebViewportWidth } from '@nui/hooks';
import { FirestoreUserModel } from '@nui/models';
import { EventTypes, useTrackEvent } from '@nui/providers';
import { Language } from '@nui/utils';
import { CourseChaptersHeaderView, CourseChaptersSlideView } from '@nui/views';
import React from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { stateModel } from '../../../../globals';
import { LayoutView } from '../../../views/layout.view';
import { ChaptersChapterController } from '../components/care101-chapters-chapter.controller';
import { ChaptersPaginationController } from '../components/care101-chapters-pagination.controller';
import { Care101ChaptersScrollviewController } from '../components/care101-chapters-scrollview.controller';

import content from '../assets/content';

interface IRedirectTo {
	pathname: string;
	state?: {
		redirect: string;
	};
}

interface ICare101ChaptersProps {
	courseId: string;
	topicId: string;
	chapterId?: string;
}

interface ICare101ChaptersRequest {
	redirect: string;
}

export const Care101ChaptersController = () => {
	// Statics
	const statics = React.useRef<{
		chapterScrollview?: HTMLDivElement | null;
		scrollviewHandlers?: {
			scrollToLastCard: () => void;
			scrollToFirstCard: () => void;
			scrollToCard: (cardIndex: number) => void;
		};
	}>({}).current;

	// Navigation
	let { courseId, topicId, chapterId } = useParams<ICare101ChaptersProps>();
	let location = useLocation<ICare101ChaptersRequest | undefined>();
	const history = useHistory();

	const trackEvent = useTrackEvent();

	// Values
	const topic = stateModel.stateCoursesModel.getTopic(courseId, topicId);
	const sortedChapters = stateModel.stateCoursesModel.getSortedChapters(
		courseId,
		topicId,
	);
	const chardCounts = {};
	const cardIndex = {};
	for (let i = 0; i < sortedChapters.length; i++) {
		chardCounts[i] = 1;
		cardIndex[i] = 0;
	}
	const userId = stateModel.stateAuthModel.getAuth()?.uid;

	// Statemanagement
	const progress = stateModel.stateCoursesModel.getProgress(
		courseId,
		topicId,
	);

	const [chapterIndex, setChapterIndex] = React.useState(
		chapterId
			? sortedChapters.find(element => element.chapterId == chapterId)
					?.chapter.pos ?? 0
			: progress.index + 1,
	);
	const [redirect, setRedirect] = React.useState<IRedirectTo | undefined>(
		undefined,
	);

	// Listen for viewport changes
	const viewportWidth = useWebViewportWidth('#layout-center-view');

	// DidMount
	useOnce(() => {
		if (userId) {
			FirestoreUserModel.saveProgress(userId, courseId, topicId);
		}
	});

	// Methods
	const saveProgress = (newChapterIndex: number) => {
		const chapter = sortedChapters[newChapterIndex - 1];
		if (chapter) {
			trackEvent(
				EventTypes.OC,
				'viewed',
				{
					inputIds: [chapter.chapterId],
				},
				{ userId },
			);
			stateModel.stateUserModel.saveProgress(
				courseId,
				topicId,
				chapter.chapterId,
			);
		} else {
			stateModel.stateUserModel.saveProgress(courseId, topicId);
		}
		setChapterIndex(newChapterIndex);
	};

	const handleChapterScrollChange = (newChapterIndex: number) => {
		saveProgress(newChapterIndex);
	};

	const handleLeftClick = () => {
		if (location.state && location.state.redirect) {
			setRedirect({ pathname: location.state.redirect });
		} else {
			console.error('MISSING Redirect');
		}
	};

	const handleGoBackClick = () => {
		history.goBack();
	};

	const handleChapterClickChange = (newChapterIndex: number) => {
		const hasTopicFinished = newChapterIndex == sortedChapters.length;
		if (hasTopicFinished) {
			saveProgress(newChapterIndex);

			setRedirect({ pathname: `/care101s/${courseId}` });
		} else {
			statics.scrollviewHandlers?.scrollToCard(newChapterIndex);
		}
	};

	// Render
	if (viewportWidth) {
		if (redirect) {
			return <Redirect push to={redirect} />;
		} else {
			return (
				<LayoutView isSignedIn={true}>
					<CourseChaptersHeaderView
						statusbar={{
							chardCounts,
							chapterIndex,
							cardIndex,
						}}
						content={{
							statusbar: {
								labelOf: Language.translate(
									content.chapters.statusbar.labelOf,
								),
								labelChapters: Language.translate(
									sortedChapters.length > 1
										? content.chapters.statusbar
												.labelChaptersPL
										: content.chapters.statusbar
												.labelChaptersSG,
								),
							},
						}}
						title={Language.translate(topic?.title)}
						onLeftClick={handleLeftClick}
						screenWidth={viewportWidth}
					/>
					<Care101ChaptersScrollviewController
						handlers={handlers =>
							(statics.scrollviewHandlers = handlers)
						}
						onChapterChange={handleChapterScrollChange}
						chapterCount={sortedChapters.length}
						viewportWidth={viewportWidth}
						chapterIndex={chapterIndex}
					>
						{sortedChapters.map((chapter, index) => (
							<CourseChaptersSlideView key={index}>
								<ChaptersChapterController
									courseId={courseId}
									topicId={topicId}
									chapterId={chapter.chapterId}
								/>
								<ChaptersPaginationController
									chapterIndex={index}
									chapterCount={sortedChapters.length}
									onChapterChange={handleChapterClickChange}
									onGoBack={handleGoBackClick}
								/>
							</CourseChaptersSlideView>
						))}
					</Care101ChaptersScrollviewController>
				</LayoutView>
			);
		}
	} else {
		return <LayoutView isSignedIn={true} />;
	}
};
