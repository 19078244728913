import React from 'react';
import styled from 'styled-components/native';
import { INativeProps } from '../wrappers/wrapper-interfaces';
import { ITextProps, FONT_WEIGHTS } from './text-interfaces';
import { TextView } from './text.view';

export const TextButtonView = styled((props: ITextProps & INativeProps) => (
	<TextView {...props} />
))`
	font-size: 17;
	letter-spacing: 0.5;
	font-weight: ${(props: ITextProps) =>
		props.weight || FONT_WEIGHTS.semiBold};
	${(props: ITextProps) => ({
		...props.styles?.text,
	})};
`;
