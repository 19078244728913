import {
	FirestoreUserModel,
	IFirestoreAssistantRequestCode,
	IFirestoreAssistantRequestPhone,
	IHistoryBlock,
	isFirestoreAssistantRequestCode,
	isFirestoreAssistantRequestPhone,
} from '@nui/models';
import { FirebaseWebAuthProvider } from '@nui/providers';
import {
	TextfieldNewPhoneSubmitView,
	TextfieldNewSubmitView,
} from '@nui/views';
import { firebase } from 'firebase-xp';
import { stateModel } from 'globals';
import React from 'react';
import { View } from 'react-native';

interface AssistantRegistrationProps {
	groupedHistory: IHistoryBlock[];
	handleFinished: () => void;
	onSucceeded: () => void;
	onFailed: () => void;
	handleForceUpdate: () => void;
	horizontalSpacing: number;
	verticalSpacing: number;
	textfieldLeftPadding: number;
}

export const AssistantRegistrationController = (
	props: AssistantRegistrationProps,
) => {
	const phone = React.useRef<string | undefined>();
	const code = React.useRef<string | undefined>();
	const recaptchaVerifier = React.useRef<
		firebase.default.auth.RecaptchaVerifier | undefined
	>();
	const confirmationResult = React.useRef<
		firebase.default.auth.ConfirmationResult | undefined
	>();

	React.useEffect(() => {
		recaptchaVerifier.current = FirebaseWebAuthProvider.initRecaptchaVerifier(
			'recaptchaContainer',
		);
	}, []);

	const handlePhone = async (
		historyId: string,
		history: IFirestoreAssistantRequestPhone,
		text: string,
	) => {
		phone.current = text;

		history.message = { de: text, en: text };
		stateModel.stateAssistantModel.addHistory(historyId, history);
		stateModel.stateAssistantModel.initializeState();
		props.handleForceUpdate();
		try {
			if (recaptchaVerifier.current) {
				const result = await FirebaseWebAuthProvider.loginWithPhoneNumber(
					text,
					recaptchaVerifier.current,
				);
				confirmationResult.current = result;
			}
		} catch (error) {
			confirmationResult.current = undefined;
			FirestoreUserModel.fetchAssistant({
				...history.replyData,
				error: error.code,
				phone: text,
			});
		} finally {
			FirestoreUserModel.fetchAssistant({
				...history.replyData,
				phone: text,
			});
		}
		props.handleFinished();
	};

	const handleCode = async (
		historyId: string,
		history: IFirestoreAssistantRequestCode,
		text: string,
	) => {
		code.current = text;

		history.message = { de: text, en: text };
		stateModel.stateAssistantModel.addHistory(historyId, history);
		stateModel.stateAssistantModel.initializeState();
		props.handleForceUpdate();

		try {
			if (confirmationResult.current) {
				const cred = firebase.auth.PhoneAuthProvider.credential(
					confirmationResult.current.verificationId,
					text,
				);
				await firebase.auth().currentUser?.linkWithCredential(cred);
				await firebase.auth().currentUser?.getIdToken(true);
				props.onSucceeded();
				FirestoreUserModel.fetchAssistant({
					...history.replyData,
					code: text,
				});
			} else {
				throw new Error('Missing confirmation result');
			}
		} catch (error) {
			props.onFailed();
			FirestoreUserModel.fetchAssistant({
				...history.replyData,
				error: error.code,
				code: text,
			});
		}
		props.handleFinished();
	};

	const blockLength = props.groupedHistory.length;
	if (blockLength > 0) {
		const itemLength = props.groupedHistory[blockLength - 1].items.length;
		if (itemLength > 0) {
			const lastHistoryItem =
				props.groupedHistory[blockLength - 1].items[itemLength - 1];
			const history = lastHistoryItem.history;
			const historyId = lastHistoryItem.historyId;
			return (
				<View nativeID="recaptchaContainer">
					{isFirestoreAssistantRequestPhone(history) &&
					!history.message ? (
						<TextfieldNewPhoneSubmitView
							horizontalSpacing={props.horizontalSpacing}
							verticalSpacing={props.verticalSpacing}
							textfieldLeftPadding={props.textfieldLeftPadding}
							nativeTextInputProps={{
								autoCorrect: false,
							}}
							onSubmit={phone => {
								return handlePhone(historyId, history, phone);
							}}
						/>
					) : isFirestoreAssistantRequestCode(history) &&
					  !history.message ? (
						<TextfieldNewSubmitView
							horizontalSpacing={props.horizontalSpacing}
							verticalSpacing={props.verticalSpacing}
							textfieldLeftPadding={props.textfieldLeftPadding}
							nativeTextInputProps={{
								placeholder: 'SMS Code',
								autoCorrect: false,
							}}
							onSubmit={phone => {
								return handleCode(historyId, history, phone);
							}}
						/>
					) : null}
				</View>
			);
		} else {
			return null;
		}
	} else {
		return null;
	}
};
