import { ContentType } from '@nui/models';
import { IParagraph, Language } from '@nui/utils';
import {
	BigCardWrapperView,
	IBigCardProps,
	IBigCardWrapperProps,
	ISmallCardProps,
	ISmallCardWrapperProps,
	SmallCardWrapperView,
	WrapperView,
} from '@nui/views';
import React, { useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { Redirect } from 'react-router-dom';

import { stateModel } from '../../../../globals';

import content from '../assets/content';

interface IHomescreenOfferinsControllerState {
	toCareStart: boolean;
	toCareTopic: boolean;
	toCourseChapters: boolean;
	topicId: string;
	option: string;
	courseId: string;
	contentType: ContentType;
	locked: boolean;
}

export const HomescreenOfferingsController = () => {
	const [
		redirect,
		setRedirect,
	] = useState<IHomescreenOfferinsControllerState>({
		toCareStart: false,
		toCareTopic: false,
		toCourseChapters: false,
		topicId: '',
		option: '',
		courseId: '',
		contentType: ContentType.info,
		locked: true,
	});
	const windowWidth = useWindowDimensions().width;
	const sortedCourses = stateModel.stateCoursesModel.getSortedCourses();

	const handleTopicClick = (courseId: string, topicId: string) => {
		const { percent } = stateModel.stateCoursesModel.getProgress(
			courseId,
			topicId,
		);
		if (percent == 0) {
			setRedirect({
				...redirect,
				topicId,
				courseId,
				toCareTopic: true,
			});
		} else {
			setRedirect({
				...redirect,
				courseId,
				topicId,
				toCourseChapters: true,
			});
		}
	};

	const handleMoreClick = (courseId: string) => {
		setRedirect({
			...redirect,
			courseId,
			toCareStart: true,
		});
	};

	if (redirect.toCareTopic) {
		return (
			<Redirect
				push
				to={{
					pathname: `/care101s/${redirect.courseId}/topics/${redirect.topicId}`,
					state: { redirect: `/` },
				}}
			/>
		);
	} else if (redirect.toCourseChapters) {
		return (
			<Redirect
				push
				to={{
					pathname: `/care101s/${redirect.courseId}/topics/${redirect.topicId}/chapters`,
					state: { redirect: `/` },
				}}
			/>
		);
	} else if (redirect.toCareStart) {
		return (
			<Redirect
				push
				to={{
					pathname: `/care101s/${redirect.courseId}`,
					state: { redirect: `/` },
				}}
			/>
		);
	} else {
		return (
			<WrapperView styles={{ wrapper: { maxWidth: windowWidth } }}>
				{sortedCourses.map((course, index) => {
					if (
						stateModel.stateUserModel.hasProgress(course.courseId)
					) {
						const primarySortedTopics = stateModel.stateCoursesModel.getSortedTopics(
							course.courseId,
						);
						const cards: ISmallCardProps[] = [];

						for (const i in primarySortedTopics) {
							const topicId = primarySortedTopics[i].topicId;
							const topic = stateModel.stateCoursesModel.getTopic(
								course.courseId,
								topicId,
							);
							if (topic) {
								const metaInfo: IParagraph[] = [];
								topic?.chapterCount &&
									metaInfo.push(
										...Language.translateFromBBCode(
											Language.replace(
												content.smallCard.countChapters,
												'{COUNT_CHAPTERS}',
												topic?.chapterCount.toString(),
											),
										),
									);
								const smallCardProps: ISmallCardProps = {
									image: stateModel.stateCoursesModel.getTopicImage(
										course.courseId,
										topicId,
									),
									headline: Language.translate(topic?.title),
									metaInfo,
									onCardClick: () =>
										handleTopicClick(
											course.courseId,
											topicId,
										),
								};
								cards.push(smallCardProps);
							}
						}

						const smallCardWrapperProps: ISmallCardWrapperProps = {
							title: Language.translate(
								Language.replace(
									course.course.caption,
									'{senior}',
									stateModel.stateTeamsModel.getSenior()
										?.firstName || '',
								),
							),
							moreLabel: Language.translate(
								content.smallCard.more,
							),
							onMoreClick: () => handleMoreClick(course.courseId),
							cards,
						};
						return (
							<SmallCardWrapperView
								key={index}
								{...smallCardWrapperProps}
							/>
						);
					} else {
						const metaInfo: IParagraph[] = [];
						metaInfo.push(
							...Language.translateFromBBCode(
								Language.replace(
									content.bigCard.topicCount,
									'{COUNT_TOPICS}',
									course.course.topicCount.toString(),
								),
							),
						);
						const bigCardProps: IBigCardProps = {
							image: stateModel.stateCoursesModel.getCourseImage(
								course.courseId,
							),
							headline: Language.translateFromBBCode(
								Language.replace(
									course.course.bigCardCaption,
									'{senior}',
									stateModel.stateTeamsModel.getSenior()
										?.firstName || '',
								),
							)[0],
							metaInfo,
							buttonLabel: Language.translate(
								content.bigCard.learnMore,
							),
							onCardClick: () => handleMoreClick(course.courseId),
						};

						const bigCardWrapperProps: IBigCardWrapperProps = {
							title: Language.translate(
								Language.replace(
									course.course.caption,
									'{senior}',
									stateModel.stateTeamsModel.getSenior()
										?.firstName || '',
								),
							),
							card: bigCardProps,
						};

						return (
							<BigCardWrapperView
								{...bigCardWrapperProps}
								key={index}
							/>
						);
					}
				})}
			</WrapperView>
		);
	}
};
