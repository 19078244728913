import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { COLOR_CODES } from '../enums/colors';
import { TextBodyLargeDefaultView } from '../texts/text-body-large-default.view';
import { TextButtonView } from '../texts/text-button.view';
import { INativeProps, IWrapperStyles } from '../wrappers/wrapper-interfaces';
import { ButtonView, IButtonProps } from './button.view';

export interface IButtonTertiaryStyles extends IWrapperStyles {
	container?: object;
	label?: object;
}

export interface IButtonTertiaryProps extends IButtonProps {
	styles?: IButtonTertiaryStyles;
	label?: string;
	// Defines the main color for the label and outline
	theme?: BUTTON_TERTIARY_THEMES;
	// Whether the button is filled or not. Defaults no false
	isFilled?: boolean;
}

export enum BUTTON_TERTIARY_THEMES {
	Pink = 'Pink',
	Blue = 'Blue',
	Grey = 'BrownGreyTwo',
}

export const ButtonTertiaryContainer = styled(
	(props: IButtonTertiaryProps & INativeProps) => (
		<View style={props.style}>{props.children}</View>
	),
)`
	height: 36;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	border-radius: 18;
	border-color: ${(props: IButtonTertiaryProps) =>
		getForegroundColor(props.isFilled, props.theme)}

	border-width: 1;
	padding-left: 15;
	padding-right: 15;
	background-color: ${(props: IButtonTertiaryProps) =>
		getBackgroundColor(props.isFilled, props.theme)}
	${props => ({
		...((props.styles && props.styles.container) || {}),
	})};
`;

const getBackgroundColor = (
	isFilled: boolean = false,
	theme: BUTTON_TERTIARY_THEMES = BUTTON_TERTIARY_THEMES.Pink,
) => {
	if (isFilled) {
		return (theme && COLOR_CODES[theme]) || COLOR_CODES.Pink;
	} else {
		return COLOR_CODES.White;
	}
};

const getForegroundColor = (
	isFilled: boolean = false,
	theme: BUTTON_TERTIARY_THEMES = BUTTON_TERTIARY_THEMES.Pink,
) => {
	return getBackgroundColor(!isFilled, theme);
};

export const ButtonTertiaryView = (props: IButtonTertiaryProps) => {
	const { label, theme, isFilled, styles, ...buttonProps } = props;
	return (
		<ButtonView {...buttonProps}>
			<ButtonTertiaryContainer {...{ theme, isFilled }}>
				{label &&
					(isFilled ? (
						<TextButtonView
							color={getForegroundColor(isFilled, theme)}
							styles={{
								text: (styles && styles.label) || {},
							}}
						>
							{label}
						</TextButtonView>
					) : (
						<TextBodyLargeDefaultView
							color={getForegroundColor(isFilled, theme)}
							styles={{
								text: (styles && styles.label) || {},
							}}
						>
							{label}
						</TextBodyLargeDefaultView>
					))}
			</ButtonTertiaryContainer>
		</ButtonView>
	);
};
