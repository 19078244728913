import { ConvertersUtil } from '@nui/utils';
import XDate from 'xdate';

import { DEFAULT_CONTENT } from '../../../utils/converters.util';
import { IContent } from '../../../utils/language.util';

export enum ASSISTANT_HISTORY_AVATAR {
	nui = 'nui',
	hidden = 'hidden',
	invisible = 'invisible',
	concierge = 'concierge',
}

export enum ASSISTANT_HISTORY_AVATAR_NAME {
	nui = 'Nui',
	concierge = 'Pflegeexpertin',
}

export enum ASSISTANT_HISTORY_TYPE {
	textMessage = 'textMessage',
	phoneInput = 'phoneInput',
	codeInput = 'codeInput',
	linkButtons = 'linkButtons',
	select = 'select',
	accordion = 'accordion',
	textInput = 'textInput',
	consent = 'consent',
}

export enum ACCORDION_BUTTON_TYPE {
	linkButton = 'linkButton',
}

export enum ASSISTANT_HISTORY_SKILL {
	welcome = 'welcome',
	webdemo = 'webdemo',
	conciergeDemo = 'conciergeDemo',
}
export interface IFirestoreAssistantTextMessage {
	type: ASSISTANT_HISTORY_TYPE.textMessage;
	created: XDate;
	message: IContent;
	authorId: string;
}

export interface IFirestoreAssistantRequestPhone {
	type: ASSISTANT_HISTORY_TYPE.phoneInput;
	created: XDate;
	message?: IContent;
	authorId: string;
	replyData?: any;
}

export interface IFirestoreAssistantRequestCode {
	type: ASSISTANT_HISTORY_TYPE.codeInput;
	created: XDate;
	message?: IContent;
	authorId: string;
	replyData?: any;
}

export interface IFirestoreAssistantTextInput {
	type: ASSISTANT_HISTORY_TYPE.textInput;
	created: XDate;
	authorId: string;
	value?: IContent;
	replyData: any;
	placeholder: IContent;
}

interface IFirestoreAssistantLinkButton {
	link: string;
	label: IContent;
}

export interface IFirestoreAssistantLinkButtons {
	type: ASSISTANT_HISTORY_TYPE.linkButtons;
	created: XDate;
	authorId: string;
	buttons: IFirestoreAssistantLinkButton[];
}

interface IFirestoreAssistantAccordionButton {
	type: ACCORDION_BUTTON_TYPE;
	label: IContent;
	link: string;
}
interface IFirestoreAssistantAccordionItem {
	title: IContent;
	icon: string;
	body: IContent;
	subtitle?: string;
	buttons: IFirestoreAssistantAccordionButton[];
	showOpened: boolean;
}

export interface IFirestoreAssistantAccordion {
	type: ASSISTANT_HISTORY_TYPE.accordion;
	created: XDate;
	items: IFirestoreAssistantAccordionItem[];
	authorId: string;
	remaining: number;
}

interface IFirestoreAssistantSelectOption {
	label: IContent;
	selected: boolean;
	replyData: any;
}

export interface IFirestoreAssistantSelect {
	type: ASSISTANT_HISTORY_TYPE.select;
	created: XDate;
	authorId: string;
	selected: boolean;
	options: IFirestoreAssistantSelectOption[];
}

export interface IFirestoreAssistantConsent {
	type: ASSISTANT_HISTORY_TYPE.consent;
	created: XDate;
	authorId: string;
	selected: boolean;
	replyData: any;
	label: IContent;
}

export type IFirestoreAssistant =
	| IFirestoreAssistantTextMessage
	| IFirestoreAssistantRequestPhone
	| IFirestoreAssistantRequestCode
	| IFirestoreAssistantLinkButtons
	| IFirestoreAssistantAccordion
	| IFirestoreAssistantSelect
	| IFirestoreAssistantTextInput
	| IFirestoreAssistantConsent;

export const isFirestoreAssistantRequestPhone = (
	item: any,
): item is IFirestoreAssistantRequestPhone => {
	return item.type === ASSISTANT_HISTORY_TYPE.phoneInput;
};

export const isFirestoreAssistantRequestCode = (
	item: any,
): item is IFirestoreAssistantRequestCode => {
	return item.type === ASSISTANT_HISTORY_TYPE.codeInput;
};

export const isFirestoreAssistantTextInput = (
	item: any,
): item is IFirestoreAssistantTextInput => {
	return item.type === ASSISTANT_HISTORY_TYPE.textInput;
};

export const isFirestoreAssistantTextMessage = (
	item: any,
): item is IFirestoreAssistantTextMessage => {
	return item.type === ASSISTANT_HISTORY_TYPE.textMessage;
};

export const isFirestoreAssistantLinkButtons = (
	item: any,
): item is IFirestoreAssistantLinkButtons => {
	return item.type === ASSISTANT_HISTORY_TYPE.linkButtons;
};

export const isFirestoreAssistantAccordion = (
	item: any,
): item is IFirestoreAssistantAccordion => {
	return item.type === ASSISTANT_HISTORY_TYPE.accordion;
};

export const isFirestoreAssistantSelect = (
	item: any,
): item is IFirestoreAssistantSelect => {
	return item.type === ASSISTANT_HISTORY_TYPE.select;
};

export class FirestoreAssistantModel {
	static toTextMessage(
		assistantHistory: IFirestoreAssistant | string,
		authorId?: string,
		created?: XDate,
	): IFirestoreAssistant | undefined {
		if (typeof assistantHistory == 'string') {
			return {
				type: ASSISTANT_HISTORY_TYPE.textMessage,
				created: created ?? new XDate(),
				authorId: authorId ?? ASSISTANT_HISTORY_AVATAR.nui,
				message:
					ConvertersUtil.toContent(assistantHistory) ??
					DEFAULT_CONTENT,
			};
		} else if (
			(isFirestoreAssistantRequestPhone(assistantHistory) ||
				isFirestoreAssistantRequestCode(assistantHistory)) &&
			assistantHistory.message
		) {
			return {
				type: ASSISTANT_HISTORY_TYPE.textMessage,
				created: created || assistantHistory.created,
				authorId: authorId || assistantHistory.authorId,
				message: assistantHistory.message,
			};
		} else if (
			isFirestoreAssistantTextInput(assistantHistory) &&
			assistantHistory.value
		) {
			return {
				type: ASSISTANT_HISTORY_TYPE.textMessage,
				created: created || assistantHistory.created,
				authorId: authorId || assistantHistory.authorId,
				message: assistantHistory.value,
			};
		} else if (
			isFirestoreAssistantSelect(assistantHistory) &&
			assistantHistory.selected
		) {
			for (const index in assistantHistory.options) {
				if (assistantHistory.options[index].selected) {
					return {
						type: ASSISTANT_HISTORY_TYPE.textMessage,
						created: created || assistantHistory.created,
						message: assistantHistory.options[index].label,
						authorId: authorId || assistantHistory.authorId,
					};
				}
			}
			return undefined;
		} else if (assistantHistory.type == ASSISTANT_HISTORY_TYPE.consent) {
			if (assistantHistory.selected) {
				return {
					type: ASSISTANT_HISTORY_TYPE.textMessage,
					created: assistantHistory.created,
					message: assistantHistory.label,
					authorId: authorId || assistantHistory.authorId,
				};
			}
		} else {
			return undefined;
		}
	}

	static toHistory(
		assistantHistoryDTO: any,
		defaultXDate = new XDate(),
	): IFirestoreAssistant | undefined {
		const created =
			ConvertersUtil.toXDate(
				assistantHistoryDTO?.created,
				defaultXDate,
			) || defaultXDate;

		const authorId =
			ConvertersUtil.toString(assistantHistoryDTO?.authorId) ||
			ConvertersUtil.toString(assistantHistoryDTO?.author) ||
			ASSISTANT_HISTORY_AVATAR.nui;

		if (
			assistantHistoryDTO.historyElementType ===
			ASSISTANT_HISTORY_TYPE.textMessage
		) {
			const message =
				ConvertersUtil.toContent(assistantHistoryDTO?.message) ||
				DEFAULT_CONTENT;
			if (message) {
				return {
					type: ASSISTANT_HISTORY_TYPE.textMessage,
					created,
					authorId,
					message,
				};
			} else return undefined;
		} else if (
			assistantHistoryDTO.historyElementType ===
			ASSISTANT_HISTORY_TYPE.phoneInput
		) {
			return {
				type: ASSISTANT_HISTORY_TYPE.phoneInput,
				created,
				authorId,
				message: ConvertersUtil.toContent(assistantHistoryDTO?.value),
				replyData: assistantHistoryDTO?.replyData,
			};
		} else if (
			assistantHistoryDTO.historyElementType ===
			ASSISTANT_HISTORY_TYPE.codeInput
		) {
			return {
				type: ASSISTANT_HISTORY_TYPE.codeInput,
				created,
				authorId,
				message: ConvertersUtil.toContent(assistantHistoryDTO?.value),
				replyData: assistantHistoryDTO?.replyData,
			};
		} else if (
			assistantHistoryDTO.historyElementType ===
			ASSISTANT_HISTORY_TYPE.textInput
		) {
			return {
				type: ASSISTANT_HISTORY_TYPE.textInput,
				created,
				authorId,
				value: ConvertersUtil.toContent(assistantHistoryDTO?.value),
				replyData: assistantHistoryDTO?.replyData,
				placeholder:
					ConvertersUtil.toContent(
						assistantHistoryDTO?.placeholder,
					) || DEFAULT_CONTENT,
			};
		} else if (
			assistantHistoryDTO.historyElementType ===
			ASSISTANT_HISTORY_TYPE.linkButtons
		) {
			return {
				type: ASSISTANT_HISTORY_TYPE.linkButtons,
				created,
				authorId,
				buttons:
					ConvertersUtil.toArray(
						assistantHistoryDTO.buttons,
						[],
						undefined,
						button => {
							return {
								label:
									ConvertersUtil.toContent(button.label) ||
									DEFAULT_CONTENT,
								link:
									ConvertersUtil.toString(button.link) || '',
							};
						},
					) || [],
			};
		} else if (
			assistantHistoryDTO.historyElementType ===
			ASSISTANT_HISTORY_TYPE.accordion
		) {
			const items =
				ConvertersUtil.toArray<IFirestoreAssistantAccordionItem>(
					assistantHistoryDTO?.items,
					[],
					undefined,
					item => {
						const icon = ConvertersUtil.toString(item.icon);
						if (icon) {
							const buttons =
								ConvertersUtil.toArray(
									item.buttons,
									[],
									undefined,
									button => {
										return {
											type:
												ACCORDION_BUTTON_TYPE[
													button.type
												] ||
												ACCORDION_BUTTON_TYPE.linkButton,
											label:
												ConvertersUtil.toContent(
													button.label,
												) || DEFAULT_CONTENT,
											link:
												ConvertersUtil.toString(
													button.link,
												) || '',
										};
									},
								) || [];
							return {
								title:
									ConvertersUtil.toContent(item.title) ||
									DEFAULT_CONTENT,
								icon,
								subtitle: item.subtitle,
								body:
									ConvertersUtil.toContent(item.body) ||
									DEFAULT_CONTENT,
								showOpened: item.showOpened,
								buttons,
							};
						} else {
							return undefined;
						}
					},
				) || [];
			return {
				type: ASSISTANT_HISTORY_TYPE.accordion,
				created,
				authorId,
				items,
				remaining:
					ConvertersUtil.toNumber(assistantHistoryDTO?.remaining) ||
					0,
			};
		} else if (
			assistantHistoryDTO.historyElementType ===
			ASSISTANT_HISTORY_TYPE.select
		) {
			const options =
				ConvertersUtil.toArray<IFirestoreAssistantSelectOption>(
					assistantHistoryDTO.options,
					[],
					undefined,
					option => {
						return {
							label:
								ConvertersUtil.toContent(option.label) ||
								DEFAULT_CONTENT,
							selected: ConvertersUtil.toBool(option.selected),
							replyData: option.replyData,
						};
					},
				) || [];
			return {
				type: ASSISTANT_HISTORY_TYPE.select,
				created,
				authorId,
				selected: ConvertersUtil.toBool(assistantHistoryDTO.selected),
				options,
			};
		} else if (
			assistantHistoryDTO.historyElementType ==
			ASSISTANT_HISTORY_TYPE.consent
		) {
			return {
				type: ASSISTANT_HISTORY_TYPE.consent,
				created,
				authorId: assistantHistoryDTO.authorId,
				selected: assistantHistoryDTO.selected,
				replyData: assistantHistoryDTO.replyData,
				label:
					ConvertersUtil.toContent(assistantHistoryDTO.label) ||
					DEFAULT_CONTENT,
			};
		} else {
			return undefined;
		}
	}
}
