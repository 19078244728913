import 'bootstrap/dist/css/bootstrap.min.css';

import { useState } from '@nui/hooks';
import { AuthObserver } from '@nui/observers';
import { EventTrackingProvider } from '@nui/providers';
import { ASSISTANT_HISTORY_SKILL } from '@nui/models';
import { DataScrubberUtil } from '@nui/utils';
import { useFonts } from 'expo-font';
import { firebase } from 'firebase-xp';
import React from 'react';
import { Platform } from 'react-native';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Sentry from 'sentry-xp';

import * as envConfig from '../env-config.json';
import { stateModel } from '../globals';
import * as metaData from '../package.json';
import { Care101ChaptersController } from './controllers/care101-chapters/routable/care101-chapters.controller';
import { Care101StartController } from './controllers/care101-start/routable/care101-start.controller';
import { Care101TopicController } from './controllers/care101-topic/care101-topic.controller';
import { HomescreenController } from './controllers/homescreen/routable/homescreen.controller';
import { LoadingController } from './controllers/loading/loading.controller';
import { LoginController } from './controllers/login/login.controller';
import { LogoutController } from './controllers/logout/logout.controller';
import { WaitingController } from './controllers/waiting/waiting.controller';
import { AssistantMemorizedController } from './controllers/assistant/routable/assistant.controller';

firebase.initializeApp(JSON.parse(atob(envConfig.webFbClientConfigB64)));
const authObserver = new AuthObserver();
Sentry.init({
	environment: envConfig.name,
	dsn:
		'https://bb8dbd522c5e435082cf66e3b848abb8@o258016.ingest.sentry.io/1451914',
	// A few error messages to be ignored because we will never fix them.
	ignoreErrors: [
		// Variations of not standardized networking errors:
		'Network request failed',
		'auth/invalid-verification-code',
		'A network error (such as timeout, interrupted connection or unreachable host) has occurred',
		'[auth/network-request-failed] A network error has occurred, please try again',
		'Purchase failed due to Verbindung mit iTunes Store nicht möglich',
		// Session management is managed by the Firebase SDK
		'auth/session-expired',
		// Bug in phone number input library
		'[auth/invalid-phone-number] The format of the phone number provided is incorrect',
	],
	beforeSend(event) {
		if (__DEV__) {
			console.log(event);
			return null;
		} else {
			// inspect and override the exception event here
			if (event.extra && event.extra.__serialized__) {
				event.extra.__serialized__ = DataScrubberUtil.redactPII(
					event.extra.__serialized__,
				);
			}
			event = DataScrubberUtil.redactPII(event);
			return event;
		}
	},
});
Sentry.setTags({
	environment: envConfig.name,
	platform: Platform.OS,
	appVersion: metaData.version,
});

export default function WebApp() {
	// state is not used but the whole app still needs to re-render
	const [, setState] = useState(stateModel.getState());
	let [fontsLoaded] = useFonts({
		Icomoon: require('../assets/fonts/icomoon.ttf'),
	});

	React.useEffect(() => {
		authObserver
			.subscribeAuthObserver(
				() => {
					setState(stateModel.getState());
				},
				() => {
					console.error('Rejected');
				},
			)
			.then(() => {
				stateModel.setLoading(false);
				setState(stateModel.getState());
			})
			.catch(error => {
				console.error(error);
			});
	}, []);

	const auth = stateModel.stateAuthModel.getAuth();
	if (stateModel.isLoading() || !fontsLoaded) {
		return <LoadingController />;
	} else if (stateModel.isWaiting()) {
		return <WaitingController />;
	} else if (auth?.uid && !auth?.isAnonymous) {
		return (
			<EventTrackingProvider>
				<Router>
					<Switch>
						<Route exact path="/care101s/:courseId">
							<Care101StartController />
						</Route>
						<Route exact path="/care101s/:courseId/topics/:topicId">
							<Care101TopicController />
						</Route>
						<Route
							exact
							path="/care101s/:courseId/topics/:topicId/chapters"
						>
							<Care101ChaptersController />
						</Route>
						<Route
							exact
							path="/care101s/:courseId/topics/:topicId/chapters/:chapterId"
						>
							<Care101ChaptersController />
						</Route>
						<Route path="/assistant">
							<AssistantMemorizedController />
						</Route>
						{/* During the registration flow the user will change isAnonymous state */}
						<Route path="/assistant-demo">
							<AssistantMemorizedController
								forceSkill={ASSISTANT_HISTORY_SKILL.webdemo}
							/>
						</Route>
						<Route path="/concierge-demo">
							<AssistantMemorizedController
								forceSkill={
									ASSISTANT_HISTORY_SKILL.conciergeDemo
								}
							/>
						</Route>
						<Route path="/logout">
							<LogoutController />
						</Route>
						<Route path="/">
							<HomescreenController />
						</Route>
					</Switch>
				</Router>
			</EventTrackingProvider>
		);
	} else {
		return (
			<EventTrackingProvider>
				<Router>
					<Switch>
						<Route path="/assistant-demo">
							<AssistantMemorizedController
								forceSkill={ASSISTANT_HISTORY_SKILL.webdemo}
							/>
						</Route>
						<Route path="/concierge-demo">
							<AssistantMemorizedController
								forceSkill={
									ASSISTANT_HISTORY_SKILL.conciergeDemo
								}
							/>
						</Route>
						<Route path="/logout">
							<LogoutController />
						</Route>
						<Route path="/">
							<LoginController />
						</Route>
					</Switch>
				</Router>
			</EventTrackingProvider>
		);
	}
}
