import { HelpersUtil } from '@nui/utils';
import React, { useEffect, useState } from 'react';
import { Platform, Text, TextInput, TextInputProps } from 'react-native';
import styled from 'styled-components/native';

import { COLOR_CODES } from '../enums/colors';
import {
	extractWrapperProps,
	INativeProps,
	IWrapperProps,
	IWrapperStyles,
} from '../wrappers/wrapper-interfaces';
import { WrapperView } from '../wrappers/wrapper.view';

export const MAX_CHARACTER_LENGTH = 256;
export const FONT_SIZE = 17;
export const LINE_HEIGHT = 22;

export interface ITextfieldNewStyles extends IWrapperStyles {
	textfield?: {
		[style: string]: any;
	};
	counter?: {
		[style: string]: any;
	};
}

export interface ITextfieldNewProps extends IWrapperProps {
	styles?: ITextfieldNewStyles;
	onChange?: (text: string) => void;
	onSubmit?: (text: string) => Promise<void>;
	onFocus?: () => void;
	onBlur?: () => void;
	nativeTextInputProps?: TextInputProps & { ref?: any };
	useCounter?: boolean;
	color?: COLOR_CODES;
	maxLines?: number;
	autoHeight?: boolean;
	handler?: (handler: { clear: () => void; isValid: () => boolean }) => void;
}

export const extractTextfieldNewProps = <T extends ITextfieldNewProps>(
	props: T,
): ITextfieldNewProps => {
	return {
		styles: props.styles,
		onChange: props.onChange,
		onSubmit: props.onSubmit,
		onFocus: props.onFocus,
		onBlur: props.onBlur,
		nativeTextInputProps: props.nativeTextInputProps,
		useCounter: props.useCounter,
		color: props.color,
		maxLines: props.maxLines,
		autoHeight: props.autoHeight,
		handler: props.handler,
	};
};

export interface ITextfieldNewState {
	length: number;
	value: string;
}

const StyledTextInput = styled((props: ITextfieldNewProps & INativeProps) => {
	const [focused, setFocused] = useState(false);
	const focusedStyle = Platform.select({
		web: {
			// This should be COLOR_CODES.Blue, but this specifix hex code is ignored
			borderColor: '#8099d1',
			borderWidth: 1,
		},
		native: {},
	});
	return (
		<TextInput
			{...props.nativeTextInputProps}
			style={[props.style, focused && focusedStyle]}
			onFocus={() => setFocused(true)}
			onBlur={() => setFocused(false)}
		/>
	);
})`
	/* focused field is highlighted by colored border */
	${HelpersUtil.isWeb() ? 'outline-width: 0;' : undefined}
	font-size: ${FONT_SIZE};
	line-height: ${LINE_HEIGHT};
	letter-spacing: -0.41;
	color: ${(props: ITextfieldNewProps) => props.color || COLOR_CODES.Black};
	margin: 0px;
	padding-right: ${(props: ITextfieldNewProps) => {
		const fontSize = props.styles?.textfield?.fontSize || FONT_SIZE;
		const maxCharacterLength =
			(props.nativeTextInputProps &&
				props.nativeTextInputProps.maxLength) ||
			MAX_CHARACTER_LENGTH;
		const NumDigits = (maxCharacterLength + '').length;
		return (props.useCounter && (NumDigits * fontSize) / 1.5) || 0;
	}};
	${(props: ITextfieldNewProps) => ({ ...props.styles?.textfield })};
`;

let textInput;
let waiting = false;

export const TextfieldNewView = (props: ITextfieldNewProps) => {
	const defaultValue =
		(props.nativeTextInputProps &&
			props.nativeTextInputProps.defaultValue) ||
		'';
	const maxCharacterLength =
		(props.nativeTextInputProps && props.nativeTextInputProps.maxLength) ||
		MAX_CHARACTER_LENGTH;

	const [state, setState] = useState({
		value: defaultValue,
		length: maxCharacterLength - defaultValue.length,
	});

	useEffect(() => {
		props.handler &&
			props.handler({
				clear: () => {
					if (textInput) {
						textInput.clear();
						setState({
							value: '',
							length: maxCharacterLength,
						});
					}
				},
				isValid: () => {
					return state.value.trim().length > 0;
				},
			});
	}, [props, maxCharacterLength, state.value]);

	const handleChange = (value: string) => {
		setState({
			value,
			length: maxCharacterLength - value.length,
		});
		if (props.onChange) {
			props.onChange(value.trim());
		}
	};

	const handleSubmit = async () => {
		if (!waiting) {
			if (props.onSubmit) {
				waiting = true;
				await props.onSubmit(state.value.trim());
				waiting = false;
			}
		}
		return Promise.resolve();
	};

	const handleFocus = () => {
		props.onFocus && props.onFocus();
	};

	// Extract wrapper props
	const { maxLines, autoHeight, color } = props;
	const wrapperProps = extractWrapperProps(props);

	const textfieldProps: ITextfieldNewProps = {
		styles: {
			...props.styles,
			...{
				textfield: {
					...(autoHeight &&
						maxLines &&
						maxLines > 1 && {
							maxHeight: LINE_HEIGHT * maxLines,
						}),
					...(!autoHeight &&
						maxLines &&
						maxLines > 1 && {
							height: LINE_HEIGHT * maxLines,
						}),
					...props.styles?.textfield,
				},
			},
		},
		nativeTextInputProps: {
			maxLength: (props.useCounter && maxCharacterLength) || undefined,
			...props.nativeTextInputProps,
			multiline: (maxLines || 1) > 1,
			onChangeText: handleChange,
			onSubmitEditing: (!maxLines && handleSubmit) || undefined,
			onFocus: handleFocus,
			onBlur: props.onBlur,
			ref: input => {
				textInput = input;
			},
		},
		color,
		useCounter: props.useCounter,
	};

	return (
		<WrapperView {...wrapperProps}>
			<StyledTextInput {...textfieldProps} />
			{props.useCounter && (
				<Text
					style={{
						position: 'absolute',
						right: 0,
						bottom: 0,
						color: COLOR_CODES.Grey25,
						fontSize: FONT_SIZE,
						lineHeight: LINE_HEIGHT,
						...props.styles?.counter,
					}}
				>
					{state.length}
				</Text>
			)}
		</WrapperView>
	);
};
