import { IHTMLChunks } from '@nui/utils';
import { ImageURISource, TextProps } from 'react-native';

import { COLOR_CODES } from '../enums/colors';
import { IBadgeSmallProps } from '../misc/badge-small.view';
import {
	extractWrapperProps,
	IStyles,
	IWrapperProps,
	IWrapperStyles,
} from '../wrappers/wrapper-interfaces';

export interface ITextStyles extends IWrapperStyles {
	text?: IStyles;
	bold?: IStyles;
	italic?: IStyles;
	underline?: IStyles;
	span?: IStyles;
	h1?: IStyles;
	h2?: IStyles;
	h3?: IStyles;
	semibold?: IStyles;
	li?: IStyles;
}

export enum FONT_WEIGHTS {
	thin = '100',
	extraLight = '200',
	light = '300',
	regular = '400',
	medium = '500',
	semiBold = '600',
	bold = '700',
	extraBold = '800',
	black = '900',
	extraBlack = '950',
}
export enum TEXT_ALIGN {
	left = 'left',
	center = 'center',
	right = 'right',
}

export enum TEXT_DECORATION {
	underline = 'underline',
	none = 'none',
}

export interface ITextProps extends IWrapperProps {
	textAlign?: TEXT_ALIGN;
	color?: COLOR_CODES;
	weight?: FONT_WEIGHTS;
	textDecoration?: TEXT_DECORATION;
	styles?: ITextStyles;
	nativeTextProps?: TextProps;
	htmlChunks?: IHTMLChunks;
	attributes?: {
		onClick?: {
			[clickName: string]: () => void;
		};
	};
	getImageFun?: (
		name: string,
	) => () => Promise<ImageURISource> | ImageURISource;
	badge?: IBadgeSmallProps;
}

export const extractTextProps = <T extends ITextProps>(
	props: T,
): ITextProps => {
	return {
		textAlign: props.textAlign,
		color: props.color,
		weight: props.weight,
		styles: props.styles,
		nativeTextProps: props.nativeTextProps,
		htmlChunks: props.htmlChunks,
		textDecoration: props.textDecoration,
		attributes: props.attributes,
		getImageFun: props.getImageFun,
		badge: props.badge,
	};
};

export const extractAllTextProps = <T extends ITextProps>(
	props: T,
): ITextProps => {
	const allWrapperProps = extractWrapperProps(props);
	const textProps = extractTextProps(props);
	return {
		...allWrapperProps,
		...textProps,
		styles: {
			...allWrapperProps.styles,
			...textProps.styles,
		},
	};
};
