import React, { Dispatch, SetStateAction } from 'react';
import { useIsMounted } from './use-is-mounted.hook';

export const useState = <S>(
	initialState: S | (() => S),
): [S, Dispatch<SetStateAction<S>>] => {
	const [state, setState] = React.useState<S>(initialState);

	const isMounted = useIsMounted();

	return [
		state,
		s => {
			isMounted() && setState(s);
		},
	];
};
