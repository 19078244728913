import XDate from 'xdate';
import { ConvertersUtil } from '@nui/utils';

export interface IFirestoreTeamPayment {
	ownerId: string;
	endTime: XDate;
	state?: string;
	platform?: string;
}

export class FirestoreTeamPaymentModel {
	static toTeamPayment(
		teamPaymentDTO: any,
	): IFirestoreTeamPayment | undefined {
		const paymentOwnerId = ConvertersUtil.toString(teamPaymentDTO?.ownerId);
		const paymentEndTime = ConvertersUtil.toXDate(teamPaymentDTO?.endTime);

		if (!paymentOwnerId || !paymentEndTime) {
			return undefined;
		} else {
			return {
				ownerId: paymentOwnerId,
				endTime: paymentEndTime,
				state: ConvertersUtil.toString(teamPaymentDTO?.state),
				platform: ConvertersUtil.toString(teamPaymentDTO?.platform),
			};
		}
	}
}
