import { IFirebaseAuth } from '../firestore/auth/firestore-auth.model';
import { StateModel } from './state.model';
import { IState } from './state-interfaces';

export class StateAuthModel {
	protected state: IState;
	protected stateModel: StateModel;
	protected stateHasChanged: boolean;

	constructor(state: IState, stateModel: StateModel) {
		this.state = state;
		this.stateModel = stateModel;
		this.stateHasChanged = true;
	}

	initializeState() {
		if (this.stateHasChanged) {
			this.stateHasChanged = false;
		}
	}

	setAuth(auth: IFirebaseAuth) {
		this.stateHasChanged = true;
		this.state.auth = auth;
	}

	getAuth(): IFirebaseAuth | undefined {
		return this.state.auth;
	}

	unsetAuth() {
		this.stateHasChanged = true;
		this.state.auth = undefined;
	}
}
