import { firebase, FirebaseFirestoreTypes } from 'firebase-xp';
import _ from 'lodash';
import XDate from 'xdate';
import { HelpersUtil, ConvertersUtil } from '@nui/utils';
import { ImageURISource } from 'react-native';
import {
	FirestoreDocumentProvider,
	FirestoreQueryProvider,
	FirestoreCollectionProvider,
} from '@nui/providers';
export enum FirestoreEntryFrequency {
	DAILY = 'daily',
	WEEKLY = 'weekly',
	BIWEEKLY = 'biweekly',
	MONTHLY = 'monthly',
	YEARLY = 'yearly',
}

export enum FirestoreEntryType {
	DEFAULT = 'default',
	TOPIC = 'topic',
}
export interface IFirestoreEntryDTO {
	created: FirebaseFirestoreTypes.Timestamp;
	updated: FirebaseFirestoreTypes.Timestamp;
	ownerId: string;
	allday: boolean;
	caregiverId?: string;
	deadline: FirebaseFirestoreTypes.Timestamp;
	declined?: {
		[userId: string]: FirebaseFirestoreTypes.Timestamp;
	};
	done: boolean;
	frequency?: FirestoreEntryFrequency;
	referenceId?: string;
	title: string;
	seen?: {
		[userId: string]: FirebaseFirestoreTypes.Timestamp;
	};
	type: FirestoreEntryType;
	isDeleted: boolean;
	avatar?: FirebaseFirestoreTypes.Blob;
}

export interface IFirestoreEntry {
	created: XDate;
	updated: XDate;
	ownerId: string;
	allday: boolean;
	caregiverId?: string;
	deadline: XDate;
	declined: {
		[userId: string]: XDate;
	};
	done: boolean;
	frequency?: FirestoreEntryFrequency;
	referenceId?: string;
	title: string;
	seen: {
		[userId: string]: XDate;
	};
	type: FirestoreEntryType;
	isDeleted: boolean;
	avatar?: ImageURISource;
}

export class FirestoreBlankEntryModel {
	protected userId: string;
	protected teamId: string;
	public entry!: IFirestoreEntry;
	protected firestoreCollectionProvider: FirestoreCollectionProvider;

	constructor(userId: string, teamId: string, deadline = new XDate()) {
		this.userId = userId;
		this.teamId = teamId;
		this.firestoreCollectionProvider = new FirestoreCollectionProvider(
			firebase
				.firestore()
				.collection('teams')
				.doc(this.teamId)
				.collection('entries'),
		);
		this.setAll(
			FirestoreBlankEntryModel.toEntry(userId, {
				deadline,
			}),
		);
	}

	setAll(entry: IFirestoreEntry) {
		this.entry = {
			...entry,
		};
		return this;
	}

	setCreated(xDate: XDate) {
		this.entry.created = xDate;
		return this;
	}

	setUpdated(xDate: XDate) {
		this.entry.updated = xDate;
		return this;
	}

	setOwnerId(ownerId: string) {
		this.entry.ownerId = ownerId;
		return this;
	}

	setAllday(allday: boolean) {
		this.entry.allday = allday;
		return this;
	}

	setCaregiverId(caregiverId?: string) {
		this.entry.caregiverId = caregiverId;
		return this;
	}

	setDeadline(dealine: XDate) {
		this.entry.deadline = dealine;
		return this;
	}

	setDeclined(declined?: { [userId: string]: XDate }) {
		this.entry.declined = { ...declined };
		return this;
	}

	setDone(done: boolean) {
		this.entry.done = done;
		return this;
	}

	setFrequency(frequency?: FirestoreEntryFrequency) {
		this.entry.frequency = frequency;
		return this;
	}

	setReferenceId(referenceId?: string) {
		this.entry.referenceId = referenceId;
		return this;
	}

	setTitle(title: string) {
		this.entry.title = title;
		return this;
	}

	setAvatar(image?: ImageURISource) {
		this.entry.avatar = image;
		return this;
	}

	setSeen(seen?: { [userId: string]: XDate }) {
		this.entry.seen = { ...seen };
		return this;
	}

	setType(type: FirestoreEntryType) {
		this.entry.type = type;
		return this;
	}

	getAllday() {
		return this.entry.allday;
	}

	getDeadline() {
		return this.entry.deadline;
	}

	getFrequency() {
		return this.entry.frequency;
	}

	getReferenceId() {
		return this.entry.referenceId;
	}

	getTitle() {
		return this.entry.title;
	}

	getAvatar() {
		return this.entry.avatar;
	}

	decline() {
		this.entry.declined[this.userId] = new XDate();
		this.uncommit();
	}

	undecline() {
		delete this.entry.declined[this.userId];
	}

	hasDeclined() {
		return !!this.entry.declined[this.userId];
	}

	done() {
		this.entry.done = true;
		this.commit();
	}

	undone() {
		this.entry.done = false;
	}

	isDone() {
		return this.entry.done;
	}

	seen() {
		this.entry.seen[this.userId] = new XDate();
	}

	commit() {
		this.undecline();
		this.entry.caregiverId = this.userId;
	}

	uncommit() {
		if (this.entry.caregiverId === this.userId) {
			this.entry.caregiverId = undefined;
		}
	}

	hasCommitted() {
		return this.entry.caregiverId === this.userId;
	}

	serialize(): IFirestoreEntryDTO {
		const declined = _.mapValues(this.entry.declined, xDate =>
			firebase.firestore.Timestamp.fromDate(xDate.toDate()),
		);
		const seen = _.mapValues(this.entry.seen, xDate =>
			firebase.firestore.Timestamp.fromDate(xDate.toDate()),
		);
		const avatar =
			this.entry.avatar?.uri &&
			this.entry.avatar.uri.startsWith('data:image/')
				? this.entry.avatar?.uri?.split(',')[1]
				: undefined;

		return {
			created: firebase.firestore.Timestamp.fromDate(
				this.entry.created.toDate(),
			),
			updated: firebase.firestore.Timestamp.fromDate(
				this.entry.updated.toDate(),
			),
			ownerId: this.entry.ownerId,
			allday: this.entry.allday,
			caregiverId: this.entry.caregiverId,
			deadline: firebase.firestore.Timestamp.fromDate(
				this.entry.deadline.toDate(),
			),
			declined: HelpersUtil.hasProperties(declined)
				? declined
				: undefined,
			done: this.entry.done,
			frequency: this.entry.frequency,
			referenceId: this.entry.referenceId,
			title: this.entry.title,
			seen,
			type: this.entry.type,
			isDeleted: this.entry.isDeleted,
			avatar:
				(avatar && firebase.firestore.Blob.fromBase64String(avatar)) ||
				undefined,
		};
	}

	public clone(deadline?) {
		const blanckEntry = new FirestoreBlankEntryModel(
			this.userId,
			this.teamId,
			deadline,
		);
		return blanckEntry
			.setCreated(this.entry.created)
			.setUpdated(this.entry.updated)
			.setOwnerId(this.entry.ownerId)
			.setAllday(this.entry.allday)
			.setCaregiverId(this.entry.caregiverId)
			.setDeclined(this.entry.declined)
			.setDone(this.entry.done)
			.setFrequency(this.entry.frequency)
			.setReferenceId(this.entry.referenceId)
			.setTitle(this.entry.title)
			.setSeen(this.entry.seen)
			.setType(this.entry.type)
			.setAvatar(this.entry.avatar);
	}

	protected createDaily() {
		let start = this.entry.deadline.toDate();
		start.setDate(start.getDate() + 1);
		let end = new Date(this.entry.deadline.toDate());
		end.setDate(end.getDate() + 60);
		const entriesToPersist: IFirestoreEntryDTO[] = [];
		for (let date = start; date < end; date.setDate(date.getDate() + 1)) {
			const nextEntry = this.clone(new XDate(date));
			entriesToPersist.push(nextEntry.serialize());
		}
		return this.firestoreCollectionProvider.addBatch<IFirestoreEntryDTO>(
			entriesToPersist,
		);
	}

	protected createWeekly() {
		let start = this.entry.deadline.toDate();
		start.setDate(start.getDate() + 7);
		let end = new Date(this.entry.deadline.toDate());
		end.setDate(end.getDate() + 60 * 7);
		const entriesToPersist: IFirestoreEntryDTO[] = [];
		for (let date = start; date < end; date.setDate(date.getDate() + 7)) {
			const nextEntry = this.clone(new XDate(date));
			entriesToPersist.push(nextEntry.serialize());
		}
		return this.firestoreCollectionProvider.addBatch<IFirestoreEntryDTO>(
			entriesToPersist,
		);
	}

	protected createBiweekly() {
		let start = this.entry.deadline.toDate();
		start.setDate(start.getDate() + 14);
		let end = new Date(this.entry.deadline.toDate());
		end.setDate(end.getDate() + 60 * 14);
		const entriesToPersist: IFirestoreEntryDTO[] = [];
		for (let date = start; date < end; date.setDate(date.getDate() + 14)) {
			const nextEntry = this.clone(new XDate(date));
			entriesToPersist.push(nextEntry.serialize());
		}
		return this.firestoreCollectionProvider.addBatch<IFirestoreEntryDTO>(
			entriesToPersist,
		);
	}

	protected createMonthly() {
		let start = this.entry.deadline.toDate();
		const entriesToPersist: IFirestoreEntryDTO[] = [];
		for (let i = 1; i < 60; i++) {
			let date = new XDate(start).addMonths(i, true);
			const nextEntry = this.clone(new XDate(date));
			entriesToPersist.push(nextEntry.serialize());
		}
		return this.firestoreCollectionProvider.addBatch<IFirestoreEntryDTO>(
			entriesToPersist,
		);
	}

	protected createYearly() {
		let start = this.entry.deadline.toDate();
		const entriesToPersist: IFirestoreEntryDTO[] = [];
		for (let i = 1; i < 20; i++) {
			let date = new XDate(start).addYears(i, true);
			const nextEntry = this.clone(new XDate(date));
			entriesToPersist.push(nextEntry.serialize());
		}
		return this.firestoreCollectionProvider.addBatch<IFirestoreEntryDTO>(
			entriesToPersist,
		);
	}

	public create(): Promise<FirestoreEntryModel> {
		const currentDate = new XDate();
		return new Promise(async (resolve, reject) => {
			try {
				const entryId = this.firestoreCollectionProvider.generateId();
				this.setCreated(currentDate)
					.setUpdated(currentDate)
					.setReferenceId(entryId);
				const {
					entity,
					entityId,
				} = await this.firestoreCollectionProvider.add(
					this.serialize(),
				);
				if (this.getFrequency() === FirestoreEntryFrequency.DAILY) {
					await this.createDaily();
				} else if (
					this.getFrequency() === FirestoreEntryFrequency.WEEKLY
				) {
					await this.createWeekly();
				} else if (
					this.getFrequency() === FirestoreEntryFrequency.BIWEEKLY
				) {
					await this.createBiweekly();
				} else if (
					this.getFrequency() === FirestoreEntryFrequency.MONTHLY
				) {
					await this.createMonthly();
				} else if (
					this.getFrequency() === FirestoreEntryFrequency.YEARLY
				) {
					await this.createYearly();
				}

				const entry = FirestoreEntryModel.toEntry(this.userId, entity);
				if (entry) {
					resolve(
						new FirestoreEntryModel(
							this.userId,
							this.teamId,
							entityId,
							entry,
						),
					);
				}
			} catch (error) {
				reject(error);
			}
		});
	}

	static toEntry(
		userId: string,
		entryDTO: any,
		defaultXDate = new XDate(),
	): IFirestoreEntry {
		const deadline =
			ConvertersUtil.toXDate(entryDTO?.deadline) || defaultXDate;
		const declined = _.mapValues(
			entryDTO?.declined,
			timestamp => ConvertersUtil.toXDate(timestamp) || defaultXDate,
		);
		const seen = _.mapValues(
			entryDTO?.seen,
			timestamp => ConvertersUtil.toXDate(timestamp) || defaultXDate,
		);

		return {
			created: ConvertersUtil.toXDate(entryDTO?.created) || defaultXDate,
			updated: ConvertersUtil.toXDate(entryDTO?.updated) || defaultXDate,
			ownerId: ConvertersUtil.toString(entryDTO?.ownerId) || userId,
			allday: ConvertersUtil.toBool(entryDTO?.allday),
			caregiverId: ConvertersUtil.toString(entryDTO?.caregiverId),
			deadline,
			declined,
			done: ConvertersUtil.toBool(entryDTO?.done || entryDTO?.confirmed),
			frequency: ConvertersUtil.toEnum(
				FirestoreEntryFrequency,
				entryDTO?.frequency,
			),
			referenceId: entryDTO?.referenceId,
			title: ConvertersUtil.toString(entryDTO?.title) || '',
			seen,
			type: ConvertersUtil.toEnum(
				FirestoreEntryType,
				entryDTO?.type,
				FirestoreEntryType.DEFAULT,
			),
			isDeleted: ConvertersUtil.toBool(entryDTO?.isDeleted),
			avatar: ConvertersUtil.toImageSource(entryDTO?.avatar),
		};
	}
}
export class FirestoreEntryModel extends FirestoreBlankEntryModel {
	protected entryId: string;
	protected firestoreDocumentProvider: FirestoreDocumentProvider;

	constructor(
		userId: string,
		teamId: string,
		entryId: string,
		entry: IFirestoreEntry,
	) {
		super(userId, teamId);
		this.entryId = entryId;
		this.entry = entry;
		this.firestoreDocumentProvider = new FirestoreDocumentProvider(
			firebase
				.firestore()
				.collection('teams')
				.doc(this.teamId)
				.collection('entries')
				.doc(this.entryId),
		);
	}

	public getEntryId() {
		return this.entryId;
	}

	public done() {
		return this.firestoreDocumentProvider.update({
			done: true,
			caregiverId: this.userId,
			[`declined.${this.userId}`]: firebase.firestore.FieldValue.delete(),
		});
	}

	public undone() {
		return this.firestoreDocumentProvider.update({ done: false });
	}

	public seen() {
		return this.firestoreDocumentProvider.update({
			[`seen.${this.userId}`]: firebase.firestore.Timestamp.fromDate(
				new XDate().toDate(),
			),
		});
	}

	public commit() {
		return this.firestoreDocumentProvider.update({
			caregiverId: this.userId,
			[`declined.${this.userId}`]: firebase.firestore.FieldValue.delete(),
		});
	}

	public uncommit() {
		if (this.entry.caregiverId === this.userId) {
			return this.firestoreDocumentProvider.update({
				caregiverId: undefined,
			});
		} else {
			return Promise.resolve();
		}
	}

	public decline() {
		const caregiverId =
			this.entry.caregiverId === this.userId
				? undefined
				: this.entry.caregiverId;
		return this.firestoreDocumentProvider.update({
			[`declined.${this.userId}`]: firebase.firestore.Timestamp.fromDate(
				new XDate().toDate(),
			),
			caregiverId,
		});
	}

	public undecline() {
		return this.firestoreDocumentProvider.update({
			[`declined.${this.userId}`]: firebase.firestore.FieldValue.delete(),
		});
	}

	public removeFollowing() {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const referenceId = this.getReferenceId();
				if (referenceId) {
					const entries = await new FirestoreQueryProvider(
						firebase
							.firestore()
							.collection('teams')
							.doc(this.teamId)
							.collection('entries')
							.where('referenceId', '==', referenceId)
							.where(
								'deadline',
								'>=',
								firebase.firestore.Timestamp.fromDate(
									this.entry.deadline.toDate(),
								),
							),
					).get();
					const entriesToUpdate: {
						[entryId: string]: IFirestoreEntryDTO;
					} = {};
					for (const entryId in entries) {
						const entry = FirestoreEntryModel.toEntry(
							this.userId,
							entries[entryId],
						);
						if (entry) {
							const entryModel = new FirestoreEntryModel(
								this.userId,
								this.teamId,
								entryId,
								entry,
							);
							entryModel.entry.isDeleted = true;
							entriesToUpdate[entryId] = entryModel.serialize();
						}
					}
					new FirestoreCollectionProvider(
						firebase
							.firestore()
							.collection('teams')
							.doc(this.teamId)
							.collection('entries'),
					)
						.updateBatch<IFirestoreEntryDTO>(entriesToUpdate)
						.catch(error => {
							reject(error);
						});
					resolve();
				}
			} catch (error) {
				reject(error);
			}
		});
	}

	public remove() {
		this.entry.isDeleted = true;
		this.setUpdated(new XDate());
		return this.firestoreDocumentProvider.update(this.serialize());
	}

	public update() {
		this.setUpdated(new XDate());
		return this.firestoreDocumentProvider.update(this.serialize());
	}
}
