import React from 'react';
import { Picker as PickerContainerView } from 'react-native-picker-xp';

import { ButtonPrimaryView } from '../buttons/button-primary.view';
import { ButtonSecondaryView } from '../buttons/button-secondary.view';
import { COLOR_CODES } from '../enums/colors';
import {
	extractWrapperProps,
	IWrapperProps,
} from '../wrappers/wrapper-interfaces';
import { WrapperView } from '../wrappers/wrapper.view';
import { JUSTIFY, WrapperAlignView } from '../wrappers/wrapper-align.view';

interface IPickerProps extends IWrapperProps {
	options: string[];
	cancel?: {
		onClick: () => void;
		label: string;
	};
	submit: {
		onClick: (index: number) => void;
		label: string;
	};
}

export const PickerView = (props: IPickerProps) => {
	const [index, setIndex] = React.useState(0);

	const handleChange = (_: string, index: number) => {
		setIndex(index);
	};

	const handleClick = () => {
		props.submit.onClick(index);
	};
	const wrappereProps = extractWrapperProps(props);
	return (
		<WrapperView {...wrappereProps}>
			<PickerContainerView
				itemStyle={{ color: COLOR_CODES.Black, fontSize: 17 }}
				onValueChange={handleChange}
				selectedValue={index}
			>
				{props.options.map((option, i) => (
					<PickerContainerView.Item
						key={i}
						label={option}
						value={i}
					/>
				))}
			</PickerContainerView>
			<WrapperAlignView itemsJustify={JUSTIFY.End}>
				<ButtonPrimaryView
					rounded={true}
					wrapperTop={15}
					wrapperBottom={10}
					onClick={handleClick}
					label={props.submit.label}
					autosize={true}
					styles={{
						container: {
							paddingLeft: 30,
							paddingRight: 30,
						},
					}}
				/>
			</WrapperAlignView>

			{props.cancel && (
				<ButtonSecondaryView
					onClick={props.cancel.onClick}
					label={props.cancel.label}
				/>
			)}
		</WrapperView>
	);
};
