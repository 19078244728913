import React from 'react';
import { Image } from 'react-native';

const FastImage = (props: any) => {
	return <Image style={props.style} source={props.source} />;
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
FastImage.preload = _sources => {
	return;
};

export default FastImage;
