import 'react-phone-number-input/style.css';

import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input';

import { COLOR_CODES } from '../enums/colors';
import { INativeProps } from '../wrappers/wrapper-interfaces';
import { ITextfieldPhoneProps } from './textfield-new-phone.view';
import { FONT_SIZE } from './textfield-new.view';
import { isValidPhoneNumber } from 'react-phone-number-input';

/* 
	Our default TextfieldView manages its own state, but for this Library we can only pass a
	controlled component to customize its look. Therefore basic Textfield styles for Web have been
	copied here to account for this corner case.
*/

// TODO: Use TextfieldView instead
const ControlledWebInput = React.forwardRef((props: INativeProps, ref) => {
	const [focused, setFocused] = useState(false);

	const WebTextInputStyles = {
		outlineWidth: 0,
		borderWidth: 1,
		borderRadius: 25,
		borderColor: focused ? COLOR_CODES.Blue : COLOR_CODES.Grey10,
		borderStyle: 'solid',
		fontSize: FONT_SIZE,
		letterSpacing: -0.41,
		color: COLOR_CODES.Black,
		margin: 0,
		marginTop: 5,
		marginBottom: 5,
		padding: 5,
		paddingLeft: 15,
		paddingRight: 15,
	};
	// any Type due to: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28884
	return (
		<input
			aria-label="Handynummer"
			ref={ref as any}
			{...props}
			style={WebTextInputStyles}
			placeholder="Handynummer"
			onFocus={() => setFocused(true)}
			onBlur={() => setFocused(false)}
		/>
	);
});

export const TextfieldNewPhoneView = (props: ITextfieldPhoneProps) => {
	const phone = React.useRef<string>('');

	const handleOnChange = (text: string) => {
		phone.current = text;
		props.onChange && props.onChange(text);
	};

	React.useEffect(() => {
		props.handler &&
			props.handler({
				clear: () => {},
				isValid: () => {
					return isValidPhoneNumber(phone.current);
				},
			});
	}, [props]);

	return (
		<PhoneInput
			style={{ width: '100%', paddingLeft: 15 }}
			inputComponent={ControlledWebInput}
			placeholder={props.nativeTextInputProps?.placeholder}
			value={props.nativeTextInputProps?.value || ''}
			onChange={handleOnChange}
			defaultCountry="DE"
		/>
	);
};
