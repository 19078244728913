import React from 'react';
import styled from 'styled-components/native';
import { JUSTIFY } from './wrapper-align.view';
import {
	WrapperAlignView,
	ALIGN,
	DIRECTION,
	IWrapperAlignProps,
} from './wrapper-align.view';
import { INativeProps } from './wrapper-interfaces';

export type IWrapperPageProps = IWrapperAlignProps;

export const WrapperPageView = styled(
	(props: IWrapperPageProps & INativeProps) => {
		return (
			<WrapperAlignView
				itemsDirection={DIRECTION.Vertical}
				itemsAlign={ALIGN.Stretch}
				itemsJustify={JUSTIFY.SpaceBetween}
				{...props}
			>
				{props.children}
			</WrapperAlignView>
		);
	},
)`
	flex: 1;
	${(props: IWrapperPageProps) => ({ ...props.styles?.wrapper })}
`;
