import React from 'react';
import styled from 'styled-components/native';
import { ITextProps } from '../../../texts/text-interfaces';
import { TextView } from '../../../texts/text.view';
import { INativeProps } from '../../../wrappers/wrapper-interfaces';
import { COLOR_CODES } from '../../../enums/colors';
import { IParagraph } from '../../../../utils/language.util';

export interface ITextMessageProps {
	message: IParagraph[];
	key: string;
}

export const TextMessageTextView = styled(
	(props: ITextProps & INativeProps) => <TextView {...props} />,
)`
	font-size: 17;
	letter-spacing: -0.41;
	line-height: 20;
	color: ${props => props.color || COLOR_CODES.Grey75};
	${(props: ITextProps) => ({
		...props.styles?.text,
	})};
`;
