import React from 'react';
import styled from 'styled-components/native';

import { ALIGN } from '../../../wrappers/wrapper-align.view';
import { INativeProps } from '../../../wrappers/wrapper-interfaces';
import { WrapperListView } from '../../../wrappers/wrapper-list.view';
import {
	ILinkButtonProps,
	LinkButtonView,
} from './components/link-button.view';

export interface ILinkButtonsProps {
	buttons: ILinkButtonProps[];
	key: string;
}

export const LinkButtonsView = styled(
	(props: ILinkButtonsProps & INativeProps) => {
		return (
			<WrapperListView wrapperBetween={7} itemsAlign={ALIGN.End}>
				{props.buttons.map((button, index) => {
					return <LinkButtonView key={index} {...button} />;
				})}
			</WrapperListView>
		);
	},
)``;
