import { useImageURI } from '@nui/hooks';
import { EventTypes, useTrackEvent } from '@nui/providers';
import {
	BUTTON_ROUNDED_SIZES,
	BUTTON_ROUNDED_OPTIONS,
	ButtonRoundedView,
	ICON_CODES,
	WrapperView,
} from '@nui/views';
import React from 'react';
import FastImage from 'react-native-fast-image-xp';
import { useHistory, useParams } from 'react-router-dom';

import { stateModel } from '../../../../globals';
import { LayoutView } from '../../../views/layout.view';
import { PagePflege1x1OverviewSubcontroller } from '../components/care101-overview.controller';

interface ICare101StartControllerProps {
	courseId: string;
}

export const Care101StartController = () => {
	let { courseId } = useParams<ICare101StartControllerProps>();
	const image = stateModel.stateCoursesModel.getCourseImage(courseId);
	const imageURL = useImageURI(image);
	const history = useHistory();
	const authId = stateModel.stateAuthModel.getAuth()?.uid;
	const trackEvent = useTrackEvent();

	trackEvent(
		EventTypes.OC,
		'viewed',
		{
			inputIds: [courseId],
		},
		{ userId: authId },
	);

	return (
		<LayoutView isSignedIn={true}>
			<WrapperView height={250} width="100%">
				<ButtonRoundedView
					styles={{
						touchable: {
							position: 'absolute',
							zIndex: 2,
							top: 7,
							left: 7,
						},
					}}
					wrapper={10}
					styleOption={BUTTON_ROUNDED_OPTIONS.GrayOnWhite}
					icon={ICON_CODES.Back}
					size={BUTTON_ROUNDED_SIZES.lg}
					shadow={true}
					onClick={history.goBack}
				/>
				{imageURL && (
					<FastImage
						style={{ flex: 1 }}
						source={{ uri: imageURL.uri }}
					/>
				)}
			</WrapperView>
			<PagePflege1x1OverviewSubcontroller courseId={courseId} />
		</LayoutView>
	);
};
