import React from 'react';
import { ImageURISource, Text } from 'react-native';
import styled from 'styled-components/native';

import { ButtonView } from '../buttons/button.view';
import { COLOR_CODES } from '../enums/colors';
import { AvatarSVGTypes, AvatarView } from '../misc/avatar.view';
import { TextBodySemiboldLeftView } from '../texts/text-body-semibold-left.view';
import { TextSubheadlineDefaultView } from '../texts/text-subheadline-default.view';
import {
	ALIGN,
	DIRECTION,
	JUSTIFY,
	WrapperAlignView,
} from '../wrappers/wrapper-align.view';
import { IWrapperProps } from '../wrappers/wrapper-interfaces';

export interface IChatItem extends IWrapperProps {
	title: string;
	name?: string;
	lastMessage: string;
	datetime: string;
	unread?: number;
	avatar?: {
		src?: ImageURISource;
		default?: AvatarSVGTypes;
	};
	onClick?: () => void;
}

const ChatItemBadgeView = styled.View<{ digits: number }>`
	align-items: center;
	justify-content: center;
	width: ${props => Math.max(18, props.digits * 15)}px;
	height: 18px;
	border-radius: 9px;
	background-color: ${COLOR_CODES.Pink};
	margin-left: 10px;
`;

const ChatItemBadgeText = styled.Text`
	color: ${COLOR_CODES.White};
`;

const ChatItemBodyDescription = styled.View`
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
`;

const ChatItemBodyView = (props: IChatItem) => {
	return (
		<WrapperAlignView
			itemsDirection={DIRECTION.Vertical}
			itemsAlign={ALIGN.Start}
			itemsJustify={JUSTIFY.Start}
			styles={{
				wrapper: { flex: 1 },
			}}
			wrapperLeft={15}
		>
			<TextBodySemiboldLeftView
				nativeTextProps={{ numberOfLines: 1 }}
				color={COLOR_CODES.Black}
				wrapperRight={70}
			>
				{props.title}
			</TextBodySemiboldLeftView>
			<ChatItemBodyDescription>
				<TextSubheadlineDefaultView
					color={COLOR_CODES.SteelGrey}
					styles={{
						wrapper: { flex: 1 },
					}}
					nativeTextProps={{ numberOfLines: 2 }}
				>
					{props.name && (
						<Text style={{ fontWeight: '600' }}>
							{props.name + ': '}
						</Text>
					)}
					{props.lastMessage}
				</TextSubheadlineDefaultView>
				{/** props.unread can be 0 or false. We don't want to show the badge in both cases */}
				{!!props.unread && (
					<ChatItemBadgeView digits={props.unread.toString().length}>
						<ChatItemBadgeText>{props.unread}</ChatItemBadgeText>
					</ChatItemBadgeView>
				)}
			</ChatItemBodyDescription>
			<ChatItemDatetimeView {...props} />
		</WrapperAlignView>
	);
};

const ChatItemDatetimeView = (props: IChatItem) => {
	return (
		<TextSubheadlineDefaultView
			color={COLOR_CODES.SteelGrey}
			styles={{ wrapper: { position: 'absolute', right: 0, top: 0 } }}
		>
			{props.datetime}
		</TextSubheadlineDefaultView>
	);
};

export const ChatItemView = (props: IChatItem) => {
	return (
		<ButtonView onClick={props.onClick}>
			<WrapperAlignView
				height={82}
				itemsAlign={ALIGN.Center}
				{...props}
				wrapperRight={20}
				styles={{
					wrapper: {
						borderBottomWidth: 1,
						borderBottomColor: COLOR_CODES.Grey25,
					},
				}}
			>
				<AvatarView
					radius={28}
					image={props.avatar?.src}
					avatarType={
						(!props.avatar?.src && props.avatar?.default) ||
						undefined
					}
				/>
				<ChatItemBodyView {...props} />
			</WrapperAlignView>
		</ButtonView>
	);
};
