import React, { ReactNode } from 'react';
import { Animated, ScrollView, ScrollViewProps, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient-xp';
import styled from 'styled-components/native';

import { KeyboardSpacerView } from '../misc/keyboard-spacer.view';
import {
	ALIGN,
	DIRECTION,
	IWrapperAlignProps,
	WrapperAlignView,
} from './wrapper-align.view';
import { extractWrapperProps, INativeProps } from './wrapper-interfaces';
import { WrapperView } from './wrapper.view';

interface IWrapperControllerProps extends IWrapperAlignProps {
	scrollView?: boolean;
	nativeScrollViewProps?: ScrollViewProps;
	header?: {
		component: ReactNode;
		minHeight?: number;
		maxHeight?: number;
		onHandleOutOpacity?: (
			opacityOutAnim: Animated.AnimatedInterpolation,
		) => void;
		onHandleInOpacity?: (
			opacityInAnim: Animated.AnimatedInterpolation,
		) => void;
	};
	footer?: {
		component: ReactNode;
	};
	gradient?: boolean;
}

export const GradientView = styled((props: INativeProps) => {
	return (
		<LinearGradient colors={['#ffffff', '#ffffff00']} style={props.style} />
	);
})`
	height: 20px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
`;

const WrapperDefaultView = (props: IWrapperControllerProps & INativeProps) => {
	return (
		<WrapperAlignView
			itemsDirection={DIRECTION.Vertical}
			itemsAlign={ALIGN.Stretch}
			styles={{ wrapper: { flex: 1 } }}
		>
			{props.header?.component}
			{props.children}
			{props.footer?.component}
		</WrapperAlignView>
	);
};

const WrapperScrollviewView = (
	props: IWrapperControllerProps & INativeProps,
) => {
	return (
		<View style={{ flex: 1 }}>
			{props.header?.component}
			<View style={{ flex: 1 }}>
				<ScrollView {...props.nativeScrollViewProps}>
					{props.children}
				</ScrollView>
				{props.gradient && <GradientView />}
			</View>
			{props.footer?.component}
		</View>
	);
};

const ScrollviewAnimatedHeaderView = (
	props: IWrapperControllerProps & INativeProps,
) => {
	const statics = React.useRef({
		scrollAnim: new Animated.Value(0),
		distance: 0,
	}).current;

	React.useEffect(() => {
		if (
			props.header &&
			props.header.onHandleOutOpacity &&
			props.header.onHandleInOpacity &&
			props.header.minHeight &&
			props.header.maxHeight
		) {
			const distance = props.header.maxHeight - props.header.minHeight;
			if (distance != statics.distance) {
				statics.distance = distance;
				const headerOutOpacity = statics.scrollAnim.interpolate({
					inputRange: [0, distance / 2, distance],
					outputRange: [1, 1, 0],
					extrapolate: 'clamp',
				});
				const headerInOpacity = statics.scrollAnim.interpolate({
					inputRange: [0, distance / 2, distance],
					outputRange: [0, 0, 1],
					extrapolate: 'clamp',
				});
				props.header.onHandleOutOpacity(headerOutOpacity);
				props.header.onHandleInOpacity(headerInOpacity);
			}
		}
	}, [props.header, statics]);

	if (props.header && props.header.minHeight && props.header.maxHeight) {
		const headerHeight = statics.scrollAnim.interpolate({
			inputRange: [0, props.header.maxHeight - props.header.minHeight],
			outputRange: [props.header.maxHeight, props.header.minHeight],
			extrapolate: 'clamp',
		});
		return (
			<View style={{ flex: 1 }}>
				<ScrollView
					scrollIndicatorInsets={{ top: 1, bottom: 1 }}
					contentInsetAdjustmentBehavior="always"
					scrollEventThrottle={16}
					onScroll={Animated.event(
						[
							{
								nativeEvent: {
									contentOffset: {
										y: statics.scrollAnim,
									},
								},
							},
						],
						{ useNativeDriver: false },
					)}
					{...props.nativeScrollViewProps}
					contentContainerStyle={[
						{
							paddingTop: props.header.maxHeight,
						},
						props.nativeScrollViewProps?.contentContainerStyle,
					]}
				>
					{props.children}
				</ScrollView>
				<Animated.View
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
						backgroundColor: 'white',
						height: headerHeight,
					}}
				>
					{props.header.component}
				</Animated.View>
			</View>
		);
	} else {
		return null;
	}
};

export const WrapperControllerView = (
	props: IWrapperControllerProps & INativeProps,
) => {
	const wrapperProps = extractWrapperProps(props);
	return (
		<WrapperView
			{...wrapperProps}
			styles={{
				...props.styles,
				wrapper: {
					flex: 1,
					...props.styles?.wrapper,
				},
			}}
			wrapperBottom={props.wrapperBottom || 0}
			wrapperTop={props.wrapperTop || 0}
		>
			{props.scrollView
				? props.header?.minHeight && props.header?.maxHeight
					? ScrollviewAnimatedHeaderView(props)
					: WrapperScrollviewView(props)
				: WrapperDefaultView(props)}
			<KeyboardSpacerView />
		</WrapperView>
	);
};
