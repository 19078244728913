import { firebase } from 'firebase-xp';

import * as configFile from 'env-config.json';

export enum CloudFunction {
	validateInitialPurchase = 'validateInitialPurchase',
	notify = 'notify',
	inviteToTeam = 'inviteToTeam',
	createTeam = 'createTeam',
}

/**
 * In order to reduce cold starts, we send an initial request to spawn an instance of the function
 * This won't be necessary once we have more users.
 * @param funcName The Cloud function to 'warm up'
 */
export const preheatCloudFunction = (funcName: CloudFunction) => {
	fetchPost(funcName, { preHeat: true });
};

export const fetchPost = async (funcName: CloudFunction, body: object) => {
	try {
		const idToken = await firebase.auth().currentUser?.getIdToken();
		const response = await fetch(
			configFile.cloudFunctionBaseURL + funcName,
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${idToken}`,
				},
				body: JSON.stringify(body),
			},
		);
		if (response.ok) {
			if (response.status === 204) {
				return Promise.resolve();
			} else {
				try {
					const parsed = await response.json();
					return Promise.resolve(parsed);
				} catch {
					// If the server does not reply with a body, parsing will fail
					return Promise.resolve();
				}
			}
		} else {
			return Promise.reject(response);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};
