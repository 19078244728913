import 'react-phone-number-input/style.css';

import React from 'react';
import PhoneInput from 'react-phone-number-input';

import { COLOR_CODES } from '../../enums/colors';
import { INativeProps } from '../../wrappers/wrapper-interfaces';
import { FONT_SIZE } from '../textfield.view';
import { ITextfieldPhoneProps } from './textfield-phone.view';

/* 
	Our default TextfieldView manages its own state, but for this Library we can only pass a
	controlled component to customize its look. Therefore basic Textfield styles for Web have been
	copied here to account for this corner case.
*/
const ControlledWebInput = React.forwardRef((props: INativeProps, ref) => {
	const WebTextInputStyles = {
		fontSize: FONT_SIZE,
		letterSpacing: -0.41,
		color: COLOR_CODES.Black,
		margin: 0,
		marginTop: 5,
		marginBottom: 5,
		borderWidth: 0,
		borderBottomWidth: 1,
		borderColor: COLOR_CODES.Grey25,
		padding: 1,
	};
	// any Type due to: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28884
	return (
		<input
			aria-label="Handynummer"
			ref={ref as any}
			{...props}
			style={WebTextInputStyles}
			placeholder="Handynummer"
		/>
	);
});

export const TextfieldPhoneView = (props: ITextfieldPhoneProps) => {
	const handleOnChange = (text?: string) => {
		props.onChange && props.onChange(text || '');
	};

	return (
		<PhoneInput
			inputComponent={ControlledWebInput}
			placeholder={props.nativeTextInputProps?.placeholder}
			value={props.nativeTextInputProps?.value || ''}
			onChange={handleOnChange}
			defaultCountry="DE"
		/>
	);
};
