import React from 'react';
import { TextTitleTitle2View } from '../texts/text-title-title2.view';
import {
	extractWrapperProps,
	IWrapperProps,
} from '../wrappers/wrapper-interfaces';
import { WrapperView } from '../wrappers/wrapper.view';
import { BigCardView, IBigCardProps } from './bigCard.view';

export interface IBigCardWrapperProps extends IWrapperProps {
	title: string;
	card: IBigCardProps;
}

export const BigCardWrapperView = (props: IBigCardWrapperProps) => {
	const { title, card } = props;
	const wrapperProps = extractWrapperProps(props);

	return (
		<WrapperView {...wrapperProps} wrapper={20} wrapperTop={30}>
			<TextTitleTitle2View
				styles={{
					wrapper: {
						marginBottom: 18,
					},
				}}
			>
				{title}
			</TextTitleTitle2View>
			<BigCardView {...card} />
		</WrapperView>
	);
};
