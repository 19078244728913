import React from 'react';

import { ALIGN, DIRECTION, JUSTIFY } from '../../wrappers/wrapper-align.view';
import {
	extractWrapperProps,
	INativeProps,
	IWrapperProps,
} from '../../wrappers/wrapper-interfaces';
import { WrapperListView } from '../../wrappers/wrapper-list.view';
import { TextMessageRightView } from '../types/text-message/text-message-right.view';
import { AssistantBubbleRightView } from './assistant-bubble-right.view';
import {
	AssistantItemView,
	IAssistantItem,
	isAssistantItemTextMessage,
} from './assistant-item.view';

export interface AssistantRightProps extends IWrapperProps {
	items: IAssistantItem[];
}

const renderItems = (props: AssistantRightProps) => {
	return props.items
		.map((item, index) => {
			if (isAssistantItemTextMessage(item)) {
				return (
					<AssistantItemView
						onFinished={item.onFinished}
						key={item.key}
					>
						<AssistantBubbleRightView
							isLast={index == props.items.length - 1}
						>
							<TextMessageRightView {...item} />
						</AssistantBubbleRightView>
					</AssistantItemView>
				);
			} else {
				return null;
			}
		})
		.filter(item => !!item);
};

export const AssistantRightView = (
	props: AssistantRightProps & INativeProps,
) => {
	return (
		<WrapperListView
			itemsDirection={DIRECTION.Vertical}
			itemsAlign={ALIGN.End}
			itemsJustify={JUSTIFY.End}
			wrapperBetween={5}
			{...extractWrapperProps(props)}
		>
			{renderItems(props)}
		</WrapperListView>
	);
};
