import React from 'react';
import { Text } from 'react-native';
import styled from 'styled-components/native';
import { HtmlBoldView } from './html/html-bold.view';
import { HtmlH1View } from './html/html-h1.view';
import { HtmlH2View } from './html/html-h2.view';
import { HtmlH3View } from './html/html-h3.view';
import { HtmlImgView } from './html/html-img.view';
import { HtmlItalicView } from './html/html-italic.view';
import { HtmlListView } from './html/html-list.view';
import { HtmlSemiboldView } from './html/html-semibold.view';
import { HtmlSpanView } from './html/html-span.view';
import { HtmlTableView } from './html/html-table.view';
import { HtmlUnderlineView } from './html/html-underline.view';
import { HtmlUrlView } from './html/html-url.view';
import {
	extractTextProps,
	ITextProps,
	FONT_WEIGHTS,
	TEXT_ALIGN,
} from './text-interfaces';
import {
	INativeProps,
	extractWrapperProps,
} from '../wrappers/wrapper-interfaces';
import { WrapperView } from '../wrappers/wrapper.view';
import { COLOR_CODES } from '../enums/colors';
import { BadgeSmallView } from '../misc/badge-small.view';

export const TextView = styled((props: ITextProps & INativeProps) => {
	const wrapperProps = extractWrapperProps(props);
	const textProps = extractTextProps(props);

	if (props.htmlChunks) {
		const handleClick = clickName => {
			props.attributes?.onClick && props.attributes.onClick[clickName]();
		};

		const blocks: any = [];
		let inlineBlock: any = [];
		let listBlock: any = [];
		const flushInlineBlock = (index?: string) => {
			if (inlineBlock.length > 0) {
				blocks.push(
					<Text
						key={'inlineblock_' + index}
						{...textProps.nativeTextProps}
						style={props.style}
						{...props.nativeTextProps}
					>
						{inlineBlock}
					</Text>,
				);
				inlineBlock = [];
			}
		};
		const flushListBlock = (index?: string) => {
			if (listBlock.length > 0) {
				blocks.push(
					<WrapperView
						wrapperTop={5}
						wrapperBottom={5}
						key={'listblock_' + index}
					>
						{listBlock}
					</WrapperView>,
				);
				listBlock = [];
			}
		};
		for (const index in props.htmlChunks) {
			const chunk = props.htmlChunks[index];

			if (chunk.li) {
				flushInlineBlock(index);
				const iconName = chunk.li.attributes?.icon;
				const weight = chunk.li.attributes?.weight;
				listBlock.push(
					<HtmlListView
						key={'block_' + index}
						{...textProps}
						style={props.style}
						icon={iconName}
						extraStyles={{
							fontWeight: weight && FONT_WEIGHTS[weight],
						}}
					>
						{chunk.li.text.trim()}
					</HtmlListView>,
				);
			} else if (chunk.h1) {
				flushInlineBlock(index);
				flushListBlock(index);
				const clickName = chunk.h1.attributes?.onClick;
				const weight = chunk.h1.attributes?.weight;
				blocks.push(
					<HtmlH1View
						key={'block_' + index}
						{...textProps}
						onClick={
							(clickName && (() => handleClick(clickName))) ||
							undefined
						}
						extraStyles={{
							fontWeight: weight && FONT_WEIGHTS[weight],
						}}
					>
						{chunk.h1.text.trim()}
					</HtmlH1View>,
				);
			} else if (chunk.h2) {
				flushInlineBlock(index);
				flushListBlock(index);
				const clickName = chunk.h2.attributes?.onClick;
				const weight = chunk.h2.attributes?.weight;
				blocks.push(
					<HtmlH2View
						key={'block_' + index}
						{...textProps}
						onClick={
							(clickName && (() => handleClick(clickName))) ||
							undefined
						}
						extraStyles={{
							fontWeight: weight && FONT_WEIGHTS[weight],
						}}
					>
						{chunk.h2.text.trim()}
					</HtmlH2View>,
				);
			} else if (chunk.h3) {
				flushInlineBlock(index);
				flushListBlock(index);
				const clickName = chunk.h3.attributes?.onClick;
				const weight = chunk.h3.attributes?.weight;
				blocks.push(
					<HtmlH3View
						key={'block_' + index}
						{...textProps}
						onClick={
							(clickName && (() => handleClick(clickName))) ||
							undefined
						}
						extraStyles={{
							fontWeight: weight && FONT_WEIGHTS[weight],
						}}
					>
						{chunk.h3.text.trim()}
					</HtmlH3View>,
				);
			} else if (chunk.img) {
				flushInlineBlock(index);
				flushListBlock(index);
				const getImage = props.getImageFun;
				if (getImage && props.width) {
					const width = parseInt(
						chunk.img.attributes?.width || '300',
						10,
					);
					const height = parseInt(
						chunk.img.attributes?.height || '200',
						10,
					);
					const scale =
						((parseInt(chunk.img.attributes?.scale || '90', 10) /
							100) *
							parseInt(props.width.toString(), 10) -
							(props.wrapperLeft || 0) -
							(props.wrapperRight || 0)) /
						width;
					const align =
						chunk.img.attributes?.align == 'center'
							? 'center'
							: chunk.img.attributes?.align == 'right'
							? 'flex-end'
							: 'flex-start';
					blocks.push(
						<HtmlImgView
							key={'block_' + index}
							image={getImage(chunk.img.text.trim())}
							extraStyles={{
								width: width * scale,
								height: height * scale,
								alignSelf: align,
							}}
						/>,
					);
				}
			} else if (chunk.table) {
				flushInlineBlock(index);
				flushListBlock(index);
				const data = chunk.table.attributes?.data;
				if (data) {
					blocks.push(
						<HtmlTableView
							key={'block_' + index}
							data={data
								.split(';')
								.map(row =>
									row.split(',').map(col => col.trim()),
								)}
						/>,
					);
				}
			} else if (chunk.b) {
				flushListBlock(index);
				const clickName = chunk.b.attributes?.onClick;
				const weight = chunk.b.attributes?.weight;
				inlineBlock.push(
					<HtmlBoldView
						key={'inline_' + index}
						{...textProps}
						onClick={
							(clickName && (() => handleClick(clickName))) ||
							undefined
						}
						extraStyles={{
							fontWeight: weight && FONT_WEIGHTS[weight],
						}}
					>
						{chunk.b.text}
					</HtmlBoldView>,
				);
			} else if (chunk.sb) {
				flushListBlock(index);
				const clickName = chunk.sb.attributes?.onClick;
				const weight = chunk.sb.attributes?.weight;
				inlineBlock.push(
					<HtmlSemiboldView
						key={'inline_' + index}
						{...textProps}
						onClick={
							(clickName && (() => handleClick(clickName))) ||
							undefined
						}
						extraStyles={{
							fontWeight: weight && FONT_WEIGHTS[weight],
						}}
					>
						{chunk.sb.text}
					</HtmlSemiboldView>,
				);
			} else if (chunk.span) {
				flushListBlock(index);
				const clickName = chunk.span.attributes?.onClick;
				const weight = chunk.span.attributes?.weight;
				inlineBlock.push(
					<HtmlSpanView
						key={'inline_' + index}
						{...textProps}
						onClick={
							(clickName && (() => handleClick(clickName))) ||
							undefined
						}
						extraStyles={{
							fontWeight: weight && FONT_WEIGHTS[weight],
						}}
					>
						{chunk.span.text}
					</HtmlSpanView>,
				);
			} else if (chunk.u) {
				flushListBlock(index);
				const clickName = chunk.u.attributes?.onClick;
				const weight = chunk.u.attributes?.weight;
				inlineBlock.push(
					<HtmlUnderlineView
						key={'inline_' + index}
						{...textProps}
						onClick={
							(clickName && (() => handleClick(clickName))) ||
							undefined
						}
						extraStyles={{
							fontWeight: weight && FONT_WEIGHTS[weight],
						}}
					>
						{chunk.u.text}
					</HtmlUnderlineView>,
				);
			} else if (chunk.i) {
				flushListBlock(index);
				const clickName = chunk.i.attributes?.onClick;
				const weight = chunk.i.attributes?.weight;
				inlineBlock.push(
					<HtmlItalicView
						key={'inline_' + index}
						{...textProps}
						onClick={
							(clickName && (() => handleClick(clickName))) ||
							undefined
						}
						extraStyles={{
							fontWeight: weight && FONT_WEIGHTS[weight],
						}}
					>
						{chunk.i.text}
					</HtmlItalicView>,
				);
			} else if (chunk.url) {
				flushListBlock(index);
				inlineBlock.push(
					<HtmlUrlView
						key={'block_' + index}
						{...textProps}
						url={chunk.url.attributes?.url}
					>
						{chunk.url.text.trim()}
					</HtmlUrlView>,
				);
			}
		}
		flushInlineBlock();
		flushListBlock();

		return <WrapperView {...wrapperProps}>{blocks}</WrapperView>;
	} else {
		return (
			<WrapperView {...wrapperProps}>
				<Text {...textProps.nativeTextProps} style={props.style}>
					{props.children}
				</Text>
				{props.badge && <BadgeSmallView {...props.badge} />}
			</WrapperView>
		);
	}
})`
	color: ${(props: ITextProps) => props.color || COLOR_CODES.Black};
	text-align: ${(props: ITextProps) => props.textAlign || TEXT_ALIGN.left};
	font-weight: ${(props: ITextProps) => props.weight || FONT_WEIGHTS.regular};
`;
