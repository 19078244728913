import { firebase } from 'firebase-xp';
import { ImageURISource } from 'react-native';
import XDate from 'xdate';
import { StorageProvider } from '@nui/providers';
import { ConvertersUtil, HelpersUtil } from '@nui/utils';
import ImageResizer from 'react-native-image-resizer';

interface IImage {
	small: ImageURISource;
	large: ImageURISource;
}

export enum IMAGE_SIZES {
	Small = 'small',
	Large = 'large',
}
export const IMAGE_DIMENSION = {
	small: {
		maxHeight: 500,
		maxWidth: 500,
		quality: 90,
	},
	large: {
		maxHeight: 2250,
		maxWidth: 2250,
		quality: 100,
	},
};

export interface IFirestoreChatMessage {
	created: XDate;
	updated: XDate;
	text?: string;
	image?: IImage;
	senderId: string;
	recipientId: string;
	hasSeen?: boolean;
}

export class FirestoreChatMessageModel {
	// Get data from DB
	static toChatMessage(
		chatMessageDTO: any,
		defaultXDate = new XDate(),
	): IFirestoreChatMessage | undefined {
		const imageSmall = ConvertersUtil.toImageSource(
			chatMessageDTO?.image,
			ConvertersUtil.toImageSource(
				chatMessageDTO?.image?.small,
				ConvertersUtil.toImageSource(chatMessageDTO?.image?.large),
			),
		);

		const imageLarge = ConvertersUtil.toImageSource(
			chatMessageDTO?.image,
			ConvertersUtil.toImageSource(
				chatMessageDTO?.image?.large,
				ConvertersUtil.toImageSource(chatMessageDTO?.image?.small),
			),
		);

		const text = ConvertersUtil.toString(chatMessageDTO?.text);
		const senderId = ConvertersUtil.toString(chatMessageDTO?.senderId);
		const recipientId = ConvertersUtil.toString(
			chatMessageDTO?.recipientId,
		);

		if (senderId && recipientId) {
			return {
				created:
					ConvertersUtil.toXDate(chatMessageDTO?.created) ||
					defaultXDate,
				updated:
					ConvertersUtil.toXDate(chatMessageDTO?.updated) ||
					defaultXDate,
				senderId,
				recipientId,
				hasSeen: ConvertersUtil.toBool(chatMessageDTO.hasSeen),
				text,
				image:
					(imageSmall &&
						imageLarge && {
							small: {
								uri: imageSmall.uri,
								cache: 'force-cache',
							},
							large: {
								uri: imageLarge.uri,
								cache: 'force-cache',
							},
						}) ||
					undefined,
			};
		} else {
			return undefined;
		}
	}

	static async createAndSaveChatMessage(
		userId: string,
		chatRoomId: string,
		image?,
		text?: string,
	): Promise<string> {
		const collectionReference = firebase
			.firestore()
			.collection('chat-messages');

		const chatMessage = {
			created: firebase.firestore.Timestamp.fromDate(new Date()),
			updated: firebase.firestore.Timestamp.fromDate(new Date()),
			senderId: userId,
			recipientId: chatRoomId,
			hasSeen: false,
		};

		try {
			if (image && image.uri) {
				const smallResizedImage = await ImageResizer.createResizedImage(
					image.uri,
					IMAGE_DIMENSION.small.maxWidth,
					IMAGE_DIMENSION.small.maxHeight,
					'JPEG',
					IMAGE_DIMENSION.small.quality,
				);

				const largeResizedImage = await ImageResizer.createResizedImage(
					image.uri,
					IMAGE_DIMENSION.small.maxWidth,
					IMAGE_DIMENSION.small.maxHeight,
					'JPEG',
					IMAGE_DIMENSION.small.quality,
				);

				// Upload to storage
				const downloadURLSmall = await StorageProvider.uploadImage(
					smallResizedImage,
					'chat-message-from/' +
						userId +
						'/chat-message-to/' +
						chatRoomId +
						'/' +
						HelpersUtil.guid() +
						'_small',
				);

				const downloadURLLarge = await StorageProvider.uploadImage(
					largeResizedImage,
					'chat-message-from/' +
						userId +
						'/chat-message-to/' +
						chatRoomId +
						'/' +
						HelpersUtil.guid() +
						'_large',
				);

				Object.assign(chatMessage, {
					image:
						(downloadURLSmall &&
							downloadURLLarge && {
								small: downloadURLSmall,
								large: downloadURLLarge,
							}) ||
						undefined,
				});
			} else {
				Object.assign(chatMessage, {
					text,
				});
			}

			const chatMessageDocument = await collectionReference.add(
				chatMessage,
			);

			return chatMessageDocument.id;
		} catch (error) {
			return Promise.reject('Some error');
		}
	}
}
