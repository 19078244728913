import { ImageURISource } from 'react-native';
import XDate from 'xdate';

import { IContent, SUPPORTED_LANGUAGE_CODES } from './language.util';
import { firebase } from 'firebase-xp';

export const DEFAULT_CONTENT: IContent = {
	de: '',
	en: '',
};
export class ConvertersUtil {
	static toPrintable(item: any): string {
		if (Array.isArray(item)) {
			return JSON.stringify(item);
		} else if (typeof item === 'object') {
			return JSON.stringify(item);
		}
		return String(item);
	}

	static toLanguageCode(
		item: any,
		def?: SUPPORTED_LANGUAGE_CODES,
	): SUPPORTED_LANGUAGE_CODES | undefined {
		return SUPPORTED_LANGUAGE_CODES[item] || def;
	}

	static toImageSource(
		item: any,
		def?: ImageURISource,
	): ImageURISource | undefined {
		if (item && item.toBase64) {
			// The correct mime type should be determined from /9j/ etc.
			return {
				uri: 'data:image/jpeg;base64,' + item.toBase64(),
			};
		}
		if (typeof item === 'string') {
			let source;
			// TODO: Implement other image formats
			if (item.startsWith('/9j/')) {
				source = {
					uri: 'data:image/jpeg;base64,' + item,
				};
			} else {
				switch (item) {
					case 'nui': {
						source = require('./../../../assets/images/avatar-large.png');
						break;
					}
					case 'avatar1': {
						source = require('./../../../assets/images/avatar1.jpg');
						break;
					}
					case 'avatar2': {
						source = require('./../../../assets/images/avatar2.jpg');
						break;
					}
					case 'avatar3': {
						source = require('./../../../assets/images/avatar3.jpg');
						break;
					}
					default: {
						source = {
							uri: item,
						};
					}
				}
			}
			return source;
		}
		if (item?.uri && typeof item.uri === 'string') {
			if (item?.uri && item.uri.startsWith('data:image/')) {
				return {
					uri: item.uri,
				};
			} else {
				// The correct mime type should be determined from /9j/ etc.
				return {
					uri: 'data:image/jpeg;base64,' + item.uri,
				};
			}
		}
		return def;
	}

	static toEnum(items, item, def?) {
		for (const i in items) {
			if (items[i] === item) {
				return item;
			}
		}
		return def;
	}

	static toContent(item: any, def?: IContent): IContent | undefined {
		if (item) {
			if (typeof item === 'string') {
				const ret: any = {};
				for (const code in SUPPORTED_LANGUAGE_CODES) {
					ret[code] = item;
				}
				return ret;
			} else if (typeof item === 'object') {
				const ret: any = {};
				for (const code in SUPPORTED_LANGUAGE_CODES) {
					if (item[code] == undefined) {
						ret[code] = 'not translated';
					} else {
						ret[code] = item[code];
					}
				}
				return ret;
			}
		} else {
			return def;
		}
	}

	static toXDate(item: any, def?: XDate): XDate | undefined {
		if (item) {
			if (item instanceof firebase.firestore.Timestamp) {
				return new XDate(item.toDate());
			} else if (item instanceof XDate) {
				return item;
			} else if (item instanceof Date) {
				return new XDate(item);
			} else if (typeof item === 'string' && !isNaN(parseInt(item, 10))) {
				return new XDate(ConvertersUtil.toNumber(item) || 0);
			} else if (typeof item === 'string' && Date.parse(item)) {
				return new XDate(item);
			} else if (typeof item === 'number' && !isNaN(item)) {
				return new XDate(item);
			}
		} else {
			return def;
		}
		return def;
	}

	static toString(
		item: any,
		def?: string,
		minLength?: number,
	): string | undefined {
		if (item === true) {
			return 'true';
		} else if (item === false) {
			return 'false';
		} else if (item === '') {
			if (!minLength) {
				return item;
			}
			return def;
		} else if (item === 0) {
			if (!minLength || minLength <= 1) {
				return '0';
			}
			return def;
		} else if (Array.isArray(item)) {
			return def;
		} else if (typeof item === 'object') {
			return def;
		} else if (!item) {
			return def;
		}
		const stringItem = String(item);
		if (!minLength || stringItem.length >= minLength) {
			return stringItem;
		}
		return def;
	}

	static toBool(item: any): boolean {
		if (Array.isArray(item) && item.length == 0) {
			item = false;
		} else if (
			item &&
			typeof item === 'object' &&
			item.constructor === {}.constructor &&
			Object.keys(item).length == 0
		) {
			item = false;
		}
		return Boolean(item);
	}

	static toNumber(item: any, def?: number): number | undefined {
		if (item === true) {
			return 1;
		} else if (item === false) {
			return 0;
		} else if (item === 0) {
			return 0;
		} else if (Array.isArray(item)) {
			return def;
		} else if (!item) {
			return def;
		} else {
			item = Number(item);
			if (isNaN(item)) {
				item = def;
			}
		}

		return item;
	}

	static toArray<Output>(
		item: any,
		def?: Output[],
		minLength = 0,
		iterator?: (elem: any, index: number) => Output | undefined,
	): Output[] | undefined {
		const obj = ConvertersUtil.toHashtable<Output>(item);
		if (obj != undefined) {
			item = [];
			for (const key in Object.keys(obj).sort()) {
				obj[key] && item.push(obj[key]);
			}
		}
		if (!Array.isArray(item)) {
			return def;
		}
		if (item.length < minLength) {
			return def;
		}
		if (iterator) {
			const filteredOutput: Output[] = [];
			for (let i = 0; i < item.length; i++) {
				const filteredItem = iterator(item[i], i);
				if (filteredItem !== undefined) {
					filteredOutput.push(filteredItem);
				}
			}
			return filteredOutput;
		} else {
			return item;
		}
	}

	static toHashtable<Output>(
		item: any,
		def?: { [key: string]: Output },
		minLength = 0,
		iterator?: (item: any, key: string) => Output | undefined,
	): { [key: string]: Output } | undefined {
		if (item) {
			if (Array.isArray(item)) {
				return def;
			} else if (typeof item !== 'object') {
				return def;
			} else if (
				Object.keys(item).length === 0 &&
				JSON.stringify(item) !== '{}'
			) {
				return def;
			} else if (Object.keys(item).length < minLength) {
				return def;
			}
		} else {
			return def;
		}

		if (iterator) {
			const filteredOutput = {};
			for (const key in item) {
				const filteredItem = iterator(item[key], key);
				if (filteredItem !== undefined) {
					filteredOutput[key] = filteredItem;
				}
			}
			return filteredOutput;
		} else {
			return item;
		}
	}
}
