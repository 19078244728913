import { firebase } from 'firebase-xp';

import { FirebaseDatabaseTypes } from '@react-native-firebase/database';

type IInitialized = (entityId?: string, entityDTO?: object) => void;
type IModifed = (entityId?: string, entityDTO?: object) => void;
type IFailed = (error: Error) => void;
type IRejected = (error: Error) => void;

class DatabaseDocumentObserverEvents {
	protected handleInitialized?: IInitialized;
	protected handleModified?: IModifed;
	protected handleFailed?: IFailed;
	protected handleRejected?: IRejected;

	constructor(
		promiseHandler: (
			initialized: IInitialized,
			modified: IModifed,
			failed: IFailed,
			rejected: IRejected,
		) => void,
	) {
		this.onInitialized = this.onInitialized.bind(this);
		this.onModified = this.onModified.bind(this);
		this.onFailed = this.onFailed.bind(this);
		promiseHandler(
			this.onInitialized,
			this.onModified,
			this.onFailed,
			this.onRejected,
		);
	}

	public initialized(
		handleInitialized: IInitialized,
	): DatabaseDocumentObserverEvents {
		this.handleInitialized = handleInitialized;
		return this;
	}

	public modified(handleModified: IModifed): DatabaseDocumentObserverEvents {
		this.handleModified = handleModified;
		return this;
	}

	public failed(handleFailed: IFailed): DatabaseDocumentObserverEvents {
		this.handleFailed = handleFailed;
		return this;
	}

	public rejected(handleRejected: IRejected): DatabaseDocumentObserverEvents {
		this.handleRejected = handleRejected;
		return this;
	}

	protected onInitialized(entityId?: string, entityDTO?: object): void {
		if (this.handleInitialized) {
			this.handleInitialized(entityId, entityDTO);
		}
	}

	protected onModified(entityId?: string, entityDTO?: object): void {
		if (this.handleModified) {
			this.handleModified(entityId, entityDTO);
		}
	}

	protected onFailed(error: Error): void {
		if (this.handleFailed) {
			this.handleFailed(error);
		}
	}

	protected onRejected(error: Error): void {
		if (this.handleRejected) {
			this.handleRejected(error);
		}
	}
}

export class DatabaseDocumentProvider {
	protected documentReference: FirebaseDatabaseTypes.Reference;
	protected path: string;

	constructor(path: string) {
		this.path = path;
		this.documentReference = firebase.database().ref(path);
	}

	public unsubscribeObserver() {
		this.documentReference.off();
	}

	public subscribeObserver() {
		this.unsubscribeObserver();
		let initialize = true;
		return new DatabaseDocumentObserverEvents(
			async (initialized, modified, failed, rejected) => {
				this.documentReference.on(
					'value',
					snapshot => {
						if (initialize) {
							if (snapshot && snapshot.key) {
								initialized(snapshot.key, snapshot.val());
							} else {
								initialized();
							}
							initialize = false;
						} else {
							if (snapshot && snapshot.key) {
								modified(snapshot.key, snapshot.val());
							} else {
								modified();
							}
						}
					},
					error => {
						if (initialize) {
							failed(error);
							initialize = false;
						} else {
							rejected(error);
						}
						this.unsubscribeObserver();
					},
				);
			},
		);
	}

	update(path: string, doc: object) {
		return this.documentReference.child(path).update(doc);
	}

	set(path: string, doc: object) {
		return this.documentReference.child(path).set(doc);
	}

	remove(path: string) {
		return this.documentReference.child(path).remove();
	}
}
