import React from 'react';
import { View, LayoutAnimation, UIManager } from 'react-native';
import styled from 'styled-components/native';
import { COLOR_CODES } from '../enums/colors';
import { TextButtonView } from '../texts/text-button.view';
import { ButtonView, IButtonProps, IButtonStyles } from './button.view';
import LottieView from 'lottie-xp';
import { TextEntryStateSemiboldView } from '../texts/text-entryState-semibold.view';
import { INativeProps } from '../wrappers/wrapper-interfaces';
import { ICON_CODES, TextIconView, ICON_SIZES } from '../texts/text-icon.view';
import { HelpersUtil } from '../../utils/helpers.util';

UIManager.setLayoutAnimationEnabledExperimental &&
	UIManager.setLayoutAnimationEnabledExperimental(true);

export interface IButtonPrimaryStyles extends IButtonStyles {
	container?: object;
	label?: object;
	icon?: object;
}

export interface IButtonPrimaryProps extends IButtonProps {
	label?: string;
	iconBefore?: ICON_CODES;
	iconAfter?: ICON_CODES;
	styles?: IButtonPrimaryStyles;
	theme?: BUTTON_PRIMARY_THEMES;
	rounded?: boolean;
	animatedDots?: boolean;
	small?: boolean;
	size?: ICON_SIZES;
	animated?: boolean;
	autosize?: boolean;
}

export enum BUTTON_PRIMARY_THEMES {
	Pink = 'Pink',
	Blue = 'Blue',
	Grey = 'GreyishBrown',
}

const ButtonPrimaryContainer = styled(
	(props: IButtonPrimaryProps & INativeProps) => (
		<View style={props.style}>{props.children}</View>
	),
)`
	height: ${(props: IButtonPrimaryProps) => (props.small ? 30 : 50)};
	justify-content: center;
	align-items: center;
	flex-direction: row;
	border-radius: ${(props: IButtonPrimaryProps) =>
		props.rounded && props.small ? 30 : props.rounded ? 50 : 10};
	padding-right: ${(props: IButtonPrimaryProps) => (props.small ? 25 : 15)};
	padding-left: ${(props: IButtonPrimaryProps) => (props.small ? 25 : 15)};
	background-color: ${(props: IButtonPrimaryProps) =>
		(props.onClick &&
			((props.theme && COLOR_CODES[props.theme]) || COLOR_CODES.Pink)) ||
		COLOR_CODES.Grey50};
	${(props: IButtonPrimaryProps) => ({
		...props.styles?.container,
	})};
`;

interface IAnimationState {
	opacity: number;
	label?: string;
	iconBefore?: ICON_CODES;
	iconAfter?: ICON_CODES;
}

export const ButtonPrimaryView = (
	props: IButtonPrimaryProps & INativeProps,
) => {
	const statics = React.useRef<{
		animation?: any;
	}>({}).current;

	const {
		label,
		iconBefore,
		iconAfter,
		small,
		animated,
		animatedDots,
		size,
		autosize,
		...buttonProps
	} = props;

	const [state, setState] = React.useState<IAnimationState>({
		opacity: animated && HelpersUtil.isIOS() ? 0 : 1,
		label,
		iconBefore,
		iconAfter,
	});

	const DURATION = 200;

	const animationConfiguration = {
		duration: DURATION,
		create: {
			type: LayoutAnimation.Types.easeInEaseOut,
			property: 'opacity',
		},
		update: {
			type: LayoutAnimation.Types.easeInEaseOut,
			property: 'scaleXY',
		},
		delete: {
			type: LayoutAnimation.Types.easeInEaseOut,
			property: 'opacity',
		},
	};

	React.useLayoutEffect(() => {
		if (label != state.label) {
			if (animated && HelpersUtil.isIOS()) {
				setState(() => ({
					opacity: 0,
					label,
					iconBefore,
					iconAfter,
				}));
				setTimeout(() => {
					LayoutAnimation.configureNext(animationConfiguration);
					setState(s => ({
						opacity: 1,
						label: s.label,
						iconBefore: s.iconBefore,
						iconAfter: s.iconAfter,
					}));
				}, DURATION);
			} else {
				setState(() => ({
					opacity: 1,
					label,
					iconBefore,
					iconAfter,
				}));
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [label]);

	React.useEffect(() => {
		if (animated && HelpersUtil.isIOS()) {
			LayoutAnimation.easeInEaseOut();
			setState(s => ({
				opacity: 1,
				label: s.label,
				iconBefore: s.iconBefore,
				iconAfter: s.iconAfter,
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (animatedDots) {
		return (
			<ButtonView
				{...buttonProps}
				styles={{
					...buttonProps.styles,
					wrapper: {
						alignItems: autosize ? 'center' : 'stretch',
						...buttonProps.styles?.wrapper,
					},
				}}
			>
				<ButtonPrimaryContainer {...props}>
					<View style={{ width: 40 }}>
						<LottieView
							style={{ width: 40 }}
							loop={true}
							autoPlay={true}
							ref={animation => {
								statics.animation = animation;
							}}
							source={require('./assets/whitedots.json')}
						/>
					</View>
				</ButtonPrimaryContainer>
			</ButtonView>
		);
	} else {
		return (
			<ButtonView
				{...buttonProps}
				styles={{
					...buttonProps.styles,
					wrapper: {
						alignItems: autosize ? 'center' : 'stretch',
						...buttonProps.styles?.wrapper,
					},
				}}
			>
				<ButtonPrimaryContainer {...props}>
					{(state.iconBefore && (
						<TextIconView
							size={size || ICON_SIZES.lg}
							color={COLOR_CODES.White}
							icon={state.iconBefore}
							wrapperRight={3}
							styles={{
								text: {
									...props.styles?.icon,
									opacity: state.opacity,
								},
							}}
						/>
					)) ||
						null}
					{(label && small && (
						<TextEntryStateSemiboldView
							color={COLOR_CODES.White}
							styles={{
								text: {
									...props.styles?.label,
									opacity: state.opacity,
								},
							}}
						>
							{state.label}
						</TextEntryStateSemiboldView>
					)) ||
						(label && (
							<TextButtonView
								color={COLOR_CODES.White}
								styles={{
									text: {
										...props.styles?.label,
										opacity: state.opacity,
									},
								}}
							>
								{state.label}
							</TextButtonView>
						)) ||
						null}
					{(state.iconAfter && (
						<TextIconView
							size={size || ICON_SIZES.lg}
							color={COLOR_CODES.White}
							icon={state.iconAfter}
							wrapperLeft={3}
							styles={{
								text: {
									...props.styles?.icon,
									opacity: state.opacity,
								},
							}}
						/>
					)) ||
						null}
				</ButtonPrimaryContainer>
			</ButtonView>
		);
	}
};
