import * as React from 'react';
import { Animated } from 'react-native';

const DURATION = 150;

export const useCollpase = (
	collapsed: boolean,
	height: number,
	onFinished?: () => void,
) => {
	const heightAnimation = React.useRef(
		new Animated.Value(collapsed ? 0 : 500),
	).current;
	const opacityAnimation = React.useRef(new Animated.Value(collapsed ? 0 : 1))
		.current;

	React.useLayoutEffect(() => {
		if (height > 0) {
			if (collapsed) {
				Animated.timing(opacityAnimation, {
					toValue: 0,
					duration: DURATION,
					useNativeDriver: true,
				}).start(() => {
					Animated.timing(heightAnimation, {
						toValue: 0,
						duration: DURATION,
						useNativeDriver: true,
					}).start(() => {
						onFinished && onFinished();
					});
				});
			} else {
				Animated.timing(heightAnimation, {
					toValue: height,
					duration: DURATION,
					useNativeDriver: true,
				}).start(() => {
					Animated.timing(opacityAnimation, {
						toValue: 1,
						duration: DURATION,
						useNativeDriver: true,
					}).start(() => {
						onFinished && onFinished();
					});
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collapsed, height]);

	return { heightAnimation, opacityAnimation };
};
