import React from 'react';
import styled from 'styled-components/native';
import { Text } from 'react-native';
import { INativeProps, IStyles } from '../../wrappers/wrapper-interfaces';
import { FONT_WEIGHTS, ITextProps } from '../text-interfaces';
import { HelpersUtil } from '../../../utils/helpers.util';

interface IHtmlSemiboldProps extends ITextProps {
	onClick?: () => void;
	extraStyles?: IStyles;
}

// TODO: enum FONT_WEIGHTS is undefined in Styleguiddist
export const HtmlSemiboldView = styled(
	(props: IHtmlSemiboldProps & INativeProps) => {
		const [opacity, setOpacity] = React.useState(1);

		const handleOnPress = () => {
			props.onClick && props.onClick();
			setOpacity(0.5);
			setTimeout(() => {
				setOpacity(1);
			}, 100);
		};

		return (
			<Text
				{...props.nativeTextProps}
				onPress={props.onClick && handleOnPress}
				style={[props.style, { opacity }]}
				suppressHighlighting={!!props.onClick}
			>
				{props.children}
			</Text>
		);
	},
)`
	font-weight: ${HelpersUtil.isIOS()
		? FONT_WEIGHTS.semiBold
		: FONT_WEIGHTS.medium};
	${(props: IHtmlSemiboldProps) => ({
		...props.styles?.text,
		...props.styles?.semibold,
		...props.extraStyles,
	})};
`;
