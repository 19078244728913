import React from 'react';
import { COLOR_CODES } from '../enums/colors';
import { CommentView, IComment, ICommentProps } from './comment.view';

export interface ICommentRightProps extends ICommentProps {
	comment: IComment;
	isLast: boolean;
}

export const CommentRightView = (props: ICommentRightProps) => {
	return (
		<CommentView
			{...props}
			styles={{
				...props.styles,
				wrapper: {
					background:
						props.comment.image && !props.comment.text
							? COLOR_CODES.Transparent
							: COLOR_CODES.Blue,
					borderBottomRightRadius: props.isLast ? 0 : 18,
					marginBottom: props.isLast ? 0 : 5,
					...props.styles?.wrapper,
				},
				text: {
					color: COLOR_CODES.White,
					...props.styles?.text,
				},
			}}
		/>
	);
};
