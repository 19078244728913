import React from 'react';
import { ImageURISource, View } from 'react-native';

import {
	ALIGN,
	DIRECTION,
	JUSTIFY,
	WrapperAlignView,
} from '../../../../wrappers/wrapper-align.view';
import { AccordionItemImageView } from './accordion-item-image.view';
import { CardView } from './accordion-item.view';
import { AccordionTitleView } from './accordion-title.view';

export interface IAccordionMoreProps {
	label: string;
	image: ImageURISource;
	onClick?: () => void;
}

export const AccordionMoreView = (props: IAccordionMoreProps) => {
	return (
		<CardView>
			<View>
				<WrapperAlignView
					itemsDirection={DIRECTION.Horizontal}
					itemsAlign={ALIGN.Center}
					itemsJustify={JUSTIFY.Start}
				>
					<AccordionItemImageView
						source={props.image}
						style={{ height: 58, width: 76 }}
					/>
					<AccordionTitleView wrapperLeft={14}>
						{props.label}
					</AccordionTitleView>
				</WrapperAlignView>
			</View>
		</CardView>
	);
};
