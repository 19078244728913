import { INativeProps } from '@nui/views';
import React from 'react';
import styled from 'styled-components';

import { useOnce } from '@nui/hooks';

interface ICare101ChaptersScrollviewProps {
	onChapterChange: (newChapterIndex: number) => void;
	chapterCount: number;
	viewportWidth: number;
	chapterIndex: number;
	handlers?: (handler: {
		scrollToLastCard: () => void;
		scrollToFirstCard: () => void;
		scrollToCard: (cardIndex: number) => void;
	}) => void;
}

export const Care101ChaptersScrollviewController = styled(
	(props: ICare101ChaptersScrollviewProps & INativeProps) => {
		const statics = React.useRef<{
			chapterIndex: number;
		}>({ chapterIndex: props.chapterIndex }).current;

		React.useEffect(() => {
			props.handlers &&
				props.handlers({
					scrollToLastCard: () => {
						const main = document.querySelector('#chapters-slides');
						const layout = document.querySelector(
							'#layout-center-view',
						);
						if (main && layout) {
							main.scrollLeft =
								props.chapterCount * props.viewportWidth;
							layout.scrollTop = 0;
						}
					},
					scrollToFirstCard: () => {
						const main = document.querySelector('#chapters-slides');
						const layout = document.querySelector(
							'#layout-center-view',
						);
						if (main && layout) {
							main.scrollLeft = 0;
							layout.scrollTop = 0;
						}
					},
					scrollToCard: (cardIndex: number) => {
						const main = document.querySelector('#chapters-slides');
						const layout = document.querySelector(
							'#layout-center-view',
						);
						if (main && layout) {
							main.scrollLeft = cardIndex * props.viewportWidth;
							layout.scrollTop = 0;
						}
					},
				});
		}, [props]);

		useOnce(() => {
			const slides = document.querySelector('#chapters-slides');
			if (slides) {
				const handleScrollEvent = () => {
					const newChapterIndex = Math.ceil(
						slides.scrollLeft / props.viewportWidth,
					);
					if (
						newChapterIndex ==
							slides.scrollLeft / props.viewportWidth &&
						statics.chapterIndex != newChapterIndex
					) {
						statics.chapterIndex = newChapterIndex;
						props.onChapterChange(newChapterIndex);
					}
				};
				slides.scrollLeft = statics.chapterIndex * props.viewportWidth;
				slides.removeEventListener('scroll', handleScrollEvent);
				slides.addEventListener('scroll', handleScrollEvent);
			}
		});

		return (
			<div className={props.className} id="chapters-slides">
				{props.children}
			</div>
		);
	},
)`
	overflow: auto;
	width: 100%;
	max-width: 100vw;
	scroll-snap-type: mandatory;
	scroll-snap-type: x mandatory;
	display: flex;
	flex-direction: row;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
`;
