import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import {
	WrapperAlignView,
	ALIGN,
	IWrapperAlignProps,
	IWrapperAlignStyles,
	JUSTIFY,
	DIRECTION,
} from './wrapper-align.view';
import { ButtonView } from '../buttons/button.view';
import { COLOR_CODES } from '../enums/colors';
import { INativeProps } from './wrapper-interfaces';

export interface IWrapperListStyles extends IWrapperAlignStyles {
	item?: object;
	lastItem?: object;
}
export interface IWrapperListProps extends IWrapperAlignProps {
	borderBetween?: COLOR_CODES | 'none';
	wrapperBetween?: number;
	itemHeight?: number;
	itemMinHeight?: number;
	styles?: IWrapperListStyles;
	onClick?: (index: number) => void;
}

interface IWrapperListItemProps extends IWrapperListProps {
	index: number;
	isLast?: boolean;
}

const Item = styled((props: IWrapperListItemProps & INativeProps) => {
	if (props.onClick) {
		return (
			<ButtonView
				onClick={() => {
					if (props.onClick) {
						props.onClick(props.index);
					}
				}}
			>
				<View style={props.style}>{props.children}</View>
			</ButtonView>
		);
	} else {
		return <View style={props.style}>{props.children}</View>;
	}
})`
	border-bottom-width: ${(props: IWrapperListItemProps) =>
		props.isLast
			? 0
			: (props.itemsDirection == DIRECTION.Vertical &&
					(props.borderBetween === 'none'
						? 0
						: (props.borderBetween && (props.borderWidth || 1)) ||
						  0)) ||
			  0};
	border-right-width: ${(props: IWrapperListItemProps) =>
		props.isLast
			? 0
			: (props.itemsDirection == DIRECTION.Horizontal &&
					(props.borderBetween === 'none'
						? 0
						: (props.borderBetween && (props.borderWidth || 1)) ||
						  0)) ||
			  0};
	border-color: ${(props: IWrapperListItemProps) =>
		props.borderBetween === 'none'
			? COLOR_CODES.Transparent
			: props.borderBetween || COLOR_CODES.Transparent};
	height: ${(props: IWrapperListItemProps) =>
		(props.itemsDirection == DIRECTION.Vertical && props.itemHeight) ||
		'auto'};
	min-height: ${(props: IWrapperListItemProps) =>
		(props.itemsDirection == DIRECTION.Vertical && props.itemMinHeight) ||
		'auto'};
	width: ${(props: IWrapperListItemProps) =>
		(props.itemsDirection == DIRECTION.Horizontal && props.itemHeight) ||
		'auto'};
	min-width: ${(props: IWrapperListItemProps) =>
		(props.itemsDirection == DIRECTION.Horizontal && props.itemMinHeight) ||
		'auto'};
	justify-content: ${JUSTIFY.Center};
	align-items: ${(props: IWrapperListItemProps) =>
		(props.itemsDirection == DIRECTION.Horizontal && ALIGN.Center) ||
		ALIGN.Stretch};
	padding-bottom: ${(props: IWrapperListItemProps) =>
		props.isLast
			? 0
			: (props.itemsDirection == DIRECTION.Vertical &&
					props.wrapperBetween) ||
			  0};
	padding-right: ${(props: IWrapperListItemProps) =>
		props.isLast
			? 0
			: (props.itemsDirection == DIRECTION.Horizontal &&
					props.wrapperBetween) ||
			  0};
	${(props: IWrapperListItemProps) =>
		props.isLast
			? { ...props.styles?.lastItem }
			: { ...props.styles?.item }};
`;

export const WrapperListView = (props: IWrapperListProps & INativeProps) => {
	let count = 0;
	const children = React.Children.toArray(props.children).filter(o => !!o);
	const listProps = { ...{ itemsDirection: DIRECTION.Vertical, ...props } };
	return (
		<WrapperAlignView
			itemsAlign={ALIGN.Stretch}
			itemsJustify={JUSTIFY.Start}
			{...listProps}
		>
			{React.Children.map(children, child => {
				count++;
				return (
					<Item
						isLast={count == children.length}
						{...listProps}
						index={count - 1}
					>
						{child}
					</Item>
				);
			})}
		</WrapperAlignView>
	);
};
