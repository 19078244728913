import React from 'react';
import styled from 'styled-components/native';
import { INativeProps } from '../wrappers/wrapper-interfaces';
import { ITextProps, FONT_WEIGHTS } from './text-interfaces';
import { TextView } from './text.view';

export const TextCaptionBoldView = styled(
	(props: ITextProps & INativeProps) => <TextView {...props} />,
)`
	font-size: 12;
	line-height: 16;
	letter-spacing: 0;
	font-weight: ${(props: ITextProps) => props.weight || FONT_WEIGHTS.bold};
	${(props: ITextProps) => ({
		...props.styles?.text,
	})};
`;
