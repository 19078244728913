import _ from 'lodash';

import { HelpersUtil } from './helpers.util';

export enum SUPPORTED_LANGUAGE_CODES {
	de = 'de',
	en = 'en',
}

export const FALLBACK_LANGUAGE_CODE = 'de';

export interface IContent {
	de: string;
	en: string;
}

export type IHTMLChunks = {
	[tag: string]: {
		text: string;
		attributes?: {
			[attributeName: string]: string;
		};
	};
}[];

export interface IParagraph {
	chunks: IHTMLChunks;
	lineBreak?: boolean;
}

export class Language {
	public static getDefaultLanguageCode(): SUPPORTED_LANGUAGE_CODES {
		return SUPPORTED_LANGUAGE_CODES[FALLBACK_LANGUAGE_CODE];
		/* Deactivate translation to english
		const locales = RNLocalize.getLocales();
		return (
			(locales &&
				locales.length > 0 &&
				SUPPORTED_LANGUAGE_CODES[locales[0].languageCode]) ||
			FALLBACK_LANGUAGE_CODE
		);
		*/
	}

	public static toCurrency(cents: number) {
		const price = (cents / 100).toFixed(2).toString();
		return `${price.replace('.', ',')} €`;
	}

	public static translate(
		content?: IContent,
		code?: SUPPORTED_LANGUAGE_CODES,
		def?: string,
	): string {
		if (!content) {
			return def || '';
		} else {
			code = code || Language.getDefaultLanguageCode();
			return (content && content[code]) || def || '';
		}
	}

	public static replace(
		content: IContent,
		search: string,
		replace?: string | IContent,
	): IContent {
		if (!replace) {
			replace = '';
		}
		const regEx = new RegExp(search, 'g');
		const replacedContent = HelpersUtil.clone(content);
		for (const code in SUPPORTED_LANGUAGE_CODES) {
			replacedContent[code] = replacedContent[code].replace(
				regEx,
				typeof replace === 'string'
					? replace
					: Language.translate(replace),
			);
		}

		return replacedContent;
	}

	public static translateFromBBCode(
		content?: IContent,
		code?: SUPPORTED_LANGUAGE_CODES,
	): IParagraph[] {
		if (content) {
			const regStartOfList = new RegExp(/\[list\](\r\n)?/, 'g');
			const regEndOfList = new RegExp(/\[\/list\]/, 'g');
			const regQuotationMarks = new RegExp(/"/, 'g');
			const regAttributes = new RegExp(/[0-9a-zA-Z]+="[^"]+"/, 'g');

			const paragraphs = Language.translate(content, code)
				.trim()
				.replace(/\t/g, '')
				.split(/\r\n\s?\r\n/);

			return _.flatten(
				paragraphs.map((paragraph, i) => {
					paragraph = paragraph
						.replace(regStartOfList, '')
						.replace(regEndOfList, '')
						.replace(/\[\*(.*?)\](.+?)\r\n/g, '[li$1]$2[/li]')
						.trim();

					const sections = paragraph.split('\r\n');
					return sections.map((section, j) => {
						const chunks: IHTMLChunks = [];
						const tokens = section.split(/\[(.+?)\]/g);
						for (
							let textTokenIndex = 0;
							textTokenIndex < tokens.length;
							textTokenIndex += 2
						) {
							if (tokens[textTokenIndex]) {
								const complexTag =
									textTokenIndex > 0 &&
									!tokens[textTokenIndex - 1].startsWith('/')
										? tokens[textTokenIndex - 1]
										: 'span';

								const [tag] = complexTag.split(/ +/);
								let attributes =
									complexTag.match(regAttributes) || [];

								const attributesObject = {};
								for (const h in attributes) {
									const [key, value] = attributes[h]
										.trim()
										.split('=');
									attributesObject[key] = value.replace(
										regQuotationMarks,
										'',
									);
								}

								// Case when [tag=] ...
								const tagKey = tag.trim().split(/=(.+)/)[0];
								const tagValue = tag.trim().split(/=(.+)/)[1];

								if (tagValue) {
									attributesObject[tagKey] = tagValue.replace(
										regQuotationMarks,
										'',
									);
								}

								chunks.push({
									[tagKey]: {
										text: tokens[textTokenIndex],
										attributes: attributesObject,
									},
								});
							}
						}
						return {
							lineBreak:
								i < paragraphs.length - 1 &&
								j == sections.length - 1,
							chunks,
						};
					});
				}),
			);
		} else {
			return [];
		}
	}
}
