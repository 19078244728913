import React from 'react';
import { COLOR_CODES } from '../enums/colors';
import { ICON_CODES } from '../texts/text-icon.view';
import {
	ButtonRoundedView,
	BUTTON_ROUNDED_SIZES,
	IButtonRoundedProps,
	IButtonRoundedStyles,
} from './button-rounded.view';

export interface IButtonCheckStyles extends IButtonRoundedStyles {
	containerUnchecked?: object;
}

export interface IButtonCheckProps
	extends Omit<IButtonRoundedProps, 'onClick' | 'size'> {
	onClick: (checked: boolean) => void;
	styles?: IButtonCheckStyles;
	size?: BUTTON_ROUNDED_SIZES;
}
export interface IButtonCheckState {
	checked: boolean;
}

export class ButtonCheckView extends React.PureComponent<
	IButtonCheckProps,
	IButtonCheckState
> {
	constructor(props) {
		super(props);
		this.state = {
			checked: false,
		};
		this.handleCheck = this.handleCheck.bind(this);
	}

	handleCheck() {
		const checked = !this.state.checked;
		this.setState({
			checked,
		});
		this.props.onClick(checked);
	}

	render() {
		if (this.state.checked) {
			const props = {
				...this.props,
				onClick: this.handleCheck,
				size: this.props.size || BUTTON_ROUNDED_SIZES.xs,
				icon: ICON_CODES.Checkmark,
			};
			return <ButtonRoundedView {...props} />;
		} else {
			const uncheckedProps = {
				...this.props,
				onClick: this.handleCheck,
				size: this.props.size || BUTTON_ROUNDED_SIZES.xs,
				styles: {
					...this.props.styles,
					container: {
						backgroundColor: COLOR_CODES.Transparent,
						borderWidth: 1,
						borderColor: COLOR_CODES.Blue,
						...((this.props.styles &&
							this.props.styles.containerUnchecked) ||
							{}),
					},
				},
			};
			return <ButtonRoundedView {...uncheckedProps} />;
		}
	}
}
