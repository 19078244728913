import React from 'react';
import { Switch } from 'react-native';
import { COLOR_CODES } from '../enums/colors';

export interface ISwitchProps {
	checked?: boolean;
	onChange?: (checked: boolean) => void;
	disabled?: boolean;
}

export const SwitchView = (props: ISwitchProps) => {
	const handleSwitch = () => {
		if (props.onChange) {
			props.onChange(!props.checked);
		}
	};
	return (
		<Switch
			value={props.checked}
			onValueChange={handleSwitch}
			trackColor={{ false: COLOR_CODES.Grey25, true: COLOR_CODES.Pink }}
			thumbColor={COLOR_CODES.White}
			disabled={props.disabled}
			{...({ activeThumbColor: COLOR_CODES.White } as any)}
		/>
	);
};
