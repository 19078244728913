import { Language } from '@nui/utils';
import { stateModel } from '../../../globals';
import {
	IPagePflege1x1TopicProps,
	PagePflege1x1TopicView,
} from '../../views/pages/page-care101-topic.view';
import React from 'react';
import content from './care101-topic.content';
import { useHistory, useLocation } from 'react-router-dom';

interface ITopicStartPageProps {
	courseId: string;
	topicId: string;
}

interface ITopicStartPageRequest {
	redirect: string;
}

export const TopicStartPageSubcontroller = (props: ITopicStartPageProps) => {
	const { courseId, topicId } = props;
	const topic = stateModel.stateCoursesModel.getTopic(courseId, topicId);

	const history = useHistory();

	// NavigationScreenProp
	let location = useLocation<ITopicStartPageRequest | undefined>();

	const handleClick = () => {
		history.push(
			`/care101s/${courseId}/topics/${topicId}/chapters`,
			location.state,
		);
	};

	if (topic) {
		const PagePflege1x1TopicTransfer: IPagePflege1x1TopicProps = {
			moduleIntro: {
				title: Language.translate(topic.title),
				courseLength:
					topic.chapterCount +
					' ' +
					Language.translate(content.moduleIntro.courseLength),

				courseIntro: Language.translateFromBBCode(
					Language.replace(
						topic.intro,
						'{senior}',
						stateModel.stateTeamsModel.getSenior()?.firstName || '',
					),
				),
			},
			autosize: true,
			buttonLabel: Language.translate(content.startButtonLabel),
			icon: 'IconGo',
			onClick: handleClick,
		};
		return <PagePflege1x1TopicView {...PagePflege1x1TopicTransfer} />;
	} else {
		return null;
	}
};
