import { StateModel } from '@nui/models';

export interface ILocation<State> {
	pathname: string;
	state: State;
}

export const stateModel = new StateModel({
	courses: {},
	assistant: {},
	teams: {},
	loading: true,
});
