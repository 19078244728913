import React, { useState } from 'react';
import { Shake } from 'react-native-motion';
import { ButtonView } from '../buttons/button.view';
import { COLOR_CODES } from '../enums/colors';
import {
	IProgressBarAvatarProps,
	ProgressBarAvatar,
} from '../fallbacks/progress-bar-avatar.view';
import { TextBodySemiboldLeftView } from '../texts/text-body-semibold-left.view';
import { ICON_CODES, TextIconView } from '../texts/text-icon.view';
import { TextSubheadlineSemiboldSteelgreyView } from '../texts/text-subheadline-semibold-steelgrey.view';
import {
	ALIGN,
	DIRECTION,
	WrapperAlignView,
} from '../wrappers/wrapper-align.view';

export interface ITopicProps {
	avatar: IProgressBarAvatarProps;
	name: string;
	chapterCountLabel: string;
	locked?: boolean;
	disabled?: boolean;
	onClick?: () => void;
}

export const TopicView = (props: ITopicProps) => {
	const {
		avatar,
		name,
		chapterCountLabel,
		locked,
		disabled,
		onClick,
	} = props;
	const [value, setValue] = useState(0);

	const startAnimation = () => {
		setValue(value + 1);
	};

	return (
		<ButtonView
			onClick={() => (disabled ? startAnimation() : onClick && onClick())}
		>
			<Shake value={value} type="timing">
				<WrapperAlignView>
					<WrapperAlignView styles={{ wrapper: { width: '100%' } }}>
						<ProgressBarAvatar
							image={avatar.image}
							percent={locked ? 0 : avatar.percent}
							disabled={disabled}
						/>
						<WrapperAlignView
							itemsDirection={DIRECTION.Vertical}
							itemsAlign={ALIGN.Start}
							wrapperLeft={10}
							// TODO: check the width of the component
							// maxWidth={width}
							styles={{
								wrapper: { flex: 1 },
							}}
						>
							<TextBodySemiboldLeftView>
								{name}
							</TextBodySemiboldLeftView>
							{(chapterCountLabel && (
								<TextSubheadlineSemiboldSteelgreyView>
									{chapterCountLabel}
								</TextSubheadlineSemiboldSteelgreyView>
							)) ||
								null}
						</WrapperAlignView>
						{locked && (
							<TextIconView
								icon={ICON_CODES.Locked}
								styles={{
									text: {
										color: COLOR_CODES.SteelGrey,
									},
								}}
							/>
						)}
					</WrapperAlignView>
				</WrapperAlignView>
			</Shake>
		</ButtonView>
	);
};
