import React from 'react';
import { Image, ImageURISource } from 'react-native';

import { SVGAvatarPhotoCameraView } from '../fallbacks/svg-avatar-photo-camera.view';
import { SVGAvatarWomanView } from '../fallbacks/svg-avatar-woman.view';
import { SVGAvatarView } from '../fallbacks/svg-avatar.view';
import { SVGAvatarGroupView } from '../fallbacks/svg-avatar-group.view';

export enum AvatarSVGTypes {
	PHOTO_CAMERA,
	DEFAULT,
	WOMAN,
	GROUP,
}

export enum AVATAR_RADIUS {
	xs = 18,
	sm = 20,
	md = 24,
	lg = 30,
	xl = 60,
}

export interface IAvatarProps {
	radius: AVATAR_RADIUS;
	image?: ImageURISource;
	avatarType?: AvatarSVGTypes;
}

export const AvatarView = (props: IAvatarProps) => {
	const { radius, image, avatarType } = props;
	if (image) {
		return (
			<Image
				source={image}
				style={{
					width: radius * 2,
					height: radius * 2,
					resizeMode: 'cover',
					borderRadius: radius,
				}}
			/>
		);
	} else {
		if (avatarType === AvatarSVGTypes.PHOTO_CAMERA) {
			return <SVGAvatarPhotoCameraView radius={radius} />;
		} else if (avatarType === AvatarSVGTypes.WOMAN) {
			return <SVGAvatarWomanView radius={radius} />;
		} else if (avatarType === AvatarSVGTypes.GROUP) {
			return <SVGAvatarGroupView radius={radius} />;
		} else {
			return <SVGAvatarView radius={radius} />;
		}
	}
};
