import XDate from 'xdate';
import { ConvertersUtil } from '@nui/utils';

export interface IFirestoreTeamMember {
	accepted?: XDate;
	invited?: XDate;
	phone: string;
	name: string;
}

export class FirestoreTeamMemberModel {
	static toTeamMember(teamMemberDTO: any): IFirestoreTeamMember | undefined {
		const invited = ConvertersUtil.toXDate(teamMemberDTO.invited);
		const accepted = ConvertersUtil.toXDate(teamMemberDTO.accepted);
		if (!invited && !accepted) {
			return undefined;
		} else {
			if (accepted) {
				return {
					accepted,
					invited,
					phone: ConvertersUtil.toString(teamMemberDTO.phone) || '?',
					name: ConvertersUtil.toString(teamMemberDTO.name) || '?',
				};
			} else {
				return {
					invited,
					phone: ConvertersUtil.toString(teamMemberDTO.phone) || '?',
					name: ConvertersUtil.toString(teamMemberDTO.name) || '?',
				};
			}
		}
	}
}
