import { COLOR_CODES } from '../../../enums/colors';
import React from 'react';
import { View } from 'react-native';

import { INativeProps } from '../../../wrappers/wrapper-interfaces';
import {
	ITextMessageProps,
	TextMessageTextView,
} from './text-message-text.view';

export const TextMessageLeftView = React.memo(
	(props: ITextMessageProps & INativeProps) => {
		return (
			<View style={props.style}>
				{props.message.map((paragraph, index) => (
					<TextMessageTextView
						key={index}
						htmlChunks={paragraph.chunks}
						color={COLOR_CODES.Grey75}
					/>
				))}
			</View>
		);
	},
	() => true,
);
