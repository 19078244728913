import React from 'react';
import { ImageURISource, View } from 'react-native';
import {
	CourseCardView,
	TextTitleTitle2View,
	TextBodyDefaultView,
} from '@nui/views';
import { IContent, Language } from '@nui/utils';
import { stateModel } from '../../../../globals';
import { useWebViewportWidth } from '@nui/hooks';

const CARD_MARGIN = 15;
const CONTENT_PADDING = 35;
const PARAGRAPH_PADDING = 8;

interface IChaptersChapterProps {
	courseId: string;
	topicId: string;
	chapterId: string;
}

const renderContent = (
	title: IContent,
	paragraphs: IContent,
	topicImageHeight: number,
	image: (name: string) => () => Promise<ImageURISource> | ImageURISource,
	viewportWidth: number,
) => {
	return (
		<View
			style={{
				paddingLeft: 0,
				paddingRight: 0,
				paddingBottom: CONTENT_PADDING - PARAGRAPH_PADDING,
				paddingTop: topicImageHeight + CARD_MARGIN + 10,
			}}
		>
			<TextTitleTitle2View
				wrapperBottom={PARAGRAPH_PADDING}
				wrapperLeft={CONTENT_PADDING}
				wrapperRight={CONTENT_PADDING}
				width={viewportWidth}
				styles={{ wrapper: { maxWidth: viewportWidth } }}
			>
				{Language.translate(title)}
			</TextTitleTitle2View>
			{Language.translateFromBBCode(paragraphs).map(
				(paragraph, index) => {
					return (
						<TextBodyDefaultView
							wrapperBottom={(paragraph.lineBreak && 12) || 0}
							wrapperLeft={CONTENT_PADDING}
							wrapperRight={CONTENT_PADDING}
							width={viewportWidth}
							key={index}
							htmlChunks={paragraph.chunks}
							image={image}
							styles={{ wrapper: { maxWidth: viewportWidth } }}
						/>
					);
				},
			)}
		</View>
	);
};

export const ChaptersChapterController = (props: IChaptersChapterProps) => {
	const viewportWidth = useWebViewportWidth('#layout-center-view');

	if (viewportWidth) {
		const topicImageHeight = Math.round(viewportWidth * (115 / 335));
		const senior = stateModel.stateTeamsModel.getSenior();
		const chapter = stateModel.stateCoursesModel.getChapter(
			props.courseId,
			props.topicId,
			props.chapterId,
		);
		return (
			<View style={{ width: '100%' }}>
				<CourseCardView
					cardMargin={CARD_MARGIN}
					cardWidth="100%"
					styles={{
						wrapper: {
							position: 'absolute',
							top: 0,
							padding: CARD_MARGIN,
							left: 0,
							width: '100%',
							bottom: 0,
							maxWidth: viewportWidth,
						},
					}}
					image={stateModel.stateCoursesModel.getChapterImage(
						props.courseId,
						props.topicId,
					)}
					imageHeight={topicImageHeight}
				/>
				{(chapter &&
					renderContent(
						Language.replace(
							chapter.title,
							'{senior}',
							senior?.firstName,
						),
						Language.replace(
							chapter.content,
							'{senior}',
							senior?.firstName,
						),
						topicImageHeight,
						name => {
							return stateModel.stateCoursesModel.getContentImage(
								props.courseId,
								props.topicId,
								props.chapterId,
								name,
							);
						},
						viewportWidth,
					)) ||
					null}
			</View>
		);
	} else {
		return null;
	}
};
