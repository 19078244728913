import {
	ButtonPrimaryView,
	ICON_CODES,
	IModuleIntro,
	ModuleIntro,
	WrapperView,
	TextCaptionDefaultView,
	TEXT_ALIGN,
	FONT_WEIGHTS,
} from '@nui/views';
import React from 'react';

export interface IPagePflege1x1TopicProps {
	moduleIntro: IModuleIntro;
	buttonLabel: string;
	onClick?: () => void;
	icon?: string;
	payInApp?: string;
	autosize?: boolean;
}

export const PagePflege1x1TopicView = (props: IPagePflege1x1TopicProps) => {
	const {
		moduleIntro,
		buttonLabel,
		onClick,
		icon,
		payInApp,
		autosize,
	} = props;

	return (
		<WrapperView wrapperLeft={20} wrapperRight={20} wrapperTop={20}>
			<ModuleIntro {...moduleIntro} wrapperBottom={10} />
			<ButtonPrimaryView
				rounded={true}
				label={buttonLabel}
				autosize={autosize}
				styles={{
					container: {
						marginLeft: 5,
						marginRight: 5,
						marginBottom: 25,
					},
				}}
				iconBefore={icon && ICON_CODES[icon]}
				onClick={onClick}
			/>
			<TextCaptionDefaultView
				textAlign={TEXT_ALIGN.center}
				weight={FONT_WEIGHTS.bold}
			>
				{payInApp && payInApp}
			</TextCaptionDefaultView>
		</WrapperView>
	);
};
