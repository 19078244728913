import React from 'react';
import styled from 'styled-components/native';
import { INativeProps } from '../wrappers/wrapper-interfaces';
import { ITextProps } from './text-interfaces';
import { TextView } from './text.view';

export const TextSubtitleView = styled((props: ITextProps & INativeProps) => (
	<TextView {...props} />
))`
	font-size: 15;
	letter-spacing: -0.24;
	line-height: 20;
	${(props: ITextProps) => ({
		...props.styles?.text,
	})};
`;
