import { ConvertersUtil } from '@nui/utils';

export interface IFirestoreSystem {
	requiredAppVersion: string;
	maintenance?: boolean;
}

export class FirestoreSystemModel {
	static toSystem(systemDTO: any): IFirestoreSystem | undefined {
		const requiredAppVersion = ConvertersUtil.toString(
			systemDTO?.requiredAppVersion,
			undefined,
			1,
		);
		const maintenance = ConvertersUtil.toBool(systemDTO?.maintenance);
		if (requiredAppVersion) {
			return { requiredAppVersion, maintenance };
		} else {
			return undefined;
		}
	}
}
