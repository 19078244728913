import XDate from 'xdate';
import { ConvertersUtil } from '@nui/utils';

export interface IFirestoreUserTeamInvitation {
	invited: XDate;
	invitedBy: {
		userId: string;
		name: string;
		seniorFirstname: string;
	};
}

export class FirestoreUserTeamInvitationModel {
	static toUserTeamInvitation(
		userTesmInvitationDTO: any,
		defaultXDate = new XDate(),
	): IFirestoreUserTeamInvitation | undefined {
		/* Invited should be a number, otherwise converted to dafaultDate  */
		const invited =
			ConvertersUtil.toXDate(userTesmInvitationDTO?.invited) ||
			defaultXDate;
		/* UserId should be a string and at least one character, otherwise invitation is not valid */
		const userId = ConvertersUtil.toString(
			userTesmInvitationDTO?.invitedBy?.userId,
			undefined,
			1,
		);
		/* InviterName should be a string and at least one character, otherwise invitation is not valid */
		const inviterName =
			ConvertersUtil.toString(userTesmInvitationDTO?.invitedBy?.name) ||
			'?';
		/* Senior firstname should be a string or "?" */
		const seniorFirstname =
			ConvertersUtil.toString(
				userTesmInvitationDTO?.invitedBy?.seniorFirstname,
			) || '?';

		if (userId) {
			return {
				invited,
				invitedBy: {
					userId,
					name: inviterName,
					seniorFirstname,
				},
			};
		} else {
			return undefined;
		}
	}
}
