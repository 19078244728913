import React from 'react';
import { ImageURISource } from 'react-native';
import { useIsMounted } from './use-is-mounted.hook';

export const useImageURI = (
	image: () => Promise<ImageURISource> | ImageURISource,
): ImageURISource | undefined => {
	const isMounted = useIsMounted();

	const imageURISource = image();
	const [imageURL, setImageURL] = React.useState<undefined | ImageURISource>(
		imageURISource instanceof Promise ? undefined : imageURISource,
	);

	React.useEffect(() => {
		if (imageURISource instanceof Promise) {
			imageURISource.then(uri => {
				if (!imageURL && isMounted()) {
					setImageURL(uri);
				}
			});
		}
	}, [imageURISource, imageURL, imageURL?.uri, isMounted]);

	if (imageURISource instanceof Promise) {
		return imageURL;
	} else {
		return imageURISource;
	}
};
